// Import the functions you need from the SDKs you need

import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBsAKsuXbFjDqfAEGgpfgsEK0z7UPd-OJE",
  authDomain: "mobilemasr-testing.firebaseapp.com",
  databaseURL: "https://mobilemasr-testing-default-rtdb.firebaseio.com",
  projectId: "mobilemasr-testing",
  storageBucket: "mobilemasr-testing.appspot.com",
  messagingSenderId: "707115540973",
  appId: "1:707115540973:android:f6703632c869e65132c7e4"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Get a reference to the database service
const db = getDatabase(firebaseApp);

export default db;