<template>
    <div>
        <b-col cols="12">
            <app-collapse id="faq-payment-qna" type="margin" class="mt-0 mb-0">
                <!-- Change Processing PaymentSession Status To Cancled -->
                <app-collapse-item title="Store Success Transaction For Payment Session" key="StorePaymentSessionTransaction">
                    <b-form @submit.stop.prevent>
                        <b-row>
                            <!-- PaymentSession ID -->
                            <b-col md="7">
                                <validation-observer ref="storeSuccessTransactionForPaymentSession">

                                    <b-row>
                                        <b-col md="4">
                                            <b-form-group label="Payment Session Id" label-for="payment_session_id">
                                                <validation-provider #default="{ errors }" name="payment_session_id" rules="required">
                                                    <b-form-input id="payment_session_id" type="text" v-model="paymentSessionId"
                                                        :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">
                                                        {{ errors[0] }}
                                                    </small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                        <b-form-group label="Transaction Ref" label-for="transaction_ref">
                                            <validation-provider #default="{ errors }" name="transaction_ref" rules="required">
                                                <b-form-input id="transaction_ref" type="text" v-model="tranactionRef"
                                                    :state="errors.length > 0 ? false : null" />
                                                <small class="text-danger">
                                                    {{ errors[0] }}
                                                </small>
                                            </validation-provider>
                                        </b-form-group>
                                        </b-col>
                                    </b-row>


                                </validation-observer>
                            </b-col>
                            <!-- submit button -->
                            <b-col md="6">
                                <b-button @click="changeProcessingToCancelModel" size="lg" variant="primary" type="submit">
                                    Submit
                                </b-button>
                            </b-col>
                            <b-modal ref="store-paymentSession-transaction-modal-validator" cancel-variant="outline-secondary"
                                @ok="storePaymentSessionTransaction()" ok-title="Ok" cancel-title="Close" centered
                                title="Validate Action">
                                <b-row>
                                    <b-col cols="12" class="my-2">
                                        please enter the password to validate the request action
                                    </b-col>
                                    <b-col cols="12">
                                        <b-form-group label="Password" label-for="Password">
                                            <b-form-input v-model="password" type="password" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-modal>
                        </b-row>
                    </b-form>
                </app-collapse-item>
            </app-collapse>
        </b-col>
    </div>
</template>
<script >
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { required } from '@validations'
export default {
    components: {
        AppCollapse,
        AppCollapseItem,
    },
    data() {
        return {
            required,
            paymentSessionId: null,
            password: null,
            tranactionRef: null,
        }
    },
    methods: {
        // change processing PaymentSession to cancel status
        async storePaymentSessionTransaction() {
            let errors_back = []
            let showDismissibleAlert = false
            await axios
                .post('payment-sessions/' + this.paymentSessionId +'/transactions/' +this.tranactionRef + '/updatefailedPaymentSession', { 'password': this.password })
                .then((result) => {
                    const data = result.data.data.data

                    this.$swal({
                        position: 'center',
                        icon: 'success',
                        title: 'Store Success Transaction For PaymentSession',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                })
                .catch((err) => {
                    // console.log(err)
                    showDismissibleAlert = true

                    if (err.response.data.data != null) {
                        if (
                            this.$store.dispatch('Errors/isString', err.response.data.data)
                        ) {
                            errors_back.push({
                                error: err.response.data.data,
                            })
                        } else {
                            errors_back = err.response.data.data
                        }
                    } else {
                        errors_back.push({
                            error: 'internal server error',
                        })
                    }

                    this.$store.commit('Errors/setErrorsBack', errors_back)
                    this.$store.commit(
                        'Errors/setShowDisnissaibleAlert',
                        showDismissibleAlert
                    )
                })
        },
        changeProcessingToCancelModel() {
            this.$refs.storeSuccessTransactionForPaymentSession.validate().then((success) => {
                if (success) {
                    this.$refs['store-paymentSession-transaction-modal-validator'].show()
                } else {
                    this.$swal({
                        position: 'center',
                        icon: 'error',
                        title: 'Please Enter The Required Data',
                        showConfirmButton: false,
                        timer: 1400,
                    })
                }
            })
        },
    },
    created() {
        this.authPermissions = this.$store.state.authPermissions
    },
    beforeDestroy() {
        this.$store.commit('Errors/setErrorsBack', [])
        this.$store.commit('Errors/setShowDisnissaibleAlert', false)
    },
}
</script>
  