<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , indexVal) in values" :key="indexVal">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-card>
      <b-row>
        <!-- page length -->
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <!-- sorting -->
        <b-col md="3" sm="6" class="mt-1">
          <b-form-group
            label="Sort"
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-25"
              >
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="3" class="mt-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="searchData">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- add new role button -->
        <b-col md="2" class="my-1">
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              style="height: 2.142rem; line-height: 0.5;"
              v-if="authPermissions.includes('create')"
              variant="primary"
              :to="{ path: '/Vendor/Pricing/Add/' + this.id }"
            >
              <span class="text-nowrap">Add Margin </span>
            </b-button>
          </div>
        </b-col>
        <!-- start data table -->
        <b-col cols="12">
          <b-table
            id="roleTable"
            striped
            hover
            responsive
            show-empty
            :items="items"
            :fields="fields"
            
          >
            <template #cell(service_type)="data">
              <b-badge variant="warning">{{ data.value }}</b-badge>
            </template>
            <template #cell(active)="data">
              <b-badge variant="primary">{{ data.value }}</b-badge>
            </template>

            <template #cell(is_portal)="data">
              <b-badge variant="info" v-if="data.value"> Portal</b-badge>
              <b-badge variant="info" v-else> InStore</b-badge>
            </template>

            <template #cell(rate)="data">
              <p v-if="data.item.type == 'percentage'">
                {{ data.value }}  %
              </p>
              <p v-else>
                {{ data.value }}  EGP
              </p>
            </template>
            <template #cell(actions)="items">
              <div class="demo-inline-spacing">
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px;"
                  v-if="authPermissions.includes('update')"
                  :to="{
                    path: '/Vendor/Pricing/update/' + items.item.id,
                  }"
                  variant="warning"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon
                    icon="EditIcon"
                    style="width: 18px; height: 18px;"
                  />
                </b-button>
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px;"
                  v-if="authPermissions.includes('delete')"
                  @click="deleteData(items.item.id)"
                  variant="danger"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    style="width: 18px; height: 18px;"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <div>
          <!-- start pagination -->
          <b-pagination
            aria-controls="roleTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

export default {
  data() {
    return {
      id: 0,
      authPermissions: [],
      showDismissibleAlert: false,
      errors_back: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      isBusy: false,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      // table fields
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'active',
          label: 'Active',
        },
        {
          key: 'service_type',
          label: 'Product Type',
        },
        {
          key: 'is_portal',
          label: 'Sales Channel',
        },
        {
          key: 'type',
          label: 'type',
        },
        {
          key: 'from',
          label: 'from',
          sortable: true,
        },
        {
          key: 'to',
          label: 'to',
          sortable: true,
        },
        {
          key: 'rate',
          label: 'rate',
          sortable: true,
        },
        {
          key: 'PaymentProvider.name',
          label: 'Payment Method',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      items: [],
      // permissions:[]
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    this.id = this.$route.params.id
    this.fetchData()
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.items.length
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  watch: {
    sortDesc: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    sortBy: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    filter: function () {
      if (this.filter == '' || this.filter == null) {
        this.fetchData()
      }
    },
  },
  methods: {
    
    searchData() {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    fetchData() {
      // request roles index
      axios
        .get('vendors/price-rates/' + this.id + '/index')
        .then((result) => {
          //console.log(result)
          const data = result.data.data

          this.items = []
          this.items = result.data.data
     
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    // delete function to delete role
    deleteData(item) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete price rate.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            const url = this.$session.get('lang')
            axios
              .delete('vendors/price-rates/' + item)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
