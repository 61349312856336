<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <!-- start data table -->
    <b-card title="Transaction">
      <b-row>
        <!-- sorting Status -->
        <b-col md="2" class="my-1">
          <b-form-group
            label="Status"
            label-cols-sm="4"
            label-align-sm="right"
            label-size="sm"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-model="statusName"
              class="border mr-0"
              size="sm"
              :text="statusName"
              variant="flat-primary"
            >
              <b-dropdown-item
                v-for="(item, index) in paymentStatus"
                :key="index"
                @click="statusName = item"
              >
                {{ item }}
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- sorting Transaction Type -->
        <b-col md="3" class="my-1">
          <b-form-group
            label="Transaction Type"
            label-cols-sm="6"
            label-align-sm="right"
            label-size="sm"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-model="transactionTypeName"
              class="border mr-0"
              size="sm"
              :text="transactionTypeName"
              variant="flat-primary"
            >
              <b-dropdown-item
                v-for="(item, index) in transactionTypes"
                :key="index"
                @click="transactionTypeName = item"
              >
                {{ item }}
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- sorting -->
        <b-col md="3" class="my-1">
          <b-form-group
            label="Sort By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              ></b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="3" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="searchData">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height: 2.142rem; line-height: 0.5"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content>
                <feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" />
                  Download Excel
                </b-dropdown-item>
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" />
                Save PDF
              </b-dropdown-item>
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>

        <!-- actions -->
        <b-col cols="12">
          <b-table
            id="TransactionsTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            :busy.sync="isBusy"
            class="position-relative"
            :items="items"
            :fields="fields"
          >

          <template #cell(id)="data">
              <span  v-b-popover.hover.top.click="''" :id="'badge'+data.value" @click="hidePopover(data.value)">{{ data.value }}</span>  

              <b-popover    :id="'badge'+data.value" :target="'badge'+data.value" placement="top" triggers="hover click" custom-class="popover-custom">
                <template >
                  <div @click="copyText(data.value)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                </template>
              </b-popover>
          </template>

          <template #cell(code)="data">
              <span  v-b-popover.hover.top.click="''" :id="'badge'+data.value" @click="hidePopover(data.value)">{{ data.value }}</span>  

              <b-popover    :id="'badge'+data.value" :target="'badge'+data.value" placement="top" triggers="hover click" custom-class="popover-custom">
                <template >
                  <div @click="copyText(data.value)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                </template>
              </b-popover>
          </template>


          
        <template #cell(transaction_ref)="data">
              <span  v-b-popover.hover.top.click="''" :id="'badge'+data.value" @click="hidePopover(data.value)">{{ data.value }}</span>  

              <b-popover    :id="'badge'+data.value" :target="'badge'+data.value" placement="top" triggers="hover click" custom-class="popover-custom">
                <template >
                  <div @click="copyText(data.value)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                </template>
              </b-popover>
          </template>


            <template #cell(payment_provider_logo)="data">
              <!-- <b-avatar :src="data.value" /> -->

              <b-img-lazy
                v-if="data.value"
                width="40"
                :src="data.value"
              />
              <p v-else>
                <b-badge variant="light-primary">{{
                  data.item.payment_provider_name
                }}</b-badge>
              </p>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="data.value == 'success' ? 'success' : (data.value == 'failed' ? 'danger' : 'secondary')">{{ data.value }}</b-badge>
            </template>
            <template #cell(transaction_sale_type)="data">
              <b-badge variant="light-primary" v-if="data.item.cart_id">Order</b-badge>
              <b-badge variant="light-primary" v-if="data.item.subscription_id">Subscription</b-badge>
              <b-badge variant="light-primary" v-if="data.item.payment_session_id">Payment Session</b-badge>
            </template>
          
            
            <template #cell(transaction_type)="data">
              <b-badge :variant="data.value == 'sale' ? 'light-success' : 'light-warning'">{{ data.value }}</b-badge>
            </template>
            
            <template #cell(actions)="items">
              <div class="demo-inline-spacing">
                <!-- show payout -->
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                  class="btn-icon rounded-circle"
                  v-if="authPermissions.includes('show')"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  :to="{ path: '/transaction/show/' + items.item.id }"
                >
                  <feather-icon
                    icon="EyeIcon"
                    style="width: 18px; height: 18px"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="TransactionsTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      authPermissions: [],
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      // table fildes
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'code',
          label: 'Code',
          sortable: true,
        },
        {
          key: 'status',
          label: 'status',
        },
        {
          key: 'transaction_ref',
          label: 'transaction ref',
        },
        {
          key: 'transaction_sale_type',
          label: 'Transaction Sale Type',
        },
        // {
        //   key: 'customer_name',
        //   label: 'customer name'
        // },

        // {
        //   key: 'customer_data',
        //   label: 'customer (email/phone)'
        // },

        {
          key: 'transaction_type',
          label: 'transaction type',
        },
        {
          key: 'payment_provider_logo',
          label: 'payment method',
        },
        {
          key: 'amount',
          label: 'amount',
        },
        {
          key: 'remaining_amount',
          label: 'remaining amount',
        },

        {
          key: 'created_at',
          label: 'created at',
        },
        {
          key: 'updated_at',
          label: 'updated at',
        },
        {
          key: 'actions',
        },
      ],
      items: [],
      exportItems: [],
      statusName: 'all',
      transactionTypeName: 'all',
      paymentStatus: ['all', 'success', 'canceled', 'failed'],
      transactionTypes: ['all', 'order', 'payment_session', 'subscription'],
      // permissions:[]
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    // request Coupons index
    this.fetchData()
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
  },
  watch: {
    sortDesc: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    sortBy: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    statusName: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    transactionTypeName: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    filter: function () {
      if (this.filter == '' || this.filter == null) {
        this.fetchData()
      }
    },
  },
  methods: {
    searchData() {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    // fetch data of Coupons
    fetchData() {
      var url = null
      if (this.filter == '') {
        url =
          'transactions/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.statusName +
          '/' +
          this.transactionTypeName +
          '?page=' +
          this.currentPage
      } else {
        url =
          'transactions/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.statusName +
          '/' +
          this.transactionTypeName +
          '/' +
          this.filter +
          '?page=' +
          this.currentPage
      }
      const promise = axios.get(url)
      return promise
        .then((result) => {
          const data = result.data.data.data
          this.totalRows = result.data.data.meta.total
          this.items.length = 0

          this.items = data

          this.exportItems = this.items
          this.$refs.admint.refresh()
          return this.items
        })
        .catch((err) => {
          //console.log(err)
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    // search function

    printEle() {
      window.print()
    },
    copyText(value) {
      navigator.clipboard.writeText(value)
        .then(() => {
          this.$bvToast.toast('copied to clipboard!', {
            title: 'Success',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          });
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
          this.$bvToast.toast('Failed to copy IMEI.', {
            title: 'Error',
            variant: 'danger',
            solid: true
          });
        });
    },
    hidePopover(PopId) {
      this.$root.$emit('bv::hide::popover', `badge${PopId}`);
    },
    genPDF() {
      const { jsPDF } = require('jspdf')
      const doc = new jsPDF()
      let print_body = []
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].vendor,
          this.items[index].status,
          this.items[index].type,
          this.items[index].rate,
          this.items[index].price,
        ]
      }
      doc.autoTable({
        head: [['ID', 'Vendor', 'Status', 'Type', 'Rate', 'Price']],
        body: print_body,
      })
      doc.save('table.pdf')
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
