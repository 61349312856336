<template>
    <div>
      <!-- error handelr -->
      <b-alert
        v-height-fade.appear
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert = false"
        variant="danger"
      >
        <h4 class="alert-heading">Alert</h4>
        <div class="alert-body">
          <ul v-for="values in errors_back" :key="values">
            <li v-for="value in values" :key="value">{{ value }}</li>
          </ul>
        </div>
      </b-alert>
      <b-card title="Installment Plans">
        <b-row>

          <b-button
                variant="primary"
                size="sm"
                  style="padding: 7px; margin: 6px; height: 35px;"
                  class="m-2"
                @click="ExportExcelPlansFile()"
              >
                <feather-icon icon="DownloadIcon" class="mr-25" />
                <span>Export Plans File</span>
            </b-button>

          <!-- table -->
          <b-col cols="12">
            <b-table
              id="attributesTable"
              ref="values"
              striped
              hover
              responsive
              class="position-relative"
              :items="items"
              :fields="fields"
            >
            <template #cell(active)="data">
                <b-badge :variant="data.value ?'primary' : 'warning'" >
                  {{ data.value }}
                </b-badge>
              </template>

              <template #cell(installment_plans)="data">

              <!-- <span v-if="data.value != ''">
              {{ JSON.parse(data.value)}}
            </span> -->
                <!-- <b-badge :variant="data.value ?'primary' : 'warning'" >
                  {{ JSON.parse(data.value) }}
                </b-badge>-->
                <div v-if="data.value != ''">
                  <!-- <span     v-for="(month, ind) in  JSON.parse(data.value)" > {{ month }}  </span>  -->
                  <div v-for="(months, ind) in JSON.parse(data.value)" :key="ind">
                    <!-- {{ month[0] }} -->
                    <b-badge v-for="(month, ind) in months" variant="primary"  style="padding: 8px;" class="my-1  w-50  d-flex justify-content-center">
                      {{ month.months }} months - {{ month.interest_rate }}% interest - {{ month.fees_type }} 
                      <br>
                    </b-badge>
                  </div>
                </div>

              </template>

              
              <template #cell(actions)="items">
                <!-- edit button value -->
                <b-button
                  v-if="authPermissions.includes('update')"
                  class="mr-1"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  @click="updatePaymentProviderModal(items.item)"
                  v-b-modal.modal-prevent-closing
                  variant="warning"
                >
                  <feather-icon
                    icon="EditIcon"
                    style="width: 18px; height: 18px"
                  />
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
  
        <!-- add Price Model -->
        <b-modal
          id="modal-prevent-closing"
          cancel-variant="outline-secondary"
          @ok="sendExcelPlansFile(updatedPaymentProvider.id)"
          ok-title="Update"
          cancel-title="Close"
          centered
          :title="'Update ' + updatedPaymentProvider.name "
        >
          <validation-observer ref="updatedPaymentProviderForm">
            <b-form @submit.prevent>
              <b-row>
              

                <!-- file -->
            <b-col md="6">
                <b-form-group label="upload plans" label-for="file">
                    <input
                    type="file"
                    class="form-control-file"
                    name="file"
                    id="file"
                    ref="file"
                    change
                    @change="fileUploadIndex($event)"
                    />
                </b-form-group>
                </b-col>


              </b-row>
            </b-form>
          </validation-observer>
          <!-- error handelr -->
          <b-alert
            v-height-fade.appear
            fade
            :show="showDismissibleAlert"
            @dismissed="showDismissibleAlert = false"
            variant="danger"
          >
            <h4 class="alert-heading">Alert</h4>
            <div class="alert-body">
              <ul v-for="(values, index) in errors_back" :key="index">
                <li v-for="(value, indexVal) in values" :key="indexVal">
                  {{ value }}
                </li>
              </ul>
            </div>
          </b-alert>
        </b-modal>
      </b-card>
    </div>
  </template>
    
<script>
  import { required } from '@validations'
  export default {
    data() {
      return {
        required,
        authPermissions: [],
        errors_back2: [],
        showDismissibleAlert: false,
        errors_back: [],
        // table fields
        fields: [
          {
            key: 'id',
            label: 'Id',
            sortable: true,
          },
          {
            key: 'name',
            label: 'Name',
          },
  
          {
            key: 'active',
            label: 'Active',
          },
          
          {
            key: 'installment_plans',
            label: 'Installment Fees',
          },
          {
            key: 'actions',
            label: 'Import Fees',
          },
        ],
        items: [],
        file: null,
        updatedPaymentProvider: {},
      }
    },
    watch: {},
    created() {
      this.authPermissions = this.$store.state.authPermissions
      this.fetchData()
    },
    computed: {
    parsedData() {
      try {
        return JSON.parse(this.items.installment_plans);
      } catch (error) {
        console.error("JSON parsing error:", error);
        return null;
      }
    }
    },
    methods: {
      fetchData() {
        // fetch data of values
        axios
          .get('payment-providers')
          .then((result) => {
            this.items = result.data.data

            return this.items
          })
          .catch((err) => {
            //console.log(err)
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      },
      fileUploadIndex(e) {
        
      this.file = e.target.files[0]
      
      },
      
      sendExcelPlansFile(id){

        const formData = new FormData();
        formData.append('file', this.file);

        axios
            .post('payment-providers/' + id + '/installment-plans/update', formData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.fetchData();
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })

      },

      ExportExcelPlansFile(){
        axios
            .get(
              'payment-providers/installment-plans/export',
              { responseType: 'blob' }
            )
            .then((result) => {

              const url = window.URL.createObjectURL(new Blob([result.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', 'installemnt plans.xlsx')
              document.body.appendChild(link)
              link.click()

              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'downloaded success',
                showConfirmButton: false,
                timer: 1500,
              })

            })
            .catch((err) => {
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            });
      },
      updatePaymentProviderModal(item) {
        this.errors_back2 = []
        this.showDismissibleAlert = false
        this.updatedPaymentProvider = item
      },
  
      updatePaymentProvider() {
        delete this.updatedPaymentProvider.logo;
        this.$refs.updatedPaymentProviderForm.validate().then((success) => {
          //  data{'hex_code' : this.hex_code}
          // fetch data of values
          axios
            .put(
              'payment-providers/' + this.updatedPaymentProvider.id + '/update',
              this.updatedPaymentProvider
            )
            .then((result) => {
              // get the value from values array to update the hex code
              const value = this.items.filter((item) => {
                if (item.id == this.updatedPaymentProvider.id) {
                  item = this.updatedPaymentProvider
                }
              })
              this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
              })
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
            })
            .catch((err) => {
              this.errors_back2.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Error!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  this.errors_back2.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back2 = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back2 = []
                this.errors_back2.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        })
      },
  
      isString(value) {
        return typeof value === 'string' || value instanceof String
      },
    },
  }
  </script>
    