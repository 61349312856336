<template>
    <div v-if="airWayBillData">
      <b-alert
        v-height-fade.appear
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert = false"
        variant="danger"
      >
        <h4 class="alert-heading">Alert</h4>
        <div class="alert-body">
          <ul v-for="(values, index) in errors_back" :key="index">
            <li v-for="(value, index) in values" :key="index">{{ value }}</li>
          </ul>
        </div>
      </b-alert>
      <b-row class="row-eq-height">
        <b-col class="mb-1" cols="12" md="6">
          <b-card title="Order" class="h-100 d-flex flex-wrap">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <th class="text-left">ID</th>
                  <td class="text-right">{{ airWayBillData.id }}</td>
                </tr>
                <tr>
                  <th class="text-left">AirWayBill No</th>
                  <td class="text-right">{{ airWayBillData.air_way_bill_number }}</td>
                </tr>
          
                
                <tr>
                  <th class="text-left">Status</th>
                  <td class="text-right">
                    <b-badge variant="primary">
                      {{ airWayBillData.status}}
                    </b-badge>
                  </td>
                </tr>
                <tr>
                  <th class="text-left">Order Code</th>
                  <td class="text-right">{{ airWayBillData.order_code }}</td>
                </tr>
  
                <tr>
                  <th class="text-left">Pickup Request Number</th>
                  <td class="text-right">
                      {{ airWayBillData.pickup_request_number}}
                    
                  </td>
                </tr>

                <tr>
                  <th class="text-left">Product Description</th>
                  <td class="text-right">{{ airWayBillData.awb_data.GoodsDescription }}</td>
                </tr>
          
                
                <tr>
                  <th class="text-left">Number of Peices</th>
                  <td class="text-right">
                      {{ airWayBillData.awb_data.NumberofPeices}}
             
                  </td>
                </tr>
                <tr>
                  <th class="text-left">Product Type</th>
                  <td class="text-right">
                      {{ airWayBillData.awb_data.ProductType}}</td>
                </tr>
  
                <tr>
                  <th class="text-left">Service Type</th>
                  <td class="text-right">
                      {{ airWayBillData.awb_data.ServiceType}}
                    
                  </td>
                </tr>

                <tr>
                  <th class="text-left">Shipping Fees</th>
                  <td class="text-right">
                      {{ airWayBillData.shipping_fees}}
                    
                  </td>
                </tr>
                
              
              
              </tbody>
            </table>
          </b-card>
        </b-col>
        <b-col md="6" class="mb-1">
          <b-card class="h-100 " footer-tag="footer">
       
            
            <div v-if="delUser" class="d-flex flex-wrap justify-content-center">
        
                
              <b-button
                block
                
                @click="showAirWayBill()"
                variant="primary "
                class="mt-1"
              >
                Airway Bill PDF
              </b-button>
              <b-modal
                ref="my-modal"
                ok-only
                ok-title="Accept"
                centered
                size="xl"
                title="Airway Bill"
              >
                <object>
                  <iframe
                    id="pdfID"
                    type="text/html"
                    width="100%"
                    height="1000px"
                    sc
                    :src="'data:application/pdf;base64,' + this.shipPDF"
                  />
                </object>
              </b-modal>
     
            </div>
            
            <hr />
            <div v-if="delUser" class="d-flex">
              <div class="m-4 w-50">
                <h6 class="text-muted font-weight-bolder">Created at</h6>
                <h3 class="mb-0">
                  {{ airWayBillData.created_at }}
                </h3>
              </div>
              <div class="m-4 w-50">
                <h6 class="text-muted font-weight-bolder">Updated at</h6>
                <h3 class="mb-0">
                  {{ airWayBillData.updated_at }}
                </h3>
              </div>
            </div>
            <div
              v-else
              class="d-flex justify-content-md-center align-items-center text-center"
            >
              <div class="m-4">
                <h6 class="text-muted font-weight-bolder">Created at</h6>
                <h3 class="mb-0">
                  {{ airWayBillData.created_at }}
                </h3>
              </div>
              <div class="m-4">
                <h6 class="text-muted font-weight-bolder">Deleted at</h6>
                <h3 class="mb-0">
                  {{ airWayBillData.deleted_at }}
                </h3>
              </div>
            </div>

            <b-col cols="12">
              <b-card title="Orders" v-if="items.length != 0">
                <b-table id="OrdersTable" ref="admint" striped responsive class="position-relative" :items="items"
                  :fields="fields">
                  <template #cell(orderstatus)="data">
                    <b-badge variant="primary">
                      {{ data.value.name }}
                    </b-badge>
                  </template>
                  <template #cell(actions)="items">
                    <div class="d-flex p-1">
                      <!-- Show Order -->
                      <b-button v-if="authPermissions.includes('update')" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success" type="submit" :to="{ path: '/orders/show/' + items.item.id }">
                        <feather-icon style="width: 18px; height: 18px" icon="EyeIcon" />
                      </b-button>

                    </div>
                  </template>
                </b-table>
              </b-card>

              <b-card title="Assign Order" class="mb-0" v-if="authPermissions.includes('assign-order-air-way-bill') && items.length == 0"   >
                <b-col md="8" >
                  <div style="position: relative">
                    <b-row>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="order"
                          rules=""
                        >
                          <b-form-group
                            label="Order Search"
                            label-for="Order"
                            :state="errors.length > 0 ? false : null"
                          >
                            <b-form-input
                              id="searchOrder"
                              :state="airWayBillData.order_code == null ? false : true"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              v-model="searchOrder"
                              placeholder="Search For Add Order"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="2">
                        <b-button
                          class="mt-2"
                          @click="searchOrderCode(searchOrder, 0)"
                          variant="outline-primary"
                          >Search</b-button
                        >
                      </b-col>
                      <b-alert
                        variant="warning"
                        style="margin: 0.438rem 1rem"
                        :show="noOrder"
                      >
                        <div class="alert-body">No Result Found.</div>
                      </b-alert>
                      <b-col
                        class="mt-2"
                        style="
                          position: absolute;
                          z-index: 9999;
                          padding: 0.438rem 1rem;
                          top: 35px;
                          max-height: 380px !important;
                          overflow-y: scroll;
                          overflow-x: hidden;
                          background-color: white;
                          scroll-padding: 0px !important;
                          padding-right: 0;
                        "
                        v-if="orderShow"
                        cols="12"
                      >
                        <b-list-group>
                          <b-list-group-item
                            @click="addOrder(item)"
                            v-for="(item, index) in oderSR"
                            :key="index"
                          >
                            <feather-icon icon="" class="mr-75" />
                            <p style="font-weight: 600">ID: {{ item.id }} - Code:
                              {{ item.code }}</p>
                            <!-- <small style="padding-left: 10px"
                              >ID: {{ item.id }} - Code:
                              {{ item.code }}</small
                            > -->
                          </b-list-group-item>
                          <infinite-loading
                            @infinite="searchOrderCode(searchOrder, orderCurrentPage)"
                          ></infinite-loading>
                        </b-list-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-card>


              <b-col cols="12">
              <b-table id="OrdersTable" ref="admint" striped responsive  v-if="selectedOrder.length != 0" :items="selectedOrder"
                  :fields="selectedOrderFields">
                  <template #cell(orderstatus)="data">
                    <b-badge variant="primary">
                      {{ data.value.name }}
                    </b-badge>
                  </template>
                  <template #cell(actions)="items">
                    <div class="d-flex p-1">
                      <!-- Show Order -->
                      <b-button v-if="authPermissions.includes('update')" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success" type="submit" :to="{ path: '/orders/show/' + items.item.id }">
                        <feather-icon style="width: 18px; height: 18px" icon="EyeIcon" />
                      </b-button>

                    </div>
                  </template>
                </b-table>
              </b-col>
                <b-col md="12" class="mb-1 d-flex justify-content-center">
                  <b-button
                  class="mt-1 w-50"
                  v-if="selectedOrder.length != 0"
                  @click="AssignOrder()"
                  variant="primary"
                  >Assign</b-button>
              </b-col>
            </b-col>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="row-eq-height">
    
        
        <b-col md="6" class="mb-1">
          <b-card class="h-100 d-flex flex-wrap" title="Sender Data" v-if="airWayBillData.booking_data != null">
            <table class="table table-bordered">
                <tbody>
              
                
              <tr>
                <th class="text-left">Contact Person</th>
                <td class="text-right">{{ airWayBillData.booking_data.BookingContactPerson }}</td>
              </tr>
        
              <tr>
                <th class="text-left">Email</th>
                <td class="text-right">
                    {{ airWayBillData.booking_data.BookingEmail}}
                  
                </td>
              </tr>
              
              <tr>
                <th class="text-left">Phone</th>
                <td class="text-right">
                    {{ airWayBillData.booking_data.BookingMobileNo}}
           
                </td>
              </tr>
              <tr>
                <th class="text-left">City</th>
                <td class="text-right">
                    {{ airWayBillData.booking_data.BookingCity}}</td>
              </tr>
              <tr>
                <th class="text-left">Address</th>
                <td class="text-right">
                    {{ airWayBillData.booking_data.BookingAddress1}}</td>
              </tr>


              
            
            </tbody>
            </table>
            
          </b-card>
        </b-col>
        <b-col md="6" class="mb-1">
          <b-card class="h-100 d-flex flex-wrap" title="Receiver Data" v-if="airWayBillData.receiver_data != null">
            <table class="table table-bordered">
              <tbody>
              
                
                <tr>
                  <th class="text-left">Contact Person</th>
                  <td class="text-right">{{ airWayBillData.receiver_data.ReceiversContactPerson }}</td>
                </tr>
          
                <tr>
                  <th class="text-left">Email</th>
                  <td class="text-right">
                      {{ airWayBillData.receiver_data.ReceiversEmail}}
                    
                  </td>
                </tr>
                
                <tr>
                  <th class="text-left">Phone</th>
                  <td class="text-right">
                      {{ airWayBillData.receiver_data.ReceiversPhone}}
             
                  </td>
                </tr>
                <tr>
                  <th class="text-left">City</th>
                  <td class="text-right">
                      {{ airWayBillData.receiver_data.ReceiversCity}}</td>
                </tr>
                <tr>
                  <th class="text-left">Address</th>
                  <td class="text-right">
                      {{ airWayBillData.receiver_data.ReceiversAddress1}}</td>
                </tr>
  
  
                
              
              </tbody>
            </table>
            
          </b-card>
        </b-col>
      </b-row>
 
     
      
      <!-- AWB Logger Data -->
      <b-card
        v-if="shipData != null"
        title="Airway Bill Status "
        class="d-flex flex-wrap"
      >
        <b-row>
          <b-col cols="12">
            <app-timeline>
              <app-timeline-item
                v-for="(item, index) in shipData.TrackingLogDetails"
                :key="index"
                variant="primary"
              >
                <div class="">
                  <h5>{{ item.Remarks }}</h5>
                  <p>{{ item.ActivityDate }}</p>
                </div>
              </app-timeline-item>
            </app-timeline>
          </b-col>
        </b-row>
      </b-card>
      
    </div>
  </template>
  
  <script>
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  import { TheMask } from 'vue-the-mask'
  import { required } from "@validations";
  
  export default {
    metaInfo: {
      meta: [
        { charset: 'utf-8' },
        {
          name: 'viewport',
          content:
            'width=device-width,initial-scale=1.0, maximum-scale=2.0, user-scalable=yes',
        },
      ],
    },
    components: {
      AppTimeline,
      AppTimelineItem,
      TheMask,
    },
  
    data() {
      return {
        required,
        authPermissions: [],
        airWayBillData: null,
        errors_back: [],
        showDismissibleAlert: false,

        identicalType: false,
        shipData: null,
        shipPDF: null,
        items: [],

        fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },

        {
          key: 'code',
          label: 'Code',
        },
        {
          key: 'total',
          label: 'Total',
        },
        {
          key: 'type',
          label: 'Type',
        },
        {
          key: 'discount',
          label: 'Discount',
        },
        {
          key: 'shipping_price',
          label: 'Shipping Price',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],

      selectedOrderFields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },

        {
          key: 'code',
          label: 'Code',
        },
        {
          key: 'total',
          label: 'Total',
        },
        {
          key: 'type',
          label: 'Type',
        },
        {
          key: 'discount',
          label: 'Discount',
        },
        {
          key: 'shipping_price',
          label: 'Shipping Price',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],


      orderShow: false,
      searchOrder: '',
      noOrder: false,
      selectedOrder: [],
      oderSR: [],


      }
    },
  
    created() {
      this.authPermissions = this.$store.state.authPermissions
      this.fetchData()
    },
    methods: {
      getBillData() {
        axios
          .get(
            'orders/awb/' +
              this.airWayBillData.air_way_bill_number +
              '/tracking/get'
          )
          .then((result) => {
            this.shipData = result.data.data
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      },
      showAirWayBill() {
        if (this.shipPDF == null) {
          this.getPDF()
        } else {
          this.showModal()
        }
      },
      showModal() {
        this.$refs['my-modal'].show()
      },
      async getPDF() {
        await axios
          .get(
            'orders/awb/' +
              this.airWayBillData.air_way_bill_number +
              '/pdf/generate'
          )
          .then((result) => {
            this.shipPDF = result.data.data.ReportDoc
            this.showModal()
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      },
      
      
      fetchData() {
        axios
          .get('airWayBills/' +this.$route.params.id + '/show')
          .then((result) => {
            this.airWayBillData = result.data.data
            // //console.log(this.airWayBillData)
            this.getBillData();
            if(this.airWayBillData.order != null){
            this.items =  [this.airWayBillData.order];
            }
            
            if (this.airWayBillData.deleted_at != null) {
              this.delUser = false
            } else {
              this.delUser = true
            }
  
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      },


      AssignOrder(){
     
        axios
          .post('AssignOrderairWayBill/' + this.$route.params.id + '/' + this.selectedOrder[0].id)
          .then((result) => {
              this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
            this.selectedOrder = [];
            this.fetchData();
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      },
      searchOrderCode(text, $page) {
      this.noOrder = false
      this.orderCurrentPage = $page + 1
      axios
        .get(
          'orders/airway-orders/20/sortBy-id-desc/' +
            text +
            '?page=' +
            this.orderCurrentPage
        )
        .then((result) => {
          const data = result.data.data.data
          // this.oderSR = data
          if(this.orderCurrentPage == 1){
            this.oderSR = []
          }
          this.oderSR = this.oderSR.concat(data)
          if (this.oderSR.length > 0) {
            this.orderShow = true
          } else {
            if(this.orderCurrentPage == 1){
              this.oderSR = []
              this.noOrder = true
              this.orderShow = false

            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    addOrder(order) {
      this.orderShow = false
      this.selectedOrder = [order];
      this.airWayBillData.order_id = order.id
    },
      isString(value) {
        return typeof value === 'string' || value instanceof String
      },
    },
  }
  </script>
  
  <style scoped>
  /* #card {
      display: flex !important;
      flex-wrap: wrap !important;
  } */
  #table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  #table td,
  #table th {
    border: 1px solid #dddddd;
    padding: 8px;
  }
  #table tr:nth-child(even) {
    background-color: #f5f5f5;
  }
  .cardH {
    margin-bottom: 0px;
  }
  .phonenumber {
    font-weight: bolder !important;
    color: #08308B;
    background: none;
  }
  </style>
  