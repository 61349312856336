<template>
  <div>
    <b-card title="Sales Report">
      <b-row class="ml-auto">

        <b-col md="3">
          <b-row>
            <b-col md="10">
              <b-form-group label="Quick Select Range">
                <b-form-select v-model="quickSelectRange" @change="updateDateRange">
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="last3">Last 3 Days</option>
                  <option value="last7">Last 7 Days</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="2" class="d-flex justify-content-center align-items-center">
              <h3 class="text-primary" style="margin-top: 21px;">OR</h3>
            </b-col>
          </b-row>

        </b-col>


        <b-col md="2">

          <b-form-group label="From Date" label-for="From Date">
            <flat-pickr v-model="fromDate" class="form-control" style="margin-top:11px;"
              v-validate="{ date_format: 'dd/MM/yyyy', date_between: ['01/01/1990', toDate] }" />
          </b-form-group>

        </b-col>
        <b-col md="2">
          <b-form-group label="To Date" label-for="To Date">
            <flat-pickr v-model="toDate" class="form-control" style="margin-top:11px;" />
          </b-form-group>

        </b-col>




        <!-- filter -->
        <b-col md="3" class="my-2">

          <b-input-group-append style="margin-top:5px;">
            <b-button variant="primary" @click="productSaleReportExport">
              Export
            </b-button>
          </b-input-group-append>

        </b-col>

      </b-row>

    </b-card>
    <!-- handle error -->
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { MonthPickerInput } from 'vue-month-picker'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    MonthPickerInput,
    flatPickr,
  },
  data() {
    return {
      dateOptionByYear: [],
      dateByYear: null,
      dateMonth: null,
      newMomentMonthDate: null,
      dateWeakly: null,
      dateDaily: null,
      totalsaleYear: null,
      fromDate: null,
      toDate: null,
      quickSelectRange: 'today', // Default range
      totalsaleQur: null,

      authPermissions: null,
      showDismissibleAlert: false,
      errors_back: [],
      perPage: 25,
      isBusy: false,
      filter: '',
      today: new Date(),
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      export: false,
      dailyDate: null,
      items: [],
      exportItems: [],
      isEmptyData: true,
    }
  },
  created() {
    this.dailyDate = `${this.today.getFullYear()}-${this.today.getMonth() + 1}-${this.today.getDate()}`
    this.fromDate = this.dailyDate
    this.toDate = this.dailyDate


    this.authPermissions = this.$store.state.authPermissions
  },
  computed: {
    // sortable in table by add (sortable: true) in field of table fields
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    // compute row of pagination
    rows() {
      return this.totalRows
    },
  },
  methods: {
    updateDateRange() {
      const today = new Date();
      let fromDate, toDate;

      switch (this.quickSelectRange) {
        case 'today':
          fromDate = toDate = this.formatDate(today);
          break;
        case 'yesterday':
          const yesterday = new Date(today);
          yesterday.setDate(yesterday.getDate() - 1);
          fromDate = toDate = this.formatDate(yesterday);
          break;
        case 'last3':
          const threeDaysAgo = new Date(today);
          threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
          fromDate = this.formatDate(threeDaysAgo);
          toDate = this.formatDate(today);
          break;
        case 'last7':
          const sevenDaysAgo = new Date(today);
          sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
          fromDate = this.formatDate(sevenDaysAgo);
          toDate = this.formatDate(today);
          break;
        default:
          fromDate = toDate = this.formatDate(today);
      }

      this.fromDate = fromDate;
      this.toDate = toDate;
    },
    formatDate(date) {
      // Format as 'YYYY-MM-DD' (adjust based on your flatPickr requirements)
      return date.toISOString().split('T')[0];
    },
    productSaleReportExport() {
      const date = this.today.getFullYear() + '-' + (this.today.getMonth() + 1) + '-' + this.today.getDate() + '-' +
        this.today.getHours() + ":" + this.today.getMinutes() + ":" + this.today.getSeconds();

      const sendData = {
        range: {
          // from: '2023-06-25',
          from: this.fromDate,
          to: this.toDate,
        },
      }
      const promise = axios.post(
        'products/sales-report/export/data', sendData, { responseType: 'blob' })
      return promise
        .then((result) => {

          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'SalesProductsReport-' + date + '.xlsx');
          document.body.appendChild(link);
          link.click();


          this.$swal({
            position: "center",
            icon: "success",
            title: "downloaded success",
            showConfirmButton: false,
            timer: 1500,
          });

        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })



    },


    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    // fetch data of sort result

    showDate(date) {
      this.dateMonth = date
    },
    changedType() {
      this.dateByYear = null
      this.dateMonth = null
      this.newMomentMonthDate = null
      this.dateWeakly = null
      this.dateDaily = null
      this.totalsaleQur = null
      this.totalsaleYear = null
      this.fromDate = null
      this.toDate = null
    },
    // fetch data of search result
    dateDiff() {
      const today = new Date()
      const oldday = new Date('1 January 2020')
      var ynew = today.getFullYear()
      var mnew = today.getMonth()
      var dnew = today.getDate()
      var yold = oldday.getFullYear()
      var mold = oldday.getMonth()
      var dold = oldday.getDate()
      var diff = ynew - yold
      if (mold > mnew) diff--
      else {
        if (mold == mnew) {
          if (dold > dnew) diff--
        }
      }
      this.dateByYear = new Date().getFullYear().toString()

      for (var i = 0; i <= diff; i++) {
        this.dateOptionByYear.push((parseInt(this.dateByYear) - i).toString())
      }

      return diff
    },
    removeExitem() {
      for (let ex in this.exportItems) {
        delete this.exportItems[ex].avatar
      }
    },
    // print function
    printEle() {
      window.print()
    },
    // generate pdf function
    genPDF() {
      const { jsPDF } = require('jspdf')
      const doc = new jsPDF()
      let print_body = []
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].name,
          this.items[index].email,
          this.items[index].created_at,
          this.items[index].updated_at,
        ]
      }
      doc.autoTable({
        head: [['NAME', 'Email', 'CREATED_AT', 'UPDATED_AT']],
        body: print_body,
      })
      doc.save('table.pdf')
    },

    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="css">
.b-table-sticky-header {
  max-height: 92vh;
}

.th-spaces-update {
  white-space: nowrap;
  text-align: center;
  direction: rtl;
}

.month-picker__container,
.month-picker--default {
  background-color: #fff;
}

[dir] .month-picker-input[data-v-201d773d] {
  height: 38px !important;
  width: 100% !important;
}

.month-picker {
  font-weight: 200;
  box-sizing: border-box;
  flex: 1;
  width: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: sans-serif;
  overflow: hidden;
  justify-content: space-evenly;
  align-content: space-around;
}

.dountRevenu {
  height: 75% !important;
}

.month-picker-input-container[data-v-201d773d] {
  width: 100% !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
</style>