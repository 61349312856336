<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
       <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-card title="Conditions">
      <b-row>
        <!-- sorting -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Sort By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="2" class="my-1">
          <!-- add new condition button -->
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              style="height:2.142rem; line-height:0.5"
              v-if="authPermissions.includes('create')"
              variant="primary"
              :to="{ path: '/Vendor/AddCondition' }"
            >
              <span class="text-nowrap">Add Condition</span>
            </b-button>
          </div>
        </b-col>
        <!-- start data table -->
        <b-col cols="12">
          <b-table
            id="ConditionsTable"
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(actions)="items">
              <div>
                <!-- delete button -->
                <b-button
                  v-b-modal.modal-delete
                  style="padding:7px; margin:6px; width:35px; height:35px;"
                  class="btn-icon rounded-circle"
                  v-if="authPermissions.includes('delete')"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  type="submit"
                  @click="conditionID = items.item.id"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    style="width:18px; height:18px;"
                  />
                </b-button>
                <b-button
                  style="padding:7px; margin:6px; width:35px; height:35px;"
                  class="btn-icon rounded-circle"
                  v-if="authPermissions.includes('update')"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  type="submit"
                  :to="{ path: '/Vendor/UpdateCondition/' + items.item.id }"
                >
                  <feather-icon
                    icon="EditIcon"
                    style="width:18px; height:18px;"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
          <b-modal
            id="modal-delete"
            cancel-variant="outline-secondary"
            @ok="deleteData(conditionID)"
            ok-title="Ok"
            cancel-title="Close"
            centered
            title="Delete Condition"
          >
            <b-form>
              <b-form-checkbox
                id="checkbox-2"
                v-model="deletedCondition"
                name="checkbox-2"
                value="1"
                unchecked-value="0"
              >
                Force Delete
              </b-form-checkbox>
            </b-form>
          </b-modal>
        </b-col>
        <!-- start pagination  -->
        <b-col cols="12"> </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            aria-controls="CategoryTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
export default {

  
  data() {
    return {
      authPermissions: [],
      showDismissibleAlert: false,
      errors_back: {},
      errors_delete: {},
      deletedCondition: "0",
      conditionID: null,
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "id",
          label: "Id",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "from",
          label: "from",
        },
        {
          key: "to",
          label: "to",
        },
        {
          key: "actions",
        },
      ],
      items: [],
    };
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    // request tags index
    this.fetchData();
  },
  watch: {
    sortDirection: function() {
      this.onFiltered();
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
    rows() {
      return this.totalRows;
    },
  },
  methods: {
    fetchData() {
      axios
        .get("conditions")
        .then((result) => {
          
          this.totalRows = result.data.data.length;
          this.items.length = 0;
          this.items = result.data.data;
          
        })
        .catch((err) => {
          // console.log(err)
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // deleted function to delete condition
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete condition.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete(
                "conditions/" +
                  id +
                  "/archive-condition/" +
                  this.deletedCondition
              )
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
