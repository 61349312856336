<template>
  <div>
    <b-card-code title="Add Margin Fees" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- Service Type -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Product Type"
                rules="required"
              >
                <b-form-group
                  label="Product Type"
                  label-for="service_type"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="service_type"
                    v-model="serviceTypeSelect"
                    :state="serviceTypeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="serviceTypeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- type -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >
                <b-form-group
                  label="Type"
                  label-for="type"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="active"
                    v-model="typeSelect"
                    :state="typeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- From -->
            <b-col md="6">
              <b-form-group label="From" label-for="from">
                <validation-provider
                  #default="{ errors }"
                  name="to"
                  rules="required|regex:^[0]*[1-9][0-9]*$"
                >
                  <b-form-input
                    id="From"
                    min="1"
                    type="number"
                    v-model="data.from"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- To -->
            <b-col md="6">
              <b-form-group label="To" label-for="to">
                <validation-provider
                  #default="{ errors }"
                  name="to"
                  rules="required|regex:^[0]*[1-9][0-9]*$"
                >
                  <b-form-input
                    id="to"
                    min="1"
                    type="number"
                    v-model="data.to"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Rate -->
            <b-col md="6">
              <b-form-group label="Rate" label-for="rate">
                <validation-provider
                  #default="{ errors }"
                  name="rate"
                  rules="required|regex:^([0-9]+)$"
                >
                  <b-form-input
                    id="rate"
                    min="1"
                    type="number"
                    v-model="data.rate"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- is_portal -->
            <b-col md="3">
              <b-form-group label="Is Portal ?" label-for="is_portal">
                <validation-provider
                  #default="{ errors }"
                  name="is_portal"
                  rules="required"
                >
                <b-form-checkbox switch v-model="data.is_portal">
                  <p v-if="data.is_portal"> Portal Price Rate </p>
                  <p v-else> In Store Price Rate</p>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- active -->
            <b-col md="3">
              <b-form-group label="Is Active ?" label-for="active">
                <validation-provider
                  #default="{ errors }"
                  name="active"
                  rules="required"
                >
                <b-form-checkbox switch v-model="data.active">
                  <p v-if="data.active"> Price Rate is Active To Used </p>
                  <p v-else> Price Rate is not Active</p>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

             <!-- payment method -->
           <b-col md="6" v-if="data.is_portal == false">
            <validation-provider
              #default="{ errors }"
              name="payment"
              :rules="data.is_portal == false ? 'required' : ''"
            >
                <b-form-group
                  label="Payment Method"
                  label-for="payment"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="payment"
                    v-model="payment_method"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="payments"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
            </validation-provider>
          </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
       <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { heightTransition } from "@core/mixins/ui/transition";
import { required } from "@validations";

export default {
  components: {

    BCardCode,

  },
  
  mixins: [heightTransition],
  data() {
    return {
      typeSelect: null,
      activeSelect: null,
      payment_method: null,
      activeOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
        {
          value: "0",
          text: "False",
        },
        {
          value: "1",
          text: "True",
        },
      ],
      serviceTypeSelect: null,
      typeOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
        {
          value: "percentage",
          text: "Percentage",
        },
        {
          value: "fixed_price",
          text: "Fixed Price",
        },
      ],
      serviceTypeOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
        {
          value: "new",
          text: "New",
        },
        {
          value: "used",
          text: "Used",
        },
      ],
      data: {
        active: false,
        from: "",
        to: "",
        rate: "",
        is_portal:false,
        type: "",
        service_type: "",
        vendor_id: "",
      },
      errors_back: [],
      showDismissibleAlert: false,
      required,
      payments:  [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
    };
  },

  created() {
    this.data.vendor_id = this.$route.params.id;
    this.getPaymentProvider();
  },
  methods: {
    // submit Brand API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.data.type = this.typeSelect.value;
          this.data.service_type = this.serviceTypeSelect.value;
          if(this.payment_method ){
            this.data.payment_provider_id = parseInt(this.payment_method.value);
          }
          axios
            .post("vendors/price-rates", this.data)
            .then((result) => {
              //console.log(result);
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.back(1);
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    getPaymentProvider() {
      // fetch data of values
      axios
                .get('getPaymentProvidersSession')
                .then((result) => {
                  const data = result.data.data;

                    for (let cat in data) {
                      this.payments.push({
                        value: data[cat].id.toString(),
                        text: data[cat].name
                      });
                    }
                })
                .catch((err) => {
                    //console.log(err)
                    this.errors_back.length = 0
                    if (err.response.data.data != null) {
                        if (this.isString(err.response.data.data)) {
                            this.errors_back.push({
                                error: err.response.data.data,
                            })
                        } else {
                            this.errors_back = err.response.data.data
                        }
                        this.showDismissibleAlert = true
                    } else {
                        this.errors_back = []
                        this.errors_back.push({
                            error: 'internal server error',
                        })
                        this.showDismissibleAlert = true
                    }
                });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
    // basic
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped></style>
