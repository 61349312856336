<template>
    <div>
          <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>

        <b-card
          title="Payouts Accounts"
          v-if="authPermissions.includes('show-vendor-payout-accounts')"
        >
        <!-- sorting -->
        <b-col md="3" class="my-1">
          <b-form-group
            label="Sort By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class=""
              >
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class=""
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>


            <!-- actions -->
        <b-col cols="12">
          <b-table
            id="OrdersTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            :busy.sync="isBusy"
            class="position-relative"
            :items="items"
            :fields="fields"
            :sort-direction="sortDirection"
            :sort-desc.sync="sortDesc" 
            :sort-by.sync="sortBy" 
          >
            <template #cell(user)="items">
              <!-- show user -->
              <b-button
                size="sm"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-info"
                type="submit"
                :to="{ path: '/User/Show/' + items.item.userId }"
              >
                {{ items.item.user }}
              </b-button>
            </template>
            <template #cell(status)="data">
              <b-badge variant="primary">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(file)="data">
                <a v-if="data.item.file != null" :href="data.item.file">Press here to open file</a>
            </template>
            <template #cell(created_at)="data">
                {{ data.item.created_at | formatDate }}
              </template>
  
              <template #cell(updated_at)="data">
                {{ data.item.updated_at | formatDate }}
              </template>
            <template #cell(actions)="items">
              <div>
                <!-- update payout Accounts -->
        
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                  v-if="
                    authPermissions.includes('update-vendor-payout-accounts')
                  "
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  @click="updatePayoutModal(items.item)"
                  v-b-modal.modal-update-payout-account
                  class="btn-icon rounded-circle"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    style="width: 18px; height: 18px"
                    class="align-middle"
                  />
                </b-button>

                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                  v-if="
                    authPermissions.includes('update-vendor-payout-accounts')
                  "
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  type="submit"
                  @click="deletePayoutDocument(items.item.id)"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    style="width: 18px; height: 18px"
                    class="align-middle"
                  />
                </b-button>


              </div>
            </template>
          </b-table>
        </b-col>

          <div v-show="payouts" class="mt-5">
            <b-row>
              <!-- Account Type -->
              <b-col md="4">
                <b-form-group label="Account Type" label-for="account_type">
                  <v-select
                    id="account_type"
                    @input="updateProviderName()"
                    v-model="payoutAccountUpdateData.account_type"
                    :state="
                      payoutAccountUpdateData.account_type == null
                        ? false
                        : true
                    "
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="payoutAccountsType"
                    :selectable="(option) => !option.includes('select_value')"
                    label="text"
                  />
                </b-form-group>
              </b-col>

              <!--Bank  name -->
              <b-col
                md="4"
                v-if="payoutAccountUpdateData.account_type == 'Bank'"
              >
                <b-form-group label="Bank Name" label-for=" Bankname">
                  <validation-provider
                    #default="{ errors }"
                    name="Bank Name"
                    :rules="
                      payoutAccountUpdateData.account_type != null &&
                      payoutAccountUpdateData.account_type == 'Bank'
                        ? 'required'
                        : ''
                    "
                  >
                    <v-select
                      id="Bank-name"
                      v-model="payoutAccountUpdateData.provider_name"
                      :state="
                        payoutAccountUpdateData.provider_name == null
                          ? false
                          : true
                      "
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="payoutBankTypes"
                      :selectable="(option) => !option.includes('select_value')"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--Wallet  name -->
              <b-col
                md="4"
                v-if="payoutAccountUpdateData.account_type == 'Wallet'"
              >
                <b-form-group label="Wallet Name" label-for="Walletname">
                  <validation-provider
                    #default="{ errors }"
                    name="Wallet Name"
                    :rules="
                      payoutAccountUpdateData.account_type != null &&
                      payoutAccountUpdateData.account_type == 'Wallet'
                        ? 'required'
                        : ''
                    "
                  >
                    <v-select
                      id="Wallet-name"
                      v-model="payoutAccountUpdateData.provider_name"
                      :state="
                        payoutAccountUpdateData.provider_name == null
                          ? false
                          : true
                      "
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="payoutWalletTypes"
                      :selectable="(option) => !option.includes('select_value')"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Account Number -->
              <b-col md="4">
                <b-form-group label="Acoount Number" label-for="Acoount Number">
                  <validation-provider
                    #default="{ errors }"
                    name="Acoount Number"
                    :rules="
                      payoutAccountUpdateData.account_type != null
                        ? 'integer|required'
                        : 'integer'
                    "
                  >
                    <b-form-input
                      id="Acoount-Number"
                      v-model="payoutAccountUpdateData.number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="XXXXXXXXXX"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Account Name -->
              <b-col
                md="4"
                v-if="payoutAccountUpdateData.account_type == 'Bank'"
              >
                <b-form-group label="Acoount Name" label-for="Acoount Name">
                  <validation-provider
                    #default="{ errors }"
                    name="Account Name"
                    :rules="
                      payoutAccountUpdateData.type != null &&
                      payoutAccountUpdateData.account_type == 'Bank'
                        ? 'required'
                        : ''
                    "
                  >
                    <b-form-input
                      id="Acoount-Name"
                      v-model="payoutAccountUpdateData.name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Account IBan -->
              <b-col
                md="4"
                v-if="payoutAccountUpdateData.account_type == 'Bank'"
              >
                <b-form-group label="Acoount IBAN" label-for="Acoount IBAN">
                  <validation-provider
                    #default="{ errors }"
                    name="Acoount IBAN"
                    :rules="
                      payoutAccountUpdateData.account_type != null &&
                      payoutAccountUpdateData.account_type == 'Bank'
                        ? 'required'
                        : ''
                    "
                  >
                    <b-form-input
                      id="Acoount-IBAN"
                      v-model="payoutAccountUpdateData.iban"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- file -->
              <b-col md="2">
                <b-form-group label="File" label-for="file">
                  <input
                    type="file"
                    multiple="multiple"
                    class="form-control-file"
                    name="file"
                    id="file"
                    ref="file"
                    change
                    @change="fileUploadPayout"
                    accept="application/pdf"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-button
                  class="mt-2"
                  @click="savePayoutDocument()"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                >
                  Save
                </b-button>
              </b-col>
            </b-row>
            <b-alert
              v-height-fade.appear
              fade
              :show="showDismissiblePayoutAlert"
              @dismissed="showDismissiblePayoutAlert = false"
              variant="danger"
            >
              <h4 class="alert-heading">Alert</h4>
              <div class="alert-body">
                <ul
                  v-for="(values, name, index) in payoutResponceErrors"
                  :key="index"
                >
                  <li v-for="(value, indexVal) in values" :key="indexVal">
                    {{ name }} {{ value }}
                  </li>
                </ul>
              </div>
            </b-alert>
          </div>
        </b-card>
    </div>
</template>

<script>
export default {
    name: 'payoutaccouts-table',
    authPermissions: null,
    props: ['link'],
    data() {
        return {

            authPermissions: null,
            showDismissibleAlert: false,
            errors_back: [],
            errors_delete: {},
            perPage: 25,
            pageOptions: [25, 50, 100],
            totalRows: 1,
            isBusy: false,
            currentPage: 1,
            sortBy: 'created_at',
            status: 'all',
            statusName: 'All',
            sortDesc: true,
            sortDirection: 'desc',
            filter: '',
            filterOn: [],
            infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
            payoutResponceErrors: [],
            showDismissiblePayoutAlert: false,
            payoutAccountUpdateData: {
                id: null,
                account_type: null,
                name: null,
                iban: null,
                file: null,
                number: null,
                provider_name: null,
            },
            payouts: false,

            // table fields
            fields: [
                    {
                    key: 'id',
                    label: 'Id',
                    sortable: true,
                    },
                    {
                    key: 'name',
                    label: 'user',
                    },
                    {
                    key: 'number',
                    label: 'Number',
                    },
                    {
                    key: 'account_type',
                    label: 'Account Type',
                    },
                    {
                    key: 'iban',
                    label: 'Iban',
                    }, {
                    key: 'provider_name',
                    label: 'provider name',
                    }, {
                    key: 'file',
                    label: 'File',
                    },
                    {
                    key: 'created_at',
                    label: 'Created_At',
                    sortable: true,
                    },
                    {
                    key: 'updated_at',
                    label: 'Updated_At',
                    sortable: true,
                    },
                    {
                    key: 'actions',
                    },
                ],
            items: [],
            exportItems: [],
            payoutAccountsType: [],
        };
    },

    mounted() {
        
    },
    computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
  },

  watch: {
      sortDirection: function () {
        this.fetchData()
      },
     
      
    },

    created(){
        this.authPermissions = this.$store.state.authPermissions;
        // request Orders index
        this.fetchData();
    },

    methods: {

 // fetch data of payout Account
    fetchData() {
      var url = null
      if (this.filter == '') {
        url =
          this.link +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '?page=' +
          this.currentPage
      } else {
        url =
          this.link +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.filter +
          '?page=' +
          this.currentPage
      }
      const promise = axios.get(url)
      return promise
        .then((result) => {
          
          this.totalRows = result.data.data.meta.total
          this.items.length = 0
         
          this.items = result.data.data.data
          this.exportItems = this.items
          return this.items
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
        
    },


    fileUploadPayout(e) {
      this.payoutAccountUpdateData.file = e.target.files[0]
    },

    changeStatus(name, slug) {
      this.status = slug
      this.statusName = name
    },



    savePayoutDocument() {
      if (this.payoutAccountUpdateData.id == null) {
        this.savePayoutUrl =
          'vendors/' + this.id + '/payouts-accounts/store'
      } else {
        this.savePayoutUrl =
          'vendors/payouts-accounts/' +
          this.payoutAccountUpdateData.id +
          '/update'
      }
      let formData = new FormData()

      if (this.payoutAccountUpdateData.file != null) {
        formData.append('file', this.payoutAccountUpdateData.file)
      }
      formData.append('account_type', this.payoutAccountUpdateData.account_type)
      formData.append('number', this.payoutAccountUpdateData.number)
      formData.append(
        'provider_name',
        this.payoutAccountUpdateData.provider_name
      )
      if (this.payoutAccountUpdateData.account_type == 'Bank') {
        formData.append('name', this.payoutAccountUpdateData.name)
        formData.append('iban', this.payoutAccountUpdateData.iban)
      }

      axios
        .post(this.savePayoutUrl, formData)
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })

          if (this.payoutAccountUpdateData.id == null) {
            this.userData.payouts_accounts.push(result.data.data)
          } else {
            // let payoutsUpdated = this.userData.payouts_accounts.find(
            //   (x) => x.id === this.payoutAccountUpdateData.id
            // )
            // let payoutIndex =
            //   this.userData.payouts_accounts.indexOf(payoutsUpdated)
            // this.userData.payouts_accounts[payoutIndex].file =
            //   result.data.data.file
            // this.userData.payouts_accounts[payoutIndex].account_type =
            //   result.data.data.account_type
            // this.userData.payouts_accounts[payoutIndex].payment_provider =
            //   result.data.data.payment_provider
            // this.userData.payouts_accounts[payoutIndex].number =
            //   result.data.data.number
            // this.userData.payouts_accounts[payoutIndex].name =
            //   result.data.data.name
            // this.userData.payouts_accounts[payoutIndex].iban =
            //   result.data.data.iban
          }
          this.payouts = false
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.payoutResponceErrors.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.payoutResponceErrors.push({
                error: err.response.data.data,
              })
            } else {
              this.payoutResponceErrors = err.response.data.data
            }
            this.showDismissiblePayoutAlert = true
          } else {
            this.payoutResponceErrors = []
            this.payoutResponceErrors.push({
              error: 'internal server error',
            })
            this.showDismissiblePayoutAlert = true
          }
        })
    },
    
    async updatePayoutModal(payoutAccount) {
      if (this.payoutAccountsType.length == 0) {
        await this.getPayoutAccountsInitialData()
      }
      this.setPayoutUpdateDatapayoutAccount(payoutAccount)
      this.payouts = !this.payouts
    },


    setPayoutUpdateDatapayoutAccount(payoutAccount) {
      this.payoutAccountUpdateData.id = payoutAccount.id
      this.payoutAccountUpdateData.account_type = payoutAccount.account_type
      this.payoutAccountUpdateData.name = payoutAccount.name
      this.payoutAccountUpdateData.iban = payoutAccount.iban
      this.payoutAccountUpdateData.number = payoutAccount.number
      this.payoutAccountUpdateData.provider_name = payoutAccount.provider_name
    },


    async getPayoutAccountsInitialData() {
      await axios
        .get('vendors/payoutAccount/initial-data')
        .then((result) => {
          this.payoutAccountsType = result.data.data.payoutAccountsType
          this.payoutBankTypes = result.data.data.payoutBankTypes
          this.payoutWalletTypes = result.data.data.payoutWalletTypes
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    deletePayoutDocument(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete file.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('vendors/payouts-accounts/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },


}
};
</script>

<style lang="scss" scoped>

</style>