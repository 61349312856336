export default {
    header: "Pages",
    icon: "FileIcon",
    children: [
        {
            title: "Home",
            route: "Home",
            icon: "HomeIcon",
        },
        {
            title: "Administration",
            icon: "UsersIcon",
            children: [
                {
                    title: "Register New Admin",
                    route: "Register-Admin",
                    icon: "UserPlusIcon",
                    // target: '_blank',
                },
                {
                    title: "Admins Index",
                    route: "Admins-Index",
                    icon: "AlignJustifyIcon",
                    // target: '_blank',
                },
                {
                    title: "Roles",
                    icon: "PlusSquareIcon",
                    children: [
                        {
                            title: "Roles Index",
                            route: "Roles-list",
                            icon: "AlignJustifyIcon",
                        },
                        {
                            title: "Add Role",
                            route: "Add-Role",
                            icon: "PlusSquareIcon",
                        },
                    ],
                },
                {
                    title: "Permissions",
                    icon: "PlusSquareIcon",
                    children: [
                        {
                            title: "Permissions Index",
                            route: "Permissions-list",
                            icon: "AlignJustifyIcon",
                        },
                        {
                            title: "Add Permission",
                            route: "Add-Permission",
                            icon: "PlusSquareIcon",
                        },
                    ],
                },
            ],
        },
        {
            title: "Classifications",
            icon: "LayersIcon",
            children: [
                {
                    title: "Categories",
                    icon: "LayersIcon",
                    children: [
                        {
                            title: "Categories Index",
                            route: "Categories-list",
                            icon: "PlusSquareIcon",
                        },
                        {
                            title: "Add Category",
                            route: "Add-Category",
                            icon: "PlusSquareIcon",
                        },
                        {
                            title: "Trashed Category",
                            route: "Trashed-Categories",
                            icon: "PlusSquareIcon",
                        },
                    ],
                },
                {
                    title: "Brands",
                    icon: "LayersIcon",
                    children: [
                        {
                            title: "Brands Index",
                            route: "Brands-list",
                            icon: "PlusSquareIcon",
                        },
                        {
                            title: "Add Brand",
                            route: "Add-Brand",
                            icon: "PlusSquareIcon",
                        },
                        {
                            title: "Trashed Brands",
                            route: "Trashed-Brands",
                            icon: "PlusSquareIcon",
                        },
                    ],
                },
                {
                    title: "Tags",
                    icon: "LayersIcon",
                    children: [
                        {
                            title: "Tags Index",
                            route: "Tags-list",
                            icon: "PlusSquareIcon",
                        },
                        {
                            title: "Add Tag",
                            route: "Add-Tag",
                            icon: "PlusSquareIcon",
                        },
                    ],
                },
                {
                    title: "Terms",
                    icon: "LayersIcon",
                    children: [
                        {
                            title: "Terms Index",
                            route: "Terms-list",
                            icon: "PlusSquareIcon",
                        },
                        {
                            title: "Add Term",
                            route: "Add-Term",
                            icon: "PlusSquareIcon",
                        },
                        {
                            title: "Trashed Terms",
                            route: "Trashed-Terms",
                            icon: "PlusSquareIcon",
                        },
                    ],
                },
                {
                    title: "Currencies",
                    icon: "DollarSignIcon",
                    children: [
                        {
                            title: "Currencies Index",
                            route: "Currencies",
                            icon: "DollarSignIcon",
                        },
                        {
                            title: "Add Currencies",
                            route: "AddCurrencies",
                            icon: "DollarSignIcon",
                        },
                        {
                            title: "Trashed Currencies",
                            route: "Trashed-Currencies",
                            icon: "DollarSignIcon",
                        },
                    ],
                },
            ],
        },
        {
            title: "Phone Guide",
            icon: "PhoneIcon",
            children: [
                {
                    title: 'Guide Insights',
                    route: 'GuideInsights',
                    customIcon: 'list.svg',
                    per: 'show',
                    per2: 'phone_guide',
                },
                {
                    title: "Phone Guide Index",
                    route: "PhoneGuide",
                    icon: "PhoneIcon",
                },
                {
                    title: "Phone Guide Ecommerce",
                    route: "PhoneGuideEcommerce",
                    icon: "PhoneIcon",
                },
                {
                    title: "Create Item",
                    route: "CreateItem",
                    icon: "PhoneIcon",
                },
                {
                    title: "Trahsed Phone Guide",
                    route: "Trashed-PhoneGuide",
                    icon: "PhoneIcon",
                },
            ],
        },
        {
            title: "Product",
            icone: "SmartphoneIcon",
            children: [
                {
                    title: "Add Product",
                    route: "AddProduct",
                    icone: "SmartphoneIcon",
                },
                {
                    title: "Product Index",
                    route: "ProductIndex",
                    icone: "SmartphoneIcon",
                },
                {
                    title: "Trashed Product",
                    route: "ProductTrashed",
                    icone: "SmartphoneIcon",
                },
            ],
        },
        {
            title: "Vendor",
            icon: "UserIcon",
            children: [
                {
                    title: "Vendor Index",
                    route: "Vendor-Index",
                    icon: "UsersIcon",
                },
                {
                    title: "Add Vendor",
                    route: "Vendor-Add",
                    icon: "UserPlusIcon",
                },
                {
                    title: "Vendor Status",
                    route: "vendorStatus",
                    icon: "UserPlusIcon",
                },
                {
                    title: "Add Vendor Status",
                    route: "addStatus",
                    icon: "UserPlusIcon",
                },
                {
                    title: "Update Vendor Status",
                    route: "updateVendorStatus",
                    icon: "UserPlusIcon",
                },
                {
                    title: "AddCondition",
                    route: "AddCondition",
                    icon: "UserPlusIcon",
                },
                {
                    title: "Condition Index",
                    route: "ConditionIndex",
                    icon: "UserPlusIcon",
                },
                {
                    title: "UpdateCondition",
                    route: "UpdateCondition",
                    icon: "UserPlusIcon",
                },
                {
                    title: "Vendor Archived Data",
                    route: "Vendor-Index-Trashed",
                    icon: "UserPlusIcon",
                },
            ],
        },

        {
            title: "Couriers",
            icon: "UserIcon",
            children: [
                {
                    title: "Couriers Index",
                    route: "Courier-Index",
                    icon: "UsersIcon",
                },
                {
                    title: "Add Courier",
                    route: "Courier-Add",
                    icon: "UserPlusIcon",
                },
            ],
        },
        {
            title: "Coupons",
            icon: "DollarSignIcon",
            children: [
                {
                    title: "Coupons Index",
                    route: "Coupon-Index",
                    icon: "DollarSignIcon",
                },
                {
                    title: "Add Coupon",
                    route: "Coupon-Add",
                    icon: "DollarSignIcon",
                },
            ],
        },
        {
            title: "Orders",
            icon: "AnchorIcon",
            children: [
                {
                    title: "Order Status",
                    route: "orderStatus",
                    icon: "AnchorIcon",
                },
                {
                    title: "Add Order Status",
                    route: "addorderStatus",
                    icon: "AnchorIcon",
                },
                {
                    title: "Invoice",
                    route: "invoice",
                    icon: "AnchorIcon",
                },
                {
                    title: "Trashed Status",
                    route: "TrashedIndexStatus",
                    icon: "AnchorIcon",
                },
                {
                    title: "OrderIndex",
                    route: "OrderIndex",
                    icon: "AnchorIcon",
                },
                {
                    title: "CreateOrder",
                    route: "CreateOrder",
                    icon: "AnchorIcon",
                },
                {
                    title: "UpdateOrder",
                    route: "UpdateOrder",
                    icon: "AnchorIcon",
                },
            ],
        },
        {
            title: "E-Commerce",
            icon: "PhoneIcon",
            children: [
                {
                    title: "Attributes",
                    icon: "PhoneIcon",
                    children: [
                        {
                            title: "Add Attributes",
                            route: "Attributes-Add",
                            icon: "PhoneIcon",
                        },
                        {
                            title: "Attributes Index",
                            route: "Attributes-Index",
                            icon: "PhoneIcon",
                        },
                    ],
                },
                {
                    title: "Values",
                    icon: "PhoneIcon",
                    children: [
                        {
                            title: "Add Values",
                            route: "Values-Add",
                            icon: "PhoneIcon",
                        },
                        {
                            title: "Values Index",
                            route: "Values-Index",
                            icon: "PhoneIcon",
                        },
                    ],
                },
            ],
        },
        {
            title: "Locations",
            icon: "PhoneIcon",
            children: [
                {
                    title: "Country",
                    icon: "PhoneIcon",
                    children: [
                        {
                            title: "Add Countries",
                            route: "Countries-Add",
                            icon: "PhoneIcon",
                        },
                        {
                            title: "Countries Index",
                            route: "Countries-Index",
                            icon: "PhoneIcon",
                        },
                    ],
                },
                {
                    title: "City",
                    icon: "PhoneIcon",
                    children: [
                        {
                            title: "Add Cities",
                            route: "Cities-Add",
                            icon: "PhoneIcon",
                        },
                        {
                            title: "Cities Index",
                            route: "Cities-Index",
                            icon: "PhoneIcon",
                        },
                    ],
                },
                {
                    title: "Area",
                    icon: "PhoneIcon",
                    children: [
                        {
                            title: "Add Areas",
                            route: "Areas-Add",
                            icon: "PhoneIcon",
                        },
                        {
                            title: "Areas Index",
                            route: "Areas-Index",
                            icon: "PhoneIcon",
                        },
                    ],
                },
            ],
        },
        {
            title: "Offers",
            icon: "DollarSignIcon",
            children: [
                {
                    title: "Add Offer",
                    icon: "PlusSquareIcon",
                    route: "Offer-Add",
                },
                {
                    title: "Offers Index",
                    icon: "ListIcon",
                    route: "Offers-Index",
                },
                {
                    title: "Offers Trashed",
                    icon: "Trash2Icon",
                    route: "Offers-Trashed",
                },
            ],
        },
        {
            title: "Users",
            icon: "UserIcon",
            children: [
                {
                    title: "Add User",
                    icon: "PlusSquareIcon",
                    route: "User-Add",
                },
                {
                    title: "Users Index",
                    icon: "ListIcon",
                    route: "Users-Index",
                },
                {
                    title: "Users Trashed",
                    icon: "Trash2Icon",
                    route: "Users-Trashed",
                },
            ],
        },
    ],
};
