<template>
  <div @click="closeOpenSearch">
    <validation-observer ref="simpleRules">
      <b-form name="create-item" autocomplete="on">
        <b-row>
          <b-col cols="12">
            <b-card title="Main Data">
              <b-row>
                <b-col sm="12">
                  <div>
                    <!-- Row Loop -->
                    <b-row v-for="(item, index) in itemData.translations" :id="item.id" :key="item.id" ref="row">
                      <!-- translation language -->
                      <b-col md="4">
                        <validation-provider #default="{ errors }" name="language" rules="required">
                          <b-form-group label="Language" label-for="language" :state="errors.length > 0 ? false : null">
                            <v-select :id="'language' + index" v-model="tranLocaleSelect[index]" :state="tranLocaleSelect[index] === null ? false : true
                              " :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                " :options="localeOptions" :selectable="(option) =>
                                  !option.value.includes('select_value')
                                " label="text" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- translation name -->
                      <b-col md="4">
                        <b-form-group label="Name" label-for="name">
                          <validation-provider #default="{ errors }" name="name" rules="required">
                            <b-form-input :id="'TranslationName' + index" autocomplete="on" :style="tranLocaleSelect[index].vlaue == 'ar'
                                ? 'text-align: right !important;'
                                : 'text-align: right !important;'
                              " v-model="itemData.translations[index].name"
                              :state="errors.length > 0 ? false : null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- translation short description -->
                      <b-col md="4">
                        <b-form-group label="Short Description" label-for="short-description">
                          <b-form-input :id="'short-description' + index" autocomplete="on" :style="tranLocaleSelect[index].vlaue == 'ar'
                              ? 'text-align: right !important;'
                              : 'text-align: right !important;'
                            " v-model="itemData.translations[index].short_description
                              " />
                        </b-form-group>
                      </b-col>
                      <!-- translation description -->
                      <b-col md="12">
                        <b-form-group label="Description" label-for="description">
                          <vue-editor :id="'translationDescription' + index" autocomplete="on"
                            v-model="itemData.translations[index].description" />
                        </b-form-group>
                      </b-col>
                      <!-- Remove Button -->
                      <b-col lg="2" md="3" class="mb-50">
                        <b-button v-show="!tranLocaleSelect[index].disa" v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger" class="mt-0 mt-md-2" @click="translationsremoveItem(index)">
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                <!-- add new button -->
                <b-col sm="2">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" block
                    @click="translationsrepeateAgain">
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>Add New</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card title="General Details">
              <b-row>
                <!-- cover -->
                <b-col md="6">
                  <b-form-group label="Cover" label-for="cover">
                    <validation-provider #default="{ errors }" name="Cover" rules="required">
                      <b-form-file v-model="coverImage" @change="uploadImage" :state="errors.length > 0 ? false : null"
                        id="cover" accept="image/*" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Purchase Date -->
                <b-col md="6">
                  <validation-provider #default="{ errors }" name="Purchase Date" rules="required">
                    <b-form-group label="Purchase Date" label-for="purchase date"
                      :state="errors.length > 0 ? false : null">
                      <flat-pickr v-model="itemData.purchse_date" class="form-control" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Active -->
                <b-col md="4">
                  <validation-provider #default="{ errors }" name="active" rules="required">
                    <b-form-group label="Active" label-for="active" :state="errors.length > 0 ? false : null">
                      <v-select id="active" v-model="activeSelect" :state="activeSelect === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="activeOptions" :selectable="(option) => !option.value.includes('select_value')
                          " label="text" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Status -->
                <b-col md="4">
                  <validation-provider #default="{ errors }" name="Status" rules="required">
                    <b-form-group label="Status" label-for="status" :state="errors.length > 0 ? false : null">
                      <v-select id="status" v-model="statusSelect" :state="statusSelect === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="statusOptions" :selectable="(option) => !option.value.includes('select_value')
                          " label="text" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                
                <!-- is_guide Status -->
                <b-col cols="4">
                  <h4>Is Guide :</h4>
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group class="d-flex" id="checkbox-group-2" button-variant="outline-primary"
                      :aria-describedby="ariaDescribedby" name="checkbox-group-2">
                      <b-form-radio v-model="itemData.is_guide" v-bind:value="true">Yes</b-form-radio>
                      <b-form-radio class="ml-1" v-model="itemData.is_guide" v-bind:value="false">No</b-form-radio>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card title="Classifications">
              <b-row>
                <!-- category -->
                <b-col md="6">
                  <validation-provider #default="{ errors }" name="category" rules="required">
                    <b-form-group label="Category" label-for="category" :state="errors.length > 0 ? false : null">
                      <v-select id="category" v-model="categorySelect" @change="getItems"
                        :state="categorySelect === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="categoryOption" :selectable="(option) => !option.value.includes('select_value')
                          " label="text" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- brand -->
                <b-col md="6">
                  <validation-provider #default="{ errors }" name="brand" rules="required">
                    <b-form-group label="Brand" label-for="brand" :state="errors.length > 0 ? false : null">
                      <v-select id="brand" v-model="brandSelect" @change="getItems"
                        :state="brandSelect === null ? false : true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="brandOptions" :selectable="(option) => !option.value.includes('select_value')
                          " label="text" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Item -->
                <b-col md="6">
                  <b-form-group label="Family" label-for="family">
                    <v-select id="family" v-model="itemSelect" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="itemOptions" :selectable="(option) => !option.value.includes('select_value')
                        " label="text" />
                  </b-form-group>
                </b-col>
                <!-- tags -->
                <b-col md="6">
                  <b-form-group label="Tag" label-for="tag">
                    <v-select multiple id="tag" mu v-model="tagSelect"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="tagOptions" :selectable="(option) => !option.value.includes('select_value')
                        " label="text" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card title="Meida">
              <b-row>
                <b-col sm="12">
                  <div>
                    <!-- Row Loop -->
                    <b-row v-for="(item, index) in itemData.images" :id="item.id" :key="item.id">
                      <!-- Image Reapeted -->
                      <b-col md="4">
                        <b-form-group label="Image" label-for="image">
                          <validation-provider #default="{ errors }" name="image" rules="required">
                            <b-form-file v-model="itemDataImages[index].file" @change="uploadImage2($event, index)"
                              :state="errors.length > 0 ? false : null" :id="'image' + index" accept="image/*" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- image description -->
                      <b-col md="4">
                        <b-form-group label="Name" label-for="name">
                          <b-form-input :id="'imagedescription' + index" autocomplete="on"
                            v-model="itemData.images[index].description" />
                        </b-form-group>
                      </b-col>
                      <!-- Remove Button -->
                      <b-col md="1" class="mb-50">
                        <b-button v-if="index > 0" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger"
                          class="mt-0 mt-md-2" @click="removeItem(index)">
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                <!-- add new button -->
                <b-col sm="2">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" block
                    @click="repeateAgain">
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>Add New</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card title="Specifications">
              <b-row>
                <b-col cols="12">
                  <!-- Row Loop -->
                  <b-row @focus="resultShowBund[index] = false" v-for="(item, index) in itemData.taxonomy" :style="taxLocaleSelect[index].value == 'ar'
                      ? 'direction:rtl;'
                      : 'direction:rtl;'
                    " :id="item.id" :key="item.id" ref="row">
                    <!-- Taxonomy locale -->
                    <b-col md="2">
                      <validation-provider #default="{ errors }" name="Locale" rules="required">
                        <b-form-group label-for="active" :state="errors.length > 0 ? false : null">
                          <v-select :id="'locale' + index" v-model="taxLocaleSelect[index]" placeholder="Select Locale"
                            @input="selecteLanguage(index)" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="localeOptions" :selectable="(option) => !option.value.includes('select_value')
                              " label="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Taxonomy term -->
                    <b-col md="2">
                      <validation-provider #default="{ errors }" name="Term" rules="required">
                        <b-form-group label-for="term" :state="errors.length > 0 ? false : null">
                          <v-select :id="'locale' + index" placeholder="Select Term" v-model="termSelect[index]"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="termOptions[index]"
                            :selectable="(option) => !option.value.includes('select_value')
                              " label="text" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Taxonomy name -->
                    <b-col md="3">
                      <b-form-group label-for="name">
                        <validation-provider #default="{ errors }" name="taxonomy_name" rules="required">
                          <b-form-input :id="'name' + index" autocomplete="on" :style="taxLocaleSelect[index].vlaue == 'ar'
                              ? 'text-align: right !important;'
                              : 'text-align: right !important;'
                            " placeholder="Name" v-model="itemData.taxonomy[index].name"
                            :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Taxonomy description -->
                    <b-col md="3" v-if="
                      termSelect[index] != null &&
                      termSelect[index].text != 'مميزات اضافية' &&
                      termSelect[index].text != 'ملحوظات'
                    ">
                      <b-form-group label-for="description">
                        <validation-provider #default="{ errors }" name="taxonomy description" rules="required">
                          <b-form-textarea v-if="
                            itemData.taxonomy[index].name == 'المعالج' ||
                            itemData.taxonomy[index].name ==
                            'عدد أنوية المعالج' ||
                            itemData.taxonomy[index].name == 'WLAN' ||
                            itemData.taxonomy[index].name == 'GPS' ||
                            itemData.taxonomy[index].name ==
                            'معلومات عن الشاحن' ||
                            itemData.taxonomy[index].typeCheck == 'new-record'
                          " autocomplete="off" placeholder="Description" :id="findAndReplace(
                              itemData.taxonomy[index].name_en,
                              ' ',
                              '-'
                            )
                              " :name="findAndReplace(
                              itemData.taxonomy[index].name_en,
                              ' ',
                              '-'
                            )
                              " class="taxDesc" v-model="itemData.taxonomy[index].description"
                            :state="errors.length > 0 ? false : null" />
                          <b-form-input list="my-list-id" v-else placeholder="Description"
                            @focusout="resultShowBund[index] = false" @focus="
                              searchResultBundle(
                                itemData.taxonomy[index].name,
                                termSelect[index].value,
                                taxLocaleSelect[index].value,
                                index,
                                itemData.taxonomy[index].description
                              )
                              " :id="findAndReplace(
                              itemData.taxonomy[index].name_en,
                              ' ',
                              '-'
                            )
                              " class="taxDesc" :name="findAndReplace(
                              itemData.taxonomy[index].name_en,
                              ' ',
                              '-'
                            )
                              " v-model="itemData.taxonomy[index].description" autocomplete="off"
                            :state="errors.length > 0 ? false : null" />

                          <small class="text-danger">{{ errors[0] }}</small>
                          <datalist id="my-list-id">
                            <option v-for="(ite, ind) in productsSRBund" :key="ind">
                              {{ ite.name }}
                            </option>
                          </datalist>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Taxonomy description -->
                    <b-col md="9" v-else>
                      <b-form-group label-for="description">
                        <validation-provider #default="{ errors }" name="taxonomy description" rules="required">
                          <vue-editor :id="findAndReplace(
                            itemData.taxonomy[index].name_en,
                            ' ',
                            '-'
                          )
                            " :name="findAndReplace(
                              itemData.taxonomy[index].name_en,
                              ' ',
                              '-'
                            )
                              " @imageAdded="handleImageAdded" v-model="itemData.taxonomy[index].description"
                            :state="errors.length > 0 ? false : null" rows="20" :editor-toolbar="customToolbar"
                            autocomplete="on"></vue-editor>

                          <small class="text-danger">{{ errors[0] }}</small>
                          <datalist id="my-list-id">
                            
                            <option v-for="(ite, ind) in productsSRBund" :key="ind">
                              {{ ite.name }}
                            </option>
                          </datalist>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="1" v-if="
                      !(
                        itemData.taxonomy[index].name == 'المعالج' ||
                        itemData.taxonomy[index].name ==
                        'عدد أنوية المعالج' ||
                        itemData.taxonomy[index].name == 'WLAN' ||
                        itemData.taxonomy[index].name == 'GPS' ||
                        itemData.taxonomy[index].name ==
                        'معلومات عن الشاحن' ||
                        itemData.taxonomy[index].typeCheck == 'new-record' ||
                        termSelect[index].text == 'مميزات اضافية' ||
                        termSelect[index].text == 'ملحوظات'
                      )
                    ">
                      <b-button style="
                          padding: 7px;
                          margin: 6px;
                          width: 35px;
                          height: 35px;
                          float: right;
                        " v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" class="btn-icon rounded-circle"
                        @click="
                          searchResultBundle(
                            itemData.taxonomy[index].name,
                            termSelect[index].value,
                            taxLocaleSelect[index].value,
                            index,
                            itemData.taxonomy[index].description
                          )
                          ">
                        <feather-icon icon="SearchIcon" style="width: 15px; height: 15px;" />
                      </b-button>
                    </b-col>
                    <b-col cols="1" v-else>
                    </b-col>

                    <!-- Remove Button -->
                    <b-col md="1" class="mb-50">
                      <b-button v-if="index > 0" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger"
                        class="mt-0 mb-0" style="margin-bottom: 0 !important;" @click="taxonomyremoveItem(index)">
                        <feather-icon icon="XIcon" class="mr-25" />
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr style="margin-top: 0 !important;" />
                    </b-col>
                  </b-row>
                </b-col>
                <!-- add new button -->
                <b-col cols="12">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
                    @click="taxonomyrepeateAgain">
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>Add New</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12" class="mt-5">
            <b-card title="Attributes and Values">
              <b-row>
                <b-col cols="12">
                  <div>
                    <!-- Row Loop -->
                    <b-row v-for="(item, index) in attrOptions" :key="index">
                      <b-col md="2" class="d-flex justify-content-center align-items-center">
                        <p class="text-primary mb-0 font-weight-bold h4">
                          {{ item.text }}
                        </p>
                      </b-col>

                      <b-col md="4">
                        <validation-provider #default="{ errors }" name="values" rules="required">
                          <b-form-group label="Values" label-for="values" :state="errors.length > 0 ? false : null">
                            <v-select multiple :id="'values' + index" v-model="valuesSelect[index]" :clearable="false"
                              :state="valuesSelect[index] === null ? false : true
                                " :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                " :options="item.values" label="text" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card title="Prices">
              <b-row>
                <b-col sm="12">
                  <div>
                    <!-- Row Loop -->
                    <b-row v-for="(item, index) in itemData.prices" :id="item.id" :key="item.id">
                      <!-- price number -->
                      <b-col md="2">
                        <b-form-group>
                          <label>Price</label>
                          <validation-provider #default="{ errors }" rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                            name="Number">
                            <b-form-input autocomplete="on" v-model="itemData.prices[index].price"
                              :state="errors.length > 0 ? false : null" placeholder="Enter Number Only" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- price date -->
                      <b-col md="3">
                        <validation-provider #default="{ errors }" name="price_date" rules="required">
                          <b-form-group label="Price Date" label-for="price_date"
                            :state="errors.length > 0 ? false : null" placeholder="Purchase Date">
                            <flat-pickr v-model="itemData.prices[index].date" class="form-control" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- price currency -->
                      <b-col md="3">
                        <validation-provider #default="{ errors }" name="Currency" rules="required">
                          <b-form-group label="Currency" label-for="currency" :state="errors.length > 0 ? false : null">
                            <v-select :id="'language' + index" v-model="currencySelect[index]" :state="currencySelect[index] === null ? false : true
                              " :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                " :options="currencyOptions" :selectable="(option) =>
                                  !option.value.includes('select_value')
                                " label="text" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- Remove Button -->
                      <b-col md="1" class="mb-50">
                        <b-button v-if="index > 0" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger"
                          class="mt-0 mt-md-2" @click="pricesremoveItem(index)">
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <!-- price discription -->
                      <b-col md="12">
                        <b-form-group>
                          <label>Description</label>
                          <validation-provider #default="{ errors }" rules="required" name="price description">
                            <!-- <b-form-input autocomplete="on" v-model="itemData.prices[index].description" :state="errors.length > 0 ? false:null" /> -->
                            <vue-editor :id="'translationDescription' + index" autocomplete="on"
                              v-model="itemData.prices[index].description" :state="errors.length > 0 ? false : null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                <!-- add new button -->
                <b-col sm="2">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" block
                    @click="pricesrepeateAgain">
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>Add New</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- submit button -->
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm">
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- error handelr -->
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import { heightTransition } from '@core/mixins/ui/transition'
import BCardCode from '@core/components/b-card-code'
// import axios from '@/libs/axios'

export default {
  components: {
    BCardCode,
    VueEditor,
    flatPickr,
  },


  mixins: [heightTransition],
  data() {
    return {
      mountedform: false,
      clearData: false,
      errors_back: [],
      showDismissibleAlert: false,
      //     refheightForm: '',
      number: '',
      ProfilePictureBase64: [],
      coverImage: [],
      itemImag: [],
      itemDataImages: [
        {
          file: [],
        },
      ],
      categorySelect: {
        value: '',
        text: '',
      },
      categoryOption: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      brandSelect: {
        value: '',
        text: '',
      },
      brandOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      tagSelect: [],
      tagOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      activeSelect: {
        value: '',
        text: '',
      },
      activeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '1',
          text: 'True',
        },
        {
          value: '0',
          text: 'False',
        },
      ],
      statusSelect: {
        value: '',
        text: '',
      },
      statusOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'enabled',
          text: 'Enabled',
        },
        {
          value: 'disabled',
          text: 'Disabled',
        },
        {
          value: 'reviewed',
          text: 'Reviewed',
        },
        {
          value: 'archieved',
          text: 'Archieved',
        },
      ],
      termSelect: [],
      termOptions: [[]],
      localeSelect: null,
      taxLocaleSelect: [],
      tranLocaleSelect: [],
      localeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      currencySelect: [],
      currencyOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      productsSRBund: [],
      resultShowBund: [],
      itemData: {
        is_guide: false,
        purchse_date: '',
        cover: [],
        active: '',
        status: '',
        category_id: '',
        brand_id: '',
        parent_id: '',
        images: [
          {
            file: [],
            description: null,
          },
        ],
        translations: [],
        prices: [],
        tags: [],
        taxonomy: [],
      },
      itemSelect: {
        value: '',
        text: '',
      },
      itemOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      itemSe: true,
      required,
      customToolbar: [
        [{ font: ['cairo'] }],

        [{ header: [false, 1, 2, 3, 4, 5, 6] }],

        [{ size: ['small', false, 'large', 'huge'] }],

        ['bold', 'italic', 'underline', 'strike'],

        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],

        [{ header: 1 }, { header: 2 }],

        ['blockquote', 'code-block'],

        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],

        [{ script: 'sub' }, { script: 'super' }],

        [{ indent: '-1' }, { indent: '+1' }],

        [
          {
            color: [
              '#000000',
              '#0C090A',
              '#34282C',
              '#3B3131',
              '#3A3B3C',
              '#454545',
              '#413839',
              '#3D3C3A',
              '#463E3F',
              '#4C4646',
              '#504A4B',
              '#565051',
              '#52595D',
              '#5C5858',
              '#625D5D',
              '#616D7E',
              '#36454F',
              '#CECECE',
              '#B6B6B4',
              '#8D918D',
              '#808080',
              '#757575',
              '#797979',
              '#C9C0BB',
              '#C0C0C0',
              '#DCDCDC',
              '#D3D3D3',
              '#FAF0E6',
              '#FFF5EE',
              '#FFFFFF',
              '#FEFCFF',
              '#FFFAFA',
              '#FBFBF9',
              '#F5F5F5',
              '#FFFFF7',
              '#FFFFF0',
              '#FFFAF0',
              '#FAF5EF',
              '#FFF5EE',
              '#08308B',
              '#000080',
              '#151B8D',
              '#00008B',
              '#15317E',
              '#0000A0',
              '#0000A5',
              '#0020C2',
              '#0000CD',
              '#00BFFF',
              '#38ACEC',
              '#79BAEC',
              '#82CAFF',
              '#87CEFA',
              '#3BB9FF',
              '#8C9EFF',
              '#954535',
              '#A0522D',
              '#8B4513',
              '#8A4117',
              '#7E3817',
              '#7E3517',
              '#C34A2C',
              '#C04000',
              '#C35817',
              '#B86500',
              '#D2691E',
              '#CC6600',
              '#E56717',
              '#E66C2C',
              '#FF6700',
              '#FF5F1F',
              '#F87217',
              '#F88017',
              '#FF8C00',
              '#ED6009',
              '#FF0000',
              '#FD1C03',
              '#FF2400',
              '#F62217',
              '#F70D1A',
              '#F62817',
              '#E42217',
              '#B71C1C',
            ],
          },
          {
            background: [
              '#000000',
              '#0C090A',
              '#34282C',
              '#3B3131',
              '#3A3B3C',
              '#454545',
              '#413839',
              '#3D3C3A',
              '#463E3F',
              '#4C4646',
              '#504A4B',
              '#565051',
              '#52595D',
              '#5C5858',
              '#625D5D',
              '#616D7E',
              '#36454F',
              '#CECECE',
              '#B6B6B4',
              '#8D918D',
              '#808080',
              '#757575',
              '#797979',
              '#C9C0BB',
              '#C0C0C0',
              '#DCDCDC',
              '#D3D3D3',
              '#FAF0E6',
              '#FFF5EE',
              '#FFFFFF',
              '#FEFCFF',
              '#FFFAFA',
              '#FBFBF9',
              '#F5F5F5',
              '#FFFFF7',
              '#FFFFF0',
              '#FFFAF0',
              '#FAF5EF',
              '#FFF5EE',
              '#08308B',
              '#00BFFF',
              '#38ACEC',
              '#79BAEC',
              '#82CAFF',
              '#87CEFA',
              '#3BB9FF',
              '#8C9EFF',
              '#954535',
              '#A0522D',
              '#8B4513',
              '#8A4117',
              '#7E3817',
              '#7E3517',
              '#C34A2C',
              '#C04000',
              '#C35817',
              '#B86500',
              '#D2691E',
              '#CC6600',
              '#E56717',
              '#E66C2C',
              '#FF6700',
              '#FF5F1F',
              '#F87217',
              '#F88017',
              '#FF8C00',
              '#ED6009',
              '#FF0000',
              '#FD1C03',
              '#FF2400',
              '#F62217',
              '#F70D1A',
              '#F62817',
              '#E42217',
              '#B71C1C',
            ],
          },
        ],

        ['link', 'image', 'video', 'formula'],

        [{ direction: 'rtl' }, { direction: 'ltr' }],
        ['clean'],
      ],
      attrOptions: [],
      valuesSelect: [],
    }
  },
  watch: {
    categorySelect: function () {
      this.itemSelect = null
      this.getItems()

      this.fetchAttributesData()

    },
    brandSelect: function () {
      this.itemSelect = null
      this.getItems()
    },
  },
  updated() {
    this.mountedform = true
  },
  created() {
    axios
      .get('duplicate/items/' + this.$route.params.id + '/show')
      .then((result) => {
        const data = result.data.data
        const responseItemData = data.item

        for (let index in responseItemData.item_translations) {
          this.itemData.translations.push({
            locale: responseItemData.item_translations[index].locale,
            name: responseItemData.item_translations[index].name,
            short_description:
              responseItemData.item_translations[index].short_description,
            description: responseItemData.item_translations[index].description,
          })

          if (responseItemData.item_translations[index].locale == 'ar') {
            this.tranLocaleSelect.push({
              text: 'Arabic',
              value: 'ar',
            })
          } else {
            this.tranLocaleSelect.push({
              text: 'English',
              value: 'en',
            })
          }
        }

        
        this.itemData.is_guide = responseItemData.is_guide
        this.itemData.purchse_date = responseItemData.purchse_date
        if (responseItemData.active == true) {
          this.activeSelect.text = 'True'
          this.activeSelect.value = '1'
        } else {
          this.activeSelect.text = 'False'
          this.activeSelect.value = '0'
        }
        if (responseItemData.status == 'enabled') {
          this.statusSelect.text = 'Enabled'
          this.statusSelect.value = 'enabled'
        } else if (responseItemData.status == 'disabled') {
          this.statusSelect.text = 'Disabled'
          this.statusSelect.value = 'disabled'
        } else if (responseItemData.status == 'reviewed') {
          this.statusSelect.text = 'reviewed'
          this.statusSelect.value = 'Reviewed'
        } else {
          this.statusSelect.text = 'Archieved'
          this.statusSelect.value = 'archieved'
        }
        this.categorySelect.value = responseItemData.category.id.toString()
        this.categorySelect.text = responseItemData.category.translation.name
        this.brandSelect.value = responseItemData.brand.id.toString()
        this.brandSelect.text = responseItemData.brand.translation.name
        if (responseItemData.parent != null) {
          this.itemSelect.value = responseItemData.parent.id.toString()
          this.itemSelect.text = responseItemData.parent.translation.name
        }
        for (let ind in responseItemData.tags) {
          this.tagSelect.push({
            value: responseItemData.tags[ind].id.toString(),
            text: responseItemData.tags[ind].name,
          })
        }

        for (let ind in responseItemData.item_prices) {
          this.itemData.prices.push({
            description: responseItemData.item_prices[ind].description,
            price: responseItemData.item_prices[ind].price,
            date: responseItemData.item_prices[ind].date,
            currency_id: responseItemData.item_prices[
              ind
            ].currency.id.toString(),
          })
          this.currencySelect.push({
            value: responseItemData.item_prices[ind].currency.id.toString(),
            text: responseItemData.item_prices[ind].currency.name,
          })
        }
        for (let ind in responseItemData.taxonomies) {
          if (responseItemData.taxonomies[ind].locale == 'ar') {
            this.taxLocaleSelect.push({
              text: 'Arabic',
              value: 'ar',
            })
          } else {
            this.taxLocaleSelect.push({
              text: 'English',
              value: 'en',
            })
          }
          this.itemData.taxonomy.push({
            term_id: responseItemData.taxonomies[ind].pivot.term_id.toString(),
            name: responseItemData.taxonomies[ind].pivot.name,
            locale: responseItemData.taxonomies[ind].pivot.locale,
            description: responseItemData.taxonomies[ind].pivot.description,
            name_en: responseItemData.taxonomies[ind].pivot.slug,
            taxDis: false,
          })
          this.termSelect.push({
            value: responseItemData.taxonomies[ind].pivot.term_id.toString(),
            text: responseItemData.taxonomies[ind].name,
          })
        }

        this.selecteLanguage(0)
        for (let index in this.taxLocaleSelect) {
          this.termOptions.push([])
        }
        for (let ind in this.termOptions) {
          this.termOptions[ind] = this.termOptions[0]
        }

        this.getItems()

        // set categories data
        const responseCategoriesData = data.categories
        for (let cat in responseCategoriesData) {
          if (responseCategoriesData[cat].translation != null) {
            this.categoryOption.push({
              value: responseCategoriesData[cat].id.toString(),
              text: responseCategoriesData[cat].translation.name,
            })
          } else {
            this.categoryOption.push({
              value: responseCategoriesData[cat].id.toString(),
              text: 'undefinded',
            })
          }
        }

        //

        // set brands data
        const responseBrandsData = data.brands
        for (let bran in responseBrandsData) {
          if (responseBrandsData[bran].translation != null) {
            this.brandOptions.push({
              value: responseBrandsData[bran].id.toString(),
              text: responseBrandsData[bran].translation.name,
            })
          } else {
            this.brandOptions.push({
              value: responseBrandsData[bran].id.toString(),
              text: 'undefinded',
            })
          }
        }

        // set tags data

        const responseTagsData = data.tags
        for (let ta in responseTagsData) {
          this.tagOptions.push({
            value: responseTagsData[ta].id.toString(),
            text: responseTagsData[ta].name,
          })
        }

        // set currenccies data
        const responseCurrenciesData = data.currencies
        for (let curr in responseCurrenciesData) {
          this.currencyOptions.push({
            value: responseCurrenciesData[curr].id.toString(),
            text: responseCurrenciesData[curr].name,
          })
        }

        // set attributes data
        const responseAttributesData = data.attributes
        for (let attr in responseAttributesData) {
          if (responseAttributesData[attr].translation != null) {
            this.attrOptions.push({
              value: responseAttributesData[attr].id,
              text: responseAttributesData[attr].translation.name,
              values: [],
            })
            this.valuesSelect[attr] = []
            for (let val in responseAttributesData[attr].values) {
              this.attrOptions[attr].values.push({
                text: responseAttributesData[attr].values[val].translation.name,
                value: responseAttributesData[attr].values[val].id,
              })

              if (
                responseItemData.values_id.includes(
                  responseAttributesData[attr].values[val].id
                )
              ) {
                this.valuesSelect[attr].push({
                  text:
                    responseAttributesData[attr].values[val].translation.name,
                  value: responseAttributesData[attr].values[val].id,
                })
              }
            }
            // if(valueExists == false){
            //   this.valuesSelect.push([]);

            // }
          }
        }


      })
      .catch((err) => {

        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
    if (this.clearData) {
      localStorage.removeItem('BFormInput')
    }
    // fetch category index
    // axios
    //   .get('categories')
    //   .then((result) => {
    //     const data = result.data.data
    //     for (let cat in data) {
    //       if (data[cat].translation != null) {
    //         this.categoryOption.push({
    //           value: data[cat].id.toString(),
    //           text: data[cat].translation.name,
    //         })
    //       } else {
    //         this.categoryOption.push({
    //           value: data[cat].id.toString(),
    //           text: 'undefinded',
    //         })
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     this.errors_back.length = 0
    //     if (err.response.data.data != null) {
    //       if (this.isString(err.response.data.data)) {
    //         this.errors_back.push({
    //           error: err.response.data.data,
    //         })
    //       } else {
    //         this.errors_back = err.response.data.data
    //       }
    //       this.showDismissibleAlert = true
    //     } else {
    //       this.errors_back = []
    //       this.errors_back.push({
    //         error: 'internal server error',
    //       })
    //       this.showDismissibleAlert = true
    //     }
    //   })
    // // fetch brands index
    // axios
    //   .get('brands')
    //   .then((result) => {
    //     const data = result.data.data
    //     for (let bran in data) {
    //       if (data[bran].translation != null) {
    //         this.brandOptions.push({
    //           value: data[bran].id.toString(),
    //           text: data[bran].translation.name,
    //         })
    //       } else {
    //         this.brandOptions.push({
    //           value: data[bran].id.toString(),
    //           text: 'undefinded',
    //         })
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     this.errors_back.length = 0
    //     if (err.response.data.data != null) {
    //       if (this.isString(err.response.data.data)) {
    //         this.errors_back.push({
    //           error: err.response.data.data,
    //         })
    //       } else {
    //         this.errors_back = err.response.data.data
    //       }
    //       this.showDismissibleAlert = true
    //     } else {
    //       this.errors_back = []
    //       this.errors_back.push({
    //         error: 'internal server error',
    //       })
    //       this.showDismissibleAlert = true
    //     }
    //   })
    // // fetch tags index
    // axios
    //   .get('tags')
    //   .then((result) => {
    //     const data = result.data.data
    //     for (let ta in data) {
    //       this.tagOptions.push({
    //         value: data[ta].id.toString(),
    //         text: data[ta].name,
    //       })
    //     }
    //   })
    //   .catch((err) => {
    //     this.errors_back.length = 0
    //     if (err.response.data.data != null) {
    //       if (this.isString(err.response.data.data)) {
    //         this.errors_back.push({
    //           error: err.response.data.data,
    //         })
    //       } else {
    //         this.errors_back = err.response.data.data
    //       }
    //       this.showDismissibleAlert = true
    //     } else {
    //       this.errors_back = []
    //       this.errors_back.push({
    //         error: 'internal server error',
    //       })
    //       this.showDismissibleAlert = true
    //     }
    //   })
    // //  fetch currency index
    // axios
    //   .get('currencies')
    //   .then((result) => {
    //     const data = result.data.data
    //     for (let curr in data) {
    //       this.currencyOptions.push({
    //         value: data[curr].id.toString(),
    //         text: data[curr].name,
    //       })
    //     }
    //   })
    //   .catch((err) => {
    //     this.errors_back.length = 0
    //     if (err.response.data.data != null) {
    //       if (this.isString(err.response.data.data)) {
    //         this.errors_back.push({
    //           error: err.response.data.data,
    //         })
    //       } else {
    //         this.errors_back = err.response.data.data
    //       }
    //       this.showDismissibleAlert = true
    //     } else {
    //       this.errors_back = []
    //       this.errors_back.push({
    //         error: 'internal server error',
    //       })
    //       this.showDismissibleAlert = true
    //     }
    //   })
  },
  methods: {

    fetchAttributesData() {
      this.attrOptions = [];
      axios
        .get('categories/' + this.categorySelect.value + '/attributes/get')
        .then((result) => {
          const data = result.data.data

          this.attrOptions = data.map(attr => ({
            value: attr.id,
            text: attr.name,
            values: attr.values.map(value => ({
              text: value.name,
              value: value.id,
            })),
          }));

          this.valuesSelect = Array(data.length).fill([]);


        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData()
      formData.append('image', file)


      axios
        .post('items/image-editor/store', formData)
        .then((result) => {
          showDismissibleAlert = false
          const url = result.data.url // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // submit add form
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {

        if (!success) {

          this.$swal({
                position: 'center',
                icon: 'warning',
                title: 'Missing Fields!',
                html: `
                  <p>Please fill Required fields</p>
                `,
                showConfirmButton: false,
                timer: 1500,
              })
    }


        if (success) {
          this.itemData.values = []
          for (let values in this.valuesSelect) {
            const currentGroup = this.valuesSelect[values];

            // Check if currentGroup exists and is an array or object with values
            if (currentGroup && typeof currentGroup === 'object' && Object.keys(currentGroup).length > 0) {
              for (let val in currentGroup) {
                const currentValue = currentGroup[val];

                // Check if currentValue has `text` and `value`
                if (currentValue && currentValue.text && currentValue.value) {
                  this.itemData.values.push(currentValue.value);
                } else {

                  return this.$swal({
                    position: 'center',
                    icon: 'warning',
                    title: 'Missing Field for Values!',
                    html: `Invalid entry found in Select`,
                    showConfirmButton: false,
                    timer: 3000,
                  });
                }
              }
            } else {

              return this.$swal({
                position: 'center',
                icon: 'warning',
                title: 'Missing Fields for Values!',
                html: `Empty or invalid Select`,
                showConfirmButton: false,
                timer: 3000,
              });
            }
          }
          this.itemData.active = this.activeSelect.value
          this.itemData.status = this.statusSelect.value
          if (this.itemData.cover.includes(',')) {
            this.itemData.cover = this.itemData.cover
              .split(',')
              .map((item) => item.trim())
            this.itemData.cover = this.itemData.cover[1]
          }
          this.itemData.category_id = this.categorySelect.value
          this.itemData.brand_id = this.brandSelect.value
          if (this.itemSelect != null) {
            this.itemData.parent_id = this.itemSelect.value
          }
          this.itemData.tags = []
          for (let tag in this.tagSelect) {
            this.itemData.tags.push(this.tagSelect[tag].value)
          }
          for (let tax in this.itemData.taxonomy) {
            this.itemData.taxonomy[tax].locale = this.taxLocaleSelect[tax].value
            this.itemData.taxonomy[tax].term_id = this.termSelect[tax].value
          }
          for (let pri in this.itemData.prices) {
            this.itemData.prices[pri].currency_id = this.currencySelect[
              pri
            ].value
          }

          for (let trn in this.itemData.translations) {
            this.itemData.translations[trn].locale = this.tranLocaleSelect[
              trn
            ].value
          }
          for (let img in this.itemData.images) {
            if (this.itemData.images[img].file.includes(',')) {
              this.itemData.images[img].file = this.itemData.images[img].file
                .split(',')
                .map((item) => item.trim())
              this.itemData.images[img].file = this.itemData.images[img].file[1]
            }
          }

          this.showDismissibleAlert = false
          this.errors_back = []
          axios
            .post('items-guide/store', this.itemData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push({
                path: '/ShowPhoneGuide/' + result.data.data.id,
              })
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    closeOpenSearch() {
      // if (this.resultShowBund.includes(true) ) {
      // //
      // //     this.resultShowBund[this.resultShowBund.indexOf(true)] = false
      //      for (let index in this.resultShowBund){
      //
      //          this.resultShowBund[index] = false
      //      }
      // }
    },
    searchResultBundle(name, temID, lang, ind, text) {

      // this.noResultBund = false
      var data = null
      if (text == '') {

        
         data = {
            locale:lang,
            name:name,
          }
      } else {
     
        
           data = {
            locale:lang,
            name:name,
            text:text,
          }
      }
      axios
        .post('autocomplete/terms/' + temID + '/taxonomies/get' , data  )
        .then((result) => {
          const data = result.data.data
          this.productsSRBund.length = 0
          for (let index in data) {
            this.productsSRBund.push({
              name: data[index].description,
            })
          }
          if (this.productsSRBund.length > 0) {
            this.resultShowBund[ind] = true
          } else {
            this.resultShowBund[ind] = false
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // get items
    getItems() {
      if (this.categorySelect != null && this.brandSelect != null) {
        this.itemOptions = [
          {
            value: 'select_value',
            text: 'Select Value',
          },
        ]
        axios
          .get(
            'items/' +
            this.categorySelect.value +
            '/' +
            this.brandSelect.value +
            '/get_items'
          )
          .then((result) => {
            const data = result.data.data

            this.itemSe = false
            if (data.length > 0) {
              for (let ind in data) {
                if (data[ind].item_translations != null) {
                  this.itemOptions.push({
                    text: data[ind].item_translations.name,
                    value: data[ind].id.toString(),
                  })
                }
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      }
    },
    // cencode cover
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.itemData.cover = e.target.result
      }
      reader.readAsDataURL(file)
    },
    // upload function images of item
    uploadImage2(event, index) {
      let files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        this.itemData.images[index].file = []
        return
      } else {
        this.createImage2(files[0], index)
      }
    },
    createImage2(file, ind) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.itemData.images[ind].file = e.target.result
      }
      reader.readAsDataURL(file)
    },
    selecteLanguage2(index) {
      // this.itemData.taxonomy[index].taxDis = false
      // fetch terms index
      axios
        .get('terms/locale-index/' + this.taxLocaleSelect[index].value)
        .then((result) => {
          const data = result.data.data
          //
          this.termOptions[index].length = 0
          // for (let ter in data) {
          //     if (data[ter].translation != null) {
          //         this.termOptions[index].push({
          //             value: data[ter].id.toString(),
          //             text: data[ter].translation.name
          //         })
          //     } else {
          //         this.termOptions[index].push({
          //             value: data[ter].id.toString(),
          //             text: "undefinded"
          //         })
          //     }
          // }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    selecteLanguage(index) {
      // this.itemData.taxonomy[index].taxDis = false
      // fetch terms index
      axios
        .get('terms/locale-index/' + this.taxLocaleSelect[index].value)
        .then((result) => {
          const data = result.data.data

          this.termOptions[index].length = 0
          for (let ter in data) {
            if (data[ter].translation != null) {
              this.termOptions[index].push({
                value: data[ter].id.toString(),
                text: data[ter].translation.name,
              })
            } else {
              this.termOptions[index].push({
                value: data[ter].id.toString(),
                text: 'undefinded',
              })
            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    //REPETED FORM
    repeateAgain() {
      //   this.refheightForm = this.$refs.Imagesform
      this.itemDataImages.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.itemData.images.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeItem(index) {
      if (this.itemData.images.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.itemDataImages.splice(index, 1)
        this.itemData.images.splice(index, 1)
      }
    },
    taxonomyrepeateAgain() {
      //    this.refheightForm = this.$refs.Taxonomiesform
      this.taxLocaleSelect.push({
        text: 'Arabic',
        value: 'ar',
      })
      this.itemData.taxonomy.push({
        term_id: '',
        name: '',
        locale: '',
        description: '',
        name_en: '',
        taxDis: true,
        typeCheck: 'new-record',
      })
      this.termSelect.push()
      this.termOptions.push(this.termOptions[0])
    },
    taxonomyremoveItem(index) {
      if (this.itemData.taxonomy.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.itemData.taxonomy.splice(index, 1)
        this.termSelect.splice(index, 1)
        this.taxLocaleSelect.splice(index, 1)
        this.termOptions.splice(index, 1)
      }
    },
    pricesrepeateAgain() {
      //  this.refheightForm = this.$refs.pricesForm
      this.itemData.prices.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.currencySelect.push()
    },
    pricesremoveItem(index) {
      if (this.itemData.prices.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.itemData.prices.splice(index, 1)
        this.currencySelect.splice(index, 1)
      }
    },
    translationsrepeateAgain() {
      // this.refheightForm = this.$refs.translationForm
      this.itemData.translations.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.tranLocaleSelect.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
    },
    translationsremoveItem(index) {
      if (this.itemData.translations.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.itemData.translations.splice(index, 1)
        this.tranLocaleSelect.splice(index, 1)
      }
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    findAndReplace(string, target, replacement) {
      var i = 0,
        length = string.length
      for (i; i < length; i++) {
        string = string.replace(target, replacement)
      }
      return string
    },
  }, beforeRouteLeave(to, from, next) {
    if(to.path.includes("ShowPhoneGuide")){
      next();

    }else{

      // Display a confirmation dialog
      const confirmLeave = window.confirm('Are you sure you want to leave this page? Any unsaved changes will be lost.');
  
      // If the user confirms, allow navigation
      if (confirmLeave) {
        next();
      } else {
        // If the user cancels, prevent navigation
        next(false);
      }
    }


  }
}
</script>

<style lang="scss">
.repeater-form {
  transition: 0.35s height;
}

.v-select {
  font-size: 14px;
}

.vs__open-indicator {
  width: 0px;
}

.taxDesc {
  text-align: right !important;
}

//  itemData.taxonomy[index].name != 'البلوتوث' && itemData.taxonomy[index].name != 'دقة الشاشة' && itemData.taxonomy[index].name != 'المعالج' && itemData.taxonomy[index].name != 'عدد انوية المعالج'
#جودة_تصوير_الفيديو {
  text-align: left !important;
}

#البلوتوث {
  text-align: left !important;
}

#دقة_الشاشة {
  text-align: left !important;
}

#المعالج {
  text-align: left !important;
}

#عدد_انوية_المعالج {
  text-align: left !important;
}
</style>
