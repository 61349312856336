<template>
  <div v-if="paymentSessionData">
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, index) in values" :key="index">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-row class="row-eq-height">
      <b-col class="mb-1" cols="12" md="6">
        <b-card title="Payment Session" class="h-100 d-flex flex-wrap">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">Code</th>
                <td class="text-right">{{ paymentSessionData.code }}</td>
              </tr>
              <tr>
                <th class="text-left">Status</th>
                <td class="text-right">
                  <b-badge
                    :variant="
                      paymentSessionRealTime.payment_status == 'pending'
                        ? 'light-primary'
                        : paymentSessionRealTime.payment_status == 'delivered'
                        ? 'light-success'
                        : paymentSessionRealTime.payment_status == 'failed'
                        ? 'light-danger'
                        : 'light-secondary'
                    "
                  >
                    {{ paymentSessionRealTime.payment_status }}
                  </b-badge>
                </td>
              </tr>
              <tr>
                <th class="text-left">Start Date</th>
                <td class="text-right">{{ paymentSessionData.start_date }}</td>
              </tr>
              <tr>
                <th class="text-left">success Date</th>
                <td class="text-right">
                  {{ paymentSessionData.success_date }}
                </td>
              </tr>
              <tr>
                <th class="text-left">payment gateway</th>
                <td class="text-right" dir="rtl" v-if="paymentSessionData.payment_provider">
                  <b-img-lazy
                  
                    width="35"
                    :src="
                      paymentSessionData.payment_provider.logo
                    "
                  />
                  
                  <b-badge variant="light-primary" class="mx-1">
                    {{ paymentSessionData.payment_gateway }}</b-badge
                  >
                </td>
              </tr>

              <tr>
                <th class="text-left">Total Paid</th>
                <td class="text-right" dir="rtl">
                  {{ paymentSessionData.total_paid }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Payout Amount</th>
                <td class="text-right" dir="rtl">
                  {{ paymentSessionData.payout_amount }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Margin Amount</th>
                <td class="text-right" dir="rtl">
                  {{ paymentSessionData.margin_amount }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col class="mb-1" cols="12" md="6">
        <b-card title="Payment Session" class="h-100 d-flex flex-wrap">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">Valid Location</th>

                <td
                  class="text-right"
                  v-if="paymentSessionRealTime.validat_location"
                >
                  <span>
                    <img
                      :src="require('@/assets/images/icons/checked-true.svg')"
                    />
                  </span>
                </td>
                <td class="text-right" v-else>
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-left">Valide Buyer Ids</th>

                <td
                  class="text-right"
                  v-if="paymentSessionRealTime.validate_buyer_id"
                >
                  <span>
                    <img
                      :src="require('@/assets/images/icons/checked-true.svg')"
                    />
                  </span>
                </td>
                <td class="text-right" v-else>
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-left">Valide Seller Ids</th>

                <td
                  class="text-right"
                  v-if="paymentSessionRealTime.validate_seller_id"
                >
                  <span>
                    <img
                      :src="require('@/assets/images/icons/checked-true.svg')"
                    />
                  </span>
                </td>
                <td class="text-right" v-else>
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-left">Payment Session Status</th>

                <td
                  class="text-right"
                  v-if="paymentSessionRealTime.payment_status != 'pending'"
                >
                  <b-badge
                    variant="light-success">
                    {{ paymentSessionRealTime.payment_status }}
                  </b-badge>
                </td>
                <td class="text-right" v-else>
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <b-button
            block
            variant="outline-success "
            :to="{ path: '/paymentSessions/show/' + paymentSessionData.id }"
            class="mt-5"
          >
            Show Payment Session
          </b-button>
        </b-card>
      </b-col>
      <b-col class="mb-1" cols="12" md="12">
        <b-row>
          <!-- Seller User Info: Left col -->
          <b-col cols="12" md="6" class="mb-1">
            <b-card
              title="Seller"
              class="cardH d-flex flex-wrap table-responsive"
            >
              <b-row>
                <b-col cols="12" md="6">
                  <b-card title="ID fornt picture">
                    <div
                      class="d-flex justify-content-start"
                      v-if="paymentSessionRealTime.seller_id && paymentSessionRealTime.seller_id.front"
                    >
                      <b-img
                        @click="img2Index = 0"
                        rounded
                        :src="paymentSessionRealTime.seller_id.front"
                        alt="d"
                        style="width: 100%; height: auto"
                      />
                    </div>
                    <div class="spinner-border m-5" role="status" v-else>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </b-card>
                </b-col>
                <b-col cols="12" md="6">
                  <b-card title="ID back picture">
                    <div
                      class="d-flex justify-content-start"
                      v-if="paymentSessionRealTime.seller_id && paymentSessionRealTime.seller_id.back"
                    >
                      <b-img
                        @click="img3Index = 0"
                        rounded
                        :src="paymentSessionRealTime.seller_id.back"
                        alt="h"
                        style="width: 100%; height: auto"
                      />
                    </div>
                    <div class="spinner-border m-5" role="status" v-else>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
              <!-- User Avatar & Action Buttons -->
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th class="text-left">Location Lat</th>
                    <td
                      class="text-right"
                      v-if="paymentSessionRealTime.seller_location.lat"
                    >
                      {{ paymentSessionRealTime.seller_location.lat }}
                    </td>
                    <td class="text-right" v-else>
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th class="text-left">Location Lng</th>
                    <td
                      class="text-right"
                      v-if="paymentSessionRealTime.seller_location.lng"
                    >
                      {{ paymentSessionRealTime.seller_location.lng }}
                    </td>
                    <td class="text-right" v-else>
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>

          <b-col class="mb-1" cols="12" md="6">
            <b-card
              title="Buyer User"
              class="d-flex flex-wrap table-responsive"
            >
              <b-row>
                <b-col cols="12" md="6">
                  <b-card title="ID fornt picture">
                    <div
                      class="d-flex justify-content-start"
                      v-if="paymentSessionRealTime.buyer_id && paymentSessionRealTime.buyer_id.front"
                    >
                      <b-img
                        @click="img2Index = 0"
                        rounded
                        :src="paymentSessionRealTime.buyer_id.front"
                        alt="d"
                        style="width: 100%; height: auto"
                      />
                    </div>
                    <div class="spinner-border m-5" role="status" v-else>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </b-card>
                </b-col>
                <b-col cols="12" md="6">
                  <b-card title="ID back picture" class="mt-0">
                    <div
                      class="d-flex justify-content-start"
                      v-if="paymentSessionRealTime.buyer_id && paymentSessionRealTime.buyer_id.back"
                    >
                      <b-img
                        @click="img3Index = 0"
                        rounded
                        :src="paymentSessionRealTime.buyer_id.back"
                        alt="h"
                        style="width: 100%; height: auto"
                      />
                    </div>
                    <div class="spinner-border m-5" role="status" v-else>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th class="text-left">Location Lat</th>
                    <td
                      class="text-right"
                      v-if="paymentSessionRealTime.buyer_location.lat"
                    >
                      {{ paymentSessionRealTime.buyer_location.lat }}
                    </td>
                    <td class="text-right" v-else>
                      <div class="spinner-border text-secondary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th class="text-left">Location Lng</th>
                    <td
                      class="text-right"
                      v-if="paymentSessionRealTime.buyer_location.lng"
                    >
                      {{ paymentSessionRealTime.buyer_location.lng }}
                    </td>
                    <td class="text-right" v-else>
                      <div class="spinner-border text-secondary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
    
    <script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { TheMask } from 'vue-the-mask'
import { required } from '@validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { ref, onValue } from 'firebase/database'
import db from '../../firebase'
export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    TheMask,
    AppCollapse,
    AppCollapseItem,
  },

  data() {
    return {
      required,
      authPermissions: [],
      paymentSessionData: null,
      errors_back: [],
      storeAttachmentErrorsBack: [],
      storeAttachmentShowDismissibleAlert: false,
      showDismissibleAlert: false,
      variantFields: [
        {
          key: 'product_name',
          label: 'Product',
        },
        {
          key: 'sku',
          label: 'SKU',
        },
        {
          key: 'quantity',
          label: 'Qty',
        },
        {
          key: 'sale_price',
          label: 'Price',
        },
        {
          key: 'profit',
          label: 'Margin',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'is_new',
          label: 'Type',
        },
        {
          key: 'show',
          label: 'show',
        },
      ],

      identicalType: false,
      shipData: null,
      shipPDF: null,
      cashStatusOptions: ['delivered', 'canceled'],
      initialPaymentSessionRealTime: {
        payment_status: null,
        buyer_id: {
          back: null,
          front: null,
        },
        buyer_location: {
          lat: null,
          lng: null,
        },
        seller_id: {
          back: null,
          front: null,
        },
        seller_location: {
          lat: null,
          lng: null,
        },
        validat_location: false,
        validate_buyer_id: false,
        validate_seller_id: false,
      },
      paymentSessionRealTime: {
        payment_status: null,
        buyer_id: {
          back: null,
          front: null,
        },
        buyer_location: {
          lat: null,
          lng: null,
        },
        seller_id: {
          back: null,
          front: null,
        },
        seller_location: {
          lat: null,
          lng: null,
        },
        validat_location: false,
        validate_buyer_id: false,
        validate_seller_id: false,
      },
    }
  },

  async created() {
    this.id = this.$route.params.id
    this.authPermissions = this.$store.state.authPermissions
    await this.fetchData()
    await this.fetchRealTimeData()
  },
  watch: {
    paymentSessionRealTime: {
      handler: function () {
        this.updateNotification()
      },
      deep: true, // This flag indicates deep watching for changes in objects
    },
  },
  methods: {
    async fetchData() {
      await axios
        .get('payment-sessions/' + this.id + '/realtime-show')
        .then((result) => {
          this.paymentSessionData = result.data.data
        })
        .catch((err) => {
         
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    async fetchRealTimeData() {
      // Set up a Firebase Realtime Database reference
      const dataRef = ref(db, '/paymentSession/' + this.paymentSessionData.code)
      // Listen for changes in the data
      onValue(dataRef, (snapshot) => {
           // Reset the entire object
      this.paymentSessionRealTime = { ...this.initialPaymentSessionRealTime };

     
        snapshot.forEach((childSnapshot) => {
          this.paymentSessionRealTime[childSnapshot.key] = childSnapshot.val()
          // console.log(childSnapshot)
        })

       
      })
    },

    updateNotification() {
      this.$bvToast.toast('there is an update in realtime database', {
        title: 'New Update',
        solid: true,
        variant: 'success',
        toaster: 'b-toaster-bottom-right',
        autoHideDelay: 1500,
      })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
    
    <style scoped>
/* #card {
        display: flex !important;
        flex-wrap: wrap !important;
    } */
#table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#table td,
#table th {
  border: 1px solid #dddddd;
  padding: 8px;
}
#table tr:nth-child(even) {
  background-color: #f5f5f5;
}
.cardH {
  margin-bottom: 0px;
}
.phonenumber {
  font-weight: bolder !important;
  color: #08308B;
  background: none;
}
.changed-color {
  transition: color 0.3s ease; /* Modify the transition properties as needed */
  color: green; /* Target color after the change */
}
</style>
    