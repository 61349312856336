<template>
    <div>
      <!-- error handelr -->
      <b-alert
        v-height-fade.appear
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert = false"
        variant="danger"
      >
        <h4 class="alert-heading">Alert</h4>
        <div class="alert-body">
          <ul v-for="(values, index) in errors_back" :key="index">
            <li v-for="(value, index) in values" :key="index">{{ value }}</li>
          </ul>
        </div>
      </b-alert>
      <!-- start data table -->
      <b-card title="payment sessions" class="w-100">
        <b-row>
          <!-- sorting Status -->
          <!-- <b-col md="2" class="my-1">
            <b-form-group
              label="Status"
              label-cols-sm="4"
              label-align-sm="right"
              label-size="sm"
            >
              <b-dropdown
                :dropup="true"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-model="status"
                class="border mr-0"
                size="sm"
                :text="statusName"
                variant="flat-primary"
              >
                <div style="overflow-y: scroll; max-height: 350px">
                  <b-dropdown-item @click="changeStatus('All', 'all')">
                    All
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="changeStatus(item.name, item.slug)"
                    v-for="(item, index) in paymentSessionStatus"
                    :key="index"
                  >
                    {{ item.name }}
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </b-form-group>
          </b-col>
   -->
          <!-- sorting -->
          <b-col md="3" class="my-1">
            <b-form-group
              label="Sort By"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                ></b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class=""
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- filter -->
          <b-col md="4" class="my-1">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button variant="primary" @click="searchData">
                    Search
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="1" class="my-1">
            <!-- print and save pdf & excel -->
            <div class="ml-auto mr-2">
              <b-dropdown
                style="height: 2.142rem; line-height: 0.5"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                right
                variant="primary"
                size="sm"
                class="dropdown-icon-wrapper"
              >
                <template #button-content>
                  <feather-icon
                    icon="PrinterIcon"
                    size="16"
                    class="align-middle"
                  />
                </template>
                <download-excel :data="exportItems">
                  <b-dropdown-item>
                    <feather-icon icon="DownloadIcon" />
                    Download Excel
                  </b-dropdown-item>
                </download-excel>
                <b-dropdown-divider />
                <b-dropdown-item @click="genPDF">
                  <feather-icon icon="DownloadIcon" />
                  Save PDF
                </b-dropdown-item>
                <b-dropdown-divider />
              </b-dropdown>
            </div>
          </b-col>
  
          <!-- actions -->
          <b-col cols="12 ">
            <b-table
              id="PaymentSessionsTable"
              ref="admint"
              striped
              responsive
              :items="items"
              :fields="fields"
              class="position-relative"
              @row-clicked="paymentSessionQuickView"
            >
              <template #cell(payment_provider)="data">
             
                
                <b-img-lazy
              
                  width="40"
                  :src="data.value.logo"
                />
              </template>
  
              <template #cell(payment_method)="data">
                {{ data.value.fees }} %
              </template>
              <template #cell(seller_username)="items">
                <router-link
                  :to="{ path: '/User/Show/' + items.item.seller_id }"
                  v-if="authPermissions.includes('user')"
                  class="text-primary"
                >
                  {{ items.item.seller_username }}
                </router-link>
                <p v-else>{{ items.item.seller_username }}</p>
              </template>
              <template #cell(buyer_username)="items">
                <router-link
                  :to="{ path: '/User/Show/' + items.item.buyer_id }"
                  v-if="authPermissions.includes('user')"
                >
                  {{ items.item.buyer_username }}
                </router-link>
                <p v-else>{{ items.item.buyer_username }}</p>
              </template>
              <template #cell(status)="data">
                <b-badge
                  :variant="
                    data.value == 'pending'
                      ? 'light-primary'
                      : data.value == 'delivered'
                      ? 'light-success'
                      : data.value == 'failed'
                      ? 'light-danger'
                      : 'light-secondary'
                  "
                >
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(actions)="items">
                <div class=" text-center d-flex p-1">
                  <!-- Show Order -->
                  <b-button
                    v-if="authPermissions.includes('show')"
                    variant="light-danger"
                    type="submit"
                    class="my-1 mx-0  pt-1 px-1"
                    :to="{ path: '/paymentSessions/show/' + items.item.id }"
                  >
                  
                      <img width="30" :src="require('@/assets/images/icons/eye.png')" />
                  </b-button>
           
                 
                    
                  <!-- </b-button> -->
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group class="mb-1">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              @change="fetchData"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
          <!-- pagination -->
          <div>
            <b-pagination
              @input="fetchData()"
              aria-controls="CategoryTable"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              align="center"
              class="my-0"
            />
          </div>
        </b-card-body>
      </b-card>
  
      <!-- Order Details in table for variant -->
      <b-modal
        id="ps-quick-view-modal"
        hide-footer
        ref="modal"
        centered
        title="Order Details"
        size="lg"
      >
        <b-row v-if="paymentSessionModal">
          <b-col md="6">
            <p class="text-primary h4 mt-1">
              Code: <span class="h5">{{ paymentSessionModal.code }}</span>
            </p>
            <p class="text-primary h4 mt-1">
              Status:
              <b-badge
                :variant="
                  paymentSessionModal.status == 'pending'
                    ? 'light-primary'
                    : paymentSessionModal.status == 'delivered'
                    ? 'light-success'
                    : paymentSessionModal.status == 'failed'
                    ? 'light-danger'
                    : 'light-secondary'
                "
                >{{ paymentSessionModal.status }}</b-badge
              >
            </p>
            <p class="text-primary h4 mt-1">
              Total: <span class="h5">{{ paymentSessionModal.total_paid }}</span>
            </p>
            <p class="text-primary h4 mt-1">
              Payout Amount :
              <span class="h5">{{ paymentSessionModal.payout_amount }}</span>
            </p>
            <p class="text-primary h4 mt-1">
              Margin Amount :
              <span class="h5">{{ paymentSessionModal.margin_amount }}</span>
            </p>
            <p
              class="text-primary h4 mt-1"
              v-if="paymentSessionModal.payment_provider"
            >
              Payment Provider:
              <b-badge variant="light-info">{{
                paymentSessionModal.payment_provider.name
              }}</b-badge>
            </p>
            <p
              class="text-primary h4 mt-1"
              v-if="paymentSessionModal.transaction"
            >
              Transaction Ref:
              <router-link
                :to="{
                  path: '/transaction/show/' + paymentSessionModal.transaction.id,
                }"
              >
                <b-badge variant="info">{{
                  paymentSessionModal.transaction.transaction_ref
                }}</b-badge>
              </router-link>
            </p>
          </b-col>
          <b-col md="6">
            <p class="text-primary h5 mt-1">
              <b-badge variant="secondary">Seller User</b-badge>
            </p>
            <p class="text-primary h4 mt-1">
              Username:
              <span class="h5">
                {{ paymentSessionModal.seller_username }}
              </span>
            </p>
            <p class="text-primary h4 mt-1">
              Email:
              <span class="h5">{{ paymentSessionModal.seller_email }}</span>
            </p>
            <p class="text-primary h5 mt-1">
              <b-badge variant="secondary">Buyer User</b-badge>
            </p>
            <p class="text-primary h4 mt-1">
              Username:
              <span class="h5">{{ paymentSessionModal.buyer_username }}</span>
            </p>
            <p class="text-primary h4 mt-1">
              Email: <span class="h5">{{ paymentSessionModal.buyer_email }}</span>
            </p>
            <p class="text-primary h5 mt-1">
              <b-badge variant="secondary">Bidding Data</b-badge>
            </p>
            <p class="text-primary h4 mt-1">
              ID:
              <router-link
                :to="{
                  path:
                    '/biddings-requests/show/' + paymentSessionModal.bidding_id,
                }"
              >
                <span class="h5">{{ paymentSessionModal.bidding_id }}</span>
              </router-link>
            </p>
            <p class="text-primary h4 mt-1">
              Status:
              <span class="h5">
                <b-badge
                  size="lg"
                  :variant="
                    paymentSessionModal.bidding_status == 'accept'
                      ? 'light-success'
                      : paymentSessionModal.bidding_status == 'canceled'
                      ? 'light-primary'
                      : paymentSessionModal.bidding_status == 'reject'
                      ? 'light-warning'
                      : 'light-secondary'
                  "
                >
                  {{ paymentSessionModal.bidding_status }}
                </b-badge>
              </span>
            </p>
          </b-col>
        </b-row>
        <b-row v-if="paymentSessionModal.variant" class="my-2">
          <b-col md="12">
            <p class="text-secondary h4 mt-1">Items</p>
            <table class="table table-striped table-responsive">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">SKU</th>
                  <th scope="col">Type</th>
                  <th scope="col">Seller Price</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="text-nowrap">
                      {{ paymentSessionModal.variant.name }}
                    </div>
                  </td>
                  <td style="text-align: center">
                    {{ paymentSessionModal.variant.sku }}
                  </td>
                  <td>
                    <b-badge
                      :variant="
                        paymentSessionModal.variant.is_new ? 'success' : 'info'
                      "
                    >
                      {{ paymentSessionModal.variant.is_new ? 'New' : 'Used' }}
                    </b-badge>
                  </td>
  
                  <td>{{ paymentSessionModal.variant.sale_price }}</td>
                  <td>
                    <b-button
                      style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                      class="btn-icon rounded-circle"
                      :to="{
                        path:
                          (paymentSessionModal.variant.is_new
                            ? '/New'
                            : '/Used') +
                          '/ShowProduct/variant/show/' +
                          paymentSessionModal.variant.id,
                      }"
                      variant="success"
                    >
                      <feather-icon
                        style="width: 18px; height: 18px"
                        icon="EyeIcon"
                      />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row v-if="paymentSessionModal.transaction" class="my-2">
          <b-col md="12">
            <p class="text-secondary h4 mt-1">Transaction</p>
            <table class="table table-striped table-responsive">
              <thead>
                <tr>
                  <th scope="col">Transaction Ref</th>
                  <th scope="col">Transaction Type</th>
                  <th scope="col">Status</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td dir="rtl">
                    {{ paymentSessionModal.transaction.transaction_ref }}
                  </td>
                  <td style="text-align: center">
                    {{ paymentSessionModal.transaction.transaction_type }}
                  </td>
                  <td>
                    <b-badge
                      :variant="
                        paymentSessionModal.transaction.status == 'success'
                          ? 'success'
                          : paymentSessionModal.transaction.status == 'failed'
                          ? 'danger'
                          : 'secondary'
                      "
                      >{{ paymentSessionModal.transaction.status }}</b-badge
                    >
                  </td>
                  <td>{{ paymentSessionModal.transaction.amount }}</td>
                  <td>
                    <b-button
                      style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                      class="btn-icon rounded-circle"
                      :to="{
                        path:
                          '/transaction/show/' +
                          paymentSessionModal.transaction.id,
                      }"
                      variant="success"
                    >
                      <feather-icon
                        style="width: 18px; height: 18px"
                        icon="EyeIcon"
                      />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </template>
    
    <script>
  export default {
    data() {
      return {
        authPermissions: [],
        showDismissibleAlert: false,
        errors_back: [],
        errors_delete: {},
        perPage: 25,
        pageOptions: [25, 50, 100],
        totalRows: 1,
        isBusy: false,
        currentPage: 1,
  
        sortBy: 'created_at',
        status: 'all',
        statusName: 'All',
        // paymentSessionStatus: [
        //   {
        //     name: 'Pending',
        //     slug: 'pending',
        //   },
        //   {
        //     name: 'Delivered',
        //     slug: 'delivered',
        //   },
        //   {
        //     name: 'Canceled',
        //     slug: 'canceled',
        //   },
        //   {
        //     name: 'Failed',
        //     slug: 'failed',
        //   },
        //   {
        //     name: 'Dispute',
        //     slug: 'dispute',
        //   },
        //   {
        //     name: 'Refund',
        //     slug: 'refund',
        //   },
        // ],
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        // table fields
        fields: [
          {
            key: 'id',
            label: 'Id',
            sortable: true,
          },
          {
            key: 'code',
            label: 'Code',
          },
          {
            key: 'buyer_username',
            label: 'Buyer Username',
          },
          {
            key: 'seller_username',
            label: 'Seller Username',
          },
          {
            key: 'status',
            label: 'Status',
          },
          {
            key: 'total_paid',
            label: 'Total',
          },
          {
            key: 'margin_amount',
            label: 'Margin Amount',
          },
          {
            key: 'payout_amount',
            label: 'Payout Amount',
          },
          {
            key: 'payment_provider',
            label: 'Payment Provider',
          },
          {
            key: 'payment_method',
            label: 'Payment Fees',
          },
          {
            key: 'created_at',
            label: 'Created_At',
            sortable: true,
          },
          {
            key: 'updated_at',
            label: 'Updated_At',
            sortable: true,
          },
          {
            key: 'actions',
          },
        ],
        items: [],
        exportItems: [],
        paymentSessionModal: {},
      }
    },
    created() {
      this.fetchData()
      this.authPermissions = this.$store.state.authPermissions
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({
            text: f.label,
            value: f.key,
          }))
      },
      rows() {
        return this.totalRows
      },
    },
    watch: {
      sortDesc: function () {
        this.currentPage = 1
        if (this.sortDesc == true) {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
        this.fetchData()
      },
      sortBy: function () {
        this.currentPage = 1
        if (this.sortDesc == true) {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
        this.fetchData()
      },
      status: function () {
        this.currentPage = 1
        if (this.sortDesc == true) {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
        this.fetchData()
      },
      filter: function () {
        // if (this.filter.length >= 3 || this.filter.length == 0) {
        //   this.currentPage = 1
        //   if (this.sortDesc == true) {
        //     this.sortDirection = 'desc'
        //   } else {
        //     this.sortDirection = 'asc'
        //   }
        //   this.fetchData()
        // }
        if (this.filter.length == 0 || this.filter == null) {
          this.fetchData()
        }
      },
    },
    methods: {
      // fetch data of payment-sessions
      fetchData() {
        var url = null
        if (this.filter == '') {
          url =
            'payment-sessions/completed/pagi/' +
            this.perPage.toString() +
            '/sortBy-' +
            this.sortBy +
            '-' +
            this.sortDirection +
            '/' +
            this.status +
            '?page=' +
            this.currentPage
        } else {
          url =
            'payment-sessions/completed/pagi/' +
            this.perPage.toString() +
            '/sortBy-' +
            this.sortBy +
            '-' +
            this.sortDirection +
            '/' +
            this.status +
            '/' +
            this.filter +
            '?page=' +
            this.currentPage
        }
        const promise = axios.get(url)
        return promise
          .then((result) => {
            this.totalRows = result.data.data.meta.total
            this.items.length = 0
            this.items = result.data.data.data
            //console.log(this.items)
            this.exportItems = this.items
            this.$refs.admint.refresh()
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      },
  
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
  
      paymentSessionQuickView(item, index, event) {
        this.$bvModal.show('ps-quick-view-modal')
        this.paymentSessionModal = item
        // //console.log(index)
        // //console.log(event)
      },
      changeStatus(name, slug) {
        this.status = slug
        this.statusName = name
      },
      searchData() {
        this.currentPage = 1
        if (this.sortDesc == true) {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
        this.fetchData()
      },
      genPDF() {
        const { jsPDF } = require('jspdf')
        const doc = new jsPDF()
        let print_body = []
        for (let index = 0; index < this.items.length; index++) {
          print_body[index] = [
            this.items[index].id,
            this.items[index].code,
            this.items[index].buyer_username,
            this.items[index].seller_username,
            this.items[index].status,
            this.items[index].total_paid,
            this.items[index].margin_amount,
            this.items[index].payout_amount,
            this.items[index].payment_provider.name,
            this.items[index].created_at,
            this.items[index].updated_at,
          ]
        }
        doc.autoTable({
          head: [
            [
              'ID',
              'Code',
              'Buyer Username',
              'Seller Username',
              'Status',
              'Totla',
              'Margin Amount',
              'payout_amount',
              'Payment Provider',
              'CREATED_AT',
              'UPDATED_AT',
            ],
          ],
          body: print_body,
        })
        doc.save('table.pdf')
      },
      isString(value) {
        return typeof value === 'string' || value instanceof String
      },
    },
  }
  </script>
    <style>
  .orders-table {
    max-height: 70vh !important;
  }
  @media screen and (max-width: 768px) {
    /* Customize font size for small screens */
    .alert-heading,
    .alert-body li,
    .label-size-sm {
      font-size: 16px;
    }
  
    .b-form-group label,
    .b-form-select,
    .b-form-input,
    .b-button,
    .b-badge,
    .h4,
    .h5,
    .text-primary,
    .text-secondary {
      font-size: 14px;
    }
  
    .table,
    .table-responsive,
    .b-modal,
    .b-pagination,
    .b-input-group,
    .b-img-lazy,
    .feather-icon {
      font-size: 12px;
    }
  }
  </style>
    