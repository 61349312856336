<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , valIndex) in values" :key="valIndex">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-row>
      <b-col cols="12" md="6" sm="12" v-if="orderData.e_contract != null">
        <b-card-code
          title="E-contract Data"
          :before-change="validationForm"
          v-if="orderData"
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <!-- Customer name -->
                <b-col md="12">
                  <b-form-group label="Customer Name" label-for="first-name">
                    <validation-provider
                      #default="{ errors }"
                      name="Customer Name"
                      rules="required"
                    >
                      <b-form-input
                        id="first-name"
                        v-model="customer_name"
                        placeholder="John"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Customer Id Number -->
                <b-col md="12">
                  <b-form-group
                    label="Customer Id Number"
                    label-for="customer-id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Customer Id Number"
                      rules="required"
                    >
                      <b-form-input
                        id="customer-id"
                        v-model="customer_id_number"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-row>
                    <!-- Id front picture -->
                    <b-col md="12">
                      <b-form-group
                        label="Id front picture"
                        label-for="Id front picture"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Id front picture"
                        >
                          <b-form-file
                            v-model="id_front_image_file"
                            @change="ChangeIDFrontImage($event)"
                            id="ProfilePictureBase642"
                            accept="image/*"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Id front picture Image Frame -->
                    <b-col cols="12">
                      <b-img
                        width="200"
                        height="auto"
                        :src="orderData.e_contract.id_front_image"
                      />
                      <!-- <iframe width="200" height="200" :src="item.value"> </iframe> -->
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12" md="6">
                  <b-row>
                    <!-- Id back picture -->
                    <b-col md="12">
                      <b-form-group
                        label="Id back picture"
                        label-for="Id back picture"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Id back picture"
                        >
                          <b-form-file
                            v-model="id_back_image_file"
                            @change="ChangeIDBackImage($event)"
                            id="ProfilePictureBase643"
                            accept="image/*"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Id back picture Image Frame -->
                    <b-col cols="12">
                      <b-img
                        width="200"
                        height="auto"
                        :src="orderData.e_contract.id_back_image"
                      />
                      <!-- <iframe width="200" height="200" :src="item.value"> </iframe> -->
                    </b-col>
                  </b-row>
                </b-col>
                <!-- submit button -->
                <b-col
                  class="py-1 text-center"
                  v-if="
                    !orderData.variants_data.is_new &&
                    authPermissions.includes('update-eContract-data')
                  "
                >
                  <b-button
                    class="mx-1 my-1"
                    size="lg"
                    variant="outline-primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Update
                  </b-button>
                  <b-button
                    size="lg"
                    variant="outline-success"
                    @click="generateEContract"
                    v-if="authPermissions.includes('generate-eContract')"
                  >
                    Generate E-Contract
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-code>
      </b-col>

      <!-- E-COntract Preview Section -->
      <b-col
        cols="12"
        md="6"
        sm="12"
        v-if="orderData.e_contract != null && !orderData.variants_data.is_new"
      >
        <b-card-code class="table-responsive">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="691"
            height="1020"
            viewBox="0 0 691 1020"
          >
            <defs>
              <clippath id="clip-Mobile_Sale_Contract">
                <rect width="691" height="1020"></rect>
              </clippath>
            </defs>
            <g
              id="Mobile_Sale_Contract"
              data-name="Mobile Sale Contract"
              clip-path="url(#clip-Mobile_Sale_Contract)"
            >
              <rect width="691" height="1020" fill="#fff"></rect>
              <g
                id="Group_11522"
                data-name="Group 11522"
                transform="translate(1 -10)"
              >
                <text
                  id="مبايعة_جهاز_موبايل"
                  data-name="مبايعة جهاز موبايل"
                  transform="translate(432.599 147)"
                  fill="#FF5100"
                  font-size="20"
                  font-family="Cairo-Bold, Cairo"
                  font-weight="700"
                >
                  <tspan x="-173.16" y="0">مبايعة جهاز موبايل</tspan>
                </text>
                <text
                  id="انه_فى_يوم_..................._الموافق_........_........_................_تم_هذا_البيع_بين_كل_من_"
                  data-name="انه فى يوم ................... الموافق ......../......../................ تم هذا البيع بين كل من "
                  transform="translate(650 201)"
                  fill="#0d1724"
                  font-size="14"
                  font-family="Cairo-SemiBold, Cairo"
                  font-weight="600"
                >
                  <tspan x="-442.708" y="0">
                    انه فى يوم ................... الموافق
                    ......../......../................ تم هذا البيع بين كل من
                  </tspan>
                  <tspan y="0"></tspan>
                </text>
                <text
                  id="السيد"
                  transform="translate(632 262.438)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-32.284" y="0">السيد</tspan>
                </text>
                <text
                  id="السيد-2"
                  data-name="السيد"
                  transform="translate(632 367.793)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-32.284" y="0">السيد</tspan>
                </text>
                <text
                  id="رقم_قومي"
                  data-name="رقم قومي"
                  transform="translate(331.05 262.438)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-63.07" y="0">رقم قومي</tspan>
                </text>
                <text
                  id="رقم_قومي-2"
                  data-name="رقم قومي"
                  transform="translate(331.05 367.793)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-63.07" y="0">رقم قومي</tspan>
                </text>
                <text
                  id="طرف_أول_بائع"
                  data-name="طرف أول بائع"
                  transform="translate(146 304.804)"
                  fill="#0d1724"
                  font-size="16"
                  font-family="Cairo-Bold, Cairo"
                  font-weight="700"
                >
                  <tspan x="-94.24" y="0">طرف أول بائع</tspan>
                </text>
                <text
                  id="طرف_ثاني_مشتري"
                  data-name="طرف ثاني مشتري"
                  transform="translate(180 410.396)"
                  fill="#0d1724"
                  font-size="16"
                  font-family="Cairo-Bold, Cairo"
                  font-weight="700"
                >
                  <tspan x="-127.792" y="0">طرف ثاني مشتري</tspan>
                </text>
                <text
                  id="باع_الطرف_الأول_إلى_الطرف_الثاني_ماهو_عبارة_عن_هاتف_محمول_ماركة_"
                  data-name="باع الطرف الأول إلى الطرف الثاني ماهو عبارة عن هاتف محمول ماركة "
                  transform="translate(650 471.776)"
                  fill="#0d1724"
                  font-size="14"
                  font-family="Cairo-SemiBold, Cairo"
                  font-weight="600"
                >
                  <tspan x="-407.4" y="0">
                    باع الطرف الأول إلى الطرف الثاني ماهو عبارة عن هاتف محمول
                    ماركة
                  </tspan>
                  <tspan y="0"></tspan>
                </text>
                <text
                  id="رقم_تسلسلى_للهاتف_"
                  data-name="رقم تسلسلى للهاتف "
                  transform="translate(650 527.568)"
                  fill="#0d1724"
                  font-size="14"
                  font-family="Cairo-SemiBold, Cairo"
                  font-weight="600"
                >
                  <tspan x="-125.566" y="0">رقم تسلسلى للهاتف</tspan>
                  <tspan y="0"></tspan>
                </text>
                <text
                  id="_IMEI_"
                  data-name="(IMEI)"
                  transform="translate(510.874 527.568)"
                  fill="#0d1724"
                  font-size="14"
                  font-family="Cairo-SemiBold, Cairo"
                  font-weight="600"
                >
                  <tspan x="-34.594" y="0">(IMEI)</tspan>
                </text>
                <text
                  id="دفعها_الطرف_الثانى_للطرف_االأول_و_اصبح_الثمن_خالص_اقر_الطرف_الأول_انه_مالك_هذا_الموبايل_و_فى_حالة_ظهور_اى_محضر_سرقة_خاص_بهذا_الموبايل_يكون_هو_وحده_المسئول_عن_هذا_البالغ_من_الناحية_الجنائية"
                  data-name="دفعها الطرف الثانى للطرف االأول و اصبح الثمن خالص
اقر الطرف الأول انه مالك هذا الموبايل و فى حالة ظهور اى محضر سرقة خاص بهذا الموبايل يكون هو وحده المسئول عن هذا البالغ من الناحية الجنائية"
                  transform="translate(648 634)"
                  fill="#0d1724"
                  font-size="14"
                  font-family="Cairo-SemiBold, Cairo"
                  font-weight="600"
                >
                  <tspan x="-311.906" y="0">
                    دفعها الطرف الثانى للطرف االأول و اصبح الثمن خالص
                  </tspan>
                  <tspan y="0"></tspan>
                  <tspan x="-588.308" y="26">
                    اقر الطرف الأول انه مالك هذا الموبايل و فى حالة ظهور اى محضر
                    سرقة خاص بهذا الموبايل يكون هو
                  </tspan>
                  <tspan y="26"></tspan>
                  <tspan x="-277.97" y="52">
                    وحده المسئول عن هذا البالغ من الناحية الجنائية
                  </tspan>
                </text>
                <text
                  id="بعت_أنا_الموقع_أدناه"
                  data-name="بعت أنا الموقع أدناه"
                  transform="translate(650 753.42)"
                  fill="#0d1724"
                  font-size="14"
                  font-family="Cairo-SemiBold, Cairo"
                  font-weight="600"
                >
                  <tspan x="-116.83" y="0">بعت أنا الموقع أدناه</tspan>
                </text>
                <text
                  id="تم_هذا_البيع_نظير_ثمن_اجمالى_قدرة_بالجنية_المصرى_"
                  data-name="تم هذا البيع نظير ثمن اجمالى قدرة بالجنية المصرى "
                  transform="translate(650 583.36)"
                  fill="#0d1724"
                  font-size="14"
                  font-family="Cairo-SemiBold, Cairo"
                  font-weight="600"
                >
                  <tspan x="-302.316" y="0">
                    تم هذا البيع نظير ثمن اجمالى قدرة بالجنية المصرى
                  </tspan>
                  <tspan y="0"></tspan>
                </text>
                <g
                  id="Group_11511"
                  data-name="Group 11511"
                  transform="translate(636 244.438)"
                >
                  <text
                    id="_1"
                    data-name="1"
                    transform="translate(14 18)"
                    fill="#0d1724"
                    font-size="14"
                    font-family="Cairo-SemiBold, Cairo"
                    font-weight="600"
                  >
                    <tspan x="-7.84" y="0">1</tspan>
                  </text>
                  <text
                    id="_-"
                    data-name="-"
                    transform="translate(6 18)"
                    fill="#0d1724"
                    font-size="14"
                    font-family="Cairo-SemiBold, Cairo"
                    font-weight="600"
                  >
                    <tspan x="-6.006" y="0">-</tspan>
                  </text>
                </g>
                <g
                  id="Group_11512"
                  data-name="Group 11512"
                  transform="translate(636 349.793)"
                >
                  <text
                    id="_2"
                    data-name="2"
                    transform="translate(14 18)"
                    fill="#0d1724"
                    font-size="14"
                    font-family="Cairo-SemiBold, Cairo"
                    font-weight="600"
                  >
                    <tspan x="-7.84" y="0">2</tspan>
                  </text>
                  <text
                    id="_--2"
                    data-name="-"
                    transform="translate(6 18)"
                    fill="#0d1724"
                    font-size="14"
                    font-family="Cairo-SemiBold, Cairo"
                    font-weight="600"
                  >
                    <tspan x="-6.006" y="0">-</tspan>
                  </text>
                </g>
                <g
                  id="Rectangle_12519"
                  data-name="Rectangle 12519"
                  transform="translate(52 443.396)"
                  fill="#fff"
                  stroke="#FF5100"
                  stroke-width="1"
                >
                  <rect width="175" height="36" rx="8" stroke="none"></rect>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="174"
                    height="35"
                    rx="7.5"
                    fill="none"
                  ></rect>
                </g>
                <g
                  id="Rectangle_12525"
                  data-name="Rectangle 12525"
                  transform="translate(52 499.232)"
                  fill="#fff"
                  stroke="#FF5100"
                  stroke-width="1"
                >
                  <rect width="408" height="36" rx="8" stroke="none"></rect>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="407"
                    height="35"
                    rx="7.5"
                    fill="none"
                  ></rect>
                </g>
                <g
                  id="Rectangle_12514"
                  data-name="Rectangle 12514"
                  transform="translate(52 233.866)"
                  fill="#fff"
                  stroke="#FF5100"
                  stroke-width="1"
                >
                  <rect width="200" height="36" rx="8" stroke="none"></rect>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="199"
                    height="35"
                    rx="7.5"
                    fill="none"
                  ></rect>
                </g>
                <g
                  id="Rectangle_12522"
                  data-name="Rectangle 12522"
                  transform="translate(52 339.339)"
                  fill="#fff"
                  stroke="#FF5100"
                  stroke-width="1"
                >
                  <rect width="200" height="36" rx="8" stroke="none"></rect>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="199"
                    height="35"
                    rx="7.5"
                    fill="none"
                  ></rect>
                </g>
                <g
                  id="Rectangle_12530"
                  data-name="Rectangle 12530"
                  transform="translate(52 804.693)"
                  fill="#fff"
                  stroke="#FF5100"
                  stroke-width="1"
                >
                  <rect width="200" height="36" rx="8" stroke="none"></rect>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="199"
                    height="35"
                    rx="7.5"
                    fill="none"
                  ></rect>
                </g>
                <g
                  id="Rectangle_12532"
                  data-name="Rectangle 12532"
                  transform="translate(52 895.535)"
                  fill="#fff"
                  stroke="#FF5100"
                  stroke-width="1"
                >
                  <rect width="200" height="36" rx="8" stroke="none"></rect>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="199"
                    height="35"
                    rx="7.5"
                    fill="none"
                  ></rect>
                </g>
                <text
                  id="_طرف_أول_"
                  data-name="(طرف أول)"
                  transform="translate(552.353 789.899)"
                  fill="#FF5100"
                  font-size="14"
                  font-family="Cairo-Bold, Cairo"
                  font-weight="700"
                >
                  <tspan x="-64.764" y="0">(</tspan>
                  <tspan y="0">طرف أول</tspan>
                  <tspan y="0">)</tspan>
                </text>
                <text
                  id="توقيع_البــائع"
                  data-name="توقيع البــائع"
                  transform="translate(650 789.899)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Bold, Cairo"
                  font-weight="700"
                >
                  <tspan x="-80.654" y="0">توقيع البــائع</tspan>
                </text>
                <text
                  id="رقم_قومي_البــائع"
                  data-name="رقم قومي البــائع"
                  transform="translate(252 789.899)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Bold, Cairo"
                  font-weight="700"
                >
                  <tspan x="-109.62" y="0">رقم قومي البــائع</tspan>
                </text>
                <text
                  id="رقم_قومي_المشتري"
                  data-name="رقم قومي المشتري"
                  transform="translate(252 883.535)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Bold, Cairo"
                  font-weight="700"
                >
                  <tspan x="-125.93" y="0">رقم قومي المشتري</tspan>
                </text>
                <text
                  id="توقيع_المشتري"
                  data-name="توقيع المشتري"
                  transform="translate(650 883.535)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Bold, Cairo"
                  font-weight="700"
                >
                  <tspan x="-96.964" y="0">توقيع المشتري</tspan>
                </text>
                <text
                  id="_طرف_ثاني_"
                  data-name="(طرف ثاني)"
                  transform="translate(534.972 883.535)"
                  fill="#FF5100"
                  font-size="14"
                  font-family="Cairo-Bold, Cairo"
                  font-weight="700"
                >
                  <tspan x="-70.518" y="0">(</tspan>
                  <tspan y="0">طرف ثاني</tspan>
                  <tspan y="0">)</tspan>
                </text>
                <g
                  id="Rectangle_12533"
                  data-name="Rectangle 12533"
                  transform="translate(20 94)"
                  fill="none"
                  stroke="#FF5100"
                  stroke-linejoin="bevel"
                  stroke-width="3"
                  stroke-dasharray="3"
                >
                  <rect
                    width="650"
                    height="878.535"
                    rx="31"
                    stroke="none"
                  ></rect>
                  <rect
                    x="1.5"
                    y="1.5"
                    width="647"
                    height="875.535"
                    rx="29.5"
                    fill="none"
                  ></rect>
                </g>
                <g
                  id="Group_257"
                  data-name="Group 257"
                  transform="translate(259.351 32)"
                >
                  <g
                    id="Group_194"
                    data-name="Group 194"
                    transform="translate(144.086)"
                  >
                    <path
                      id="Path_272"
                      data-name="Path 272"
                      d="M811.727,223.895h-2.049a7.588,7.588,0,0,1-7.588-7.587V189.082h9.637Z"
                      transform="translate(-790.975 -181.873)"
                      fill="#08308B"
                    ></path>
                    <path
                      id="Path_273"
                      data-name="Path 273"
                      d="M765.862,280.883l-1.332.965-.98-1.653-.979,1.653-1.332-.965,1.27-1.454-1.882-.413.52-1.561,1.76.765-.169-1.806h1.607l-.168,1.883,1.729-.75.49,1.53-1.775.4Zm-2.349-6.572a4.819,4.819,0,1,0,4.819,4.819,4.82,4.82,0,0,0-4.819-4.819"
                      transform="translate(-758.693 -245.273)"
                      fill="#FF5100"
                    ></path>
                    <path
                      id="Path_274"
                      data-name="Path 274"
                      d="M855.881,227.24h-2.049a7.587,7.587,0,0,1-7.587-7.587v-30.57h2.049a7.587,7.587,0,0,1,7.587,7.587Z"
                      transform="translate(-823.821 -181.873)"
                      fill="#08308B"
                    ></path>
                    <path
                      id="Path_275"
                      data-name="Path 275"
                      d="M763.515,188.577a6.2,6.2,0,0,1,4.818,2.29V170.572a9.636,9.636,0,0,0-9.636-9.636v29.752a1.461,1.461,0,0,0,.016.159,6.2,6.2,0,0,1,4.8-2.27"
                      transform="translate(-758.696 -160.936)"
                      fill="#08308B"
                    ></path>
                  </g>
                  <g
                    id="Group_197"
                    data-name="Group 197"
                    transform="translate(0 0.594)"
                  >
                    <g id="Group_195" data-name="Group 195">
                      <path
                        id="Path_276"
                        data-name="Path 276"
                        d="M196.143,184.347l1.41-12.392c.274-2.572,1.293-3.552,3.408-3.552,2.141,0,3.042.98,3.526,3.552l1.789,9.01c.078.4.222.536.392.536s.326-.131.392-.536l1.8-9.01c.483-2.572,1.384-3.552,3.513-3.552s3.147.98,3.421,3.552l1.41,12.392a1.028,1.028,0,0,1-1.136,1.267h-1.253a1.135,1.135,0,0,1-1.254-1.136l-1.214-11.7c-.026-.183-.118-.274-.287-.274s-.248.091-.274.261l-2.128,10.617c-.327,1.593-1.254,2.233-2.99,2.233s-2.664-.64-2.977-2.233l-2.128-10.617c-.026-.17-.1-.261-.274-.261s-.261.091-.287.274l-1.214,11.7a1.135,1.135,0,0,1-1.254,1.136h-1.253A1.028,1.028,0,0,1,196.143,184.347Z"
                        transform="translate(-196.131 -167.084)"
                        fill="#08308B"
                      ></path>
                      <path
                        id="Path_277"
                        data-name="Path 277"
                        d="M285.4,191.225c0-4.244,2.337-6.66,6.607-6.66s6.607,2.416,6.607,6.66-2.337,6.647-6.607,6.647S285.4,195.456,285.4,191.225Zm9.532,0c0-2.533-.966-3.6-2.925-3.6s-2.925,1.071-2.925,3.6c0,2.507.966,3.591,2.925,3.591S294.934,193.732,294.934,191.225Z"
                        transform="translate(-262.538 -179.107)"
                        fill="#08308B"
                      ></path>
                      <path
                        id="Path_278"
                        data-name="Path 278"
                        d="M359.275,176.08c0,4.962-2.716,6.66-7.3,6.66-3.813,0-5.445-1.175-5.445-4.1V165.424a1.087,1.087,0,0,1,1.2-1.2h1.228a1.087,1.087,0,0,1,1.2,1.2v4.087c.627-.052,1.241-.078,1.815-.078C356.559,169.433,359.275,171.1,359.275,176.08Zm-3.63,0c0-2.742-1.018-3.6-3.447-3.6a15.371,15.371,0,0,0-2.037.157v5.458c0,1.058.209,1.593,2.063,1.593C354.626,179.684,355.644,178.783,355.644,176.08Z"
                        transform="translate(-308.009 -163.975)"
                        fill="#08308B"
                      ></path>
                      <path
                        id="Path_279"
                        data-name="Path 279"
                        d="M406.384,165.278a2.024,2.024,0,1,1,4.048,0,2.024,2.024,0,0,1-4.048,0Z"
                        transform="translate(-352.533 -163.254)"
                        fill="#08308B"
                      ></path>
                      <path
                        id="Rectangle_159"
                        data-name="Rectangle 159"
                        d="M1.848,0h0A1.848,1.848,0,0,1,3.7,1.848v9.141a1.848,1.848,0,0,1-1.848,1.848h0A1.848,1.848,0,0,1,0,10.988V1.848A1.848,1.848,0,0,1,1.848,0Z"
                        transform="translate(54.021 5.693)"
                        fill="#08308B"
                      ></path>
                      <path
                        id="Path_280"
                        data-name="Path 280"
                        d="M435.727,164.223h1.293a1.087,1.087,0,0,1,1.2,1.2V181.3a1.088,1.088,0,0,1-1.2,1.2h-1.293a1.087,1.087,0,0,1-1.2-1.2V165.424A1.087,1.087,0,0,1,435.727,164.223Z"
                        transform="translate(-373.467 -163.975)"
                        fill="#08308B"
                      ></path>
                      <path
                        id="Path_281"
                        data-name="Path 281"
                        d="M469.086,192.4l-5.524.574c.47,1.462,1.658,1.985,3.395,1.985a12.969,12.969,0,0,0,2.99-.418c.849-.2,1.358.17,1.358,1.019v.392a1.257,1.257,0,0,1-1.019,1.345,15.416,15.416,0,0,1-3.97.575c-3.957,0-6.607-2.155-6.607-6.647,0-4.244,2.246-6.66,6.634-6.66,3.682,0,5.863,1.724,5.863,4.989C472.207,191.381,471.436,192.152,469.086,192.4Zm-.314-3.186c0-1.24-.757-1.907-2.429-1.907-1.815,0-2.781.823-2.977,3.029l4.505-.457C468.629,189.8,468.772,189.658,468.772,189.214Z"
                        transform="translate(-392.201 -179.107)"
                        fill="#08308B"
                      ></path>
                      <path
                        id="Path_282"
                        data-name="Path 282"
                        d="M515.551,184.347l1.41-12.392c.274-2.572,1.293-3.552,3.408-3.552,2.142,0,3.043.98,3.526,3.552l1.789,9.01c.078.4.222.536.392.536s.327-.131.392-.536l1.8-9.01c.483-2.572,1.384-3.552,3.513-3.552s3.147.98,3.421,3.552l1.41,12.392a1.028,1.028,0,0,1-1.136,1.267h-1.254a1.135,1.135,0,0,1-1.253-1.136l-1.214-11.7c-.026-.183-.118-.274-.287-.274s-.248.091-.274.261l-2.129,10.617c-.326,1.593-1.253,2.233-2.99,2.233s-2.664-.64-2.977-2.233l-2.128-10.617c-.026-.17-.1-.261-.274-.261s-.261.091-.287.274l-1.214,11.7a1.135,1.135,0,0,1-1.254,1.136h-1.253A1.028,1.028,0,0,1,515.551,184.347Z"
                        transform="translate(-433.731 -167.084)"
                        fill="#FF5100"
                      ></path>
                      <path
                        id="Path_283"
                        data-name="Path 283"
                        d="M617,189.579v4.309c0,2.716-1.241,3.748-4.048,3.748h-2.964c-3.186,0-5.132-.849-5.132-3.918s1.959-3.93,5.132-3.93h3.382v-.209c0-1.436-1.136-2.063-3.042-2.063a17.921,17.921,0,0,0-3.369.4c-.823.156-1.319-.222-1.319-1.058v-.483a1.326,1.326,0,0,1,1.253-1.358,19.793,19.793,0,0,1,3.957-.457C614.392,184.565,617,186.067,617,189.579Zm-3.63,4.126v-1.319h-3.2c-1.58,0-1.906.366-1.906,1.332s.326,1.319,1.893,1.319h1.907C613.19,195.038,613.373,194.581,613.373,193.706Z"
                        transform="translate(-500.174 -179.107)"
                        fill="#FF5100"
                      ></path>
                      <path
                        id="Path_284"
                        data-name="Path 284"
                        d="M661.365,196v-.366c0-.836.5-1.228,1.345-1.071,3.042.588,6.163.771,6.163-.679,0-.561-.4-.875-1.28-1.11l-2.742-.7c-2.559-.653-3.656-1.933-3.656-3.7,0-3.669,4.74-4.453,9.323-3.317a1.248,1.248,0,0,1,1.032,1.345v.313c0,.849-.5,1.215-1.345,1.032-2.533-.549-5.537-.679-5.537.522,0,.431.34.731.927.875l2.938.757c2.56.653,3.8,1.906,3.8,3.891,0,4.035-5.289,4.779-9.95,3.552A1.257,1.257,0,0,1,661.365,196Z"
                        transform="translate(-542.081 -179.112)"
                        fill="#FF5100"
                      ></path>
                      <path
                        id="Path_285"
                        data-name="Path 285"
                        d="M721.479,186.212v.757c0,.77-.457,1.2-1.319,1.215-2.742.039-2.86.274-2.86,1.253v7.339a1.087,1.087,0,0,1-1.2,1.2h-1.293a1.088,1.088,0,0,1-1.2-1.2v-7.861c0-2.99,1.071-3.8,6.594-3.892C721.035,185.01,721.479,185.428,721.479,186.212Z"
                        transform="translate(-581.068 -179.447)"
                        fill="#FF5100"
                      ></path>
                    </g>
                    <g
                      id="Group_196"
                      data-name="Group 196"
                      transform="translate(0.244 22.988)"
                    >
                      <path
                        id="Path_286"
                        data-name="Path 286"
                        d="M591.434,316.448a2.006,2.006,0,1,0,0,4.011,1.809,1.809,0,0,0,2.018-1.994A1.836,1.836,0,0,0,591.434,316.448Z"
                        transform="translate(-488.93 -300.2)"
                        fill="#08308B"
                      ></path>
                      <path
                        id="Path_287"
                        data-name="Path 287"
                        d="M510.179,316.448a2.006,2.006,0,1,0,0,4.011,1.809,1.809,0,0,0,2.018-1.994A1.837,1.837,0,0,0,510.179,316.448Z"
                        transform="translate(-428.487 -300.2)"
                        fill="#08308B"
                      ></path>
                      <path
                        id="Path_288"
                        data-name="Path 288"
                        d="M493.936,316.448a2.006,2.006,0,1,0,0,4.011,1.809,1.809,0,0,0,2.018-1.994A1.837,1.837,0,0,0,493.936,316.448Z"
                        transform="translate(-416.404 -300.2)"
                        fill="#08308B"
                      ></path>
                      <path
                        id="Path_289"
                        data-name="Path 289"
                        d="M239.845,263.717h-.177a5.419,5.419,0,0,0-5.412,5.412v1.95a.793.793,0,0,1-.793.793h-2.1v-.7c0-4.9-2.119-7.385-6.3-7.385-2.062,0-4.021,1.009-5.978,3.082v-.931a1.067,1.067,0,0,0-1.065-1.065h-1.6a1.067,1.067,0,0,0-1.065,1.065v5.937h-11.7v-6.149a1.067,1.067,0,0,0-1.065-1.065h-1.624a1.067,1.067,0,0,0-1.065,1.065v9.722a1.459,1.459,0,0,1-1.546,1.568h-.2a1.067,1.067,0,0,0-1.065,1.065v1.6a1.067,1.067,0,0,0,1.065,1.065h.2a5.308,5.308,0,0,0,5.3-5.141h29.813a4.32,4.32,0,0,0,2.967-1.123,6.4,6.4,0,0,0,3.548,1.057,5.353,5.353,0,0,0,5.279-5.412v-1A5.418,5.418,0,0,0,239.845,263.717Zm-1.856,5.412a1.563,1.563,0,0,1,1.657-1.679h.222a1.563,1.563,0,0,1,1.657,1.679v1a1.606,1.606,0,0,1-1.546,1.657,2.659,2.659,0,0,1-1.989-.8Zm-18.9,2.743v-1.259c1.79-2.143,3.55-3.141,5.535-3.141,1.953,0,3.12,1.382,3.12,3.7v.7Z"
                        transform="translate(-197.083 -260.974)"
                        fill="#FF5100"
                      ></path>
                      <path
                        id="Path_290"
                        data-name="Path 290"
                        d="M428.934,264.475v-6.42a1.048,1.048,0,0,0-1.047-1.047h-1.6a1.048,1.048,0,0,0-1.047,1.047v5.179a.664.664,0,0,1-.656.744H410.3v-9.851a1.048,1.048,0,0,0-1.047-1.047h-1.625a1.048,1.048,0,0,0-1.047,1.047v12.231a3.065,3.065,0,0,1-3.226,3.249h-.177a3.065,3.065,0,0,1-3.226-3.249v-3.305a1.048,1.048,0,0,0-1.047-1.047h-1.6a1.048,1.048,0,0,0-1.047,1.047v3.305A6.953,6.953,0,0,0,403.2,273.3h.155a6.768,6.768,0,0,0,6.832-5.629h15.568A3.155,3.155,0,0,0,428.934,264.475Z"
                        transform="translate(-345.242 -253.063)"
                        fill="#08308B"
                      ></path>
                      <path
                        id="Path_291"
                        data-name="Path 291"
                        d="M552.915,256.771H551.29a1.067,1.067,0,0,0-1.065,1.065v5.344a.637.637,0,0,1-.615.726h-9.749a.641.641,0,0,1-.638-.726v-9.106a1.067,1.067,0,0,0-1.065-1.065h-1.625a1.067,1.067,0,0,0-1.065,1.065v10.347a3.2,3.2,0,0,0,3.24,3.218h12.053a3.184,3.184,0,0,0,3.218-3.218v-6.585A1.067,1.067,0,0,0,552.915,256.771Z"
                        transform="translate(-448.8 -253.009)"
                        fill="#08308B"
                      ></path>
                      <path
                        id="Path_292"
                        data-name="Path 292"
                        d="M642.721,263.717h-.2a5.419,5.419,0,0,0-5.412,5.412v1.95a.793.793,0,0,1-.793.793H625.954v-2.721a5.418,5.418,0,0,0-5.412-5.412h-.177a5.283,5.283,0,0,0-5.39,5.412v1a5.35,5.35,0,0,0,5.211,5.412h2.023v.049a1.46,1.46,0,0,1-1.546,1.569h-.2a1.067,1.067,0,0,0-1.065,1.065v1.6a1.067,1.067,0,0,0,1.065,1.065h.2a5.308,5.308,0,0,0,5.3-5.3v-.036h6.793v.031h3.558a4.319,4.319,0,0,0,2.967-1.123,6.4,6.4,0,0,0,3.548,1.057,5.353,5.353,0,0,0,5.279-5.412v-1.019A5.4,5.4,0,0,0,642.721,263.717Zm1.657,5.412v1a1.606,1.606,0,0,1-1.546,1.657,2.659,2.659,0,0,1-1.989-.8v-1.859a1.563,1.563,0,0,1,1.657-1.679h.222A1.563,1.563,0,0,1,644.377,269.129Zm-24.146,2.7a1.629,1.629,0,0,1-1.524-1.679v-1a1.563,1.563,0,0,1,1.657-1.679h.222a1.553,1.553,0,0,1,1.635,1.679v2.677Z"
                        transform="translate(-507.943 -260.974)"
                        fill="#08308B"
                      ></path>
                    </g>
                  </g>
                </g>
                <text
                  id="www.mobilemasr.com"
                  transform="translate(273 1006.535)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-SemiBold, Cairo"
                  font-weight="600"
                  letter-spacing="0.05em"
                >
                  <tspan x="0" y="0">www.mobilemasr.com</tspan>
                </text>
                <g
                  id="Group_11518"
                  data-name="Group 11518"
                  transform="translate(384 233.866)"
                >
                  <g
                    id="Rectangle_12515"
                    data-name="Rectangle 12515"
                    fill="#fff"
                    stroke="#FF5100"
                    stroke-width="1"
                  >
                    <rect width="200" height="36" rx="8" stroke="none"></rect>
                    <rect
                      x="0.5"
                      y="0.5"
                      width="199"
                      height="35"
                      rx="7.5"
                      fill="none"
                    ></rect>
                  </g>
                </g>
                <g
                  id="Group_11519"
                  data-name="Group 11519"
                  transform="translate(384 339.339)"
                >
                  <g
                    id="Rectangle_12521"
                    data-name="Rectangle 12521"
                    fill="#fff"
                    stroke="#FF5100"
                    stroke-width="1"
                  >
                    <rect width="200" height="36" rx="8" stroke="none"></rect>
                    <rect
                      x="0.5"
                      y="0.5"
                      width="199"
                      height="35"
                      rx="7.5"
                      fill="none"
                    ></rect>
                  </g>
                </g>
                <g
                  id="Group_11520"
                  data-name="Group 11520"
                  transform="translate(321 804.693)"
                >
                  <g
                    id="Rectangle_12529"
                    data-name="Rectangle 12529"
                    fill="#fff"
                    stroke="#FF5100"
                    stroke-width="1"
                  >
                    <rect width="329" height="36" rx="8" stroke="none"></rect>
                    <rect
                      x="0.5"
                      y="0.5"
                      width="328"
                      height="35"
                      rx="7.5"
                      fill="none"
                    ></rect>
                  </g>
                </g>
                <g
                  id="Group_11521"
                  data-name="Group 11521"
                  transform="translate(321 895.535)"
                >
                  <g
                    id="Rectangle_12531"
                    data-name="Rectangle 12531"
                    fill="#fff"
                    stroke="#FF5100"
                    stroke-width="1"
                  >
                    <rect width="329" height="36" rx="8" stroke="none"></rect>
                    <rect
                      x="0.5"
                      y="0.5"
                      width="328"
                      height="35"
                      rx="7.5"
                      fill="none"
                    ></rect>
                  </g>
                </g>
                <text
                  id="imei"
                  data-name="imei"
                  transform="translate(430 523.22)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan
                    x="-164.64"
                    y="0"
                    v-if="orderData.variants_data.imei != null"
                  >
                    {{ orderData.variants_data.imei }}
                  </tspan>
                </text>
                <g id="Group_11523" data-name="Group 11523">
                  <g
                    id="Rectangle_12528"
                    data-name="Rectangle 12528"
                    transform="translate(52 555.067)"
                    fill="#fff"
                    stroke="#FF5100"
                    stroke-width="1"
                  >
                    <rect
                      width="280.05"
                      height="36"
                      rx="8"
                      stroke="none"
                    ></rect>
                    <rect
                      x="0.5"
                      y="0.5"
                      width="279.05"
                      height="35"
                      rx="7.5"
                      fill="none"
                    ></rect>
                  </g>
                  <text
                    id="order_total"
                    data-name="order_total"
                    transform="translate(302 578.982)"
                    fill="#08308B"
                    font-size="14"
                    font-family="Cairo-Regular, Cairo"
                  >
                    <tspan x="-130.3" y="0">{{ orderData.total_paid }}</tspan>
                    <tspan y="0"></tspan>
                  </text>
                </g>
                <text
                  id="customer_id"
                  data-name="customer_id"
                  transform="translate(222 258.253)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-109.76" y="0">{{ vendorData.idnumber }}</tspan>
                </text>
                <text
                  id="customer_id"
                  data-name="customer_id"
                  transform="translate(222 828.535)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-109.76" y="0">{{ vendorData.idnumber }}</tspan>
                </text>
                <text
                  id="vendor_id_number"
                  data-name="vendor_id_number"
                  transform="translate(222 919)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-109.76" y="0">{{ customer_id_number }}</tspan>
                </text>
                <text
                  id="_vendor_id_number"
                  data-name="vendor_id_number"
                  transform="translate(222 362.454)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-109.76" y="0">{{ customer_id_number }}</tspan>
                </text>
                <text
                  id="سامسونج"
                  transform="translate(197 466.386)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-56.77" y="0">
                    {{ orderData.variants_data.brand_name }}
                  </tspan>
                </text>
                <text
                  id="order_created"
                  transform="translate(573 194)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-70.488" y="4">{{ orderData.order_day }}</tspan>
                </text>
                <text
                  id="date_day"
                  data-name="date_day"
                  transform="translate(455 194)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-35.68" y="4">{{ orderData.date_day }}</tspan>
                </text>
                <text
                  id="date_month"
                  data-name="date_month"
                  transform="translate(423 194)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-37.68" y="4">{{ orderData.date_month }}</tspan>
                </text>
                <text
                  id="date_year"
                  data-name="date_year"
                  transform="translate(387 194)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-50.36" y="4">{{ orderData.date_year }}</tspan>
                </text>
                <text
                  id="customer_name"
                  data-name="customer_name"
                  transform="translate(562 257)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-170.312" y="0">
                    {{ vendorData.firstname }} {{ vendorData.lastname }} ({{
                      vendorData.storename
                    }})
                  </tspan>
                </text>
                <text
                  id="vendor_name"
                  data-name="vendor_name"
                  transform="translate(564 362)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-140.312" y="0">{{ customer_name }}</tspan>
                </text>
                <text
                  id="customer_name"
                  data-name="customer_name"
                  transform="translate(625 828)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-144.312" y="0">
                    {{ vendorData.firstname }} {{ vendorData.lastname }}
                  </tspan>
                </text>
                <text
                  id="vendor_name"
                  data-name="vendor_name"
                  transform="translate(625 920)"
                  fill="#08308B"
                  font-size="14"
                  font-family="Cairo-Regular, Cairo"
                >
                  <tspan x="-144.312" y="0">{{ customer_name }}</tspan>
                </text>
              </g>
            </g>
          </svg>
        </b-card-code>
      </b-col>
    </b-row>
  </div>
</template>
  
  <script>
import { required } from '@validations'
export default {
  data() {
    return {
      required,
      errors_back: [],
      showDismissibleAlert: false,
      orderData: [],
      user: null,
      customer_name: null,
      customer_id_number: null,
      id_front_image_file: null,
      id_front_image: null,
      id_back_image_file: null,
      id_back_image: null,
      vendorData: null,
    }
  },
  components: {},
  created() {
    this.id = this.$route.params.id
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  computed: {},
  watch: {},
  methods: {
    ChangeIDFrontImage(e) {
      // this.id_front_image_file = e
      const image = e.target.files[0]
      if (e != null) {
        this.orderData.e_contract.id_front_image = URL.createObjectURL(image)

        var fileReader = new FileReader()
        let vm = this
        fileReader.onload = (e) => {
          vm.id_front_image = e.target.result
            .split(',')
            .map((item) => item.trim())[1]
          // console.log(this.id_front_image)
        }
        fileReader.readAsDataURL(image)
      }
    },
    ChangeIDBackImage(e) {
      // this.id_back_image_file = e
      const image = e.target.files[0]
      if (e != null) {
        this.orderData.e_contract.id_back_image = URL.createObjectURL(image)

        var fileReader = new FileReader()
        fileReader.readAsDataURL(image)

        fileReader.onload = (e) => {
          this.id_back_image = e.target.result
            .split(',')
            .map((item) => item.trim())[1]
        }
      }
    },

    fetchData() {
      axios
        .get('orders/' + this.$route.params.id + '/ShowOrderData')
        .then((result) => {
          this.orderData = result.data.data
          this.vendorData = result.data.data.vendor

          this.user = result.data.data.user
          this.customer_name = this.orderData.e_contract.customer_name
          this.customer_id_number = this.orderData.e_contract.customer_id_number
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          const data = {}
          if (this.id_back_image_file != null) {
            data.id_back_image = this.id_back_image
          }
          if (this.id_front_image_file != null) {
            data.id_front_image = this.id_front_image
          }
          data.customer_name = this.customer_name
          data.customer_id_number = this.customer_id_number

          // return ;
          axios
            .put(
              '/e-contracts/' + this.orderData.e_contract.id + '/update',
              data
              // {
              // customer_name: this.customer_name,
              // customer_id_number: this.customer_id_number,
              // }
            )
            .then((result) => {
           
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    generateEContract() {
      axios
        .get(
          'e-contracts/' + this.orderData.e_contract.id + '/imageGenerate'
        )
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.$router.push('/orders/show/' + this.$route.params.id)
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
  },
}
</script>
  