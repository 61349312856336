<template>
  <div>
    <b-card-code>
      <b-card-text class="mb-0">
        <h3>{{ attrbuiteName }}</h3>
        <span>Assigned Values</span>
      </b-card-text>
      <!-- attrbuite's values -->
      <div class="demo-inline-spacing">
        <b-badge variant="primary"  v-for="(permission,index) in attrbuiteAssignedValue" :key="index">
          {{ permission }}
        </b-badge>
      </div>
    </b-card-code>
    <b-card-code title="Assign values To Attrbuite">
      <b-row>
        <!-- select values box -->
        <b-col :md="12">
          <v-select
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="text"
            :options="option"
          />
        </b-col>
        <!-- assign permission button -->
        <b-col :md="4">
          <div class="demo-inline-spacing">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              @click="assign"
            >
              Assign
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-code>
    <!-- success handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showSuccessleAlert"
      @dismissed="showSuccessAlert = false"
      variant="success"
    >
      <h4 class="alert-heading">
        Success
      </h4>
      <div class="alert-body">
        <span>{{ success_message }}</span>
      </div>
    </b-alert>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{value}}</li>
                </ul>
      </div>
    </b-alert>
    <!-- empty permission message -->
    <b-modal
      id="modal-warning"
      v-model="modalShow"
      title="Warrning"
      ok-title="Accept"
      ok-only
      ok-variant="warning"
      modal-class="modal-warning"
      centered
    >
      Please select pirmissions first
    </b-modal>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
export default {
  components: {
    BCardCode,
  },

  props: ["attributeID", "itemID"],
  data() {
    return {
      id: 0,
      attrbuiteName: "",
      attrbuiteAssignedValue: [],
      success_message: "",
      showDismissibleAlert: false,
      showSuccessleAlert: false,
      errors_back: [],
      errors_assign: {},
      option: [],
      dir: "ltr",
      selected: [],
      values: [],
      modalShow: false,
    };
  },
  methods: {
    // assign function to submit assign values to attrbuite
    assign() {
      this.values = [];
      this.showDismissibleAlert = false;
      for (let select in this.selected) {
        this.values.push(this.selected[select].value);
      }
      if (this.values.length > 0) {
        axios
          .post("items-guide/" + this.$route.params.id + "/values/attach", {
            values: this.values,
          })
          .then((result) => {
            this.attrbuiteAssignedValue = [];
            this.$swal({
              position: "center",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
            this.fetchData();
          })
          .catch((err) => {
            this.$swal({
              position: "center",
              icon: "error",
              title: "Error!",
              showConfirmButton: false,
              timer: 1500,
            });
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
      } else {
        this.modalShow = !this.modalShow;
      }
    },
    fetchData() {
      axios
        .get("items-guide/" + this.$route.params.id)
        .then((result) => {
          const data = result.data.data;
          // this.attrbuiteName = data.name
          for (let index in data.attributes) {
            if (data.attributes[index].id == this.$route.params.attr_id) {
              this.name = data.attributes[index].translation.name;
              for (let ind in data.attributes[index].itemValues) {
                this.attrbuiteAssignedValue.push(
                  data.attributes[index].itemValues[ind].translation.name
                );
              }
            }
          }
          this.getAttrbuites();
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    getAttrbuites() {
      axios
        .get("attributes/" + this.$route.params.attr_id + "/values/get")
        .then((result) => {
          const data = result.data.data;
          // console.log(data);
          for (let index in data) {
            let attrbuites = this.attrbuiteAssignedValue.filter(
              (item) => item === data[index].translation.name
            );

            // console.log(this.attrbuiteAssignedValue);
            if (attrbuites.length == 0) {
              this.option.push({
                value: data[index].id,
                text: data[index].translation.name,
              });
            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
  created() {
    this.fetchData();
    // request all values data
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
