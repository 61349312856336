<template>
  <b-card title="Banners Setting">
    <validation-observer ref="simpleRules">
      <b-form>
        <!-- Display Setting Data -->
        <b-row v-if="settings">
          <b-col cols="12" v-for="(item, index) in settings" :key="index">
            <b-row>
              <b-col cols="9">
                <!-- setting attribute for single media-->
                <div v-if="item.data_type == 'single_media'">
                  <!-- setting attribute -->
                  <p style="font-weight: bold;">
                    {{ item.attribute.replace(/([A-Z,'_'])/g, ' ').trim() }}
                  </p>
                  <b-row>
                    <b-col cols="6" style="text-align: center">
                      <b-img-lazy
                      style="max-width: 100%;"
                        width="auto"
                        height="auto"
                        :src="item.value.image"
                      />
                      <!-- <iframe width="200" height="200" :src="item.value"> </iframe> -->
                    </b-col>
                    <b-col cols="6">
                      <!-- alt data -->
                      <b-form-group
                        class=""
                        label="alt"
                        :label-for="item.attribute"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="item.attribute"
                          rules="required"
                        >
                          <b-form-input
                            :disabled="editableIndex != item.id"
                            :id="item.attribute + index"
                            v-model="item.value.alt"
                            :state="errors.length > 0 ? false : null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- link data -->
                      <b-form-group
                        class=""
                        label="link"
                        :label-for="item.attribute"
                      >
                        <b-form-input
                          :disabled="editableIndex != item.id"
                          :id="item.attribute + index"
                          v-model="item.value.link"
                        />
                      </b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        :name="item.attribute"
                        rules="required"
                      >
                        <b-form-group
                          label="Active"
                          label-for="Active"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            :disabled="editableIndex != item.id"
                            :id="item.attribute + index"
                            v-model="item.active"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :clearable="false"
                            :options="boolOptions"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </div>
                <!-- setting of type single_media -->
                <div v-if="item.data_type == 'media_array'">
                  <p style="font-weight: bold;">
                    {{ item.attribute.replace(/([A-Z,'_'])/g, ' ').trim() }}
                  </p>
                  <b-row>
                    <swiper
                      class="swiper-autoplay"
                      :options="swiperOptions"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                      <swiper-slide
                        v-for="(data, index) in item.value"
                        :key="index"
                      >
                        <b-img
                          @click="imgIndex = index"
                          width="auto"
                          height="auto"
                          :src="data.image"
                          style="display: block; margin: auto;max-width: 100%;"
                        />
                      </swiper-slide>
                      <div slot="pagination" class="swiper-pagination" />
                    </swiper>
                  </b-row>
                </div>
              </b-col>

              <b-col md="3">
                <b-row>
                  <b-col cols="2" class="my-2">
                    <b-button
                      style="
                        padding: 7px;
                        margin: 6px;
                        width: 35px;
                        height: 35px;
                      "
                      v-if="permissionsAccess.includes('update_setting')"
                      :to="{ path: '/settings/' + item.attribute + '/update' }"
                      variant="warning"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon
                        icon="EditIcon"
                        style="width: 18px; height: 18px"
                      />
                    </b-button>
                  </b-col>

                  <b-col cols="2" class="my-2">
                    <b-button
                      style="
                        padding: 7px;
                        margin: 6px;
                        width: 35px;
                        height: 35px;
                      "
                      :to="{ path: '/settings/' + item.attribute + '/show' }"
                      variant="success"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        style="width: 18px; height: 18px"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="12"> </b-col>
              <b-col lg="4" md="2" class="my-1" v-if="editableIndex == item.id">
                <b-button
                  variant="primary"
                  class="mx-1"
                  @click="updateSetting($event, item)"
                  >Submit</b-button
                >
                <b-button
                  variant="secondary"
                  class="mx-1"
                  @click="disableEditing"
                  >Close</b-button
                >
              </b-col>
            </b-row>
            <hr v-if="index+1 != settings.length"
              class="w-100 border-gray my-1"
              style="border-top: 5px solid #ebe9f1"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { required, url } from '@validations'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      required,
      url,
      editableIndex: null,

      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  computed: {
    boolOptions() {
      return this.$store.state.Setting.boolOptions
    },
  },

  props: ['settings', 'permissionsAccess'],

  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      this.$store.commit('Setting/storeImageEditor', {
        file,
        Editor,
        cursorLocation,
        resetUploader,
      })
    },
    async fetchData() {
      await this.$store.dispatch('Setting/getSettings')
    },
    changeSettingType(item) {
      this.$store.commit('Setting/updateSettingTypeFiltering', item)
    },
    ChangeImageValue(e, index) {
      // console.log(e)
      if (e != null) {
        if (this.settings[index].data_type == 'single_media') {
          this.settings[index].value.image = URL.createObjectURL(e)
        } else {
          this.settings[index].value = URL.createObjectURL(e)
        }
      }
    },
    enableEditing(e, id) {
      this.editableIndex = id
    },
    disableEditing(e) {
      this.editableIndex = null
    },
    async updateSetting(e, item) {
      if (item.data_type == 'boolean') {
        if (item.value == 'true') {
          // console.log(item.value)
          item.value = 'true'
        } else {
          item.value = 'false'
        }
      }

      if (item.active == 'true') {
        item.active = 'true'
      } else {
        item.active = 'false'
      }

      if (item.data_type == 'file' || item.data_type == 'image') {
        if (this.file == null) {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'File Cannot be null!',
            showConfirmButton: false,
            timer: 1500,
          })
          return
        }

        // item.value = this.file.value
      }
      await this.$store.dispatch('Setting/updateSetting', item)
      if (this.$store.state.Setting.showDismissibleAlert == false) {
        this.$swal({
          position: 'center',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500,
        })

        this.disableEditing()
      } else {
        this.$swal({
          position: 'center',
          icon: 'error',
          title: 'Error!',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('Setting/setSetting', [])
  },
}
</script>
  
<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>

  
<style scoped lang="scss">
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px !important;
}
.swiper-container {
  padding: 30px !important;
}
</style>
  