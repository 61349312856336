!<template>
  <div>
    <b-row>
       
            
    <b-col cols="12" md="12">
        <GuideInsightsChart :chartsData="GuideInsightsCountsData" @update-users-charts-data="updateGuideInsightsData"/>
       </b-col>
    </b-row>
  </div>
</template>

<script>
import GuideInsightsChart from "../../Components/charts/GuideInsightsChart.vue";
import TotalCardSection from "../../Components/charts/TotalCardSection.vue"
import * as echarts from 'echarts'
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/flatpickr.css';
import FlatPickr from 'vue-flatpickr-component';
import { MonthPickerInput } from 'vue-month-picker'

export default {
    components: {
        flatPickr: FlatPickr,
        MonthPickerInput,
        GuideInsightsChart,
        TotalCardSection
    },
  data() {
        return {
            date: new Date(),
            activeYearInput: false,
            activeMonthInput: true,
            activeRangeInput: false,
            activeQuarterInput: false,
            selectedMonth: null,
            currentYearStart: new Date(new Date().getFullYear(), 0, 1), // Start of the current year
            currentYearEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
            selectedDateRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), // First day of the current month
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0) // Last day of the current month
            ],
            GuideInsightsCountsData: [],
            flatpickrOptions: {
                mode: 'range',
                dateFormat: 'Y-m-d',
            },
            yearSelect: null,
            yearOptions: ["2024", "2023", "2022"],
            quarterOptions: [
                {
                    'text': "Q1",
                    'value': "1",

                },
                {
                    'text': "Q2",
                    'value': "2",

                },
                {
                    'text': "Q3",
                    'value': "3",

                },
                {
                    'text': "Q4",
                    'value': "4",

                },
            ],
            quarterSelect: null,
            errors_back: [],
        }
    },
    async created() {
    // await this.getGuideInsightsReport('month',new Date().getFullYear() + '-' + (new Date().getMonth() + 1))
    // this.run()
     },
    methods: {
        updateGuideInsightsData(newData) {
            this.GuideInsightsCountsData = newData;
        },
        async getGuideInsightsReport() {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based


            await axios
                .post('items-guide/GuideInsightsChart', {
                month:  year + '-' + month
                })
                .then((result) => {``
                
                this.responseData = result.data.data
                this.GuideInsightsCountsData = result.data.data[0].total_count
                  
                    

                })
                .catch((err) => {
             
                this.errors_back.length = 0
                if (err.response != null) {
                    if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                        error: err.response.data.data,
                    })
                    } else {
                    this.errors_back = err.response.data.data
                    }
                    this.showDismissibleAlert = true
                } else {
                    this.errors_back = []
                    this.errors_back.push({
                    error: 'internal server error',
                    })
                    this.showDismissibleAlert = true
                }
                })
            },
    }
}
</script>
