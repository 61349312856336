<template>
  <div>
    <b-card-code title="Add accessory" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- image -->
            <b-col md="6">
              <b-form-group label="Image" label-for="Iamge">
                <validation-provider
                  #default="{ errors }"
                  name="Iamge"
                  rules="required"
                >
                  <b-form-file
                    v-model="ProfilePictureBase64"
                    :state="errors.length > 0 ? false : null"
                    @change="uploadImage"
                    id="ProfilePictureBase64"
                    accept=".jpg, .png"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

             <!-- select permissions box -->
             <!-- <b-col md="4">
                      <validation-provider #default="{ errors }" name="category" rules="required">
                        <b-form-group label="Select Category" label-for="category"
                          :state="errors.length > 0 ? false : null">
                          <v-select v-model="data.category" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="text"
                            value="id"
                            :options="categories.map(category => ({ id: category.id, text: category.translation.name }))" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                </b-col> -->

              <!-- Row Loop -->
            <b-col cols="12">
              
              <b-row v-for="(item, index) in data.categories" :id="'category-' + index + 1"
                :key="'category-' + index + 1" ref="row">

                <!-- locale -->
                <b-col md="4">
                  <validation-provider #default="{ errors }" name="category" rules="required">
                    <b-form-group label="category" label-for="category">
                      <v-select v-model="selectedCategories[index]" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name" value="id" :state="errors.length > 0 ? false : null"
                        :options="categories.map(category => ({ id: category.id, name: category.translation.name }))"
                         />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Remove Button -->
                <b-col md="4" class="mb-50">
                  <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" v-show="index >= 1" variant="outline-danger"
                    class="mt-0 mt-md-2" @click="removeCategory(index)">
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Remove</span>
                  </b-button>
                </b-col>
                 <!-- add new button -->
           
                <b-col cols="12">
                  
                </b-col>
              </b-row>
              </b-col>

              <b-col cols="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="addCategory">
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add Category</span>
              </b-button>
            </b-col>
            

            <b-col sm="12">
            <hr />

              <div>
                <!-- Row Loop -->
                <b-row v-for="(item, index) in tranLocaleSelect" :key="index">
                  <!-- translation language -->

                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="language"
                      rules="required"
                    >
                      <b-form-group
                        label="Language"
                        label-for="language"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          :id="'language' + index"
                          v-model="tranLocaleSelect[index]"
                          :state="
                            tranLocaleSelect[index] === null ? false : true
                          "
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="localeOptions"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Name -->
                  <b-col md="4">
                    <b-form-group
                      v-b-popover.hover.top="'Please Enter Brand Name'"
                      label="Name"
                      label-for="name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          :id="'name' + index"
                          v-model="data.translations[index].name"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col lg="2" md="3" class="mb-50">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      v-show="!tranLocaleSelect[index].disa"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItem(index)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Remove</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <!-- add new button -->
            <b-col sm="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                block
                @click="repeateAgain"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add New</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{value}}</li>
                </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { heightTransition } from "@core/mixins/ui/transition";
import { required } from "@validations";
export default {
  mixins: [heightTransition],
  data() {
    return {
      ProfilePictureBase64: [],
      ProfilePictureBase642: [],
      activeSelect: null,
      activeOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
        {
          value: "0",
          text: "False",
        },
        {
          value: "1",
          text: "True",
        },
      ],
      data: {
        image: [],
        categories: [ {
        }],
        translations: [
          {
            locale: "",
            name: "",
          },
          {
            locale: "",
            name: "",
          },
        ],
        nextTodoId: 5,
      },
      tranLocaleSelect: [
        {
          value: "ar",
          text: "Arabic",
          disa: true,
        },
        {
          value: "en",
          text: "English",
          disa: true,
        },
      ],
      localeOptions: [
        {
          value: "en",
          text: "English",
        },
        {
          value: "ar",
          text: "Arabic",
        },
      ],
      errors_back: "",
      showDismissibleAlert: false,
      required,
      categories: [],
      selectedCategories: [],
        };
  },
  async created() {
    await this.getCategories();
  },
  watch: {
    selectedCategories: {
      deep: true,
      handler(newSelectedCategories, oldSelectedCategories) {
        // Loop through the new selectedCategories array
        newSelectedCategories.forEach((selectedCategory, index) => {
          // Update the corresponding ID in data.categories[index].id
          this.$set(this.data.categories, index, { ...this.data.categories[index], id: selectedCategory.id });
        });
        // console.log(this.data.categories)
      },
    },
  },
  methods: {
    // submit Brand API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.data.image.includes(",")) {
            this.data.image = this.data.image
              .split(",")
              .map((item) => item.trim());
            this.data.image = this.data.image[1];
          }

          for (let trn in this.data.translations) {
            this.data.translations[trn].locale = this.tranLocaleSelect[
              trn
            ].value;
          }

          axios
            .post("accessories", this.data)
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.push({
                path: "/accessory/index",
              });
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    // cencode cover
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.data.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    // REPETED FORM
    repeateAgain() {
      this.data.translations.push({
        //  id: this.nextTodoId += this.nextTodoId,
      });
      this.tranLocaleSelect.push({});
    },
    // remove function to delete single translation from category
    removeItem(index) {
      if (this.data.translations.length <= 1) {
        this.$bvModal.msgBoxConfirm("You need minmum one tab to continue.", {
          title: "Alert",
          size: "sm",
          okVariant: "danger",
          okTitle: "Accept",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        });
      } else {
        this.data.translations.splice(index, 1);
        this.tranLocaleSelect.splice(index, 1);
      }
    },
    
    getCategories(){
      axios
      .get('categories')
      .then((result) => {
        this.categories = result.data.data
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
    },
    addCategory() {
      this.data.categories.push(
        {
          id: null,
        }
      )
    },
    removeCategory(index) {
      if (this.data.categories.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.data.categories.splice(index, 1)
      }
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
    // basic
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped></style>
