<template>
  <div>

    <b-card  v-if="showAddForm == false"> 
      <b-card-title> Choose AirWay Bill Type</b-card-title>

                 <b-col md="12" class="d-flex justify-content-center  shadow mt-1 p-1">
                        <b-form-group >
                          <div class="d-flex  p-2" >
                            <b-button v-model="AirWayBillType" class="p-3"  :variant="AirWayBillType == 'order' ? 'primary' : ''"  @click="AirWayBillType = 'order'"
                              name="some-radios">Order AirWayBill</b-button>
                            <b-button class="ml-3 p-3" :variant="AirWayBillType == 'blank' ? 'primary' : ''"  @click="AirWayBillType = 'blank'" v-model="AirWayBillType"  name="some-radios"
                              >Blank  AirWayBill</b-button>
                          </div>
                        </b-form-group>
                    </b-col>
                <b-col md="12" class="d-flex justify-content-center  shadow p-1" v-if="AirWayBillType">
                          <b-col md="12" class="d-flex justify-content-center  mt-1 p-2">
                                  <b-form-group class="d-flex  p-0" >
                                    <div class="d-flex">
                                      <b-button v-model="selected" class="p-3"  :variant="selected == 'send' ? 'primary' : ''"  @click="selected = 'send'" 
                                      name="some-radios">Send Order</b-button>
                                      <b-button  class="ml-3 p-3" :variant="selected == 'return' ? 'primary' : ''"  @click="selected = 'return'" v-model="selected"  name="some-radios"
                                        >Return Order</b-button>
                                      
                                    </div>
                                  </b-form-group>
                          </b-col>
                  </b-col>
                  <b-col md="12" class="d-flex justify-content-center ">
                          
                        <b-col cols="3" >
                          <b-button
                            class="mt-3 w-100"
                            :disabled="selected == null || AirWayBillType == null"
                            @click="showAddFormFun"
                            variant="primary"
                            >Next</b-button>
                        </b-col>
                  </b-col>
     </b-card>

    <b-card-code
    v-if="showAddForm"
      title="Store AirWayBill And Send Pickup Request"
      :before-change="validationForm"
    >
  
      <validation-observer ref="simpleRules" >
        <b-form>
          <b-row>
            <b-col md="6" v-if="AirWayBillType == 'order'" class="shadow p-2 mb-2" >
                  <div style="position: relative">
                    <b-row>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="order"
                          rules=""
                        >
                          <b-form-group
                            label="Order"
                            label-for="Order"
                            :state="errors.length > 0 ? false : null"
                          >
                            <b-form-input
                              id="searchOrder"
                              :state="airWayBillData.order_code == null ? false : true"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              v-model="searchOrder"
                              placeholder="Search For Add Order"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="2">
                        <b-button
                          class="mt-2"
                          @click="searchOrderCode(searchOrder, 0)"
                          variant="outline-primary"
                          >Search</b-button
                        >
                      </b-col>
                      <b-alert
                        variant="warning"
                        style="margin: 0.438rem 1rem"
                        :show="noOrder"
                      >
                        <div class="alert-body">No Result Found.</div>
                      </b-alert>
                      <b-col
                        class="mt-2"
                        style="
                          position: absolute;
                          z-index: 9999;
                          padding: 0.438rem 1rem;
                          top: 35px;
                          max-height: 380px !important;
                          overflow-y: scroll;
                          overflow-x: hidden;
                          background-color: white;
                          scroll-padding: 0px !important;
                          padding-right: 0;
                        "
                        v-if="orderShow"
                        cols="12"
                      >
                        <b-list-group>
                          <b-list-group-item
                            @click="addOrder(item)"
                            v-for="(item, index) in oderSR"
                            :key="index"
                          >
                            <feather-icon icon="" class="mr-75" />
                            <p style="font-weight: 600">ID: {{ item.id }} - Code:
                              {{ item.code }}</p>
                            <!-- <small style="padding-left: 10px"
                              >ID: {{ item.id }} - Code:
                              {{ item.code }}</small
                            > -->
                          </b-list-group-item>
                          <infinite-loading
                            @infinite="searchOrderCode(searchOrder, orderCurrentPage)"
                          ></infinite-loading>
                        </b-list-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>

                <b-col cols="12" class="my-2"> 
                    <h3> Add Products :</h3>
                </b-col>
                  <b-col cols="6" v-if="AirWayBillType == 'blank'"> 
                    <div style="position: relative" class="shadow p-2 mb-3" >
                      <b-row>
                        <b-col cols="9" >
                          <validation-provider
                          #default="{ errors }"
                          name="Product_Search"
                          rules=""
                         >
                          <b-form-group
                            label="Product Search"
                            label-for="Product Search"
                            :state="errors.length > 0 ? false : null"
                          >
                          <b-form-input
                            id="searchText"
                            style="height: 37.45px"
                            type="search"
                            :state="variants.length == 0 ? false : true"
                            v-model="searchText"
                            placeholder="Search For Add Product"
                          />
                          </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col cols="2">
                          <b-button
                            @click="searchResultsubmit(searchText)"
                            class="mt-2"
                            variant="outline-primary"
                            >Search</b-button
                          >
                        </b-col>
                        <b-col cols="2" class="mt-2" v-if="noResult">
                          <b-alert variant="warning" style="" :show="noResult">
                            <div class="alert-body">No Result Found.</div>
                          </b-alert>
                        </b-col>

                        <b-col
                          class="mt-2"
                          style="
                            position: absolute;
                            z-index: 9999;
                            padding: 0.438rem 1rem;
                            top: 38px;
                            height: 380px !important;
                            overflow: scroll;
                            scroll-padding: 0px !important;
                            padding-right: 0;
                            background-color: #fff;
                          "
                          v-if="resultShow"
                          cols="12"
                        >
                          <b-list-group>
                            <b-list-group-item
                              v-b-modal.modal-prevent-closing
                              @click="
                                addProduct(
                                  item.id,
                                  item.name,
                                  item.price,
                                  item.quantity,
                                  item.weight
                                )
                              "
                              v-for="(item, index) in productsSR"
                              :key="index"
                            >
                              <feather-icon icon="" class="mr-75" />
                              <p style="font-weight: 600">{{ item.name }}</p>
                              <div class="d-inline">
                                <small style="padding-left: 10px"
                                  >Price: {{ item.price }}</small
                                >
                                <small style="padding-left: 10px"
                                  >SKU: {{ item.sku }}</small
                                >
                              </div>
                            </b-list-group-item>
                            <infinite-loading
                              @infinite="searchResult"
                            ></infinite-loading>
                          </b-list-group>
                        </b-col>

                        <b-col cols="12">
                          <b-row>
                            <b-col
                              v-if="variants.length"
                              class="pt-1 pb-1 pr-5 pl-5"
                              cols="12"
                            >
                              <h2
                                v-if="variants.length"
                                class="mr-3 pt-2"
                              >
                                Items List:
                              </h2>
                              <!-- Row Loop -->
                              <div v-if="variants.length">
                                <b-row
                                  v-for="(item, index) in variants"
                                  :key="index"
                                >
                                  <b-col cols="12">
                                    <hr />
                                  </b-col>
                                  <b-col class="pl-5" cols="7">
                                    <h4>{{ item.name }}</h4>
                                    <small style="padding-left: 10px"
                                      >Price: {{ item.price }}</small
                                    >
                                  </b-col>
                                  <b-col md="2">
                                    <b-form-group
                                      label="Quantity"
                                      label-for="Quantity"
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        name="Quantity"
                                      >
                                        <b-form-input
                                          min="1"
                                          id="Quantity"
                                          type="number"
                                          v-if="item.max_qun"
                                          :max="item.max_qun"
                                          v-model="item.quantity"
                                          @input="recalcCost()"
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                          placeholder="15"
                                        />
                                        <small class="text-danger">{{
                                          errors[0]
                                        }}</small>
                                      </validation-provider>
                                    </b-form-group>
                                  </b-col>
                                  <!-- Remove Button -->
                                  <b-col md="2" class="mb-50">
                                    <b-button
                                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                      variant="outline-danger"
                                      class="mt-0 mt-md-2"
                                      @click="removeItem(index)"
                                    >
                                      <feather-icon icon="XIcon" class="mr-25" />
                                      <span>Remove</span>
                                    </b-button>
                                  </b-col>
                                  <b-col cols="12">
                                    <hr />
                                  </b-col>
                                </b-row>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                
          
            <b-col md="12" v-for="(variant,index) in  productValues" :key="index"  class="row   values-shadow" >
            <b-col md="4">
              <b-form-group
                label="Product Description"
                label-for="product_description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Product Description"
                  rules="required"
                >
                  <b-form-input
                    id="product_description"
                    v-model="variant.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Iphone ....."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>


                

              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Number Of Pieices"
                label-for="number_of_peices"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Number Of Pieices"
                  rules="required|integer"
                >
                <!-- <span style="display: none;" v-if="AirWayBillType == 'order'"> {{ variant.number_of_peices = variant.quantity  }}</span> -->
                  <b-form-input
                    id="number_of_peices"
                    type="number"
                    v-model="variant.quantity"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="weight"
                label-for="weight"
              >
                <validation-provider
                  #default="{ errors }"
                  name="weight"
                  rules=""
                >
                  <b-form-input
                  :disabled="variant.weight != null ? true : false"
                    id="weight"
                    type="number"
                    v-model="variant.weight"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="1" md="2" class="mb-50">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="mt-0 mt-md-2"
                @click="addItem"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add New</span>
              </b-button>
            </b-col>
            <b-col lg="2" md="3" class="mb-50">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                v-show="productValues.length != 1"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon icon="XIcon" class="mr-25" />
                <span>Remove</span>
              </b-button>
          </b-col>
          </b-col>
         
            <b-col md="6" class="my-3" v-if="authPermissions.includes('add-price-to-air-way-bill')">
              <b-form-group
                label="Collect Price"
                label-for="cod_amount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Collect Price"
                  rules="required|integer"
                >
                  <b-form-input
                    id="cod_amount"
                    type="number"
                    v-model="airWayBillData.cod_amount"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>


              
            <b-col cols="12">
              <app-collapse
                id="airWayBill-data"
                type="margin"
                class="mt-0 mb-0"
              >
                <!-- Sender Data -->
                <app-collapse-item title="Sender Data">
                  <div>
                    <!-- Row Loop -->
                    <b-row>

                      <b-col md="6" v-if="selected == 'return' && AirWayBillType == 'blank'">
                  <div style="position: relative">
                    <b-row>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="User Search"
                          rules="required"
                        >
                          <b-form-group
                            label="User"
                            label-for="User"
                            :state="errors.length > 0 ? false : null"
                          >
                            <b-form-input
                              id="searchUser"
                              :state="searchUser == null ? false : true"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              v-model="searchUser"
                              placeholder="Search For Add user"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="2">
                        <b-button
                          class="mt-2"
                          @click="searchUserR(searchUser, 0)"
                          variant="outline-primary"
                          >Search</b-button
                        >
                      </b-col>
                      <b-alert
                        variant="warning"
                        style="margin: 0.438rem 1rem"
                        :show="noUser"
                      >
                        <div class="alert-body">No Result Found.</div>
                      </b-alert>
                      <b-col
                        class="mt-2"
                        style="
                          position: absolute;
                          z-index: 9999;
                          padding: 0.438rem 1rem;
                          top: 35px;
                          max-height: 380px !important;
                          overflow-y: scroll;
                          overflow-x: hidden;
                          background-color: white;
                          scroll-padding: 0px !important;
                          padding-right: 0;
                        "
                        v-if="userShow"
                        cols="12"
                      >
                        <b-list-group>
                          <b-list-group-item
                            @click="addUser(item)"
                            v-for="(item, index) in userSR"
                            :key="index"
                          >
                            <feather-icon icon="" class="mr-75" />
                            <p style="font-weight: 600">{{ item.name }}</p>
                            <small style="padding-left: 10px"
                              >Email: {{ item.email }} - PhoneNumber:
                              {{ item.phone }}</small
                            >
                          </b-list-group-item>
                          <infinite-loading
                            @infinite="searchUserR(searchUser, userCurrentPage)"
                          ></infinite-loading>
                        </b-list-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                      <!-- city -->
                      <b-col md="6">
                        <validation-provider
                          #default="{ errors }"
                          name="City"
                          rules="required"
                        >
                          <b-form-group
                            label="City"
                            label-for="city"
                            :state="errors.length > 0 ? false : null"
                          >
                            <v-select
                              id="city"
                              label="name"
                              v-model="senderSelectedCity"
                              :state="
                                airWayBillData.sender.city_id === null
                                  ? false
                                  : true
                              "
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="citiesData"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- Address -->
                      <b-col md="6">
                        <b-form-group
                          label="Address"
                          label-for="sender-address"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Address"
                            rules="required"
                          >
                            <b-form-input
                              id="sender-address"
                              v-model="airWayBillData.sender.address"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Street ..."
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Contact Person -->
                      <b-col md="4">
                        <b-form-group
                          label="Contact Person"
                          label-for="sender-contact-person"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Contact Person"
                            rules="required"
                          >
                            <b-form-input
                              id="sender-contact-person"
                              v-model="airWayBillData.sender.contact_person"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Ahmed"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Contact Email -->
                      <b-col md="4">
                        <b-form-group label="Email" label-for="sender-email">
                          <validation-provider
                            #default="{ errors }"
                            name="Email"
                            rules="required|email"
                          >
                            <b-form-input
                              id="sender-email"
                              v-model="airWayBillData.sender.email"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Example@MobileMasr.com"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="Phone" label-for="sender-phone">
                          <validation-provider
                            #default="{ errors }"
                            name="Phone"
                            rules="required|integer"
                          >
                            <b-form-input
                              id="sender-phone"
                              type="number"
                              v-model="airWayBillData.sender.phone"
                              :state="errors.length > 0 ? false : null"
                              placeholder="xxxxxxxx"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- add new button -->
                  </div>
                </app-collapse-item>
                <!-- Receiver Data -->
                <app-collapse-item title="Receiver Data">
                  <div>
                    <!-- Row Loop -->
                    <b-row>

                  <b-col md="12" v-if="selected == 'send' && AirWayBillType == 'blank'" >
                  <b-col  md="6" class="shadow  p-2 mb-2 ">
                  <div style="position: relative" >
                    <b-row>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="user_Search"
                          rules=""
                        >
                          <b-form-group
                            label="Search User"
                            label-for="User"
                            :state="errors.length > 0 ? false : null"
                          >
                            <b-form-input
                              id="searchUser"
                              :state="searchUser == null ? false : true"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              v-model="searchUser"
                              placeholder="Search For Add user"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="2">
                        <b-button
                          class="mt-2"
                          @click="searchUserR(searchUser, 0)"
                          variant="outline-primary"
                          >Search</b-button
                        >
                      </b-col>
                      <b-alert
                        variant="warning"
                        style="margin: 0.438rem 1rem"
                        :show="noUser"
                      >
                        <div class="alert-body">No Result Found.</div>
                      </b-alert>
                      <b-col
                        class="mt-2"
                        style="
                          position: absolute;
                          z-index: 9999;
                          padding: 0.438rem 1rem;
                          top: 35px;
                          max-height: 380px !important;
                          overflow-y: scroll;
                          overflow-x: hidden;
                          background-color: white;
                          scroll-padding: 0px !important;
                          padding-right: 0;
                        "
                        v-if="userShow"
                        cols="12"
                      >
                        <b-list-group>
                          <b-list-group-item
                            @click="addUser(item)"
                            v-for="(item, index) in userSR"
                            :key="index"
                          >
                            <feather-icon icon="" class="mr-75" />
                            <p style="font-weight: 600">{{ item.name }}</p>
                            <small style="padding-left: 10px"
                              >Email: {{ item.email }} - PhoneNumber:
                              {{ item.phone }}</small
                            >
                          </b-list-group-item>
                          <infinite-loading
                            @infinite="searchUserR(searchUser, userCurrentPage)"
                          ></infinite-loading>
                        </b-list-group>
                      </b-col>
                    </b-row>
                  </div>
                  </b-col>
                </b-col>

                      <!-- city -->
                      <b-col md="6">
                        <validation-provider
                          #default="{ errors }"
                          name="City"
                          rules="required"
                        >
                          <b-form-group
                            label="City"
                            label-for="city"
                            :state="errors.length > 0 ? false : null"
                          >
                            <v-select
                              id="city"
                              label="name"
                              v-model="receiverSelectedCity"
                              :state="
                                airWayBillData.receiver.city_id === null
                                  ? false
                                  : true
                              "
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="citiesData"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- Address -->
                      <b-col md="6">
                        <b-form-group
                          label="Address"
                          label-for="receiver-address"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Address"
                            rules="required"
                          >
                            <b-form-input
                              id="receiver-address"
                              v-model="airWayBillData.receiver.address"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Street ..."
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Contact Person -->
                      <b-col md="4">
                        <b-form-group
                          label="Contact Person"
                          label-for="receiver-contact-person"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Contact Person"
                            rules="required"
                          >
                            <b-form-input
                              id="receiver-contact-person"
                              v-model="airWayBillData.receiver.contact_person"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Ahmed"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Contact Email -->
                      <b-col md="4">
                        <b-form-group label="Email" label-for="receiver-email">
                          <validation-provider
                            #default="{ errors }"
                            name="Email"
                            rules="required|email"
                          >
                            <b-form-input
                              id="receiver-email"
                              v-model="airWayBillData.receiver.email"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Example@MobileMasr.com"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="Phone" label-for="receiver-phone">
                          <validation-provider
                            #default="{ errors }"
                            name="Phone"
                            rules="required|integer"
                          >
                            <b-form-input
                              id="receiver-phone"
                              type="number"
                              v-model="airWayBillData.receiver.phone"
                              :state="errors.length > 0 ? false : null"
                              placeholder="xxxxxxxx"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- add new button -->
                  </div>
                </app-collapse-item>
              </app-collapse>
            </b-col>

            <b-col class="my-1">
              <b-button
                size="lg"
                variant="success"
                type="submit"
                @click.prevent="CalcShippingFees('calc')"
              >
                Clac Shipping Fees
              </b-button>

              <span class="h3 text-bold text-success px-4"  v-if="shipping_fees_value != null">
                 Shipping Fees:  ( {{ shipping_fees_value }} EGP )</span>
            </b-col>
            <!-- submit button -->
            <b-col class="my-1">
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="CalcShippingFees('submit')"
              >
                Submit
              </b-button>

              <b-button
                size="lg"
                class="mx-2"
                variant="danger"
                type="submit"
                @click.prevent="closeAddForm()"
              >
                Back
              </b-button>
            </b-col>

            
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
  <script>
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
export default {
  components: {
    BCardCode,
    AppCollapse,
    AppCollapseItem,
  },

  mixins: [heightTransition],
  watch: {
    senderSelectedCity(newCity) {
      this.airWayBillData.sender.city_id = newCity ? newCity.id : null;
    },
    receiverSelectedCity(newCity) {
      this.airWayBillData.receiver.city_id = newCity ? newCity.id : null;
    },
    selected(value){
      // this.DefaultPickupData(value);
    },
    AirWayBillType(value){
      this.productValues = [{}];
    }
  },
  data() {
    return {
      authPermissions: [],
      citiesData: [],
      senderSelectedCity: null,
      receiverSelectedCity: null,
      airWayBillData: {
        product_description: null,
        number_of_peices: null,
        total_of_weight: null,
        cod_amount: 0,
        order_id: null,
        receiver: {
          city_id: null,
          email: null,
          contact_person: null,
          address: null,
          phone: null,
        },
        sender: {
          city_id: null,
          email: null,
          contact_person: null,
          address: null,
          phone: null,
        },
      },
      productValues: [{}],
      errors_back: '',
      showDismissibleAlert: false,
      required,
      currentPage: 0,
      orderCurrentPage: 0,
      noOrder: false,
      orderShow: false,
      searchOrder: '',
      oderSR: [],
      selected: null,
      AirWayBillType: null,
      mobmasrShipping: null,
      shipping_fees_value: null,
      selectedOrder: [],
      productsSR: [],
      noResult: false,
      resultShow: false,
      variants: [],
      userSR: [],
      userCurrentPage: 0,
      noUser: false,
      searchUser: '',
      searchText: '',
      userShow: false,
      userSelected: null,
      showAddForm: false,
    }
  },
  async created() {
    this.authPermissions = this.$store.state.authPermissions
    // fetch index of cities
    // fetch index of cities
    await this.getCities()
    // this.DefaultPickupData('send');
  },
  methods: {
    async getCities() {
      await axios
        .get('cities')
        .then((result) => {
          this.citiesData = result.data.data
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },


    


    // submit CalcShippingFees API
    CalcShippingFees(type) {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          console.log('productValues',this.productValues);
          const names = this.productValues.map(item => item.name);
          const namesString = names.join(', ');

          const totalPeices = this.productValues.reduce((sum, item) => sum + parseInt(item.quantity), 0);

          this.airWayBillData.product_description = namesString;
          this.airWayBillData.number_of_peices = parseInt(totalPeices);

          const totalweight = this.productValues.reduce((sum, item) => sum + (parseInt(item.weight) * parseInt(item.quantity) ), 0);
          this.airWayBillData.total_of_weight = parseInt(totalweight);


          axios
            .post('airWayBills/calcOrderShippingPrice', this.airWayBillData)
            .then((result) => {

           

              // this.$swal({
              //   position: 'center',
              //   icon: 'success',
              //   title: 'Your work has been saved',
              //   showConfirmButton: false,
              //   timer: 1500,
              // });
              if(result.data.net_amount){
                this.shipping_fees_value = result.data.net_amount;

                if(type == 'submit') {
                this.$bvModal
                  .msgBoxConfirm('Your shipping Fees is : (  ' + this.shipping_fees_value + ' ) EGP', {
                    title: 'Are You Sure?',
                    size: 'sm',
                    okVariant: 'info',
                    okTitle: 'confirm',
                    cancelTitle: 'Close',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                  })
                  .then((value) => {
                    if (value == true) {

                      
                        this.validationForm();
                      }
                    });
                  }

                    
              }else{
                this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Sorry, value cannot be displayed',
                showConfirmButton: false,
                timer: 1500,
              });
              }
              // console.log('result',result.data.net_amount);
              
              
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },


    // submit area API
    validationForm() {
      this.$refs.simpleRules.validate().then( (success) => {
        if (success) {
          

      
          const names = this.productValues.map(item => item.name);
          const namesString = names.join(', ');

          // const totalPeices = this.productValues.reduce((sum, item) => sum + item.number_of_peices, 0);

          this.airWayBillData.product_description = namesString;
          // this.airWayBillData.number_of_peices = parseInt(totalPeices);

          axios
            .post('airWayBills/createAWBAndPickup', this.airWayBillData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              
              this.$router.push({
                path: '/AirWayBill/index',
              })
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    searchOrderCode(text, $page) {
      this.noOrder = false
      this.orderCurrentPage = $page + 1
      axios
        .get(
          'orders/airway-orders/20/sortBy-id-desc/' +
            text +
            '?page=' +
            this.orderCurrentPage
        )
        .then((result) => {
          const data = result.data.data.data
          // this.oderSR = data
          if(this.orderCurrentPage == 1){
            this.oderSR = []
          }
          this.oderSR = this.oderSR.concat(data)
          if (this.oderSR.length > 0) {
            this.orderShow = true
          } else {
            if(this.orderCurrentPage == 1){
              this.oderSR = []
              this.noOrder = true
              this.orderShow = false

            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    DefaultPickupData(type) {

      axios
        .get(
          'setting/data/DefaultPickupData')
        .then((result) => {
          // this.mobmasrShipping = result.data.data;
          const data = result.data.data;
          if(type == 'send'){

          const filteredAddressItem = data.find(item => item.attribute === "airway_bill_address");
          if (filteredAddressItem) {
              this.airWayBillData.sender.address = filteredAddressItem.value;
            }



            const filteredContactPersonItem = data.find(item => item.attribute === "airway_bill_contact_person");
          if (filteredContactPersonItem) {
              this.airWayBillData.sender.contact_person = filteredContactPersonItem.value;
            }



            const filteredemailItem = data.find(item => item.attribute === "airway_bill_email");
          if (filteredemailItem) {
              this.airWayBillData.sender.email = filteredemailItem.value;
            }



            const filteredPhoneItem = data.find(item => item.attribute === "airway_bill_phone");
          if (filteredPhoneItem) {
              this.airWayBillData.sender.phone = filteredPhoneItem.value;
            }

            this.senderSelectedCity = {
                            active: true,
                            name: "CAIRO",
                            country_name: "Egypt",
                            code: "CAI",
                            id:18
                          }

          //  if(this.AirWayBillType == 'order' && this.selectedOrder.length != 0){
          //   this.airWayBillData.receiver = {
          //             city_id: this.selectedOrder.city.id,
          //             email:  this.selectedOrder.user.email,
          //             contact_person: this.selectedOrder.user.firstname + ' ' + this.selectedOrder.user.lastname,
          //             address: this.selectedOrder.address,
          //             phone: this.selectedOrder.user.phone,
          //           };

          //     this.receiverSelectedCity = {
          //                   active: true,
          //                   name: this.selectedOrder.city.name,
          //                   country_name: "Egypt",
          //                   code: this.selectedOrder.city.code,
          //                   id: this.selectedOrder.city.id
          //     };
          //   }else{
       
          //       if(this.userSelected != null){
          //                   this.airWayBillData.receiver = {
          //                         city_id: null,
          //                         email:  this.userSelected.email,
          //                         contact_person: this.userSelected.name,
          //                         address: this.userSelected.address,
          //                         phone: this.userSelected.phone,
          //                       };
          //             }else{
          //                     this.airWayBillData.receiver = {
          //                       city_id: null,
          //                       email: null,
          //                       contact_person: null,
          //                       address: null,
          //                       phone: null,
          //                     }
          //                   }
          //   }

          }else{
            
            const filteredAddressItem = data.find(item => item.attribute === "airway_bill_address");
            if (filteredAddressItem) {
                 this.airWayBillData.receiver.address = filteredAddressItem.value;
                }



                const filteredContactPersonItem = data.find(item => item.attribute === "airway_bill_contact_person");
              if (filteredContactPersonItem) {
                  this.airWayBillData.receiver.contact_person = filteredContactPersonItem.value;
                }



                const filteredemailItem = data.find(item => item.attribute === "airway_bill_email");
              if (filteredemailItem) {
                  this.airWayBillData.receiver.email = filteredemailItem.value;
                }



                const filteredPhoneItem = data.find(item => item.attribute === "airway_bill_phone");
              if (filteredPhoneItem) {
                  this.airWayBillData.receiver.phone = filteredPhoneItem.value;
                }


                
                this.receiverSelectedCity = {
                            active: true,
                            name: "CAIRO",
                            country_name: "Egypt",
                            code: "CAI",
                            id:18
                          }

              // if(this.AirWayBillType == 'order' && this.selectedOrder.length != 0  ){
              //       this.airWayBillData.sender = {
              //             city_id: this.selectedOrder.city.id,
              //             email:  this.selectedOrder.user.email,
              //             contact_person: this.selectedOrder.user.firstname + ' ' + this.selectedOrder.user.lastname,
              //             address: this.selectedOrder.address,
              //             phone: this.selectedOrder.user.phone,
              //                          };
              //       this.senderSelectedCity = {
              //                   active: true,
              //                   name: this.selectedOrder.city.name,
              //                   country_name: "Egypt",
              //                   code: this.selectedOrder.city.code,
              //                   id: this.selectedOrder.city.id
              //            };
              // }else{
              //             if(this.userSelected != null){
              //                     this.airWayBillData.sender = {
              //                           city_id: null,
              //                           email:  this.userSelected.email,
              //                           contact_person: this.userSelected.name,
              //                           address: this.userSelected.address,
              //                           phone: this.userSelected.phone,
              //                         };
              //               }else{
              //                 this.airWayBillData.sender = {
              //                   city_id: null,
              //                   email: null,
              //                   contact_person: null,
              //                   address: null,
              //                   phone: null,
              //                 }
              //               }
                        
              //     }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    addOrder(order) {
      this.orderShow = false
      this.searchOrder = order.code
      this.airWayBillData.order_code = order.code
      this.oderSR.length = 0
      this.productValues = order.variants;
      this.productValues.number_of_peices = 1;
      this.selectedOrder = order;
      this.airWayBillData.product_description = order.variants
      this.airWayBillData.order_id = order.id

      const type = this.selected;
      if(type == 'send'){

          this.airWayBillData.receiver = {
                          city_id: this.selectedOrder.city.id,
                          email:  this.selectedOrder.user.email,
                          contact_person: this.selectedOrder.user.firstname + ' ' + this.selectedOrder.user.lastname,
                          address: this.selectedOrder.address,
                          phone: this.selectedOrder.user.phone,
                        };


          this.receiverSelectedCity = {
                                active: true,
                                name: this.selectedOrder.city.name,
                                country_name: "Egypt",
                                code: this.selectedOrder.city.code,
                                id: this.selectedOrder.city.id
                  };

      }else{
        
        this.airWayBillData.sender = {
                          city_id: this.selectedOrder.city ? this.selectedOrder.city : null,
                          email:  this.selectedOrder.user.email,
                          contact_person: this.selectedOrder.user.firstname + ' ' + this.selectedOrder.user.lastname,
                          address: this.selectedOrder.address,
                          phone: this.selectedOrder.user.phone,
                        };


          this.senderSelectedCity = {
                                active: true,
                                name: this.selectedOrder.city.name,
                                country_name: "Egypt",
                                code: this.selectedOrder.city.code,
                                id: this.selectedOrder.city.id
                  };
      }


    },
    // remove function to delete single translation from category
    removeItem(index) {
      if (this.productValues.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        // this.data.areas.splice(index, 1)
        this.productValues.splice(index, 1)
        // this.activeSelect.splice(index, 1)
      }
    },
    searchResultsubmit($state) {
      this.noResult = false
      this.currentPage = 1
      axios
        .get(
          'orders/variants-pagi/20/sortBy-id-desc/' +
            this.searchText +
            '?page=' +
            this.currentPage
        )
        .then((result) => {
          const data = result.data.data.data

          this.productsSR = []

          if (data.length > 0) {
            this.productsSR = data
            // for (let index in data) {
            //   if (data[index].name != null) {
            //     this.productsSR.push({
            //       id: data[index].id,
            //       name: data[index].name,
            //       price: data[index].sale_price,
            //       sku: data[index].sku,
            //       max_qun: data[index].quantity.toString(),
            //     })
            //   } else {
            //     this.productsSR.push({
            //       id: data[index].id,
            //       name: 'undefinded',
            //       price: data[index].sale_price,
            //       sku: data[index].sku,
            //       max_qun: data[index].quantity.toString(),
            //     })
            //   }
            // }

            if (this.productsSR.length > 0) {
              this.resultShow = true
            } else {
              this.noResult = true
              this.resultShow = false
            }
           
          } else {
            if (this.productsSR.length > 0) {
              this.resultShow = true
            } else {
              this.noResult = true
              this.resultShow = false
            }
            // $state.complete();
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    searchResult($state) {
      this.noResult = false
      this.currentPage += 1
      axios
        .get(
          'orders/variants-pagi/20/sortBy-id-desc/' +
            this.searchText +
            '?page=' +
            this.currentPage
        )
        .then((result) => {
          const data = result.data.data.data
          // this.productsSR.length = 0
          // this.loadMore = false
          if (data.length > 0) {
            this.productsSR = this.productsSR.concat(data)
            // this.productsSR = data
            if (this.productsSR.length > 0) {
              this.resultShow = true
            } else {
              this.noResult = true
              this.resultShow = false
            }
          } else {
            $state.complete()
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    addProduct(id, name, price, quantity,weight) {
      this.resultShow = false
      const found = this.productValues.some((el) => el.name == name)
      if(this.productValues.length == 1 && this.productValues[0].name == null){
        this.productValues[0].name = name;
        this.productValues[0].number_of_peices = quantity;
        this.productValues[0].weight = weight;

        // this.productValues= [{
        //   name: name,
        //   number_of_peices: quantity,
        //   weight:weight
        // }];
      }else{
      if (!found) {
        this.productValues.push({
          name: name,
          number_of_peices: quantity,
          weight:weight
        })
      } else {
        const index = this.productValues.findIndex(
          (x) => x.variant_id === id
        )
        this.productValues[index].quantity += quen
      }

    }
      this.productsSR.length = 0
    },
    addUser(user) {
      this.userShow = false
      this.userSelected = user;
      if(this.selected == 'send'){
        this.airWayBillData.receiver = {
                      city_id: user.city != null ? user.city.id : null,
                      email:  user.email,
                      contact_person: user.name,
                      address: user.order_address,
                      phone: user.phone,
                    };
      if(user.city != null){
            this.receiverSelectedCity = {
                active: user.city.active,
                name: user.city.name,
                country_name: "Egypt",
                code: user.city.code,
                id: user.city.id
              };
      }
      

      }else{
        this.airWayBillData.sender = {
                      city_id: user.city != null ? user.city.id : null,
                      email:  user.email,
                      contact_person: user.name,
                      address: user.order_address,
                      phone: user.phone,
                    };

       

       if(user.city != null){
               this.senderSelectedCity = {
                            active: user.city.active,
                            name: user.city.name,
                            country_name: "Egypt",
                            code: user.city.code,
                            id: user.city.id
              };
         }
      }
      // this.searchUser = user.firstname + user.lastname
      // this.orderData.user_id = user.id
      // this.orderData.name = user.firstname + ' ' + user.lastname 
      // this.orderData.phone = user.phone
      this.userSR.length = 0
    },
    searchUserR(text, $page) {
      this.noUser = false
      this.userCurrentPage = $page + 1
      axios
        .get(
          'users/store-orders/20/sortBy-id-desc/' +
            text +
            '?page=' +
            this.userCurrentPage
        )
        .then((result) => {
          const data = result.data.data.data
          // this.userSR = data
          if(this.userCurrentPage == 1){
            this.userSR = []
          }
          this.userSR = this.userSR.concat(data)
          // console.log(this.userSR)
          if (this.userSR.length > 0) {
            this.userShow = true
          } else {
            if(this.userCurrentPage == 1){
              this.userSR = []
              this.noUser = true
              this.userShow = false

            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },


    closeAddForm(){
      this.airWayBillData = {
        product_description: null,
        number_of_peices: null,
        total_of_weight: null,
        cod_amount: 0,
        order_id: null,
        receiver: {
          city_id: null,
          email: null,
          contact_person: null,
          address: null,
          phone: null,
        },
        sender: {
          city_id: null,
          email: null,
          contact_person: null,
          address: null,
          phone: null,
        },
      };

      this.productValues = [{}];
      this.errors_back = '';
      this.showDismissibleAlert = false;
      this.required;
      this.currentPage = 0;
      this.orderCurrentPage = 0;
      this.noOrder = false;
      this.orderShow = false;
      this.searchOrder = '';
      this.oderSR = [];
      this.selected = null;
      this.AirWayBillType = null;
      this.mobmasrShipping = null;
      this.shipping_fees_value = null;
      this.selectedOrder = [];
      this.productsSR = [];
      this.noResult = false;
      this.resultShow = false;
      this.variants = [];
      this.userSR = [];
      this.userCurrentPage = 0;
      this.noUser = false;
      this.searchUser = '';
      this.searchText = '';
      this.userShow = false;
      this.userSelected = null;
      this.showAddForm = false;
      
    },

    showAddFormFun(){
      this.DefaultPickupData(this.selected); 

      this.showAddForm = true;
    },

    addItem(){
      this.productValues.push({})
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
  ​
  <style lang="scss">
</style>
  ​
  <style lang="scss" scoped>
// REPETED FORM
.repeater-form {
  transition: 0.35s height;
}
</style>