import { render, staticRenderFns } from "./CreateAirWayBill.vue?vue&type=template&id=0a253f0c&scoped=true&"
import script from "./CreateAirWayBill.vue?vue&type=script&lang=js&"
export * from "./CreateAirWayBill.vue?vue&type=script&lang=js&"
import style1 from "./CreateAirWayBill.vue?vue&type=style&index=1&id=0a253f0c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a253f0c",
  null
  
)

export default component.exports