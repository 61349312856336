<template>
  <div>
    <b-card-code title="change Status" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- Active -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="status"
                rules="required"
              >
                <b-form-group
                  label="Status"
                  label-for="status"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    :disabled="disState"
                    id="status"
                    v-model="statusSelect"
                    :state="statusSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- file -->
            <b-col md="6">
              
              <b-form-group label="File" label-for="file">
                <input
                  type="file"
                  class="form-control-file"
                  name="file"
                  id="file"
                  ref="file"
                  change
                  @change="fileUploadIndex"
                  accept="application/pdf, image/*"
                 
                />
               
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'

import { required } from '@validations'

export default {
  components: {
    BCardCode,
  },
  mixins: [heightTransition],
  data() {
    return {
      disState: false,
      showDismissibleAlert: false,
      id: 0,
      errors_back: [],
      required,
      statusSelect: {
        text: '',
        value: '',
      },
      statusOptions: [],
      payoutData: {},
      data: {
        status: '',
        file: [],
      },
    }
  },
  created() {
    // request status data
    this.id = this.$route.params.id
    axios
      .get('payouts/' + this.id)
      .then((result) => {
        this.payoutData = result.data.data
        this.statusSelect.text = this.payoutData.status
        this.statusSelect.value = this.payoutData.status
        if (this.payoutData.status == 'pending') {
          const data = [
            {
              text: 'paid',
              value: 'paid',
            },
            {
              text: 'cancel',
              value: 'cancel',
            },
          ]
          for (let ind in data) {
            this.statusOptions.push(data[ind])
          }
        } else if (this.payoutData.status == 'paid') {
          this.disState = true
        } else if (this.payoutData.status == 'cancel') {
          this.disState = true
        } else {
          this.disState = true
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {

    validateOrderAttachmentData() {
      if (this.orderAttachmentData.file == null) {
        this.showDismissibleAlert = true
        this.errors_back.push({
          error: 'must upload attachment file ',
        })
        return
      }
      if (this.orderAttachmentData.type == null) {
        this.showDismissibleAlert = true
        this.errors_back.push({
          error: 'invalid selected type ',
        })
        return
      }
    },

    // update function to status
    async validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {

          let formData = new FormData();
           if(this.statusSelect.value == 'paid'){

            if (this.data.file == null) {
                this.errors_back.push({
                  error: 'must upload attachment file ',
                })
                return
              }
        }   
        this.data.status = this.statusSelect.value;
        formData.append('file', this.data.file);
          formData.append('status', this.data.status)
         
          
          axios
            .post('payouts/' + this.id + '/status/change', formData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    fileUploadIndex(e) {
      this.data.file = e.target.files[0]
        },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
<style lang="scss">
 
</style>
