<template>
  <div v-if="data != null">
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col md="1">
              <div class="d-flex justify-content-center align-items-center">
                <b-img fluid :src="data.cover"></b-img>
              </div>
            </b-col>
            <b-col class="my-auto" md="9">
              <h4>
                <span style="color: #7367f0">name :</span>
                {{ name }}
              </h4>
              <br />
              <h4>
                <span style="color: #7367f0">SKU رمز : </span>
                {{ data.sku }}
              </h4>
              <br />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="authPermissions.includes('update-product-status') "
      title="Varaint Status"
    >
      <b-row>
        <b-col md="6">
          <b-row>
            <b-col
              md="4"
              class="d-flex justify-content-center align-items-center"
            >
              <!-- Avability -->
              <b-card-text v-if="productStatus === 'confirmed'">
                <span style="color: #0d6efd">Status:</span>
                -
                <b-badge variant="success">{{ productStatus }}</b-badge>
              </b-card-text>
              <b-card-text v-else-if="productStatus === 'enabled'">
                <span style="color: #0d6efd">Status:</span>
                -
                <b-badge variant="success">{{ productStatus }}</b-badge>
              </b-card-text>
              <b-card-text v-else-if="productStatus === 'disabled'">
                <span style="color: #0d6efd">Status:</span>
                -
                <b-badge variant="danger">{{ productStatus }}</b-badge>
              </b-card-text>
              <b-card-text v-else-if="productStatus === 'review'">
                <span style="color: #0d6efd">Status:</span>
                -
                <b-badge variant="warning">{{ productStatus }}</b-badge>
              </b-card-text>
              <b-card-text v-else-if="productStatus === 'rejected'">
                <span style="color: #0d6efd">Status:</span>
                -
                <b-badge variant="dark">{{ productStatus }}</b-badge>
              </b-card-text>
              <b-card-text v-else>
                <span style="color: #0d6efd">Status:</span>
                -
                <b-badge variant="dark">{{ productStatus }}</b-badge>
              </b-card-text>
            </b-col>
            <b-col md="8">
              <b-button
                v-if="authPermissions.includes('update-product-status') && data.status != 'sold_out'"
                block
                v-b-modal.modal-status
                variant="info"
              >
                Update Status
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-modal
          style="height: 700px !important"
          size="lg" 
          id="modal-status"
          cancel-variant="outline-secondary"
          @ok="updateStatus"
          ok-title="Ok"
          cancel-title="Close"
          centered
          title="Update Status"
        >
          <validation-provider
            #default="{ errors }"
            name="status"
            rules="required"
          >
            <b-form-group
              label="Status"
              label-for="status"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="status"
                v-model="statusSelect"
                :state="statusSelect === null ? false : true"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <b-form-group v-if="statusSelect.value == 'rejected' && statusSelect.value != data.status"
            label="Reject Note"
            v-slot="{ ariaDescribedby }"
            class="my-1"
          >
            <b-form-checkbox
              v-for="(item , index) in rejectNotes"
              :key="index"
              v-model="note"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              :value="item"
              >
              <h5>{{ item.key }}</h5>
              <p>{{ item.value }}</p>
              
              </b-form-checkbox>
            
            
          </b-form-group>
          <b-form-textarea
          v-if="note == 'Other'"
            id="body"
            v-model="note_description"
            placeholder="Review Note"
            rows="3"
            max-rows="3"
          ></b-form-textarea>
          <b-form-checkbox
            id="checkbox-1"
            v-model="disabled"
            v-show="
              statusSelect.value == 'disabled' ||
              statusSelect.value == 'enabled'
            "
            name="checkbox-1"
            value="1"
            unchecked-value="0"
          >
            Force Disabled
          </b-form-checkbox>
        </b-modal>
      </b-row>
    </b-card>
    <b-card
      v-if="authPermissions.includes('update-product-price') && data.vendor"
      title="Update Variant Data"
    >
      <validation-observer ref="simpleRules">
        <b-row v-if="data.is_portal">
          <b-col md="3">
            <b-form-group>
              <label class="h4 text-gray">Quantity</label>
              <validation-provider
                #default="{ errors }"
                name="Quantity"
                rules="required|regex:\b\d+(?:\.\d\d?)?\b"
              >
                <b-form-input
                  v-model="variant.quantity"
                  min="0"
                  class="text-center"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3" class="bg-lightblue p-2">
            <b-row>
              <b-col
                cols="12"
                class="d-flex justify-content-between align-items-center"
              >
                <p class="h4 mb-0 text-gray text-left">Selleing Price</p>
                <p
                  class="mb-0 h3 bg-light selling-color selling-shadow px-1 py-1 rounded"
                >
                  {{ data.sale_price }} EGP
                </p>
              </b-col>
              <b-col
               v-if="data.payment_provider != null"
                cols="12"
                class="d-flex justify-content-center align-items-center"
              >
                <hr class="w-75 border-top-gray" />
              </b-col>
              <b-col
              v-if="data.payment_provider != null"
                cols="12"
                class="d-flex justify-content-between align-items-center"
              >
                <p class="h4 mb-0 text-gray text-left">payment Method</p>
                <p class="mb-0 h4 px-1 py-1 text-gray">
                  <img  v-if="data.payment_provider.code == 'valu'" src="/icons/valu_icon.svg" alt="Bank Installment Icon" class="icon">
                  <img  v-if="data.payment_provider.code == 'bank-installment'" src="/icons/bank_installment.svg" alt="Bank Installment Icon" class="icon">
                  <img  v-if="data.payment_provider.code == 'contact'" src="/icons/contact_icon.svg" alt="Bank Installment Icon" class="icon">
                  <img  v-if="data.payment_provider.code == 'aman'" src="/icons/aman_icon.svg" alt="Bank Installment Icon" class="icon">
                  <img  v-if="data.payment_provider.code == 'souhoola'" src="/icons/sahoola_icon.svg" alt="Bank Installment Icon" class="icon">
                  <img  v-if="data.payment_provider.code == 'forsa'" src="/icons/forsa_icon.svg" alt="Bank Installment Icon" class="icon">
                  <img  v-if="data.payment_provider.code == 'visa'" src="/icons/credit_card_icon.svg" alt="Bank Installment Icon" class="icon">
                 

                  {{ data.payment_provider.name }} 
                </p>
              </b-col>
              <b-col
                cols="12"
                class="d-flex justify-content-center align-items-center"
              >
                <hr class="w-75 border-top-gray" />
              </b-col>
              <b-col
                cols="12"
                class="d-flex justify-content-between align-items-center"
              >
                <p class="h5 mb-0 text-gray text-left">Original Price</p>
                <p class="mb-0 h4 px-1 py-1 text-gray">
                  <del v-if="data.offer != null">{{ data.original_price }}</del>
                  <span v-if="data.offer == null">{{
                    data.original_price
                  }}</span>
                  EGP
                </p>
              </b-col>
              <b-col
                cols="12"
                class="d-flex justify-content-center align-items-center"
              >
                <hr class="w-75 border-top-gray" />
              </b-col>
              <b-col
                cols="12"
                class="d-flex justify-content-between align-items-center"
              >
                <p class="h5 mb-0 text-gray text-left">
                  Seller Payout <br />(Payable)
                </p>
                <p class="mb-0 h4 px-1 py-1 text-gray">
                  {{ parseInt(data.sale_price) - parseInt(data.profit) }} EGP
                </p>
              </b-col>
              <b-col
                cols="12"
                class="d-flex justify-content-center align-items-center"
              >
                <hr class="w-75 border-top-gray" />
              </b-col>
              <b-col
                cols="12"
                class="d-flex justify-content-between align-items-center"
              >
                <p class="h5 mb-0 text-gray text-left">Discount</p>
                <p class="mb-0 h4 px-1 py-1 text-gray">
                  {{
                    data.offer != null
                      ? parseInt(data.original_price) -
                        parseInt(data.sale_price)
                      : '0'
                  }}
                  EGP
                </p>
              </b-col>
              <b-col v-if="data.offer != null" cols="12">
                <b-row>
                  <b-col
                    cols="12"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="text-gray">Rate</p>
                    <p v-if="data.is_portal" class="text-gray px-1 text-left">
                      {{
                        Math.round(
                          ((parseInt(data.original_price) -
                            parseInt(data.sale_price)) /
                            parseInt(data.original_price)) *
                            100
                        )
                      }}
                      %
                    </p>
                    <p v-else class="text-gray px-1 text-left">
                      {{
                        (parseFloat(data.profit) /
                          (parseFloat(data.sale_price) -
                            parseFloat(data.profit))) *
                        100
                      }}
                      %
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="text-gray">Piece (Qty)</p>
                    <p class="text-gray px-1 text-left">
                      {{ data.offer.usernumbers }}
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="text-gray">Start Date</p>
                    <p class="text-gray px-1 text-left">
                      {{ data.offer.startdate }}
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="text-gray">End Date</p>
                    <p class="text-gray px-1 text-left">
                      {{ data.offer.enddate }}
                    </p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="d-flex justify-content-center align-items-center"
              >
                <hr class="w-75 border-top-gray" />
              </b-col>
              <b-col
                cols="12"
                class="d-flex justify-content-between align-items-center"
              >
                <p class="h5 mb-0 text-gray text-left">Markup (Profit)</p>
                <p class="mb-0 h4 px-1 py-1 text-gray">
                  {{ data.profit }}
                  EGP
                </p>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col
                    cols="12"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="text-gray">Rate</p>
                    <p v-if="data.is_portal" class="text-gray px-1 text-left">
                      {{
                        Math.round(
                          ((parseInt(data.original_price) -
                            parseInt(data.sale_price)) /
                            parseInt(data.original_price)) *
                            100
                        )
                      }}
                      %
                    </p>
                    <p v-else class="text-gray px-1 text-left">
                      {{
                        (parseFloat(data.profit) /
                          (parseFloat(data.sale_price) -
                            parseFloat(data.profit))) *
                        100
                      }}
                      %
                    </p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="d-flex justify-content-center align-items-center"
              >
                <hr class="w-75 border-top-gray" />
              </b-col>
              <b-col
                cols="12"
                class="d-flex justify-content-center align-items-center"
              >
                <b-button
                  class="w-75"
                  @click="showPriceForm = !showPriceForm"
                  variant="warning"
                >
                  Update Price</b-button
                >
              </b-col>
            </b-row>
          </b-col>
          <b-col v-if="showPriceForm" md="6">
            <b-row class="d-flex justify-content-start align-items-center">
              <b-col md="8">
                <!-- original price -->
                <b-form-group>
                  <label class="h4 text-gray">Price</label>
                  <validation-provider
                    #default="{ errors }"
                    name="price"
                    rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                  >
                    <b-form-input
                      v-model="variant.original_price"
                      min="0"
                      class="text-center"
                      type="number"
                      @input="checkPrice"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="2" class="px-0"
                ><p class="mb-0 my-auto">EGP</p></b-col
              >
            </b-row>
            <b-row v-if="!data.is_portal">
                      <h5 class="text-left m-1"> Payment Methods </h5>
                        <validation-provider
                                #default="{ errors }"
                                name="payment Method"
                                rules="required"
                                class="w-100"
                                v-if="isAnyProviderValid"
                              >
                        <b-form-radio-group v-model="variant.payment_provider_id" name="paymentOption" class="w-100">
                        <b-col md="12"  class="change-padding p-0"  v-if="bankInstallmentProvider" >
                              <b-card >
                                  <!-- Option 1: Bank Installment -->
                                  <b-form-radio :value="bankInstallmentProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                    <div class="d-flex justify-content-between w-100 align-items-center text-right">
                                      <div class="d-flex align-items-left">
                                        <img  src="/icons/bank_installment.svg" alt="Bank Installment Icon" class="icon mr-1">
                                        <!-- <span class="mr-1 text-left text-secondary"> Bank Installment</span> -->
                                      </div>
                                      <div class="font-small-2">

                                        <div class="price-info">
                                        <span class="m-0 text-nowrap">Seller price: {{ variant.original_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          Platform price:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              sale_price =  valuProvider.type == 'percentage'
                                                ? ((Number(variant.original_price) * valuProvider.rate) / 100 +
                                                    Number(variant.original_price))
                                                : variant.original_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          Platform commission:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variant.original_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>


                                       <!-- Scrolling Container -->
                                       <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_CIB'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'CIB'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/cib.svg"></b-img>
                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_CIB'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} Months</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} EGP -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>

                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_Mashreq'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;  padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'Mashreq'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/mashreq.svg"></b-img>

                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_Mashreq'"
                                           class="installment-plan-item"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver  mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} Months</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} EGP -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>


                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_Nbd'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;  padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'Nbd'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/nbd.svg"></b-img>
                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_Nbd'"
                                           class="installment-plan-item"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver  mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} Months</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} EGP -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>


                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_fab'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;  padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'Fab'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/nbd.svg"></b-img>

                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_Fab'"
                                           class="installment-plan-item"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver  mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} Months</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} EGP -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>

                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_NBK'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;  padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'NBK'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/nbk.svg"></b-img>

                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_NBK'"
                                           class="installment-plan-item"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver  mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} Months</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} EGP -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>


                                      
                                     
                                      </div>
                                    </div>
                                  </b-form-radio>
                              </b-card>
                            <!-- Add more options similarly -->
                        
                      </b-col>


                      
                        <b-col md="12">
                          <h5 class="text-left m-1"> Companies installment </h5>
                        
                              <b-card   class="change-padding p-0" v-if="valuProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="valuProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center text-right">
                                    <div class="d-flex align-items-left">
                                      <img  src="/icons/valu_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">
                                    </div>

                                    <div class="text-right font-small-2 mt-4">
                                          <span class="m-0">Seller price: {{variant.original_price}} EGP</span>
                                          <span class="m-0 mx-2">
                                            Platform price:
                                            <span v-if="variant.original_price != ''">
                                               {{ sale_price = valuProvider.type == 'percentage'
                                                ? ((Number(variant.original_price) * valuProvider.rate) / 100 + Number(variant.original_price))
                                                : variant.original_price + valuProvider.rate}} EGP
                                            </span>
                                          </span>
                                          <span class="text-success m-0">
                                            Platform commission:
                                            <span v-if="variant.original_price != ''">
                                              {{valuProvider.type == 'percentage'
                                                ? ((Number(variant.original_price) * valuProvider.rate) / 100)
                                                : valuProvider.rate}} EGP
                                            </span>
                                          </span>
                                          
                                          <!-- Scrolling Container -->
                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_valu'"
                                          >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;   padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'valu'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_valu'"
                                           class="installment-plan-item"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} Months</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} EGP -->
                                                  </span>
                                                </p>
                                              </b-card>
                                    </div>
                                    </div>                                 
                                          </div>
                                        </div>

                                  </div>
                                </b-form-radio>
                            </b-card>

                            <b-card  class="change-padding p-0" v-if="contactProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="contactProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center text-right">
                                    <div class="d-flex align-items-left">
                                      <img  src="/icons/contact_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="text-right font-small-2">
                                        <span class="m-0 ">Seller price:  {{Number(variant.original_price)}} EGP</span>
                                        <span class="m-0 mx-2">Platform price:  <span v-if=" variant.original_price != ''">  {{ sale_price = contactProvider.type == 'percentage'  ? ((Number(variant.original_price) * contactProvider.rate ) / 100  + Number(variant.original_price) ) : Number(variant.original_price) + contactProvider.rate }}  EGP</span> </span>
                                        <span class="text-success m-0">Platform commission:  <span v-if=" variant.original_price != ''">   {{contactProvider.type == 'percentage'   ? ( (Number(variant.original_price) * contactProvider.rate ) / 100 ) :  contactProvider.rate }}  EGP</span> </span>

                                        <!-- Scrolling Container -->
                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_contact'"
                                          >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;   padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'contact'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_contact'"
                                           class="installment-plan-item"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} Months</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} EGP -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>


                                        </div>

                                  </div>
                                </b-form-radio>
                            </b-card>


                            
                            <b-card   class="change-padding p-0" v-if="amanProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="amanProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center text-right">
                                    <div class="d-flex align-items-left">
                                      <img  src="/icons/aman_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="text-right font-small-2">
                                        <span class="m-0 ">Seller price:  {{Number(variant.original_price)}} EGP</span>
                                        <span class="m-0 mx-2">Platform price:  <span v-if="variant.original_price != ''"> {{ sale_price = amanProvider.type == 'percentage'  ? ((Number(variant.original_price) * amanProvider.rate ) / 100  + Number(variant.original_price) ) : Number(variant.original_price) + amanProvider.rate }}  EGP</span> </span>
                                        <span class="text-success m-0">Platform commission:  <span v-if="variant.original_price != ''">  {{amanProvider.type == 'percentage'   ? ( (Number(variant.original_price) * amanProvider.rate ) / 100 ) :  amanProvider.rate }}  EGP</span> </span> 
                                     
                                      <!-- Scrolling Container -->
                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_aman'"
                                          >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;   padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'aman'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_aman'"
                                           class="installment-plan-item"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} Months</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} EGP -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>

                            <b-card  class="change-padding p-0"  v-if="shoolaProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="shoolaProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center text-right">
                                    <div class="d-flex align-items-left">
                                      <img  src="/icons/sahoola_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="text-right font-small-2">
                                        <span class="m-0 ">Seller price: {{ Number(variant.original_price)}}" EGP</span>
                                        <span class="m-0 mx-2">Platform price:  <span v-if="variant.original_price != ''"> {{ sale_price = shoolaProvider.type == 'percentage'  ? ((Number(variant.original_price) * shoolaProvider.rate ) / 100  + Number(variant.original_price) ) : Number(variant.original_price) + shoolaProvider.rate }}  EGP</span> </span>
                                        <span class="text-success m-0">Platform commission: <span v-if="variant.original_price != ''">  {{shoolaProvider.type == 'percentage'  ? ( (Number(variant.original_price) * shoolaProvider.rate ) / 100 ) :  shoolaProvider.rate }}  EGP</span> </span>
                                      
                                       <!-- Scrolling Container -->
                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_souhoola'" >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;   padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'souhoola'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_souhoola'"
                                           class="installment-plan-item"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} Months</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} EGP -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>

                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>

                            <b-card  class="change-padding p-0" v-if="forsaProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="forsaProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center text-right">
                                    <div class="d-flex align-items-left">
                                      <img  src="/icons/forsa_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="text-right font-small-2">
                                        <span class="m-0 ">Seller price:  {{Number(variant.original_price)}} EGP</span>
                                        <span class="m-0 mx-2">Platform price:  <span v-if=" variant.original_price != ''"> {{ sale_price = forsaProvider.type == 'percentage'  ? ((Number(variant.original_price) * forsaProvider.rate ) / 100  + Number(variant.original_price) ) : Number(variant.original_price) + forsaProvider.rate }}  EGP  </span></span>
                                        <span class="text-success m-0">Platform commission: <span v-if=" variant.original_price != ''">  {{forsaProvider.type == 'percentage'    ? ( (Number(variant.original_price) * forsaProvider.rate ) / 100 ) :  forsaProvider.rate }}  EGP </span> </span>
                                       <!-- Scrolling Container -->
                                          <div
                                            v-for="(items, index) in InstallmentPlans"
                                            :key="index + 'installment_data_plans_forsa'" >
                                          <div
                                            class="scrolling-container"
                                          style="display: flex; overflow-x: auto; gap: 10px;   padding: 10px 0;"
                                            v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                            v-if="key == 'forsa'"
                                          >
                                        <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_forsa'"
                                          class="installment-plan-item"
                                        style="flex: 0 0 auto; white-space: nowrap;">
                                            <!-- Installment Plan Card -->
                                            <b-card class="installment-plans-card bg-sliver">
                                              <!-- Months -->
                                              <p class="p-0 m-0 text-center card-font">{{ item.months }} Months</p>
                                              {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                              <!-- Installment Price -->
                                              <p class="p-0 m-0 text-center card-font">
                                                <span class="show-product-used battery-status">
                                                  <!-- {{ numberWithCommas(item.installment_price) }} EGP -->
                                                </span>
                                              </p>
                                            </b-card>
                                          </div>
                                          </div>
                                        </div>

                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>


                          
                          

                       

                      </b-col>


                      <b-col md="12"   class="change-padding p-0 mt-3" v-if="visaProvider" >
                              <b-card >
                                  <!-- Option 1: Bank Installment -->
                                  <b-form-radio :value="visaProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                    <div class="d-flex justify-content-between w-100 align-items-center text-right">
                                      <div class="d-flex align-items-left">
                                        <img  src="/icons/credit_card_icon.svg" alt="Company Icon" class="icon ml-2 max-width-42">
                                        <span class="mr-2 text-secondary">Credit card</span>
                                      </div>
                                      <div class="text-right font-small-2">
                                        <span class="m-0 ">Seller price:  {{Number(variant.original_price)}} EGP</span>
                                        <span class="m-0 mx-2">Platform price: <span v-if="variant.original_price != ''">   {{ sale_price = visaProvider.type == 'percentage'  ? ((Number(variant.original_price) * visaProvider.rate ) / 100  + Number(variant.original_price) ) : Number(variant.original_price) + visaProvider.rate }}  EGP</span></span>
                                        <span class="text-success m-0">Platform commission:  <span v-if="variant.original_price != ''">   {{visaProvider.type == 'percentage'    ? ( (Number(variant.original_price) * visaProvider.rate ) / 100 ) :  visaProvider.rate }}  EGP</span></span>
                                       <!-- Scrolling Container -->
                                          <div
                                            v-for="(items, index) in InstallmentPlans"
                                            :key="index + 'installment_data_plans_visa'" >
                                          <div
                                            class="scrolling-container"
                                          style="display: flex; overflow-x: auto; gap: 10px;   padding: 10px 0;"
                                            v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                            v-if="key == 'visa'"
                                          >
                                        <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_visa'"
                                          class="installment-plan-item"
                                        style="flex: 0 0 auto; white-space: nowrap;">
                                            <!-- Installment Plan Card -->
                                            <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                              <!-- Months -->
                                              <p class="p-0 m-0 text-center card-font">{{ item.months }} Months</p>
                                              {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                              <!-- Installment Price -->
                                              <p class="p-0 m-0 text-center card-font">
                                                <span class="show-product-used battery-status">
                                                  <!-- {{ numberWithCommas(item.installment_price) }} EGP -->
                                                </span>
                                              </p>
                                            </b-card>
                                          </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </b-form-radio>
                              </b-card>
                            <!-- Add more options similarly -->
                      </b-col>

                    </b-form-radio-group>

                    <b-form-invalid-feedback
                          :state="
                            errors.length > 0 ? false : null
                          ">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                    </validation-provider>
                  
                   <b-col md="12" v-else>
                      <div >
                        <p class="text-danger text-center">No available payment Method for the selected price range.</p>
                      </div>
                   </b-col>

                    </b-row>


           <div v-if="data.is_portal">
            <b-row class="mt-2">
              <b-col cols="12" class="mt-2">
                <p class="h5 mb-0 text-gray text-left">
                  Selleing Price <span class="ml-5"> : {{ selleing }} EGP</span>
                </p>
              </b-col>
              <b-col
                cols="12"
                class="d-flex mt-2 justify-content-start align-items-center"
              >
                <p class="h5 mb-0 text-gray text-left">
                  Seller Payout <br />(Payable)
                </p>
                <p class="h5 mb-0 text-gray text-left">
                  <span class="ml-5"> : {{ payout }} EGP</span>
                </p>
              </b-col>
            </b-row>
            <!-- Commission -->
            <b-row class="mt-3">
              <b-col cols="12">
                <b-form-checkbox
                  :disabled="!authPermissions.includes('high-level-update-product-price')"
                  id="commission"
                  v-model="variant.rateChecked"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                >
                  <span class="h4 text-gray">Commision</span>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <!-- Commission rate -->
            <b-row v-if="!variant.rateChecked" class="mt-1">
              <b-col
                cols="12"
                class="d-flex justify-content-start align-items-center"
              >
                <b-form-group
                  class="text-gray"
                  label="Commission Rate"
                  label-for="Commission Rate"
                >
                  <b-form-input
                    disabled
                    class="text-center"
                    v-model="variant.rateInPercent"
                    min="0"
                    type="number"
                  />
                </b-form-group>
                <p class="ml-1 my-auto text-gray">%</p>
                <p class="mx-2 my-auto text-gray">=</p>
                <b-form-group
                  class="text-gray"
                  label="Commission Amount"
                  label-for="Commission Amount"
                >
                  <b-form-input
                    disabled
                    v-model="variant.rate"
                    min="0"
                    class="text-center"
                    type="number"
                  />
                </b-form-group>
                <p class="ml-1 my-auto text-gray">EGP</p>
              </b-col>
            </b-row>
            <b-row v-else class="mt-1">
              <b-col
                cols="12"
                class="d-flex justify-content-start align-items-center"
              >
                <b-form-group
                  class="text-gray"
                  label="Commission Rate"
                  label-for="Commission Rate"
                  ><validation-provider
                    #default="{ errors }"
                    name="Commission Rate"
                    rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                  >
                    <b-form-input
                      class="text-center"
                      v-model="variant.rateInPercent"
                      min="0"
                      type="number"
                      @input="checkRateInPercent"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <p class="ml-1 my-auto text-gray">%</p>
                <p class="mx-2 my-auto text-gray">=</p>
                <b-form-group
                  class="text-gray"
                  label="Commission Amount"
                  label-for="Commission Amount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Commission Amount"
                    rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                  >
                    <b-form-input
                      v-model="variant.rate"
                      min="0"
                      class="text-center"
                      type="number"
                      @input="checkRate"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <p class="ml-1 my-auto text-gray">EGP</p>
              </b-col>
            </b-row>
          </div>

            <!-- Discount -->
            <b-row v-if="data.is_portal" class="mt-3">
              <b-col cols="12">
                <b-form-checkbox
                  id="discount"
                  v-model="variant.offer.checked"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                >
                  <span class="h4 text-gray">Discount</span>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <!-- Discount rate -->
            <b-row v-if="!variant.offer.checked && data.is_portal" class="mt-1">
              <b-col
                cols="12"
                class="d-flex justify-content-start align-items-center"
              >
                <b-form-group
                  class="text-gray"
                  label="Discount Rate"
                  label-for="Discount Rate"
                >
                  <b-form-input
                    disabled
                    class="text-center"
                    v-model="variant.offer.amountInPercent"
                    min="0"
                    type="number"
                  />
                </b-form-group>
                <p class="ml-1 my-auto text-gray">%</p>
                <p class="mx-2 my-auto text-gray">=</p>
                <b-form-group
                  class="text-gray"
                  label="Discount Amount"
                  label-for="Discount Amount"
                >
                  <b-form-input
                    disabled
                    v-model="variant.offer.amount"
                    min="0"
                    class="text-center"
                    type="number"
                  />
                </b-form-group>
                <p class="ml-1 my-auto text-gray">EGP</p>
              </b-col>
              <b-col cols="9">
                <b-form-group
                  class="text-gray"
                  label="Piece (Qty)"
                  label-for="Piece (Qty)"
                >
                  <b-form-input
                    disabled
                    class="text-center"
                    v-model="variant.offer.usernumbers"
                    min="0"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="d-flex justify-content-start align-items-center"
              >
                <b-form-group
                  class="text-gray"
                  label="Start Date"
                  label-for="Start Date"
                >
                  <b-form-input
                    disabled
                    class="text-center"
                    v-model="variant.offer.startdate"
                    min="0"
                    type="number"
                  />
                </b-form-group>

                <p class="mx-2 my-auto text-gray h4">To</p>
                <b-form-group
                  class="text-gray"
                  label="End Date"
                  label-for="End Date"
                >
                  <b-form-input
                    disabled
                    v-model="variant.offer.enddate"
                    min="0"
                    class="text-center"
                    type="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-else-if="variant.offer.checked && data.is_portal"
              class="mt-1"
            >
              <b-col
                cols="12"
                class="d-flex justify-content-start align-items-center"
              >
                <b-form-group
                  class="text-gray"
                  label="Discount Rate"
                  label-for="Discount Rate"
                  ><validation-provider
                    #default="{ errors }"
                    name="Discount Rate"
                    rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                  >
                    <b-form-input
                      class="text-center"
                      v-model="variant.offer.amountInPercent"
                      min="0"
                      type="number"
                      @input="checkOfferAmountInPercent"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <p class="ml-1 my-auto text-gray">%</p>
                <p class="mx-2 my-auto text-gray">=</p>
                <b-form-group
                  class="text-gray"
                  label="Discount Amount"
                  label-for="Discount Amount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Discount Amount"
                    rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                  >
                    <b-form-input
                      v-model="variant.offer.amount"
                      min="0"
                      class="text-center"
                      type="number"
                      @input="checkOfferAmount"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <p class="ml-1 my-auto text-gray">EGP</p>
              </b-col>
              <b-col cols="9">
                <b-form-group
                  class="text-gray"
                  label="Piece (Qty)"
                  label-for="Piece (Qty)"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Piece (Qty)"
                    rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                  >
                    <b-form-input
                      v-model="variant.offer.usernumbers"
                      min="0"
                      class="text-center"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="d-flex justify-content-start align-items-center"
              >
                <b-form-group
                  class="text-gray"
                  label="Start Date"
                  label-for="Start Date"
                  ><validation-provider
                    #default="{ errors }"
                    name="Start Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="variant.offer.startdate"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                        minDate: 'today',
                      }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <p class="mx-2 my-auto text-gray h4">To</p>
                <b-form-group
                  class="text-gray"
                  label="End Date"
                  label-for="End Date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="End Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="variant.offer.enddate"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                        minDate: 'today',
                      }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Apply Price -->
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="warning" class="mt-2" block @click="updatePrice"
              >Apply</b-button
            >
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <b-card v-if="data.is_portal && data.vendor" title="Warranty">
      <validation-observer ref="simpleRules2">
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Warranty"
              rules="required"
            >
              <b-form-group
                label="Warranty"
                label-for="warranty"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="warranty"
                  v-model="warrantySelect"
                  :state="warrantySelect === null ? false : true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="warrantyOptions"
                  :selectable="
                    (option) => !option.value.includes('select_value')
                  "
                  label="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- warranty duration -->
          <b-col
            md="6"
            v-if="warrantySelect != null && warrantySelect.value == 'seller'"
          >
            <validation-provider
              #default="{ errors }"
              name="Warranty Duration"
              rules="required"
            >
              <b-form-group
                label="Warranty Duration"
                label-for="Warranty Duration"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="Warranty-Duration"
                  v-model="warrantyDurationSelect"
                  :state="warrantyDurationSelect === null ? false : true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="warrantyDurationOptions"
                  :selectable="
                    (option) => !option.value.includes('select_value')
                  "
                  label="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="6"
            v-else-if="
              warrantySelect != null &&
              (warrantySelect.value == 'agency' ||
                warrantySelect.value == 'supplier')
            "
          >
            <validation-provider
              #default="{ errors }"
              name="End Date"
              rules="required"
            >
              <b-form-group
                label="End Date"
                label-for="End date"
                :state="errors.length > 0 ? false : null"
              >
                <flat-pickr
                  v-model="warranty.warranty.end_date"
                  class="form-control"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="6"
            v-if="
              warrantySelect != null &&
              (warrantySelect.value == 'agency' ||
                warrantySelect.value == 'supplier')
            "
          >
            <b-form-group label="Warranty file" label-for="Warranty file">
              <input
                type="file"
                class="form-control-file"
                multiple="multiple"
                name="file"
                id="file"
                ref="file"
                change
                @change="fileUpload2($event)"
                accept="application/pdf, image/*"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="success()"
      >
        <span>Update Warranty</span>
      </b-button>
    </b-card>

    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    flatPickr,
  },

  mixins: [heightTransition],
  watch: {
    statusSelect: function () {
      this.disabled = '1'
    },
    'variant.rateChecked': function () {
      if (this.variant.rateChecked == false) {
        this.variant.rate = null
        this.variant.rateInPercent = null
        if (this.variant.offer.amount == null) {
          this.selleing = this.variant.original_price
          this.payout = 'Will caculate from system'
        } else {
          this.selleing =
            parseInt(this.variant.original_price) -
            parseInt(this.variant.offer.amount)
          this.payout = 'Will caculate from system'
        }
      }
    },
    'variant.offer.checked': function () {
      if (this.variant.offer.checked == false) {
        this.variant.offer.amount = null
        this.variant.offer.amountInPercent = null
        this.variant.offer.usernumbers = null
        this.variant.offer.startdate = null
        this.variant.offer.enddate = null
        if (this.variant.rateChecked) {
          this.variant.rate =
            (parseInt(this.variant.rateInPercent) *
              parseInt(this.variant.original_price)) /
            100
          this.selleing = this.variant.original_price
          this.payout =
            parseInt(this.variant.original_price) - parseInt(this.variant.rate)
        } else {
          this.selleing = this.variant.original_price
          this.payout = 'Will caculate from system'
        }
      }
    },
  },
  data() {
    return {
      note: [],
      note_description: '',
      showPriceForm: false,
      authPermissions: [],
      errors_back2: [],
      showDismissibleAlert2: false,
      required,
      vendorID: null,
      itemID: null,
      errors_back: [],
      selleing: '0',
      payout: '0',

      showDismissibleAlert: false,
      vendorCommissionRate: null,
      itemData: null,
      id: 0,
      disabled: '1',
      productStatus: null,
      rejectNotes:[
        {
          key :  'Incomplete DT Test' ,
          value :  'The DT test for your mobile phone ad is incomplete. Please ensure you complete the DT test and generate the report before resubmitting.',
        },
        {
          key :  'Invalid IMEI',
          value : 'The provided IMEI for your mobile phone ad is not valid. Please double-check and enter a valid IMEI before resubmitting.',
        },
        {
          key :   'Unclear Images',
          value :'The images provided to identify the phone\'s condition are unclear. Please upload clear images that meet our guidelines for a better understanding of the phone\'s condition.',
        },
        {
          key : 'Policy Violations' ,
          value : 'Ensure both the text and images in your ad comply with our app policies. Review and edit the description and images to align with our guidelines before resubmitting for approval.',
        },
        {
          key :'Invalid Price'  ,
          value :'The price provided for your ad does not meet the market trend. Please review and adjust the price to align with current market trends before resubmitting.',
        }
      ],
      statusSelect: {
        text: '',
        value: '',
      },
      warrantyOptions: [
        {
          text: 'Seller',
          value: 'seller',
        },
        {
          text: 'Agency',
          value: 'agency',
        },
        {
          text: 'Supplier',
          value: 'supplier',
        },
      ],
      warrantyDurationSelect: null,
      warrantyDurationOptions: [
        {
          text: '7 يوم',
          value: '7',
        },
        {
          text: '14 يوم',
          value: '14',
        },
        {
          text: '30 يوم',
          value: '30',
        },
      ],
      warrantySelect: null,
      typeSelect: null,
      statusOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },

        {
          value: 'disabled',
          text: 'Disabled',
        },
        {
          value: 'rejected',
          text: 'Rejected',
        },
      ],
      offerTypeSelect: {
        text: '',
        value: '',
      },
      offerTypeOptions: [
        {
          text: 'Fixed Price',
          value: 'fixed_price',
        },
        {
          text: 'Percentage',
          value: 'percentage',
        },
      ],
      localeOptions: [
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      tranLocaleSelect: [],
      warranty: {
        warranty: {
          type: null,
          duration: null,
          end_date: null,
          agency_file: null,
        },
      },
      variant: {
        quantity: null,
        original_price: null,
        type: 'fixed_price',
        rate: null,
        rateInPercent: null,
        rateChecked: false,
        offer: {
          checked: false,
          amountInPercent: null,
          type: 'fixed_price',
          amount: null,
          startdate: null,
          enddate: null,
          usernumbers: null,
        },
        payment_provider_id: null,
      },
      data: null,
      name: '',
      PriceRates: [],
      InstallmentPlans: []
    }
  },
  computed: {
    // Ensure `variant.original_price` is reactive
    variantPrice() {
      return Number(this.variant.original_price);
    },

  valuProvider() {
    const provider = this.PriceRates.find(item => 
      item.PaymentProvider &&
      item.PaymentProvider.code == "valu" &&
      item.service_type == (this.data.is_new ? 'new' : 'used') && 
      item.from <= this.variantPrice &&
      item.to >= this.variantPrice

    );
    return provider  ? provider : null;
  },

  
  bankInstallmentProvider() {
    const provider = this.PriceRates.find(item => 
      item.PaymentProvider  &&
      item.PaymentProvider.code == "bank-installment" &&
      item.service_type == (this.data.is_new ? 'new' : 'used') && 
      Number(item.from) <= this.variantPrice &&
      Number(item.to) >= this.variantPrice
  );
    return provider ? provider : null;
  },

  contactProvider() {
    const provider = this.PriceRates.find(item => 
      item.PaymentProvider &&
      item.PaymentProvider.code == "contact" &&
      item.service_type == (this.data.is_new ? 'new' : 'used') && 
      item.from <= this.variantPrice &&
      item.to >= this.variantPrice
    );
    return provider ? provider : null;
  },

  amanProvider() {
    const provider = this.PriceRates.find(item => 
      item.PaymentProvider &&
      item.PaymentProvider.code == "aman" &&
      item.service_type == (this.data.is_new ? 'new' : 'used') && 
      item.from <= this.variantPrice &&
      item.to >= this.variantPrice
    );
    return provider ? provider : null;
  },

  shoolaProvider() {
    const provider = this.PriceRates.find(item => 
      item.PaymentProvider &&
      item.PaymentProvider.code == "souhoola" &&
      item.service_type == (this.data.is_new ? 'new' : 'used') && 
      item.from <= this.variantPrice &&
      item.to >= this.variantPrice
    );
    return provider ? provider : null;
  },

  forsaProvider() {
    const provider = this.PriceRates.find(item => 
      item.PaymentProvider &&
      item.PaymentProvider.code == "forsa" &&
      item.service_type == (this.data.is_new ? 'new' : 'used') && 
      item.from <= this.variantPrice &&
      item.to >= this.variantPrice
    );
    return provider ? provider : null;
  },

  visaProvider() {
    const provider = this.PriceRates.find(item => 
      item.PaymentProvider &&
      item.PaymentProvider.code == "visa" &&
      item.service_type == (this.data.is_new ? 'new' : 'used') && 
      item.from <= this.variantPrice &&
      item.to >= this.variantPrice
    );
    return provider ? provider : null;
  },


   // New computed property to check if any provider is valid
   isAnyProviderValid() {
    return this.valuProvider || this.contactProvider || this.amanProvider ||
           this.shoolaProvider || this.forsaProvider || this.visaProvider || this.bankInstallmentProvider;
  }
    
  },
  async created() {
    this.authPermissions = this.$store.state.authPermissions

    this.id = this.$route.params.id
    this.getInstallmentPlans();

    await this.getVariantData(this.id)
    if (
      this.authPermissions.includes('enabled-product') &&
      this.productStatus != 'enabled' &&
      this.productStatus != 'blocked'
    ) {
      this.statusOptions.push({
        value: 'enabled',
        text: 'Enabled',
      })
    }
    if (
      this.authPermissions.includes('confirmed-product') &&
      this.productStatus != 'confirmed'
    ) {
      this.statusOptions.push({
        value: 'confirmed',
        text: 'Confirmed',
      })
    }
  },
  methods: {
    updatePrice() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if(this.variant.payment_provider_id == null  && !this.data.is_portal){
            this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'You must select payment method',
                  showConfirmButton: false,
                  timer: 1500,
                })
            return;
          }
          let variant = this.variant

          if (variant.offer.checked) {
            delete variant.offer.checked
            delete variant.offer.amountInPercent
          } else {
            delete variant.offer
          }

          if (variant.rate != null) {
            delete variant.rateInPercent
            delete variant.rateChecked
          } else {
            delete variant.rateInPercent
            delete variant.rate
            delete variant.type
            delete variant.rateChecked
          }

          if (this.data.is_portal) {
            axios
              .post('variants/' + this.id + '/customize/update', variant)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                window.location.reload()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          } else {
            delete variant.quantity
            axios
              .post('variants/' + this.id + '/instore/update', variant)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                window.location.reload()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        }
      })
    },
    checkPrice() {
      if (this.variant.original_price != null) {
        if (this.variant.offer.checked == true) {
          this.variant.offer.amount =
            (parseInt(this.variant.offer.amountInPercent) *
              parseInt(this.variant.original_price)) /
            100

          if (this.variant.rateChecked) {
            this.variant.rate =
              (parseInt(this.variant.rateInPercent) *
                (parseInt(this.variant.original_price) -
                  parseInt(this.variant.offer.amount))) /
              100
            this.selleing =
              parseInt(this.variant.original_price) -
              parseInt(this.variant.offer.amount)
            this.payout =
              parseInt(this.variant.original_price) -
              parseInt(this.variant.offer.amount) -
              this.variant.rate
          } else {
            this.selleing =
              parseInt(this.variant.original_price) -
              parseInt(this.variant.offer.amount)
            this.payout = 'Will caculate from system'
          }
        } else {
          if (this.variant.rateChecked == true) {
            if (this.variant.rate != null) {
              this.variant.rateInPercent =
                (parseInt(this.variant.rate) /
                  parseInt(this.variant.original_price)) *
                100
            } else if (this.variant.rateInPercent != null) {
              this.variant.rate =
                (parseInt(this.variant.rateInPercent) *
                  parseInt(this.variant.original_price)) /
                100
            }
            this.selleing = parseInt(this.variant.original_price)
            this.payout =
              parseInt(this.variant.original_price) - this.variant.rate
          } else {
            this.selleing = parseInt(this.variant.original_price)
            this.payout = 'Will caculate from system'
          }
        }
      } else {
        this.variant.rateChecked = false
        this.variant.offer.checked = false
      }
    },
    checkRate() {
      if (this.variant.original_price != null) {
        if (this.variant.offer.amount == null) {
          if (this.variant.rate != null) {
            if (
              parseInt(this.variant.rate) <
              parseInt(this.variant.original_price)
            ) {
              this.variant.rateInPercent =
                (parseInt(this.variant.rate) /
                  parseInt(this.variant.original_price)) *
                100
              this.selleing = parseInt(this.variant.original_price)
              this.payout =
                parseInt(this.variant.original_price) -
                parseInt(this.variant.rate)
            } else {
              this.variant.rateInPercent = null
              this.selleing = parseInt(this.variant.original_price)
              this.payout = 'Will caculate from system'
              let variant = 'danger'
              this.$bvToast.toast('Must Commission Amount Less Than price', {
                title: 'Warning',
                variant,
                solid: true,
              })
            }
          } else {
          }
        } else {
          if (
            parseInt(this.variant.rate) < parseInt(this.variant.original_price)
          ) {
            this.variant.rateInPercent =
              (parseInt(this.variant.rate) /
                (parseInt(this.variant.original_price) -
                  parseInt(this.variant.offer.amount))) *
              100
            this.selleing =
              parseInt(this.variant.original_price) -
              parseInt(this.variant.offer.amount)
            this.payout =
              parseInt(this.variant.original_price) -
              parseInt(this.variant.offer.amount) -
              parseInt(this.variant.rate)
          } else {
            this.variant.rateInPercent = null
            this.selleing =
              parseInt(this.variant.original_price) -
              parseInt(this.variant.offer.amount)
            this.payout = 'Will caculate from system'
            let variant = 'danger'
            this.$bvToast.toast('Must Commission Amount Less Than price', {
              title: 'Warning',
              variant,
              solid: true,
            })
          }
        }
      } else {
        let variant = 'danger'
        this.$bvToast.toast('Set Price First', {
          title: 'Warning',
          variant,
          solid: true,
        })
      }
    },
    checkRateInPercent() {
      if (this.variant.original_price != null) {
        if (this.variant.rateInPercent != null) {
          if (this.variant.offer.amount == null) {
            if (parseInt(this.variant.rateInPercent) < 100) {
              this.variant.rate =
                (parseInt(this.variant.rateInPercent) *
                  parseInt(this.variant.original_price)) /
                100
              this.selleing = parseInt(this.variant.original_price)
              this.payout =
                parseInt(this.variant.original_price) -
                parseInt(this.variant.rate)
            } else {
              this.variant.rate = null
              this.selleing = parseInt(this.variant.original_price)
              this.payout = 'Will caculate from system'
              let variant = 'danger'
              this.$bvToast.toast('Must Commission Rate Less Than 100%', {
                title: 'Warning',
                variant,
                solid: true,
              })
            }
          } else {
            if (parseInt(this.variant.rateInPercent) < 100) {
              this.variant.rate =
                (parseInt(this.variant.rateInPercent) *
                  (parseInt(this.variant.original_price) -
                    parseInt(this.variant.offer.amount))) /
                100
              this.selleing =
                parseInt(this.variant.original_price) -
                parseInt(this.variant.offer.amount)
              this.payout =
                parseInt(this.variant.original_price) -
                parseInt(this.variant.offer.amount) -
                parseInt(this.variant.rate)
            } else {
              this.variant.rateInPercent = null
              this.selleing =
                parseInt(this.variant.original_price) -
                parseInt(this.variant.offer.amount)
              this.payout = 'Will caculate from system'
              let variant = 'danger'
              this.$bvToast.toast('Must Commission Amount Less Than price', {
                title: 'Warning',
                variant,
                solid: true,
              })
            }
          }
        } else {
          this.selleing =
            parseInt(this.variant.original_price) -
            parseInt(this.variant.offer.amount)
          this.payout = 'Will caculate from system'
          this.variant.rate = null
        }
      } else {
        let variant = 'danger'
        this.$bvToast.toast('Set Price First', {
          title: 'Warning',
          variant,
          solid: true,
        })
      }
    },
     
    getInstallmentPlans(){
      axios
        .get('products/installment/plans')
        .then((result) => {
          const data = result.data.data;
          this.InstallmentPlans = data;
        })
        .catch((err) => {
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        });
    },
    calculateInstallmentPrice(original_price,interestRate ,fees_type,admin_fees, months) {
      // const interest = (original_price * interestRate) / 100;
      // const totalWithInterest = original_price + interest;
      // return Math.round(totalWithInterest / months);

      
      let totalVariantPrice;
      const original_pricenum = Number(original_price);
      const admin_feesnum = Number(admin_fees);
      const interestRatenum = Number(interestRate);
      

        if (fees_type == 'fixed') {
          // If fees are fixed, add admin fees directly to the original price
          totalVariantPrice = original_pricenum + admin_feesnum;
        } else {
          // If fees are percentage-based
          if (admin_feesnum != 0) {
            totalVariantPrice = original_pricenum + (original_pricenum * admin_feesnum) / 100;
          } else {
            totalVariantPrice = original_pricenum; // No admin fees
          }
        }

        // Add interest to the total price
        const totalWithInterest = totalVariantPrice + (totalVariantPrice * interestRatenum) / 100;
        // Calculate installment price
        return Math.round(totalWithInterest / months);

    },
    checkOfferAmount() {
      if (this.variant.original_price != null) {
        if (this.variant.offer.amount != null) {
          if (
            parseInt(this.variant.offer.amount) <
            parseInt(this.variant.original_price)
          ) {
            this.variant.offer.amountInPercent =
              (parseInt(this.variant.offer.amount) /
                parseInt(this.variant.original_price)) *
              100
            if (this.variant.rateChecked) {
              this.variant.rate =
                (parseInt(this.variant.rateInPercent) *
                  (parseInt(this.variant.original_price) -
                    parseInt(this.variant.offer.amount))) /
                100
              this.selleing =
                parseInt(this.variant.original_price) -
                parseInt(this.variant.offer.amount)
              this.payout =
                parseInt(this.variant.original_price) -
                parseInt(this.variant.offer.amount) -
                parseInt(this.variant.rate)
            } else {
              this.selleing =
                parseInt(this.variant.original_price) -
                parseInt(this.variant.offer.amount)
              this.payout = 'Will caculate from system'
            }
          } else {
            this.variant.offer.amountInPercent = null
            if (this.variant.rateChecked) {
              this.variant.rate =
                (parseInt(this.variant.rateInPercent) *
                  parseInt(this.variant.original_price)) /
                100
              this.selleing = parseInt(this.variant.original_price)
              this.payout =
                parseInt(this.variant.original_price) -
                parseInt(this.variant.rate)
            } else {
              this.selleing = parseInt(this.variant.original_price)
              this.payout = 'Will caculate from system'
            }

            let variant = 'danger'
            this.$bvToast.toast('Must Discount Amount Not Be Empty', {
              title: 'Warning',
              variant,
              solid: true,
            })
          }
        } else {
        }
      } else {
        let variant = 'danger'
        this.$bvToast.toast('Set Price First', {
          title: 'Warning',
          variant,
          solid: true,
        })
      }
    },
    checkOfferAmountInPercent() {
      if (this.variant.original_price != null) {
        if (this.variant.offer.amountInPercent != null) {
          if (parseInt(this.variant.offer.amountInPercent) < 100) {
            this.variant.offer.amount =
              (parseInt(this.variant.offer.amountInPercent) *
                parseInt(this.variant.original_price)) /
              100
            if (this.variant.rateChecked) {
              this.variant.rate =
                (parseInt(this.variant.rateInPercent) *
                  (parseInt(this.variant.original_price) -
                    parseInt(this.variant.offer.amount))) /
                100
              this.selleing =
                parseInt(this.variant.original_price) -
                parseInt(this.variant.offer.amount)
              this.payout =
                parseInt(this.variant.original_price) -
                parseInt(this.variant.offer.amount) -
                parseInt(this.variant.rate)
            } else {
              this.selleing =
                parseInt(this.variant.original_price) -
                parseInt(this.variant.offer.amount)
              this.payout = 'Will caculate from system'
            }
          } else {
            this.variant.offer.amount = null

            if (this.variant.rateChecked) {
              this.variant.rate =
                (parseInt(this.variant.rateInPercent) *
                  parseInt(this.variant.original_price)) /
                100
              parseInt(this.variant.original_price)
              this.payout =
                parseInt(this.variant.original_price) -
                parseInt(this.variant.rate)
            } else {
              parseInt(this.variant.original_price)
              this.payout = 'Will caculate from system'
            }
            let variant = 'danger'
            this.$bvToast.toast('Must Discount Rate Not Empty', {
              title: 'Warning',
              variant,
              solid: true,
            })
          }
        } else {
          this.variant.offer.amount = null
          if (this.variant.rateChecked) {
            parseInt(this.variant.original_price)
            this.payout =
              parseInt(this.variant.original_price) -
              parseInt(this.variant.rate)
          } else {
            this.selleing = parseInt(this.variant.original_price)
            this.payout =
              parseInt(this.variant.original_price) -
              parseInt(this.variant.rate)
          }
        }
      } else {
        let variant = 'danger'
        this.$bvToast.toast('Set Price First', {
          title: 'Warning',
          variant,
          solid: true,
        })
      }
    },
    fileUpload2(event) {
      this.warranty.warranty.agency_file = event.target.files[0]
    },
    success() {
      this.$refs.simpleRules2.validate().then((success) => {
        if (success) {
          let formData = new FormData()

          this.warranty.warranty.type = this.warrantySelect.value
          if (this.warranty.warranty.type == 'seller') {
            this.warranty.warranty.duration = this.warrantyDurationSelect.value
          }

          formData.append('warranty[type]', this.warranty.warranty.type)
          if (this.warranty.warranty.type == 'seller') {
            formData.append(
              'warranty[duration]',
              this.warranty.warranty.duration
            )
          } else {
            formData.append(
              'warranty[end_date]',
              this.warranty.warranty.end_date
            )

            formData.append(
              'warranty[agency_file]',
              this.warranty.warranty.agency_file
            )
          }

          axios
            .post('variants/' + this.id + '/warranty/update', formData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    setPrice() {
      this.errors_back2 = []
      // this.variant = item;
      this.showDismissibleAlert2 = false
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.addPriceRate()
    },
    async addPriceRate() {
      this.showDismissibleAlert2 = false
      const isValid = await this.$refs.addPrice.validate()
      if (isValid) {
        var sendData = null
        if (
          this.variant.offerBDel.amount == 0 ||
          this.variant.offerBDel.amount == null ||
          this.variant.offerBDel.amount == ''
        ) {
          sendData = {
            original_price: this.variant.original_price,
            item_id: this.itemID,
            vendor_id: this.vendorID,
            product_type: 'used',
            type: this.typeSelect.value,
            rate: this.variant.rate,
          }
        } else {
          sendData = {
            original_price: this.variant.original_price,
            offer_type:
              this.offerTypeSelect == null ? null : this.offerTypeSelect.value,
            offer_amount:
              this.variant.offerBDel.amount == 0
                ? null
                : this.variant.offerBDel.amount,
            item_id: this.itemID,
            vendor_id: this.vendorID,
            product_type: 'used',
            type: this.typeSelect.value,
            rate: this.variant.rate,
          }
        }
        axios
          .post('products/rate/sale-price/get', sendData)
          .then((result) => {
            this.variant.offer.amount =
              this.variant.offerBDel.amount == 0
                ? null
                : this.variant.offerBDel.amount
            this.variant.offer.type =
              this.offerTypeSelect == null ? null : this.offerTypeSelect.value
            this.variant.original_price = this.variant.original_price
            this.variant.offer.startdate =
              this.variant.offerBDel.startdate == null
                ? null
                : this.variant.offerBDel.startdate
            this.variant.offer.enddate =
              this.variant.offerBDel.enddate == null
                ? null
                : this.variant.offerBDel.enddate
            this.variant.offer.usernumbers =
              this.variant.offerBDel.usernumbers == null
                ? null
                : this.variant.offerBDel.usernumbers
            this.variant.profit = result.data.profit
            this.variant.salePrice = result.data.sale_price
            this.$nextTick(() => {
              this.$bvModal.hide('modal-prevent-closing')
            })
          })
          .catch((err) => {
            this.errors_back2.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back2.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back2 = err.response.data.data
              }
              this.showDismissibleAlert2 = true
            } else {
              this.errors_back2 = []
              this.errors_back2.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert2 = true
            }
          })
      } else {
      }
    },
    async getVariantData(id) {
      await axios
        .get('variants/' + id + '/show')
        .then((result) => {
          const data = result.data.data
          this.data = data
          // if(data.product.vendor_id)
          this.name = data.translation[0].name
          this.itemID = data.product.item.id
          this.vendorID = data.product.vendor ? data.product.vendor.id : null
          this.productStatus = data.status
          if(this.vendorID != null){
            this.vendorsPriceRates(this.vendorID);
          }

          if (data.status == 'enabled') {
            this.statusSelect.text = 'Enabled'
            this.statusSelect.value = 'enabled'
          } else if (data.status == 'disabled') {
            this.statusSelect.text = 'Disabled'
            this.statusSelect.value = 'disabled'
          } else if (data.status == 'review') {
            this.statusSelect.text = 'Review'
            this.statusSelect.value = 'review'
          } else if (data.status == 'confirmed') {
            this.statusSelect.text = 'Confirmed'
            this.statusSelect.value = 'confirmed'
          } else if (data.status == 'blocked') {
            this.statusSelect.text = 'Blocked'
            this.statusSelect.value = 'blocked'
          } else if (data.status == 'modified') {
            this.statusSelect.text = 'Modified'
            this.statusSelect.value = 'modified'
          } else {
            this.statusSelect.text = 'Rejected'
            this.statusSelect.value = 'rejected'
          }
          this.variant.quantity = this.data.quantity
          this.variant.payment_provider_id = this.data.payment_provider.id

          this.variant.offer = {
            checked: false,
            amountInPercent: null,
            type: 'fixed_price',
            amount: null,
            startdate: null,
            enddate: null,
            usernumbers: null,
          }

          this.variant.rateInPercent = null
          this.variant.rate = null
          this.variant.type = 'fixed_price'

          if (data.warranty) {
            this.warranty.warranty.type = data.warranty.type
            if (this.warranty.warranty.type == 'seller') {
              this.warrantySelect = {
                text: 'Seller',
                value: 'seller',
              }
              this.warranty.warranty.duration = data.warranty.duration
              if (data.warranty.duration == '7') {
                this.warrantyDurationSelect = {
                  text: '7 يوم',
                  value: '7',
                }
              } else if (data.warranty.duration == '14') {
                this.warrantyDurationSelect = {
                  text: '14 يوم',
                  value: '14',
                }
              } else {
                this.warrantyDurationSelect = {
                  text: '30 يوم',
                  value: '30',
                }
              }
              this.warranty.warranty.end_date = data.warranty.end_date
            } else if (this.warranty.warranty.type == 'agency') {
              this.warrantySelect = {
                text: 'Agency',
                value: 'agency',
              }
              this.warranty.warranty.agency_file = data.warranty.agency_file
            } else {
              this.warrantySelect = {
                text: 'Supplier',
                value: 'supplier',
              }
              this.warranty.warranty.agency_file = data.warranty.agency_file
            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    updateStatus() {
      const data = {}
      if( this.statusSelect.value == 'rejected' ){
   
        data.note = this.note

      }
      axios
        .post(
          'variants/' +
            this.id +
            '/change-status/' +
            this.statusSelect.value +
            '/' +
            this.disabled ,data
        )
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.getVariantData(this.id)
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // handleOk(bvModalEvt) {
    //     bvModalEvt.preventDefault();
    //     this.addPriceRate();
    // },
    // async addPriceRate() {
    //     this.showDismissibleAlert2 = false;
    //     const isValid = await this.$refs.addPrice.validate();
    //     if (isValid) {
    //         axios
    //             .post("products/rate/sale-price/get", {
    //                 original_price: this.variant.original_price,
    //                 product_type: "new",
    //                 item_id: this.data.product.item.id.toString(),
    //                 offer_type:
    //                     this.offerTypeSelect == null ? null : this.offerTypeSelect.value,
    //                 offer_amount:
    //                     this.variant.offer.amount == 0 ? null : this.variant.offer.amount,
    //                 vendor_id: this.data.product.vendor.id.toString(),
    //             })
    //             .then((result) => {
    //                 //console.log("tttt", result);
    //                 this.variant.offer.amount =
    //                     this.variant.offer.amount == 0 ? null : this.variant.offer.amount;
    //                 this.variant.offer.type =
    //                     this.offerTypeSelect == null ? null : this.offerTypeSelect.value;
    //                 this.variant.original_price = this.variant.original_price;
    //                 this.variant.offer.startdate =
    //                     this.variant.offer.startdate == null
    //                         ? null
    //                         : this.variant.offer.startdate;
    //                 this.variant.offer.enddate =
    //                     this.variant.offer.enddate == null ? null : this.variant.offer.enddate;
    //                 this.variant.offer.usernumbers =
    //                     this.variant.offer.usernumbers == null
    //                         ? null
    //                         : this.variant.offer.usernumbers;
    //                 this.variant.profit = result.data.profit;
    //                 this.variant.salePrice = result.data.sale_price;
    //                 this.$nextTick(() => {
    //                     this.$bvModal.hide("modal-prevent-closing");
    //                 });
    //             })
    //             .catch((err) => {
    //                 this.errors_back2.length = 0;
    //                 if (err.response.data.data != null) {
    //                     if (this.isString(err.response.data.data)) {
    //                         this.errors_back2.push({
    //                             error: err.response.data.data,
    //                         });
    //                     } else {
    //                         this.errors_back2 = err.response.data.data;
    //                     }
    //                     this.showDismissibleAlert2 = true;
    //                 } else {
    //                     this.errors_back2 = [];
    //                     this.errors_back2.push({
    //                         error: "internal server error",
    //                     });
    //                     this.showDismissibleAlert2 = true;
    //                 }
    //             });
    //     } else {
    //     }
    // },
    // show offer in variants
    showOffer() {
      const oP = parseFloat(this.variant.original_price)
      const sP = parseFloat(this.varian.salePrice)
      if (
        this.variant.salePrice > 0 &&
        this.variant.salePrice != '' &&
        this.variant.salePrice != null &&
        oP >= sP
      ) {
        this.variant.offerHide = true
        this.variant.offer.amount =
          this.variant.original_price - this.variant.salePrice
        this.variant.type = 'fixed_price'
      } else {
        this.variant.offerHide = false
        ;(this.variant.offer.amount = ''),
          (this.variant.type = ''),
          (this.variant.startdate = '')
        this.variant.enddate = ''
        this.variant.usernumbers = ''
      }
    },
    // when change amount of offer
    changeAmount() {
      const oP = parseFloat(this.variant.original_price)
      const aP = parseFloat(this.variant.offer.amount)
      if (
        this.variant.offer.amount > 0 &&
        this.variant.offer.amount != '' &&
        this.variant.offer.amount != null &&
        oP > aP
      ) {
        this.variant.offerHide = true
        let price =
          parseFloat(this.variant.original_price) -
          parseFloat(this.variant.offer.amount)
        let profit = (price * this.vendorCommissionRate) / 100
        this.variant.salePrice = price + profit
        this.variant.type = 'fixed_price'
      } else {
        ;(this.variant.offer.amount = ''), (this.variant.type = '')
        this.variant.startdate = ''
        this.variant.enddate = ''
        this.variant.usernumbers = ''
        this.variant.offerHide = false
        let price = parseFloat(this.variant.original_price)
        let profit = (price * this.vendorCommissionRate) / 100
        this.variant.salePrice = price + profit
      }
    },
    // when change original price of vaiants
    changeOP() {
      if (
        this.variant.original_price > 0 &&
        this.variant.vriginal_price != '' &&
        this.variant.original_price != null
      ) {
        this.variant.offer.amountPriceDis = false
        let price = parseFloat(this.variant.original_price)
        let profit = (price * this.vendorCommissionRate) / 100
        this.variant.salePrice = price + profit
        this.variant.type = 'fixed_price'
      } else {
        this.variant.offer.amountPriceDis = true
        ;(this.variant.offer.amount = ''),
          (this.variant.offer.amount = ''),
          (this.variant.type = ''),
          (this.variant.startdate = '')
        this.variant.enddate = ''
        this.variant.usernumbers = ''
        this.variant.salePrice = ''
        this.variant.offer.amountPriceDis = false
        let price = parseFloat(this.variant.original_price)
        let profit = (price * this.vendorCommissionRate) / 100
        this.variant.salePrice = price + profit
      }
    },
    // translation repeated form functions
    translationrepeateAgain() {
      // this.refheightForm = this.$refs.translationForm
      this.variant.translations.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.tranLocaleSelect.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
    },
    translationremoveItem(index) {
      if (this.variant.translations.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.variant.translations.splice(index, 1)
        this.tranLocaleSelect.splice(index, 1)
      }
    },
    vendorsPriceRates(vendorSelect) {
      // request roles index
      this.variant.payment_provider_id = null;

      axios
        .get('vendors/price-rates/' + vendorSelect + '/indexForVendorIsStore')
        .then((result) => {
          //console.log(result)
          // const data = result.data.data

          // this.PriceRates = []
          this.PriceRates = result.data.data
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
<style scoped lang="scss">
.repeater-form {
  transition: 0.35s height;
}



.icon {
  width: 45px;
  height: 24px;
  margin-left: 10px;
}

p {
  margin-bottom: 0;
}

.text-success {
  color: green;
}

.b-form-radio {
  cursor: pointer;
}





@media (max-width: 992px) and (min-width: 480px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 85px;
  width: 300px;
}
}


@media (max-width:480px) and (min-width: 361px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 85px;
  width: 200px;
}

.scrolling-container::-webkit-scrollbar {
  height: 10px !important;
}
}

@media (max-width: 360px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 85px;
  width: 180px;
}

.scrolling-container::-webkit-scrollbar {
  height: 10px !important;
}

}



@media (min-width:992px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 85px;
  width: 600px;
}
}


.change-padding .card-body {
    padding: 1rem !important;
  } 


  .price-info {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.price-info span {
  font-size: 15px; 
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .price-info {
    // flex-direction: column; /* Stack spans vertically */
    align-items: flex-start; /* Align text to the left */
  }

  .price-info span {
    font-size: 13px;
    white-space: normal; /* Allow text wrapping */
  }
}

@media (max-width: 576px) {
  .price-info span {
    font-size: 11px;
    margin-bottom: 3px;
  }
}

.installment-plans-card .card-body {
  padding: 10px !important;
}



@media (max-width: 768px) {
.installment-plans-card .card-body {
  padding: 5px !important;
}
}

.max-width-42 {
  max-width: 42px;
}
</style>
