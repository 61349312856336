<template>
  <div>
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <b-row class="row-eq-height" v-if="paymentData">
      <!-- Main Info -->
      <b-col class="mb-1" cols="12" md="6">
        <b-card title="Main Info" class="h-100 d-flex flex-wrap">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-left">
                  <span  v-b-popover.hover.top.click="''" :id="'badge'+paymentData.id" @click="hidePopover(paymentData.id)">{{ paymentData.id }}</span>  

                  <b-popover    :id="'badge'+paymentData.id" :target="'badge'+paymentData.id" placement="top" triggers="hover click" custom-class="popover-custom">
                    <template >
                      <div @click="copyText(paymentData.id)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                    </template>
                  </b-popover>
                </td>
              </tr>
              <tr>
                <th class="text-left">Code</th>
                <td class="text-left">
                  <span  v-b-popover.hover.top.click="''" :id="'badge'+paymentData.code" @click="hidePopover(paymentData.code)">{{ paymentData.code }}</span>  

                  <b-popover    :id="'badge'+paymentData.code" :target="'badge'+paymentData.code" placement="top" triggers="hover click" custom-class="popover-custom">
                    <template >
                      <div @click="copyText(paymentData.code)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                    </template>
                  </b-popover>

                </td>
              </tr>
              <tr>
                <th class="text-left">Status</th>
                <td class="text-left">
                  <b-badge variant="primary">
                    {{ paymentData.status }}
                  </b-badge>
                </td>
              </tr>

              <tr>
                <th class="text-left">Transaction Type</th>
                <td class="text-left">
                  {{ paymentData.transaction_type }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Amount</th>
                <td class="text-left">{{ paymentData.amount }} EGP</td>
              </tr>
              <tr>
                <th class="text-left">Remaining Amount</th>
                <td class="text-left">
                  {{ paymentData.remaining_amount }} EGP
                </td>
              </tr>
              <tr>
                <th class="text-left">Payment Commission</th>
                <td class="text-left">
                  {{ paymentData.payment_commission }} EGP
                </td>
              </tr>
              <tr>
                <th class="text-left">Transaction Fees</th>
                <td class="text-left">
                  {{ paymentData.transaction_fees }} EGP
                </td>
              </tr>
              <tr v-if="paymentInfo">
                <th class="text-left">Payment Method</th>
                <td class="text-left">{{ paymentInfo.payment_method }}</td>
              </tr>

              <tr>
                <th class="text-left">Date</th>
                <td class="text-left">{{ paymentData.created_at }}</td>
              </tr>
              <tr>
                <th class="text-left">Last Update Date</th>
                <td class="text-left">{{ paymentData.updated_at }}</td>
              </tr>
              <tr>
                <th class="text-left">Transaction Ref</th>
                <td class="text-left">
                  <span  v-b-popover.hover.top.click="''" :id="'badge'+paymentData.transaction_ref" @click="hidePopover(paymentData.transaction_ref)">{{ paymentData.transaction_ref }}</span>  

                  <b-popover    :id="'badge'+paymentData.transaction_ref" :target="'badge'+paymentData.transaction_ref" placement="top" triggers="hover click" custom-class="popover-custom">
                    <template >
                      <div @click="copyText(paymentData.transaction_ref)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                    </template>
                  </b-popover>
                </td>
              </tr>
              <tr>
                <th class="text-left">Cart ID</th>
                <td class="text-left">{{ paymentData.cart_id }}</td>
              </tr>
            </tbody>
          </table>
          <b-card-footer>
            <b-button
              variant="danger"
              v-if="
                paymentData.transaction_type == 'sale' &&
                paymentData.status == 'success' &&
                authPermissions.includes('create_refund') &&
                ((paymentData.orders.length > 0 &&
                  paymentData.orders.every((order) => order.is_refund)) ||
                  (paymentData.subscription != null &&
                    paymentData.subscription.is_refund) ||
                  (paymentData.payment_session != null &&
                    paymentData.payment_session.is_refund))
              "
              class="mb-2"
              block
              v-b-modal.modal-danger
            >
              Create Refund
            </b-button>
            <b-alert
              v-height-fade.appear
              fade
              :show="showRefundDismissibleAlert"
              @dismissed="showRefundDismissibleAlert = false"
              variant="danger"
            >
              <h4 class="alert-heading">Alert</h4>
              <div class="alert-body">
                <ul v-for="(values, index) in refund_errors_back" :key="index">
                  <li v-for="(value, counter) in values" :key="counter">
                    {{ value }}
                  </li>
                </ul>
              </div>
            </b-alert>
          </b-card-footer>
        </b-card>
      </b-col>
      <!-- User Data -->
      <b-col  class="mb-1" cols="12" md="6" v-if="userData || paymentData.orders.every(order => order.is_refund)">
        <b-card :title="userData ? 'User Data' : ''" class="h-100 d-flex flex-wrap">
          <table class="table table-bordered" v-if="userData">
            <tbody>
              <tr>
                <th class="text-left">Name</th>
                <td class="text-left">
                  {{ userData.name }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Email / Phone</th>
                <td class="text-left">
                  {{ userData.email != null ? userData.email : userData.phone }}
                </td>
              </tr>

              <tr>
                <th class="text-left">Street</th>
                <td class="text-left">
                  {{ userData.street1 }}
                </td>
              </tr>
              <tr>
                <th class="text-left">City</th>
                <td class="text-left">
                  {{ userData.city }}
                </td>
              </tr>
              <tr>
                <th class="text-left">State</th>
                <td class="text-left">
                  {{ userData.state }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Country</th>
                <td class="text-left">
                  {{ userData.country }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <!-- Payment Data -->
      <b-col v-if="paymentResult" class="mb-1" cols="12" md="6">
        <b-card title="Payment Result" class="h-100 d-flex flex-wrap">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">Response Status</th>
                <td class="text-left">
                  {{ paymentResult.response_status }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Response Code</th>
                <td class="text-left">
                  {{ paymentResult.response_code }}
                </td>
              </tr>

              <tr>
                <th class="text-left">Response Message</th>
                <td class="text-left">
                  {{ paymentResult.response_message }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Transaction Time</th>
                <td class="text-left">
                  {{ paymentResult.transaction_time }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <!-- payment provider Data -->
      <b-col v-if="paymentProvider" class="mb-1" cols="12" md="6">
        <b-card title="Payment Provider" class="h-100 d-flex flex-wrap">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">Id</th>
                <td class="text-left">
                  {{ paymentProvider.id }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Admin Fees</th>
                <td class="text-left">
                  {{ paymentProvider.admin_fees }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Name</th>
                <td class="text-left">
                  {{ paymentProvider.name }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Provider Fees</th>
                <td class="text-left">
                  {{ paymentProvider.provider_fees }}
                </td>
              </tr>
              <tr>
                <th class="text-left">created_at</th>
                <td class="text-left">
                  {{ paymentProvider.created_at }}
                </td>
              </tr>
              <tr>
                <th class="text-left">updated_at</th>
                <td class="text-left">
                  {{ paymentProvider.updated_at }}
                </td>
              </tr>
              <tr>
                <th class="text-left">deleted_at</th>
                <td class="text-left">
                  {{ paymentProvider.deleted_at }}
                </td>
              </tr>
              <tr>
                <th class="text-left">logo</th>
                <td class="text-left">
                  <b-img :src="paymentProvider.logo" height="25px"></b-img>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <!-- payment Info Data -->
      <b-col v-if="paymentInfo" class="mb-1" cols="12" md="6">
        <b-card title="Payment Info" class="h-100 d-flex flex-wrap">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">Card Scheme</th>
                <td class="text-left">
                  {{ paymentInfo.card_scheme }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Card Type</th>
                <td class="text-left">
                  {{ paymentInfo.card_type }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Expiry Month</th>
                <td class="text-left">
                  {{ paymentInfo.expiryMonth }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Expiry Year</th>
                <td class="text-left">
                  {{ paymentInfo.expiryYear }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Payment Description</th>
                <td class="text-left">
                  {{ paymentInfo.payment_description }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Payment Method</th>
                <td class="text-left">
                  {{ paymentInfo.payment_method }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col v-if="paymob_response_sub_data" class="mb-1" cols="12" md="6">
        <b-card title="Payment Info" class="h-100 d-flex flex-wrap">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">Card Type</th>
                <td class="text-left">
                  {{ paymob_response_sub_data.card_type }}
                </td>
              </tr>

              <tr>
                <th class="text-left">Payment Description</th>
                <td class="text-left">
                  {{ paymob_response_sub_data.card_num }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <!-- Instalment Data -->
      <b-col v-if="instalmentData" class="mb-1" cols="12" md="6">
        <b-card title="Instalment Data" class="h-100 d-flex flex-wrap">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">Bill Number</th>
                <td class="text-left">
                  {{ instalmentData.loan_number }}
                </td>
              </tr>
              <tr>
                <th class="text-left">First Instalment Date</th>
                <td class="text-left">
                  {{ instalmentData.first_emi_due_date }}
                </td>
              </tr>

              <tr>
                <th class="text-left">last Instalment Date</th>
                <td class="text-left">
                  {{ instalmentData.last_installment_date }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Instalment Duration</th>
                <td class="text-left">{{ instalmentData.tenure }} Month</td>
              </tr>
              <tr>
                <th class="text-left">Instalment Total Price</th>
                <td class="text-left">
                  {{ instalmentData.product_price }} EGP
                </td>
              </tr>
              <tr>
                <th class="text-left">Discount</th>
                <td class="text-left">{{ instalmentData.discount }} EGP</td>
              </tr>
              <tr>
                <th class="text-left">Expense</th>
                <td class="text-left">
                  {{ instalmentData.expense }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Due Amount</th>
                <td class="text-left">{{ instalmentData.due_amount }} EGP</td>
              </tr>
              <tr>
                <th class="text-left">Instalment Price Per Month</th>
                <td class="text-left">{{ instalmentData.emi }} EGP</td>
              </tr>
              <tr>
                <th class="text-left">Down Payments</th>
                <td class="text-left">{{ instalmentData.down_payment }} EGP</td>
              </tr>
              <tr>
                <th class="text-left">Admin Fees</th>
                <td class="text-left">{{ instalmentData.admin_fees }} EGP</td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>

      <!---------   Paymob Source Data -------------------->
      <b-col
        v-if="paymentData.paymob_source_data"
        class="mb-1"
        cols="12"
        md="6"
      >
        <b-card title="Source Data" class="h-100 d-flex flex-wrap">
          <table class="table table-bordered">
            <tbody>
              <tr v-if="paymentData.paymob_source_data.national_id">
                <th class="text-left">National ID</th>
                <td class="text-left">
                  {{ paymentData.paymob_source_data.national_id }}
                </td>
              </tr>
              <tr>
                <th class="text-left">pan</th>
                <td class="text-left">

                  <span  v-b-popover.hover.top.click="''" :id="'badge'+paymentData.paymob_source_data.pan" @click="hidePopover(paymentData.paymob_source_data.pan)">{{ paymentData.paymob_source_data.pan }}</span>  

                    <b-popover    :id="'badge'+paymentData.paymob_source_data.pan" :target="'badge'+paymentData.paymob_source_data.pan" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(paymentData.paymob_source_data.pan)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>

                </td>
              </tr>

              <tr>
                <th class="text-left">Method Type</th>
                <td class="text-left">
                  {{ paymentData.paymob_source_data.type }}
                </td>
              </tr>
            </tbody>
          </table>
          <h3 class="my-2" v-if="paymentData.paymob_source_data.items">
            Items
          </h3>
          <table
            class="table table-bordered"
            v-if="paymentData.paymob_source_data.items"
          >
            <tbody
              v-for="(item, index) in paymentData.paymob_source_data.items"
              :key="index"
            >
              <tr>
                <th class="text-left">name</th>
                <td class="text-left">
                  {{ item.item_name }}
                </td>
              </tr>
              <tr>
                <th class="text-left">quantity</th>
                <td class="text-left">
                  {{ item.quantity }}
                </td>
              </tr>
              <tr>
                <th class="text-left">tenure</th>
                <td class="text-left">
                  {{ item.tenure }}
                </td>
              </tr>
              <tr>
                <th class="text-left">down_payment</th>
                <td class="text-left">
                  {{ item.down_payment }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        v-if="paymentData != null && paymentProvider != null"
        md="12"
      >
        <b-modal
          id="modal-danger"
          ok-only
          ok-variant="danger"
          ok-title="refund"
          modal-class="modal-danger"
          centered
          size="lg"
          title="Refund Transaction"
          @ok="refund()"
        >
          <b-row>
            <b-col md="6" v-if="paymentData.orders.length > 0">
              <b-form-group
                label="Add Service Cost ?"
                label-for="is_service_cost"
              >
                <validation-provider
                  #default="{ errors }"
                  name="is_service_cost"
                  rules="required"
                >
                  <b-form-checkbox
                    switch
                    :disabled="
                      (paymentProvider.name != 'visa' &&
                        paymentProvider.name != 'cod') ||
                      (paymentProvider.name == 'visa' &&
                        parseInt(paymentData.remaining_amount) != 0)
                    "
                    v-model="refundOrders.is_service_cost"
                  >
                    <p
                      v-if="refundOrders.is_service_cost"
                      style="color: #7e7a7a"
                    >
                      Add Service Cost ({{ serviceCost }} EGP) To Refund
                      Transaction Amount
                    </p>
                    <p v-else style="color: #7e7a7a">
                      remove Service Cost ( {{ serviceCost }} EGP) From Refund
                      Transaction Amount
                    </p>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              v-if="
                paymentProvider.name == 'visa' || paymentProvider.name == 'cod'
              "
            >
              <!-- Is Apply Refund Fees -->

              <b-form-group
                label="Apply Refund Fees ?"
                label-for="is_apply_refund_fees"
              >
                <validation-provider
                  #default="{ errors }"
                  name="is_apply_refund_fees"
                  rules="required"
                >
                  <b-form-checkbox
                    switch
                    v-model="refundOrders.is_apply_refund_fees"
                  >
                    <p v-if="refundOrders.is_apply_refund_fees">
                      Remove Refund Fees From Refund Transaction Amount
                    </p>
                    <p v-else>
                      Don't Remove Refund Fees From Refund Transaction Amount
                    </p>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              v-if="
                (paymentProvider.name == 'visa' ||
                  paymentProvider.name == 'cod') &&
                refundOrders.is_apply_refund_fees
              "
            >
              <b-form-group label="Refund Fees" label-for="refund_fees">
                <validation-provider
                  #default="{ errors }"
                  name="refund_fees"
                  :rules="
                    'required|between:0,' +
                    (paymentData.remaining_amount != 0 &&
                    paymentProvider.name != 'visa'
                      ? paymentData.remaining_amount
                      : paymentData.amount)
                  "
                >
                  <b-form-input
                    id="refund_fees"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    v-model="refundOrders.refund_fees"
                    min="0"
                    :max="
                      paymentData.remaining_amount != 0 &&
                      paymentProvider.name != 'visa'
                        ? paymentData.remaining_amount
                        : paymentData.amount
                    "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <div v-if="paymentData.orders.length != 0">
            <b v-if="paymentData.orders.length != 0">Choose Orders:</b>
            <br />
            <validation-observer>
              <div v-for="(item, index) in paymentData.orders" :key="index">
                <div v-if="item.is_refund">
                  <b-form-checkbox
                    class="my-1"
                    disabled
                    v-model="refundOrders.orders[index].order_id"
                    @change="checkSelect(index)"
                    :value="item.id"
                    :unchecked-value="null"
                  >
                    <p style="color: #7e7a7a">
                      {{ item.code }}
                    </p>
                  </b-form-checkbox>
                  <div
                    class="ml-3"
                    v-for="(vir, ind) in item.variants"
                    :key="ind"
                  >
                    <b-row>
                      <b-col md="6">
                        <b-form-checkbox
                          disabled
                          class="my-1"
                          @change="checkSelectVariant(index, ind, vir)"
                          v-model="
                            refundOrders.orders[index].variants[ind].variant_id
                          "
                          :value="vir.id"
                          :unchecked-value="null"
                        >
                          <p style="color: #7e7a7a">
                            {{ vir.name }}
                          </p>
                        </b-form-checkbox>
                      </b-col>
                      <b-col md="3">
                        <b-form-group
                          v-if="
                            refundOrders.orders[index].order_id != null &&
                            refundOrders.orders[index].variants[ind].variant_id !=
                              null
                          "
                          label="Quantity"
                          label-for="quantity"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="quantity"
                            :rules="'required|between:1,' + vir.quantity"
                          >
                            <b-form-input
                              id="quantity"
                              type="number"
                              disabled
                              v-model="
                                refundOrders.orders[index].variants[ind].quantity
                              "
                              :state="errors.length > 0 ? false : null"
                              min="1"
                              :max="vir.quantity"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group
                          v-if="
                            refundOrders.orders[index].order_id != null &&
                            refundOrders.orders[index].variants[ind].variant_id !=
                              null
                          "
                          label="Price"
                          label-for="price"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="quantity"
                            :rules="'required|between:1,' + vir.price"
                          >
                            <b-form-input
                              id="price"
                              type="number"
                              disabled
                              :state="errors.length > 0 ? false : null"
                              v-model="
                                refundOrders.orders[index].variants[ind].price
                              "
                              min="1"
                              :max="vir.price"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </validation-observer>
            <!-- <hr /> -->

          </div>



          <h5>
            Total Refund Transaction:
            <span style="font-weight: bold; color: #0d6efd"
              >{{ refundOrders.total_refund_transaction }} EGP</span
            >
          </h5>
        </b-modal>
      </b-col>
      <b-col cols="12" md="12">
        <b-row>
          <b-col cols="12" md="12">
            <b-card title="Orders" v-if="items.length != 0">
              <b-table
                id="OrdersTable"
                striped
                responsive
                class="position-relative"
                :items="items"
                :fields="fields"
              >

              
            <template #cell(code)="data">
              <span  v-b-popover.hover.top.click="''" :id="'badge'+data.value" @click="hidePopover(data.value)">{{ data.value }}</span>  

                <b-popover    :id="'badge'+data.value" :target="'badge'+data.value" placement="top" triggers="hover click" custom-class="popover-custom">
                  <template >
                    <div @click="copyText(data.value)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                  </template>
                </b-popover>
            </template>

                <template #cell(desc)="data">
                  <b-card-text><b v-html="data.value"></b> </b-card-text>
                </template>
                <template #cell(orderstatus)="data">
                  <b-badge variant="primary">
                    {{ data.value }}
                  </b-badge>
                </template>
                <template #cell(actions)="items">
                  <div class="d-flex p-1">
                    <!-- Show Order -->
                    <b-button
                      v-if="authPermissions.includes('update')"
                      size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      class="d-flex justify-content-center align-items-center"
                      type="submit"
                      :to="{ path: '/orders/show/' + items.item.id }"
                    >
                      <feather-icon
                        style="width: 18px; height: 18px"
                        icon="EyeIcon"
                      />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-card>
          </b-col>
          <b-col cols="12" md="12">
            <b-card title="Subscription" v-if="subscription.length != 0">
              <table class="table table-bordered">
                <b-table
                  id="tagTable"
                  ref="admint"
                  striped
                  hover
                  responsive
                  show-empty
                  class="position-relative"
                  @sort-changed="fetchData"
                  :items="subscription"
                  :fields="fieldsSubs"
                >
                  <template #cell(name)="data">
                    <!-- <p>  ar:  {{ JSON.parse( data.item.name).name_ar}}</p> -->
                    <p>{{ JSON.parse(data.item.name).name_en }}</p>
                  </template>

                  <template #cell(description)="data">
                    <p>
                      <span
                        v-html="
                          JSON.parse(
                            data.item.description
                          ).description_en.substring(0, 15) + '..'
                        "
                      >
                      </span>
                    </p>
                  </template>

                  <template #cell(package_snapshot)="data">
                    <p>
                      <span v-if="data.item.package_snapshot != null"
                        >{{
                          JSON.parse(data.item.package_snapshot.name).name_en
                        }}
                      </span>
                    </p>
                  </template>

                  <template #cell(user)="data">
                    <p>
                      <span>
                        {{
                          data.item.user.firstname +
                          ' ' +
                          data.item.user.lastname
                        }}
                      </span>
                    </p>
                  </template>

                  <template #cell(payment_provider)="data">
                    <p>
                      <span v-if="data.item.payment_provider != null">
                        {{ data.item.payment_provider.name }}
                      </span>
                    </p>
                  </template>

                  <template #cell(subscription_status)="data">
                    <b-badge variant="primary" v-if="data.value">
                      {{ data.value }}
                    </b-badge>
                  </template>

                  <template #cell(filter_data)="data">
                    <span v-if="data.item.filter_data != null">
                      <p
                        v-if="
                          JSON.parse(data.item.description).description_ar !=
                          null
                        "
                      >
                        <span class="text-success">Start Date:</span>
                        {{ JSON.parse(data.item.filter_data).description_ar }}
                      </p>
                      <p
                        v-if="
                          JSON.parse(data.item.description).description_en !=
                          null
                        "
                      >
                        <span class="text-success">End Date:</span>
                        {{ JSON.parse(data.item.description).description_en }}
                      </p>
                      <p
                        v-if="JSON.parse(data.item.filter_data).gender != null"
                      >
                        <span class="text-success">Gender:</span>
                        {{ JSON.parse(data.item.filter_data).gender }}
                      </p>
                      <p
                        v-if="
                          JSON.parse(data.item.filter_data).UserCategory != null
                        "
                      >
                        <span class="text-success">User Category:</span>
                        {{ JSON.parse(data.item.filter_data).UserCategory }}
                      </p>
                    </span>
                  </template>

                  <template #cell(created_at)="data">
                    {{ data.item.created_at | formatDate }}
                  </template>

                  <template #cell(updated_at)="data">
                    {{ data.item.updated_at | formatDate }}
                  </template>

                  <template #cell(actions)="items">
                    <!-- edit button value -->
                    <div class="d-flex p-1">
                      <!-- Show Order -->
                      <b-button
                        style="
                          padding: 3px;
                          margin: 3px;
                          width: 35px;
                          height: 35px;
                        "
                        size="sm"
                        v-if="authPermissions.includes('show')"
                        variant="success"
                        type="submit"
                        :to="{ path: '/subscription/show/' + items.item.id }"
                      >
                        <feather-icon class="mt-50" icon="EyeIcon" />
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </table>
            </b-card>
          </b-col>
          <b-col cols="12" md="12">
            <b-card
              title="Payment Session"
              v-if="this.paymentSession.length != 0"
            >
              <b-col cols="12" md="12">
                <b-table
                  striped
                  hover
                  responsive
                  :items="paymentSession"
                  :fields="paymentSessionFields"
                >
                  <template #cell(payment_provider)="data">
                    <b-img-lazy width="40" :src="data.value.logo" />
                  </template>

                  <template #cell(payment_method)="data">
                    {{ data.value.fees }} %
                  </template>
                  <template #cell(seller_username)="items">
                    <router-link
                      :to="{ path: '/User/Show/' + items.item.seller_id }"
                      v-if="authPermissions.includes('user')"
                      class="text-primary"
                    >
                      {{ items.item.seller_username }}
                    </router-link>
                    <p v-else>{{ items.item.seller_username }}</p>
                  </template>
                  <template #cell(buyer_username)="items">
                    <router-link
                      :to="{ path: '/User/Show/' + items.item.buyer_id }"
                      v-if="authPermissions.includes('user')"
                    >
                      {{ items.item.buyer_username }}
                    </router-link>
                    <p v-else>{{ items.item.buyer_username }}</p>
                  </template>
                  <template #cell(status)="data">
                    <b-badge
                      :variant="
                        data.value == 'pending'
                          ? 'light-primary'
                          : data.value == 'delivered'
                          ? 'light-success'
                          : data.value == 'failed'
                          ? 'light-danger'
                          : 'light-secondary'
                      "
                    >
                      {{ data.value }}
                    </b-badge>
                  </template>
                  <template #cell(actions)="items">
                    <div class="text-center d-flex p-1">
                      <!-- Show Order -->
                      <b-button
                        v-if="authPermissions.includes('show')"
                        variant="light-danger"
                        type="submit"
                        class="my-1 mx-0 pt-1 px-1"
                        :to="{ path: '/paymentSessions/show/' + items.item.id }"
                      >
                        <img
                          width="30"
                          :src="require('@/assets/images/icons/eye.png')"
                        />
                      </b-button>

                      <b-button
                        v-if="
                          authPermissions.includes('show') &&
                          items.item.status == 'pending'
                        "
                        variant="light-danger"
                        type="submit"
                        class="my-1 mx-0 pt-1 px-1"
                        :to="{
                          path:
                            '/paymentSessions/realtime/show/' + items.item.id,
                        }"
                      >
                        <img
                          :src="
                            require('@/assets/images/icons/live-streaming.svg')
                          "
                        />
                      </b-button>

                      <!-- </b-button> -->
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, between } from '@validations'
export default {
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        name: 'viewport',
        content:
          'width=device-width,initial-scale=1.0, maximum-scale=2.0, user-scalable=yes',
      },
    ],
  },
  watch: {
    'refundOrders.is_service_cost': function () {
      if (this.paymentProvider.name == 'visa') {
        if (this.refundOrders.is_service_cost) {
          this.refundOrders.refund_fees =
            ((this.transactionTotalPrice + this.serviceCost) *
              this.paymentProvider.refund_fees) /
            100
        } else {
          this.refundOrders.refund_fees =
            (this.transactionTotalPrice * this.paymentProvider.refund_fees) /
            100
        }
      }
      if (this.refundOrders.is_service_cost) {
        this.refundOrders.total_refund_transaction =
        parseFloat(this.transactionTotalPrice +
          this.serviceCost -
          this.refundOrders.refund_fees).toFixed(2)
      } else {
        this.refundOrders.total_refund_transaction =
        parseFloat(this.transactionTotalPrice - this.refundOrders.refund_fees).toFixed(2)
      }
    },
    'refundOrders.is_apply_refund_fees': function () {
      if (this.refundOrders.is_apply_refund_fees) {
        this.refundOrders.total_refund_transaction =
        parseFloat(this.transactionTotalPrice +
          this.serviceCost -
          this.refundOrders.refund_fees).toFixed(2)
      } else {
        this.refundOrders.total_refund_transaction =parseFloat( this.transactionTotalPrice).toFixed(2)
      }
    },
    'refundOrders.refund_fees': function () {
      if (this.refundOrders.is_apply_refund_fees) {
        this.refundOrders.total_refund_transaction =
        parseFloat(this.transactionTotalPrice +
          this.serviceCost -
          this.refundOrders.refund_fees).toFixed(2)
      } else {
        this.refundOrders.total_refund_transaction =parseFloat( this.transactionTotalPrice).toFixed(2)
      }
    },
  },

  data() {
    return {
      authPermissions: [],
      paymentData: null,
      userData: null,
      paymentInfo: null,
      paymentResult: null,
      paymobResult: null,
      paymentProvider: null,
      instalmentData: null,
      paymob_response_sub_data: null,
      errors_back: [],
      showDismissibleAlert: false,
      errors_back2: [],
      showDismissibleAlert2: false,
      refund_errors_back: [],
      showRefundDismissibleAlert: false,
      refundOrders: {
        transaction_id: null,
        is_service_cost: false,
        is_apply_refund_fees: false,
        total_refund_transaction: 0,
        refund_fees: 0,
        orders: [],
        subscription: [],
      },
      // table fields
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'code',
          label: 'Code',
        },
        {
          key: 'orderstatus',
          label: 'Order Status',
        },
        {
          key: 'total',
          label: 'Total',
        },
        {
          key: 'type',
          label: 'Type',
        },
        {
          key: 'discount',
          label: 'Discount',
        },
        {
          key: 'shipping_price',
          label: 'Shipping Price',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      fieldsSubs: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'code',
          label: 'code',
        },

        {
          key: 'user',
          label: 'User Name',
        },
        {
          key: 'package_snapshot',
          label: 'packages Name',
        },

        {
          key: 'subscription_status',
          label: 'Status',
        },
        {
          key: 'payment_provider',
          label: 'Payment Provider',
        },
        {
          key: 'total_paid',
          label: 'total_paid',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },

        {
          key: 'actions',
          label: 'actions',
        },
      ],
      paymentSessionFields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'code',
          label: 'Code',
        },
        {
          key: 'buyer_username',
          label: 'Buyer Username',
        },
        {
          key: 'seller_username',
          label: 'Seller Username',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'total_paid',
          label: 'Total',
        },
        {
          key: 'margin_amount',
          label: 'Margin Amount',
        },
        {
          key: 'payout_amount',
          label: 'Payout Amount',
        },
        {
          key: 'payment_provider',
          label: 'Payment Provider',
        },
        {
          key: 'payment_method',
          label: 'Payment Fees',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      items: [],
      subscription: [],
      paymentSession: [],
      required,
      between,
      serviceCost: 0,
      transactionTotalPrice: 0,
    }
  },

  created() {
    this.id = this.$route.params.id
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  methods: {
    async fetchData() {
      await axios
        .get('transactions/' + this.id + '/show')
        .then((result) => {
          this.paymentData = result.data.data
          this.userData = this.paymentData.customer_details
          this.paymentInfo = this.paymentData.payment_info
          this.paymentResult = this.paymentData.payment_result
          this.instalmentData = this.paymentData.instalment_data
          this.paymentProvider = this.paymentData.payment_provider
          this.paymob_source_data = this.paymentData.paymob_source_data
          this.paymob_response_sub_data =
            this.paymentData.paymob_response_sub_data
          this.items = []
          this.refundOrders.orders = []
          if (this.paymentData.subscription != null) {
            this.subscription = Array(this.paymentData.subscription)
          }

          for (let index in this.paymentData.orders) {
            this.serviceCost = +this.paymentData.orders[index].shipping_price
            this.transactionTotalPrice =
              +this.paymentData.orders[index].total_paid
            this.items.push({
              id: this.paymentData.orders[index].id,
              code: this.paymentData.orders[index].code,
              orderstatus: this.paymentData.orders[index].orderstatus.name,
              total: this.paymentData.orders[index].total_paid,
              type: this.paymentData.orders[index].type,
              discount: this.paymentData.orders[index].discount,
              shipping_price: this.paymentData.orders[index].shipping_price,
              created_at: this.paymentData.orders[index].created_at,
              updated_at: this.paymentData.orders[index].updated_at,
            })
            // console.log(this.refundOrders.orders.every(order => order.is_refund))
            // if (this.paymentData.orders[index].type != 'instalment') {
            //   this.refundOrders.orders.push({
            //     order_id: null,
            //     variants: [],
            //   })
            //   for (let vir in this.paymentData.orders[index].variants) {
            //     this.refundOrders.orders[index].variants.push({
            //       variant_id: null,
            //       quantity: null,
            //       price: null,
            //     })
            //   }
            // } else {
            this.refundOrders.orders.push({
              order_id: this.paymentData.orders[index].id,
              variants: [],
            })
            for (let vir in this.paymentData.orders[index].variants) {
              this.refundOrders.orders[index].variants.push({
                variant_id: this.paymentData.orders[index].variants[vir].id,
                quantity: this.paymentData.orders[index].variants[vir].quantity,
                price: this.paymentData.orders[index].variants[vir].price,
              })
            }
            // }
          }

          if (this.paymentData.cart_id != null) {
            // console.log(this.transactionTotalPrice)
            if (this.paymentProvider != null) {
              if (this.paymentProvider.name == 'cod') {
                this.refundOrders.refund_fees =
                  this.paymentData.remaining_amount
              } else if (this.paymentProvider.name == 'visa') {
                // ( ($priceData['total'] + $service_cost) * $paymentProvider->refund_fees ) / 100;
                this.refundOrders.refund_fees =
                  (this.transactionTotalPrice *
                    this.paymentProvider.refund_fees) /
                  100
              } else {
                this.refundOrders.is_service_cost = true
              }
            }
            if (this.refundOrders.is_service_cost) {
              this.refundOrders.total_refund_transaction =
              parseFloat(this.transactionTotalPrice +
                this.serviceCost -
                this.refundOrders.refund_fees).toFixed(2)
            } else {
              this.refundOrders.total_refund_transaction =
              parseFloat(this.transactionTotalPrice - this.refundOrders.refund_fees).toFixed(2)
            }
          } else if (this.paymentData.subscription_id != null) {
            this.transactionTotalPrice =
              this.paymentData.subscription.total_paid
            this.refundOrders.refund_fees =
              (this.paymentData.subscription.total_paid *
                this.paymentProvider.refund_fees) /
              100
            this.refundOrders.is_service_cost = false
          } else if (this.paymentData.payment_session_id != null) {
            this.paymentSession = Array(this.paymentData.payment_session)
            this.transactionTotalPrice =
              this.paymentData.payment_session.total_paid
            if (this.paymentProvider.name == 'visa') {
              this.refundOrders.refund_fees =
                (this.paymentData.payment_session.total_paid *
                  this.paymentProvider.refund_fees) /
                100
            }

            this.refundOrders.is_service_cost = false
            this.refundOrders.total_refund_transaction =
            parseFloat(this.transactionTotalPrice).toFixed(2)
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    checkSelect(index) {
      if (this.refundOrders.orders[index].order_id == null) {
        for (let ind in this.refundOrders.orders[index].variants) {
          this.refundOrders.orders[index].variants[ind].variant_id = null
          this.refundOrders.orders[index].variants[ind].quantity = null
          this.refundOrders.orders[index].variants[ind].price = null
        }
      }
    },
    checkSelectVariant(index, ind, variant) {
      this.refundOrders.orders[index].variants[ind].quantity = variant.quantity
      this.refundOrders.orders[index].variants[ind].price = variant.price
    },
    // new
    refund() {
      this.showRefundDismissibleAlert = false
  
      
          var refundData = this.refundOrders
          if (this.refundOrders.orders.length != 0) {
            for (let index in refundData.orders) {
              if (refundData.orders[index].order_id == null) {
                delete refundData.orders[index]
              }
            }
          } else if (this.subscription.length != 0) {
            refundData.subscription = this.subscription
          }
          this.refundOrders.transaction_id = this.$route.params.id
          // return;
          axios
            .post('refunds', refundData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.fetchData()
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.refund_errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.refund_errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.refund_errors_back = err.response.data.data
                }
                this.showRefundDismissibleAlert = true
              } else {
                this.refund_errors_back = []
                this.refund_errors_back.push({
                  error: 'internal server error',
                })
                this.showRefundDismissibleAlert = true
              }
            })
    
            
    },
    copyText(value) {
      navigator.clipboard.writeText(value)
        .then(() => {
          this.$bvToast.toast('copied to clipboard!', {
            title: 'Success',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          });
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
          this.$bvToast.toast('Failed to copy IMEI.', {
            title: 'Error',
            variant: 'danger',
            solid: true
          });
        });
    },
    hidePopover(PopId) {
      this.$root.$emit('bv::hide::popover', `badge${PopId}`);
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style scoped>
/* #card {
    display: flex !important;
    flex-wrap: wrap !important;
} */
#table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#table td,
#table th {
  border: 1px solid #dddddd;
  padding: 8px;
}
#table tr:nth-child(even) {
  background-color: #f5f5f5;
}
.cardH {
  margin-bottom: 0px;
}
</style>
