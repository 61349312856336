<!-- MyChart.vue -->
<template>
  <b-card class="count-report-sec">
    <div class="v-card-text">
    <!-- {{ card_mnumber.length }} -->
      <h4 class="text-h4" v-if="card_mnumber && card_mnumber.length != 0"><strong>{{ formatPrice(card_mnumber) }} </strong> {{ currency }}
      </h4>
      <h4 class="text-h4" v-else><strong>0</strong> 
      </h4>
      <p class="text-sm text-disabled my-1 mb-0">{{ card_text }}</p>
    </div>
    <div class="v-card-text" style="align-self: center;">
      <b-img :src="require('@/assets/images/icons/' + card_icon)" alt="Meeting Pic" />
    </div>
  </b-card>
</template>
    
<script>

export default {
  props: {
    card_mnumber: [],
    card_text: null,
    card_icon: null,
    currency: null,
  },
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    formatPrice(value) {
      if (this.currency) {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      } else {
        return value
      }
    }


  },
}
</script>
    
<style>
.drop-down-range {
  padding: 3px 0px !important;
  background-color: #F2F2F2 !important;
}

.drop-down-range button {
  color: #3f4144;
}

.btn-flat-primary.dropdown-toggle::after {
  filter: grayscale(1);
}

.inpute-range input {
  padding: 8px 6px !important;
}

.monthSelect {
  background-color: #7367F0;
}

.count-report-sec .card-body {
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.text-disabled {
  font-size: 15px;
}

.count-report-sec{
  max-height: 130px;
}
/* Your component styles go here */
</style>
  
  
  
     