<template>
  <section class="app-ecommerce-details">
    <!-- Content -->
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body" v-if="errors_back != null">
        <span>{{ errors_back }}</span>
      </div>
    </b-alert>
    <!-- Brand Information -->
    <b-row>
      <b-col md="6">
        <b-card
          img-src=""
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile"
        >
          <div class="justify-content-between align-items-center text-left ">
            <h5 class="mt-1">
              Phone Name:
              <b-badge variant="light-primary">{{ reportData.phone_name }}</b-badge>
            </h5>

            <h5 class="mt-1">
              OS Version:
              <b-badge variant="light-primary">{{ reportData.os_version }}</b-badge>
            </h5>

            <h5 class="mt-1">
              Type:
              <b-badge :variant="reportData.type == 'ads' ? 'light-info' : (reportData.type == 'testing' ? 'light-primary' : 'light-success')">{{ reportData.type }}</b-badge>
            </h5>
            <h5 class="mt-1">
              IMEI :
      
           <b-badge v-b-popover.hover.top.click="''"  id="imei-badge"   variant="light-primary" @click="hidePopover('imei-badge')">{{ reportData.imei }}</b-badge>
            <!-- for copy value -->
            <b-popover target="imei-badge" placement="top" triggers="hover click" custom-class="popover-custom">
              <template >
                <div @click="copyText(reportData.imei)" class="cursor-pointer">  <feather-icon size="20" icon="CopyIcon" class="text-primary" /> Copy</div>
              </template>
            </b-popover>

            </h5>
            <h5 class="mt-1">
              Serial Number:
              <b-badge v-b-popover.hover.top.click="''" id="serial_number-badge"  @click="hidePopover('serial_number-badge')" variant="light-primary">{{
                reportData.serial_number
              }}</b-badge>

             <!-- for copy value -->
            <b-popover  id="serial_number-badge" target="serial_number-badge" placement="top" triggers="hover click" custom-class="popover-custom">
              <template >
                <div @click="copyText(reportData.serial_number)" class="cursor-pointer"> <feather-icon size="20" icon="CopyIcon" class="text-primary" /> Copy</div>
              </template>
            </b-popover>

            </h5>
            <h5 class="mt-1">
              Device ID:
              <b-badge  v-b-popover.hover.top.click="''" id="device_id-badge" variant="light-primary" @click="hidePopover('device_id-badge')">{{ reportData.device_id }}</b-badge>

              <!-- for copy value -->
              <b-popover    id="device_id-badge" target="device_id-badge" placement="top" triggers="hover click" custom-class="popover-custom">
              <template >
                <div @click="copyText(reportData.device_id)" class="cursor-pointer"> <feather-icon size="20" icon="CopyIcon" class="text-primary" /> Copy</div>
              </template>
            </b-popover>

           
            </h5>
            <h5 class="mt-1" v-if="reportData.vendor != null">
              Seller Vendor:
              <b-badge  variant="light-primary" :to="{ path: '/Vendor/Show/' +  reportData.vendor.id }">
                  
                <span  v-b-popover.hover.top.click="''" :id="'badge'+reportData.vendor.storename" @click="hidePopover('badge'+reportData.vendor.storename)">   {{ reportData.vendor.storename }}</span>
                  
                  <!-- for copy value -->
                  <b-popover    :id="'badge'+reportData.vendor.storename" :target="'badge'+reportData.vendor.storename" placement="top" triggers="hover click" custom-class="popover-custom">
                  <template >
                    <div @click="copyText(reportData.vendor.storename)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                  </template>
                </b-popover>
                
              </b-badge>

            </h5>
            <h5 class="mt-1" v-if="reportData.user != null">
              Seller User:
              <b-badge variant="light-primary" :to="{ path: '/User/Show/' +  reportData.user.id }">{{ reportData.user.firstname }} {{ reportData.user.lastname }}</b-badge>
            </h5>
            <h5 class="mt-1">
              Variant Id:
              <b-badge variant="primary" :to="{ path: '/Used/ShowProduct/variant/show/' +  reportData.variant_id }">
                {{ reportData.variant_id }}</b-badge>
            </h5>
            <h5 class="mt-1">
              Pass Test:
              <b-badge variant="light-success">{{ reportData.pass_test }}</b-badge>
            </h5>
            <h5 class="mt-1">
              Fail Test:
              <b-badge variant="light-success">{{ reportData.fail_test }}</b-badge>
            </h5>
            <h5 class="mt-1">
              Score:
              <b-badge variant="danger">{{ reportData.score }}%</b-badge>
            </h5>
          </div>
          
          
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <div v-if="delCat" class="">
            <b-button
              block
              v-b-modal.modal-xl
              variant="primary"
              class="mr-1 mt-1"
              
            >
              Report Image
            </b-button>
            <b-modal
              id="modal-xl"
              ok-only
              centered
              size="lg"
              title="Report Image"
            >
             <img 
                  width="100%"
                  
                  
                  :src="reportData.image" />
                <!-- <iframe
                  id="reportImage"
                  type="text/html"
                  width="100%"
                  height="1000px"
                  
                  :src="reportData.image"
                /> -->
            </b-modal>
            
            <b-button
              block
              v-if="authPermissions.includes('delete')"
              @click="deleteData(reportData.id)"
              variant="outline-danger"
              class=""
            >
              Delete
            </b-button>
            
          </div>
          <div v-else class="d-flex justify-content-center">
            <b-button
              block
              v-if="authPermissions.includes('update')"
              @click="restoreSensor(reportData.id)"
              variant="outline-danger"
              class=" mr-1 mt-1"
            >
              Restore
            </b-button>
          </div>
          <hr />
          <div
            v-if="delCat"
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Created at
              </h6>
              <h3 class="mb-0">
                {{ reportData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Updated at
              </h6>
              <h3 class="mb-0">
                {{ reportData.updated_at }}
              </h3>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Created at
              </h6>
              <h3 class="mb-0">
                {{ reportData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Deleted at
              </h6>
              <h3 class="mb-0">
                {{ reportData.deleted_at }}
              </h3>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card title="Dignostic Report " class="table-responsive">
      <b-table striped hover :fields="fields" :items="items">
        <template #cell(actions)="items" v-if="authPermissions.includes('fix_report')">
          <span v-if="items.item.is_score ==true && items.item.score ==0">
                  <b-dropdown
                  
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                    
                      @click="fiXReportSensor(reportData.id , items.item.id)"
                     
                    >
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Fix Sensor</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="notAvailableReportSensor(reportData.id , items.item.id)"
                    
                     
                    >
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Not Available Sensor</span>
                    </b-dropdown-item>
                  
                  </b-dropdown>
                </span>
        </template>
      </b-table>
    </b-card>
  </section>
</template>

<script>

export default {

  data() {
    return {
      id: 0,
      authPermissions:[],
      reportData: {},
      errors_back: [],
      showDismissibleAlert: false,
      delCat: true,
      items: [],
      fields: [
        {
          key: "name",
          label: "name",
        },
        {
          key: "type",
          label: "type",
        },

        {
          key: "is_score",
          label: "is score",
        },
        {
          key: "total_score",
          label: "total score",
        },
        {
          key: "score",
          label: "score",
        },
        {
          key: "actions",
        },
      ],
    };
  },
  mounted() {
    // rquest brand data
  },
  created() {
    this.id = this.$route.params.id;
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("diagnostic/reports/" + this.id)
        .then((result) => {
          this.setReportData(result.data.data)
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    // fix function to fix sensor in the report
    fiXReportSensor(id , sensor_id) {
      //console.log(id)
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to fix this sensor for report.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get("diagnostic/reports/" + id + "/sensors/" + sensor_id + "/fix" )
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.setReportData(result.data.data)
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    // notAvailable function to make sensor not-vailable in the report
    notAvailableReportSensor(id , sensor_id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to fix this sensor for report.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get("diagnostic/reports/" + id + "/sensors/" + sensor_id + "/not-available" )
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.setReportData(result.data.data)
                
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    // delete function to delete brand
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete report.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("diagnostic/reports/" + id)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },

    restoreSensor(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to restore report.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "warning",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get(
                "diagnostic/reports/" + id + "/restore",
                this.restoreSelected
              )
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    setReportData(report){
      this.reportData = null;
      this.items = [];
      this.reportData = report;
        if (this.reportData.deleted_at != null) {
          this.delCat = false;
        } else {
          this.delCat = true;
        }
        for (let index in this.reportData.sensors) {
          this.items.push({
            id: this.reportData.sensors[index].id,
            name: this.reportData.sensors[index].translations.name,
            type: this.reportData.sensors[index].type,
            is_score: this.reportData.sensors[index].is_score,
            total_score: this.reportData.sensors[index].total_score,
            score: this.reportData.sensors[index].score,
          });
        }

    },
    copyText(value) {
      navigator.clipboard.writeText(value)
        .then(() => {
          this.$bvToast.toast('copied to clipboard!', {
            title: 'Success',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          });
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
          this.$bvToast.toast('Failed to copy IMEI.', {
            title: 'Error',
            variant: 'danger',
            solid: true
          });
        });
    },
    hidePopover(PopId) {
      this.$root.$emit('bv::hide::popover', `${PopId}`);
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

</style>
