<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-card title="Reports">
      <b-row>
        <!-- Report Types -->
        <b-col md="2" class="my-1">
          <b-form-group
            label="Types"
            label-cols-sm="4"
            label-align-sm="right"
            label-size="sm"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-model="type"
              class="border mr-0"
              size="sm"
              :text="typeName"
              variant="flat-primary"
            >
              <b-dropdown-item @click="changeTypeFilter('All', 'all')">
                All
              </b-dropdown-item>

              <b-dropdown-item
                @click="changeTypeFilter(item.name, item.slug)"
                v-for="(item, index) in reportTypes"
                :key="index"
              >
                {{ item.name }}
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- sorting -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Sort By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="fetchData">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height: 2.142rem; line-height: 0.5"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              @click="removeExitem"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
              >
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>

        <!-- start data table -->
        <b-col cols="12">
          <b-table
            id="DTTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            :busy.sync="isBusy"
            class="position-relative"
            :items="items"
            :fields="fields"
          >
            <template #cell(user)="items">
              <b-badge
                size="sm"
                v-if="items.item.vendor != null"
                variant="primary"
                > <a :href="'/Vendor/Show/' + items.item.vendor.id">
                {{ items.item.vendor.storename }}</a></b-badge
              >
              <b-badge
                size="sm"
                v-else-if="items.item.user != null"
                variant="info"
              ><a :href="'/User/Show/' + items.item.user.id">
                {{ items.item.user.firstname }} {{ items.item.user.lastname }} </a>
              </b-badge>
            </template>


            <template #cell(serial_number)="items">
           <span  v-b-popover.hover.top.click="''" :id="'imei-badge'+items.value"  @click="hidePopover('imei-badge'+items.value)">{{ items.value }}</span>

              <b-popover    :id="'imei-badge'+items.value" :target="'imei-badge'+items.value" placement="top" triggers="hover click" custom-class="popover-custom">
                  <template >
                    <div @click="copyText(items.value)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                  </template>
                </b-popover>
            </template>

            <template #cell(device_id)="items">
           <span  v-b-popover.hover.top.click="''" :id="'imei-badge'+items.value"   @click="hidePopover('imei-badge'+items.value)">{{ items.value }}</span>

              <b-popover    :id="'imei-badge'+items.value" :target="'imei-badge'+items.value" placement="top" triggers="hover click" custom-class="popover-custom">
                  <template >
                    <div @click="copyText(items.value)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                  </template>
                </b-popover>
            </template>

            <template #cell(score)="items"> {{ items.value }} % </template>

            <template #cell(variant_id)="items">
              <b-badge v-if="items.item.variant_id != null"
                size="lg"
                :to="{ path: '/Used/ShowProduct/variant/show/' +  items.item.variant_id }"
                variant="light-success"
              >
                {{ items.item.variant_id }}
              </b-badge>
            </template>
            <template #cell(type)="items">
              <b-badge
                size="lg"
                v-if="items.item.type == 'ads'"
                variant="light-info"
              >
                {{ items.item.type }}
              </b-badge>
              <b-badge
                size="lg"
                v-if="items.item.type == 'testing'"
                variant="light-primary"
              >
                {{ items.item.type }}
              </b-badge>
              <b-badge
                size="lg"
                v-if="items.item.type == 'selling'"
                variant="light-success"
              >
                {{ items.item.type }}
              </b-badge>
            </template>

            <template #cell(actions)="items">
              <div class="demo-inline-spacing">
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                  :to="{ path: '/diagnostic/reports/show/' + items.item.id }"
                  variant="success"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon
                    icon="EyeIcon"
                    style="width: 18px; height: 18px"
                  />
                </b-button>
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                  v-if="authPermissions.includes('delete')"
                  @click="deleteData(items.item.id)"
                  variant="danger"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    style="width: 18px; height: 18px"
                  />
                </b-button>
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                  @click="openImage(items.item.image)"
                  variant="primary"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon
                    icon="LayoutIcon"
                    style="width: 18px; height: 18px"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="DTTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pauthPermissionser: [],
      showDismissibleAlert: false,
      errors_back: [],
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      // table fields
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'user',
          label: 'user / vendor',
        },
        {
          key: 'type',
          label: 'type',
        },
        {
          key: 'variant_id',
          label: 'variant id',
        },
        {
          key: 'serial_number',
          label: 'serial number',
        },

        {
          key: 'score',
          label: 'score',
        },
        {
          key: 'phone_name',
          label: 'phone name',
        },
        {
          key: 'device_id',
          label: 'device id',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      items: [],
      typeName: 'All',
      type: 'all',
      exportItems: [],
      reportTypes: [
        {
          slug: 'selling',
          name: 'Selling',
        },
        {
          slug: 'testing',
          name: 'Testing',
        },
        {
          slug: 'ads',
          name: 'Ads',
        },
      ],
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
  },
  watch: {
    sortDesc: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    sortBy: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    filter: function () {
      if (this.filter == '' || this.filter == null) {
        this.fetchData()
      }
    },
    type: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
  },
  methods: {
    changeTypeFilter(name, slug) {
      this.type = slug
      this.typeName = name
    },
    // fetch Data of Brands
    fetchData() {
      this.showDismissibleAlert = false
      // request Brand index API
      var url = null
      if (this.filter == '') {
        url =
          'diagnostic/reports-pagi/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.type.toLowerCase() +
          '?page=' +
          this.currentPage
      } else {
        url =
          'diagnostic/reports-pagi/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.type.toLowerCase() +
          '/' +
          this.filter +
          '?page=' +
          this.currentPage
      }
      const promise = axios.get(url)
      return promise
        .then((result) => {
          //console.log(data);
          this.totalRows = result.data.data.meta.total
          this.items.length = 0

          this.items = result.data.data.data
          this.$refs.admint.refresh()

          // console.log(this.items)
          return this.items
        })
        .catch((err) => {
          console.log(err)
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    // delete function to delete Brand
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete report.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('diagnostic/reports/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    openImage(image) {
      // //console.log(image)
      window.open(image, '_blank')
    },
    removeExitem() {
      for (let ex in this.exportItems) {
        delete this.exportItems[ex].logo
      }
    },
    copyText(value) {
      navigator.clipboard.writeText(value)
        .then(() => {
          this.$bvToast.toast('copied to clipboard!', {
            title: 'Success',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          });
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
          this.$bvToast.toast('Failed to copy IMEI.', {
            title: 'Error',
            variant: 'danger',
            solid: true
          });
        });
    },
    hidePopover(PopId) {
      this.$root.$emit('bv::hide::popover', `${PopId}`);
    },
    genPDF() {
      const { jsPDF } = require('jspdf')
      const doc = new jsPDF()
      let print_body = []
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].name,
          this.items[index].desc,
          this.items[index].active,
          this.items[index].created_at,
          this.items[index].updated_at,
        ]
      }
      doc.autoTable({
        head: [
          [
            'ID',
            'User',
            'Type',
            'Variant ID',
            'Serial Number',
            'Score',
            'Brand Name',
          ],
        ],
        body: print_body,
      })
      doc.save('table.pdf')
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>