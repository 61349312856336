<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card title="Attributes and Values">
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col md="1">
                  <div class="d-flex justify-content-center align-items-center">
                    <b-img fluid :src="data.cover"></b-img>
                  </div>
                </b-col>
                <b-col class="my-auto" md="9">
                  <h4>
                    <span style="color: #7367f0">name :</span>
                    {{ name }}
                  </h4>
                  <br />
                  <h4>
                    <span style="color: #7367f0">SKU رمز : </span>
                    {{ data.sku }}
                  </h4>
                  <br />
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <div>
                <!-- Row Loop -->
                <b-row v-for="(item, index) in product.attributes" :key="'item_' +index">
                  <!-- Attrbuites -->
                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="attribute"
                      rules="required"
                    >
                      <b-form-group
                        label="Attribute"
                        label-for="attribute"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          :id="'attributes' + index"
                          disabled
                          v-model="attrSelect[index]"
                          :state="attrSelect[index] === null ? false : true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="attr2Options"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col v-if="attrSelect[index].is_variant" md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="values"
                      rules="required"
                    >
                      <b-form-group
                        label="Values"
                        label-for="values"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          multiple
                          :id="'values' + index"
                          v-model="valuesSelect[index]"
                          :clearable="false"
                          :state="valuesSelect[index] === null ? false : true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="valuesOptions[index]"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col v-else md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="values"
                      rules="required"
                    >
                      <b-form-group
                        label="Value"
                        label-for="values"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          :id="'values' + index"
                          v-model="valuesSelect[index]"
                          :clearable="false"
                          :state="valuesSelect[index] === null ? false : true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="valuesOptions[index]"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Remove Button -->
                  <!-- <b-col lg="2" md="3" class="mb-50">
                                                <b-button v-if="index > 0" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="taxonomyremoveItem(index)">
                                                    <feather-icon icon="XIcon" class="mr-25" />
                                                    <span>Remove</span>
                                                </b-button>
                                            </b-col> -->
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <!-- add new button -->
            <!-- <b-col sm="2">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" block @click="taxonomyrepeateAgain">
                                        <feather-icon icon="PlusIcon" class="mr-25" />
                                        <span>Add New</span>
                                    </b-button>
                                </b-col> -->
          </b-row>
          <b-button class="mt-2" @click="generate" block variant="success">
            Generate
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" v-if="varints.length > 0">
        <b-card title="Variants" ref="varintsHeight">
          <b-row>
            <b-col cols="12">
              <div>
                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in varints"
                  :key="'variant' + index"
                >
                  <b-col cols="12">
                    <app-collapse
                      id="faq-payment-qna"
                      type="margin"
                      class="mt-0 mb-0"
                    >
                      <app-collapse-item
                        :key="'variantIndex' + index"
                        :title="item.vname"
                      >
                        <b-row>
                          <b-col cols="12" class="">
                            <b-row>
                              <!-- price button -->
                              <b-col
                                md="2"
                                class="d-flex justify-content-center align-items-center"
                              >
                                <b-button
                                  variant="warning"
                                  @click="setPrice(item, index)"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  v-b-modal.modal-prevent-closing
                                  >Add Price</b-button
                                >
                              </b-col>
                              <!-- varint quantity -->
                              <b-col
                                md="2"
                                class="d-flex justify-content-center align-items-center"
                              >
                                <b-form-group
                                  label="Quantity"
                                  label-for="quantity"
                                >
                                  <validation-provider
                                    #default="{ errors }"
                                    name="quantity"
                                    rules="required|regex:^[0]*[1-9][0-9]*$"
                                  >
                                    <b-form-input
                                      :id="'quantity' + index"
                                      min="0"
                                      type="number"
                                      v-model="item.vquantity"
                                      :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col
                                md="4"
                                v-show="
                                  item.salePrice > 0 && item.salePrice != null
                                "
                              >
                                <p>
                                  Original Price:
                                  {{ item.voriginal_price }} EGP
                                </p>
                                <p
                                  v-show="
                                    item.offerBDel.amount != 0 &&
                                      item.offerBDel.amount != '' &&
                                      item.offerBDel.amount != null
                                  "
                                >
                                  Offer:
                                  <b-badge
                                    varaint="primary"
                                    v-if="item.offerBDel.type == 'fixed_price'"
                                    >{{ item.offerBDel.amount }} EGP</b-badge
                                  >
                                  <b-badge variant="primary" v-else
                                    >{{ item.offerBDel.amount }}%</b-badge
                                  >
                                </p>
                                <p>Price: {{ item.salePrice }} EGP</p>
                                <p>Profit: {{ item.profit }} EGP</p>
                              </b-col>
                              <b-col cols="12">
                                <hr />
                              </b-col>

                              <b-col cols="12">
                                <hr />
                              </b-col>
                              <b-col cols="12">
                                <b-row>
                                  <b-col>
                                    <b-row>
                                      <b-col cols="12">
                                        <div>
                                          <b-form
                                            ref="vImagesform"
                                            :style="{ height: trHeight }"
                                            class="repeater-form"
                                            @submit.prevent="
                                              vImagesRepeateAgain(item)
                                            "
                                          >
                                            <!-- Row Loop -->
                                            <b-row
                                              v-for="(im, ind) in item.vimages2"
                                              :id="im + ind"
                                              :key="im + ind"
                                              ref="row"
                                            >
                                              <!-- Image Reapeted -->
                                              <b-col
                                                md="4"
                                                class="mt-auto mb-auto"
                                              >
                                                <b-form-group
                                                  label="Image"
                                                  label-for="image"
                                                >
                                                  <validation-provider
                                                    #default="{ errors }"
                                                    :name="'image' + ind"
                                                  >
                                                    <b-form-file
                                                      @change="
                                                        uploadImage3(
                                                          $event,
                                                          item,
                                                          ind
                                                        )
                                                      "
                                                      :id="
                                                        'image' +
                                                          ind +
                                                          index +
                                                          ind
                                                      "
                                                      v-model="
                                                        item.vimages3[ind].vname
                                                      "
                                                      :state="
                                                        errors.length > 0
                                                          ? false
                                                          : null
                                                      "
                                                      accept="image/*"
                                                    />
                                                    <small
                                                      class="text-danger"
                                                      >{{ errors[0] }}</small
                                                    >
                                                  </validation-provider>
                                                </b-form-group>
                                              </b-col>
                                              <b-col md="2">
                                                <b-media class="mb-1 mr-auto">
                                                  <b-img
                                                    v-if="
                                                      item.vimages2[ind]
                                                        .vname != null &&
                                                        item.vimages2[ind]
                                                          .vname != ''
                                                    "
                                                    class="img-thumbnail"
                                                    ref="previewEl"
                                                    :src="
                                                      item.vimages2[ind].vname
                                                    "
                                                    height="100px"
                                                  />
                                                </b-media>
                                              </b-col>
                                              <!-- Remove Button -->
                                              <b-col
                                                lg="2"
                                                md="3"
                                                class="mt-auto mb-auto"
                                              >
                                                <b-button
                                                  v-ripple.400="
                                                    'rgba(234, 84, 85, 0.15)'
                                                  "
                                                  v-if="
                                                    item.vimages2.length > 1
                                                  "
                                                  variant="outline-danger"
                                                  class="mt-0 mt-md-2"
                                                  @click="
                                                    vImagesRemoveItem(ind, item)
                                                  "
                                                >
                                                  <feather-icon
                                                    icon="XIcon"
                                                    class="mr-25"
                                                  />
                                                  <span>Remove</span>
                                                </b-button>
                                              </b-col>
                                              <b-col cols="12">
                                                <hr />
                                              </b-col>
                                            </b-row>
                                          </b-form>
                                        </div>
                                      </b-col>
                                      <!-- add new button -->
                                      <b-col cols="12">
                                        <b-button
                                          v-ripple.400="
                                            'rgba(255, 255, 255, 0.15)'
                                          "
                                          style="width:40%"
                                          variant="outline-primary"
                                          block
                                          @click="vImagesRepeateAgain(item)"
                                        >
                                          <feather-icon
                                            icon="PlusIcon"
                                            class="mr-25"
                                          />
                                          <span>Add New Image</span>
                                        </b-button>
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <!-- Remove Button -->
                          <b-col cols="12" class="mb-50">
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="success"
                              class=" m-2 border-0"
                              @click="addVariant(index)"
                            >
                              <feather-icon icon="XIcon" class="mr-25" />
                              <span>Add Product</span>
                            </b-button>
                          </b-col>
                          <b-col cols="12">
                            <hr />
                          </b-col>
                        </b-row>
                      </app-collapse-item>
                    </app-collapse>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <!-- add Price Model -->
          <b-modal
            id="modal-prevent-closing"
            cancel-variant="outline-secondary"
            @ok="handleOk"
            ok-title="Add"
            cancel-title="Close"
            centered
            title="Add Price"
          >
            <validation-observer ref="addPrice">
              <b-form @submit.stop.prevent="addPriceRate">
                <b-row>
                  <!-- varint price -->
                  <b-col md="6">
                    <b-form-group label="Price" label-for="price">
                      <validation-provider
                        #default="{ errors }"
                        name="price"
                        rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                      >
                        <b-form-input
                          v-model="variantItem.voriginal_price"
                          min="0"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- varint Amount -->
                  <b-col md="6">
                    <b-form-group label="Amount" label-for="amount">
                      <b-form-input
                        min="1"
                        type="number"
                        v-model="variantItem.offerBDel.amount"
                        placeholder="enter amount"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col v-if="variantItem.offerBDel.amount > 0" md="12">
                    <b-row>
                      <b-col>
                        <app-collapse
                          id="faq-payment-qna"
                          type="margin"
                          class="mt-0 mb-0"
                        >
                          <app-collapse-item
                            :key="variantIndex"
                            title="Click To Customize Offer"
                          >
                            <b-row>
                              <b-col md="6">
                                <validation-provider
                                  #default="{ errors }"
                                  name="Offer Type"
                                  rules="required"
                                >
                                  <b-form-group
                                    label="Offer Type"
                                    label-for="Offer Type"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <v-select
                                      id="Offer-Type"
                                      v-model="offerTypeSelect"
                                      :state="
                                        offerTypeSelect === null ? false : true
                                      "
                                      :dir="
                                        $store.state.appConfig.isRTL
                                          ? 'rtl'
                                          : 'ltr'
                                      "
                                      :options="offerTypeOptions"
                                      :selectable="
                                        (option) =>
                                          !option.value.includes('select_value')
                                      "
                                      label="text"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                              <b-col md="6">
                                <b-form-group
                                  label="Users Number"
                                  label-for="User-Numbers"
                                >
                                  <validation-provider
                                    #default="{ errors }"
                                    name="user numbers"
                                    rules="required|regex:^[0]*[1-9][0-9]*$"
                                  >
                                    <b-form-input
                                      min="1"
                                      id="User-Numbers"
                                      type="number"
                                      v-model="
                                        variantItem.offerBDel.usernumbers
                                      "
                                      :state="errors.length > 0 ? false : null"
                                      placeholder="Users Number"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="6">
                                <validation-provider
                                  #default="{ errors }"
                                  name="start date"
                                  rules="required"
                                >
                                  <b-form-group
                                    label="Start Date"
                                    label-for="start date"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <flat-pickr
                                      v-model="variantItem.offerBDel.startdate"
                                      class="form-control"
                                      :config="{
                                        enableTime: true,
                                        dateFormat: 'Y-m-d',
                                        minDate: 'today',
                                      }"
                                    />
                                    <b-form-invalid-feedback
                                      :state="errors.length > 0 ? false : null"
                                    >
                                      {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                              <b-col md="6">
                                <validation-provider
                                  #default="{ errors }"
                                  name="end date"
                                  rules="required"
                                >
                                  <b-form-group
                                    label="End Date"
                                    label-for="end date"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <flat-pickr
                                      v-model="variantItem.offerBDel.enddate"
                                      :disabled="
                                        variantItem.offerBDel.startdate ==
                                          null ||
                                          variantItem.offerBDel.startdate == ''
                                      "
                                      class="form-control"
                                      :config="{
                                        enableTime: true,
                                        dateFormat: 'Y-m-d',
                                        minDate:
                                          variantItem.offerBDel.startdate,
                                      }"
                                    />
                                    <b-form-invalid-feedback
                                      :state="errors.length > 0 ? false : null"
                                    >
                                      {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                            </b-row>
                          </app-collapse-item>
                        </app-collapse>
                      </b-col>
                    </b-row>
                    <!-- error handelr -->
                    <b-alert
                      v-height-fade.appear
                      fade
                      :show="showDismissibleAlert2"
                      @dismissed="showDismissibleAlert2 = false"
                      variant="danger"
                    >
                      <h4 class="alert-heading">
                        Alert
                      </h4>
                      <div class="alert-body">
                        <ul v-for="values in errors_back2" :key="values">
                          <li v-for="value in values" :key="value">
                            {{ value }}
                          </li>
                        </ul>
                      </div>
                    </b-alert>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
        </b-card>
      </b-col>
    </b-row>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{value}}</li>
                </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import { required } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
export default {
  mixins: [heightTransition],
  components: {
AppCollapse,
AppCollapseItem
  },
  data() {
    return {
      required,
      errors_back2: [],
      showDismissibleAlert2: false,
      errors_back: [],
      showDismissibleAlert: false,
      vendorCommissionRate: null,
      itemData: null,
      id: 0,
      itemId: null,
      vendorId: null,
      offerTypeSelect: null,
      offerTypeOptions: [
        {
          text: "Fixed Price",
          value: "fixed_price",
        },
        {
          text: "Percentage",
          value: "percentage",
        },
      ],
      variantItem: {
        offerHide: false,
        offerBDel: {
          type: null,
          amount: 0,
          startdate: null,
          enddate: null,
          usernumbers: null,
        },
        voriginal_price: "",
      },
      variantIndex: null,
      attrSelect: [],
      attrOptions: [],
      attr2Options: [],
      vTransSelect: [],
      localeOptions: [
        {
          value: "en",
          text: "English",
        },
        {
          value: "ar",
          text: "Arabic",
        },
      ],
      tranLocaleSelect: [
        {
          value: "en",
          text: "English",
          disa: true,
        },
        {
          value: "ar",
          text: "Arabic",
          disa: true,
        },
      ],
      vConditionSelect: [],
      conditionOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      valuesSelect: [],
      valuesOptions: [
        [
          {
            value: "select_value",
            text: "Select Value",
          },
        ],
      ],
      valuesID: [],
      combTextArr: [],
      combValuesArr: [],
      varints: [],
      product: {
        attributes: [],
        variants: [],
      },
      oldVariantTitle: [],
      oldVariantId: [],
      translations: [],
      files: null,
      warrantyOptions: [
        {
          text: "Seller",
          value: "seller",
        },
        {
          text: "Agency",
          value: "agency",
        },
      ],
      warrantyDurationSelect: [],
      warrantyDurationOptions: [
        {
          text: "7 يوم",
          value: "7",
        },
        {
          text: "14 يوم",
          value: "14",
        },
        {
          text: "30 يوم",
          value: "30",
        },
      ],
      warrantySelect: [],
      variantItem: {
        offerHide: false,
        offerBDel: {
          type: null,
          amount: "",
          startdate: null,
          enddate: null,
          usernumbers: null,
        },
        voriginal_price: "",
      },
      data: null,
      name:"",
    };
  },
  watch: {
    attrSelect: function() {
      this.getOptions();
    },
  },
  methods: {
     async getProduct() {
      await axios
        .get('products/' + this.id)
        .then((result) => {
          this.data = result.data.data

          this.name = this.data.translation[0].name
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    setPrice(item, index) {
      this.errors_back2 = [];
      this.variantItem = item;
      this.variantIndex = index;
      this.showDismissibleAlert2 = false;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.addPriceRate();
    },
    async addPriceRate() {
      this.showDismissibleAlert2 = false;
      const isValid = await this.$refs.addPrice.validate();
      if (isValid) {
        var sendData = null;
        if (
          this.variantItem.offerBDel.amount == 0 ||
          this.variantItem.offerBDel.amount == null ||
          this.variantItem.offerBDel.amount == ""
        ) {
          sendData = {
            original_price: this.variantItem.voriginal_price,
            item_id: this.itemId,
            vendor_id: this.vendorId,
            product_type: "new",
          };
        } else {
          sendData = {
            original_price: this.variantItem.voriginal_price,
            item_id: this.itemId,
            offer_type:
              this.offerTypeSelect == null ? null : this.offerTypeSelect.value,
            offer_amount:
              this.variantItem.offerBDel.amount == 0
                ? null
                : this.variantItem.offerBDel.amount,
            vendor_id: this.vendorId,
            product_type: "new",
          };
        }
        axios
          .post("products/sale-price/get", sendData)
          .then((result) => {
            this.varints[this.variantIndex].offerBDel.amount =
              this.variantItem.offerBDel.amount == 0
                ? null
                : this.variantItem.offerBDel.amount;
            this.varints[this.variantIndex].offerBDel.type =
              this.offerTypeSelect == null ? null : this.offerTypeSelect.value;
            this.varints[
              this.variantIndex
            ].voriginal_price = this.variantItem.voriginal_price;
            this.varints[this.variantIndex].offerBDel.startdate =
              this.variantItem.offerBDel.startdate == null
                ? null
                : this.variantItem.offerBDel.startdate;
            this.varints[this.variantIndex].offerBDel.enddate =
              this.variantItem.offerBDel.enddate == null
                ? null
                : this.variantItem.offerBDel.enddate;
            this.varints[this.variantIndex].offerBDel.usernumbers =
              this.variantItem.offerBDel.usernumbers == null
                ? null
                : this.variantItem.offerBDel.usernumbers;
            this.varints[this.variantIndex].profit = result.data.profit;
            this.varints[this.variantIndex].salePrice = result.data.sale_price;
            this.$nextTick(() => {
              this.$bvModal.hide("modal-prevent-closing");
            });
          })
          .catch((err) => {
            this.errors_back2.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back2.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back2 = err.response.data.data;
              }
              this.showDismissibleAlert2 = true;
            } else {
              this.errors_back2 = [];
              this.errors_back2.push({
                error: "internal server error",
              });
              this.showDismissibleAlert2 = true;
            }
          });
      } else {
      }
    },
    // set attrbuites
    getOptions() {
      this.attr2Options = this.attrOptions.filter(
        (item) => !this.attrSelect.includes(item)
      );
    },
    // generate variants
    generate() {
      for (let val in this.valuesSelect) {
        const vale = this.valuesSelect[val];
        this.product.attributes[val].values = [];

        if (Array.isArray(vale)) {
          for (let valID in vale) {
            this.product.attributes[val].values.push(vale[valID].value);
          }
        } else {
          this.product.attributes[val].values.push(vale.value);
        }
      }
      //console.log(this.product.attributes.length);
      //console.log(this.attrSelect.length);
      for (let att in this.product.attributes) {
        const attrbu = this.product.attributes[att];
        attrbu.id = this.attrSelect[att].value;
      }
      var mainValues = [];
      for (let val in this.valuesSelect) {
        const vale = this.valuesSelect[val];
        if (Array.isArray(vale)) {
          //console.log(vale);
          mainValues.push(vale);
        }
      }
      for (let main in mainValues) {
        const mainVal = mainValues[main];
        this.combTextArr[main].length = 0;

        if (Array.isArray(mainVal)) {
          for (let valID in mainVal) {
            this.combTextArr[main].push(mainVal[valID].text);
          }
        }
      }
      for (let main in mainValues) {
        const mainVal = mainValues[main];
        this.combValuesArr[main].length = 0;

        if (Array.isArray(mainVal)) {
          for (let valID in mainVal) {
            this.combValuesArr[main].push(mainVal[valID].value);
          }
        }
      }
      const compainText = this.getCombn(this.combTextArr);
      const compainValues = this.getCombn2(this.combValuesArr);
      for (let tran in this.varints) {
        this.varints[tran].vis_new = "new";
      }
      this.subActive = false;
    },
    // make combination to get names of variants
    getCombn(arr) {
      if (arr.length == 1) {
        var ans = [];
        for (let ind in arr[0]) {
          ans.push(arr[0][ind]);
        }
        this.warrantySelect.length = 0;
        this.warrantyDurationSelect.length = 0;
        this.varints.length = 0;
        for (let inde in ans) {
          this.warrantySelect.push();
          this.warrantyDurationSelect.push();
          this.varints.push({
            vname: ans[inde],
            vquantity: "1",
            vis_new: "new",
            warranty: {
              type: null,
              duration: null,
              end_date: null,
              agency_file: null,
            },
            voriginal_price: "",
            salePrice: "",
            amountPriceDis: true,
            report_serial_number: "",
            vtranslations: [
              {
                vlocale1: {
                  text: "English",
                  value: "en",
                  disa: true,
                },
                vlocale: "",
                vdescription: "",
                vshort_description: "",
              },
              {
                vlocale1: {
                  text: "Arabic",
                  value: "ar",
                  disa: true,
                },
                vlocale: "",
                vdescription: "",
                vshort_description: "",
              },
            ],
            offerHide: false,
            offerBDel: {
              type: "fixed_price",
              amount: "",
              startdate: "",
              enddate: "",
              usernumbers: "",
            },
            offer: {
              type: "fixed_price",
              amount: "",
              startdate: "",
              enddate: "",
              usernumbers: "",
            },
            vimages: [
              {
                vname: "",
              },
            ],
            vimages2: [
              {
                vname: "",
              },
            ],
            vimages3: [
              {
                vname: [],
              },
            ],
          });
        }
        return ans;
      } else {
        var ans = [];
        // recur with the rest of the array.
        var otherCases = this.getCombn(arr.slice(1));
        for (var i = 0; i < otherCases.length; i++) {
          for (var j = 0; j < arr[0].length; j++) {
            ans.push(arr[0][j] + " - " + otherCases[i]);
          }
        }
        this.warrantySelect.length = 0;
        this.varints.length = 0;
        this.warrantyDurationSelect.length = 0;
        for (let ind in ans) {
          this.warrantySelect.push();
          this.warrantyDurationSelect.push();
          this.varints.push({
            vname: ans[ind],
            vquantity: "1",
            voriginal_price: "",
            salePrice: "",
            vis_new: "new",
            warranty: {
              type: null,
              duration: null,
              end_date: null,
              agency_file: null,
            },
            amountPriceDis: true,
            report_serial_number: "",
            vtranslations: [
              {
                vlocale1: {
                  text: "English",
                  value: "en",
                  disa: true,
                },
                vlocale: "",
                vdescription: "",
                vshort_description: "",
              },
              {
                vlocale1: {
                  text: "Arabic",
                  value: "ar",
                  disa: true,
                },
                vlocale: "",
                vdescription: "",
                vshort_description: "",
              },
            ],
            offerHide: false,
            offerBDel: {
              type: "fixed_price",
              amount: "",
              startdate: "",
              enddate: "",
              usernumbers: "",
            },
            offer: {
              type: "fixed_price",
              amount: "",
              startdate: "",
              enddate: "",
              usernumbers: "",
            },
            vimages: [
              {
                vname: "",
              },
            ],
            vimages2: [
              {
                vname: "",
              },
            ],
            vimages3: [
              {
                vname: [],
              },
            ],
          });
        }
        return ans;
      }
    },
    // make combinations to get values of variants
    getCombn2(arr) {
      if (arr.length == 1) {
        var ans = [];
        for (let ind in arr[0]) {
          ans.push(arr[0][ind]);
        }
        for (let inde in ans) {
          this.varints[inde].values = [ans[inde]];
        }
        return ans;
      } else {
        var ans = [];
        // recur with the rest of the array.
        var otherCases = this.getCombn2(arr.slice(1));
        for (var i = 0; i < otherCases.length; i++) {
          for (var j = 0; j < arr[0].length; j++) {
            ans.push(arr[0][j] + "-" + otherCases[i]);
          }
        }
        for (let ind in ans) {
          this.varints[ind].values = ans[ind];
          for (let valu in this.valuesSelect) {
            if (
              !Array.isArray(this.valuesSelect[valu]) &&
              this.valuesSelect[valu].is_variant == false
            ) {
              this.varints[ind].values += "-" + this.valuesSelect[valu].value;
            }
          }
          //console.log(this.varints[ind].values);
        }
        return ans;
      }
    },

    // show offer in variants
    showOffer(index) {
      const oP = parseFloat(this.varints[index].voriginal_price);
      const sP = parseFloat(this.varints[index].salePrice);
      if (
        this.varints[index].salePrice > 0 &&
        this.varints[index].salePrice != "" &&
        this.varints[index].salePrice != null &&
        oP >= sP
      ) {
        this.varints[index].offerHide = true;
        this.varints[index].offerBDel.amount =
          this.varints[index].voriginal_price - this.varints[index].salePrice;
        this.varints[index].offerBDel.type = "fixed_price";
      } else {
        this.varints[index].offerHide = false;
        (this.varints[index].offerBDel.amount = ""),
          (this.varints[index].offerBDel.type = ""),
          (this.varints[index].offerBDel.startdate = "");
        this.varints[index].offerBDel.enddate = "";
        this.varints[index].offerBDel.usernumbers = "";
      }
    },
    // return vendor commission rate
    fetchRateData() {
      // axios.get('vendors/' + this.vendorSelect.value + "/show").then((result) => {
      //     const data = result.data.data
      //     this.vendorCommissionRate = data.commission.rate
      // }).catch((err) => {
      //     this.errors_back.length = 0
      //     if (err.response.data.data != null) {
      //         if (this.isString(err.response.data.data)) {
      //             this.errors_back.push({
      //                 error: err.response.data.data
      //             })
      //         } else {
      //             this.errors_back = err.response.data.data
      //         }
      //         this.showDismissibleAlert = true
      //     } else {
      //         this.errors_back = []
      //         this.errors_back.push({
      //             error: 'internal server error'
      //         })
      //         this.showDismissibleAlert = true
      //     }
      // })
    },
    // when change amount of offer
    changeAmount(index) {
      const oP = parseFloat(this.varints[index].voriginal_price);
      const aP = parseFloat(this.varints[index].offerBDel.amount);
      if (
        this.varints[index].offerBDel.amount > 0 &&
        this.varints[index].offerBDel.amount != "" &&
        this.varints[index].offerBDel.amount != null &&
        oP > aP
      ) {
        this.varints[index].offerHide = true;
        let price =
          parseFloat(this.varints[index].voriginal_price) -
          parseFloat(this.varints[index].offerBDel.amount);
        let profit = (price * this.vendorCommissionRate) / 100;
        this.varints[index].salePrice = price + profit;
        this.varints[index].offerBDel.type = "fixed_price";
      } else {
        (this.varints[index].offerBDel.amount = ""),
          (this.varints[index].offerBDel.type = "");
        this.varints[index].offerBDel.startdate = "";
        this.varints[index].offerBDel.enddate = "";
        this.varints[index].offerBDel.usernumbers = "";
        this.varints[index].offerHide = false;
        let price = parseFloat(this.varints[index].voriginal_price);
        let profit = (price * this.vendorCommissionRate) / 100;
        this.varints[index].salePrice = price + profit;
      }
    },
    // when change original price of vaiants
    changeOP(index) {
      if (
        this.varints[index].voriginal_price > 0 &&
        this.varints[index].voriginal_price != "" &&
        this.varints[index].voriginal_price != null
      ) {
        this.varints[index].amountPriceDis = false;
        let price = parseFloat(this.varints[index].voriginal_price);
        let profit = (price * this.vendorCommissionRate) / 100;
        this.varints[index].salePrice = price + profit;
        this.varints[index].offerBDel.type = "fixed_price";
      } else {
        this.varints[index].amountPriceDis = true;
        (this.varints[index].amount = ""),
          (this.varints[index].offerBDel.amount = ""),
          (this.varints[index].offerBDel.type = ""),
          (this.varints[index].offerBDel.startdate = "");
        this.varints[index].offerBDel.enddate = "";
        this.varints[index].offerBDel.usernumbers = "";
        this.varints[index].salePrice = "";
        this.varints[index].amountPriceDis = false;
        let price = parseFloat(this.varints[index].voriginal_price);
        let profit = (price * this.vendorCommissionRate) / 100;
        this.varints[index].salePrice = price + profit;
      }
    },
    taxonomyrepeateAgain() {
      //    this.refheightForm = this.$refs.Taxonomiesform
      this.product.attributes.push({
        id: "",
        values: [],
      });
      this.combTextArr.push([]);
      this.attrSelect.push({});
      this.valuesOptions.push([
        {
          value: "select_value",
          text: "Select Value",
        },
      ]);
      this.valuesSelect.push();
      this.valuesID.push();
    },
    taxonomyremoveItem(index) {
      if (this.product.attributes.length <= 1) {
        this.$bvModal.msgBoxConfirm("You need minmum one tab to continue.", {
          title: "Alert",
          size: "sm",
          okVariant: "danger",
          okTitle: "Accept",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        });
      } else {
        this.valuesOptions[index].length = 0;
        this.combTextArr.splice(index, 1);
        this.product.attributes.splice(index, 1);
        this.attrSelect.splice(index, 1);
        this.valuesSelect.splice(index, 1);
        this.valuesID.splice(index, 1);
      }
    },
    pricesremoveItem(index) {
      if (this.varints.length <= 1) {
        this.$bvModal.msgBoxConfirm("You need minmum one tab to continue.", {
          title: "Alert",
          size: "sm",
          okVariant: "danger",
          okTitle: "Accept",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        });
      } else {
        this.varints.splice(index, 1);
        this.vConditionSelect.splice(index, 1);
      }
    },
    addVariant(index) {
      //   this.$refs.element[index].validate().then((success) => {
      //     if (success) {
      //   for (let ind in this.varints[index].vtranslations) {
      //     this.varints[index].vtranslations[ind].locale = this.varints[
      //       index
      //     ].vtranslations[ind].locale1.value;
      //   }
      var vria = {
        original_price: this.varints[index].voriginal_price,
        quantity: this.varints[index].vquantity,
        product_id: this.id,
        images: [],
        values: this.varints[index].values
          .split("-")
          .map((item) => item.trim()),
        // translations: this.varints[index].vtranslations,
        offer: {
          type: this.varints[index].offerBDel.type,
          startdate: this.varints[index].offerBDel.startdate,
          enddate: this.varints[index].offerBDel.enddate,
          usernumbers: this.varints[index].offerBDel.usernumbers,
          amount: this.varints[index].offerBDel.amount,
        },
      };
      if (
        vria.offer.amount == null ||
        vria.offer.amount == 0 ||
        vria.offer.amount == ""
      ) {
        delete vria.offer;
      }
      for (let inde in this.varints[index].vimages2) {
        if (this.varints[index].vimages2[inde].vname != "") {
          vria.images.push({
            name: this.varints[index].vimages2[inde].vname,
          });
          if (vria.images[inde].name.includes(",")) {
            vria.images[inde].name = vria.images[inde].name
              .split(",")
              .map((item) => item.trim());
            vria.images[inde].name = vria.images[inde].name[1];
          }
        }
      }
      if (vria.images == []) {
        delete vria.images;
      }
      axios
        .post("products/" + this.id + "/variant-store", vria)
        .then((result) => {
          this.$swal({
            position: "center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          this.$swal({
            position: "center",
            icon: "error",
            title: "Error!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
      // }
      //   });
    },
    vTransRepeateAgain(item) {
      item.vtranslations.push({
        locale1: {
          text: "",
          value: "",
        },
        locale: "",
        description: "",
        short_description: "",
      });
    },
    vTransRemoveItem(index, item) {
      if (item.vtranslations.length <= 1) {
        this.$bvModal.msgBoxConfirm("You need minmum one tab to continue.", {
          title: "Alert",
          size: "sm",
          okVariant: "danger",
          okTitle: "Accept",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        });
      } else {
        item.vtranslations.splice(index, 1);
      }
    },
    vImagesRepeateAgain(item) {
      //  this.refheightForm = this.$refs.pricesForm
      item.vimages.push({
        vname: [],
      });
      item.vimages2.push({
        vname: [],
      });
    },
    vImagesRemoveItem(index, item) {
      if (item.vimages2.length <= 1) {
        this.$bvModal.msgBoxConfirm("You need minmum one tab to continue.", {
          title: "Alert",
          size: "sm",
          okVariant: "danger",
          okTitle: "Accept",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        });
      } else {
        item.vimages2.splice(index, 1);
        item.vimages.splice(index, 1);
      }
    },
    // upload function vimages of item
    uploadImage3(event, item, index) {
      let files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        item.vimages2[index].vname = [];
        return;
      } else {
        this.createImage3(files[0], index, item);
      }
    },
    createImage3(file, ind, item) {
      let reader = new FileReader();
      reader.onload = (e) => {
        item.vimages2[ind].vname = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    getVariantTitles() {
      axios
        .get("products/" + this.id + "/variants-names/show")
        .then((result) => {
          const data = result.data.data;
          //console.log(data);
          for (let index in data.variantsName) {
            this.oldVariantTitle.push(data.variantsName[index]);
          }
          for (let index in data.variantsId) {
            this.oldVariantId.push(data.variantsId[index]);
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    getAttrValue() {
      axios
        .get("products/items/" + this.itemId + "/valid-data" + '/' + this.product.is_portal ? 'portal/get' : 'instore/get')
        .then((result) => {
          const data = result.data.data.attributes;
          this.attrOptions.length = 0;
          this.attrSelect.length = 0;
          this.product.attributes.length = 0;
          this.valuesOptions.length = 0;
          this.combTextArr.length = 0;
          this.combValuesArr.length = 0;
          this.attr2Options = null;
          if (data.length > 0) {
            for (let attr in data) {
              if (data[attr].translation != null) {
                this.attrOptions.push({
                  value: data[attr].id.toString(),
                  text: data[attr].translation.name,
                  is_variant: data[attr].is_variant,
                });
                this.attrSelect.push({
                  value: data[attr].id.toString(),
                  text: data[attr].translation.name,
                  is_variant: data[attr].is_variant,
                });
                this.product.attributes.push({
                  id: "",
                  values: [],
                });
                this.valuesOptions.push([]);
                for (let val in data[attr].values) {
                  this.valuesOptions[attr].push({
                    text: data[attr].values[val].translation.name,
                    value: data[attr].values[val].id.toString(),
                    is_variant: data[attr].is_variant,
                  });
                }
                // if (data[attr].values.length == 1) {
                //   for (let val in data[attr].values) {
                //     this.valuesSelect.push([]);
                //     this.valuesSelect[attr].push({
                //       text: data[attr].values[val].translation.name,
                //       value: data[attr].values[val].id.toString(),
                //       is_variant: data[attr].is_variant,
                //     });
                //   }
                // } else {
                this.valuesSelect.push([]);
                // }
              }
              if (data[attr].is_variant) {
                this.combTextArr.push([]);
                this.combValuesArr.push([]);
              }
            }
            this.attr2Options = this.attrOptions;
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
  async created() {
    this.id = this.$route.params.id;
    await this.getProduct() 
    // this.fetchData();
    this.getVariantTitles();

    await axios
      .get("products/" + this.id)
      .then((result) => {
        //console.log(result);
        this.itemId = result.data.data.phoneGuide.id;
        this.vendorId = result.data.data.vendor.id;
        this.getAttrValue();
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
   
  },
};
</script>
<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.repeater-form {
  transition: 0.35s height;
}
</style>
