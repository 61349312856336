<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , indexVal)  in values" :key="indexVal">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-card title="Buyer's Guide">

 
      <b-row  class="w-100 my-2 p-2  align-items-center" >
          <!-- Active -->
          <b-col cols="6" sm="4" md="1" class="d-flex align-items-center mb-2 mb-md-0">
            <b-form-group 
              label="Active"
              label-for="active"
              label-size="sm"
              class="mb-0 w-100"
            >
              <v-select 
                size="sm"
                id="active" 
                placeholder="Active" 
                v-model="activeSelect"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="activeOptions"
                :selectable="option => !option.value.includes('select_value')" 
                label="text"
                class="form-control-sm"
              />
            </b-form-group>
          </b-col>

          <!-- Status -->
          <b-col cols="6" sm="4" md="2" class="d-flex align-items-center mb-2 mb-md-0">
            <b-form-group 
              label="Status"
              label-for="status"
              label-size="sm"
              class="mb-0 w-100"
            >
              <v-select 
                id="status" 
                placeholder="Status"
                v-model="statusSelect"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :selectable="option => !option.value.includes('select_value')" 
                label="text"
                class="form-control-sm"
              />
            </b-form-group>
          </b-col>

          <!-- Model Number -->
          <b-col cols="6" sm="4" md="2" class="d-flex align-items-center mb-2 mb-md-0">
            <b-form-group 
              label="Model Number"
              label-for="modelNumber"
              label-size="sm"
              class="mb-0 w-100"
            >
              <v-select 
                id="modelNumber" 
                placeholder="Model"
                v-model="modelNumberSelect"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="modelNumberOptions"
                :selectable="option => !option.value.includes('select_value')" 
                label="text"
                class="form-control-sm"
              />
            </b-form-group>
          </b-col>

          <!-- Is Guide -->
          <b-col cols="6" sm="4" md="2" class="d-flex align-items-center mb-2 mb-md-0">
            <b-form-group 
              label="Guide"
              label-for="isGuide"
              label-size="sm"
              class="mb-0 w-100"
            >
              <v-select 
                id="isGuide" 
                placeholder="Guide"
                v-model="IsGuideSelect"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="IsGuideOptions"
                :selectable="option => !option.value.includes('select_value')" 
                label="text"
                class="form-control-sm"
              />
            </b-form-group>
          </b-col>

          <!-- Category -->
          <b-col cols="6" sm="4" md="2" class="d-flex align-items-center mb-2 mb-md-0">
            <b-form-group 
              label="Category"
              label-for="Category"
              label-size="sm"
              class="mb-0 w-100"
            >
              <v-select
                id="Category"
                placeholder="Category"
                v-model="categorySelect"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categoryOptions"
                :selectable="option => !option.value.includes('select_value')"
                label="text"
                class="form-control-sm"
              />
            </b-form-group>
          </b-col>

          <!-- Brand -->
          <b-col cols="6" sm="4" md="2" class="d-flex align-items-center mb-2 mb-md-0">
            <b-form-group 
              label="Brand"
              label-for="Brand"
              label-size="sm"
              class="mb-0 w-100"
            >
              <v-select
                id="Brand"
                placeholder="Brand"
                v-model="brandSelect"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="brandOptions"
                :selectable="option => !option.value.includes('select_value')"
                label="text"
                class="form-control-sm"
              />
            </b-form-group>
          </b-col>

        </b-row>


     <!-- filter -->
     <b-row class="ml-2">

      <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group class="d-flex" id="checkbox-group-2" button-variant="outline-primary"
              :aria-describedby="ariaDescribedby" name="flavour-2">
              <b-form-radio class="ml-1" v-model="searchType" :aria-describedby="ariaDescribedby"
                name="some-radios" value="normal">Name Filter </b-form-radio>
              <b-form-radio class="ml-1" v-model="searchType" :aria-describedby="ariaDescribedby"
                name="some-radios" value="detials">Detials Filter </b-form-radio>
            </b-form-checkbox-group>
      </b-form-group>

        <b-col md="3" class="my-1">
          <b-form-group
            v-if="searchType == 'detials'"
            label="Filter In Details"
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search in all Data"
              />
              <!-- <b-input-group-append>
                <b-button variant="primary" @click="searchData()">
                  Search
                </b-button>
              </b-input-group-append> -->
            </b-input-group>
          </b-form-group>


          <b-form-group
            v-else
            label="Filter"
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <!-- <b-input-group-append>
                <b-button variant="primary" @click="searchData()">
                  Search
                </b-button>
              </b-input-group-append> -->
            </b-input-group>
          </b-form-group>
        </b-col>

          <!-- sorting -->
          <b-col md="3" class="my-1">
          <b-form-group
            label="Sort By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

 
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height: 2.142rem; line-height: 0.5"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              @click="removeExitem"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content>
                <feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
              >
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>

          <!-- add new brand -->
          <div class="d-flex align-items-center justify-content-end">
          <!-- <b-button
            style="height: 2.142rem; line-height: 0.5"
            v-if="authPermissions.includes('create-phone-guide')"
            variant="primary"
            :to="{ path: '/CreateItem' }"
          >
            <span class="text-nowrap">Create Item</span>
          </b-button> -->
          <b-button 
              variant="outline-primary" 
              size="sm" 
              class="w-100 "
              @click="fetchData"
            >
              Apply Filter
            </b-button>
        </div>
      </b-row>

      <b-row>
     


        <!-- start data table -->
        <b-col cols="12">
          <b-table
            id="ItemTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            :busy.sync="isBusy"
            class="position-relative"
            :items="items"
            :fields="fields"
          >
            <template #cell(cover)="data">
              <!-- <b-avatar :src="data.item.cover"/> -->
              <b-img-lazy
                rounded="circle"
                width="50"
                height="50"
                :src="data.item.cover"
              />
            </template>
            <template #cell(actions)="items">
              <div>
                <!-- show button item -->
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  :to="{ path: '/ShowPhoneGuide/' + items.item.id }"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon
                    style="width: 18px; height: 18px"
                    icon="EyeIcon"
                    class="align-middle"
                  />
                </b-button>
                <!-- delete button item -->
                <!-- <b-button style='padding:7px; margin:6px; width:35px; height:35px;' v-if="authPermissions.includes('delete')" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" type="submit" @click="deleteData(items.item.id)" class="btn-icon rounded-circle">
                                    <feather-icon icon="Trash2Icon" style="width:18px; height:18px;" class="align-middle"/>
                                </b-button> -->
              </div>
            </template>
            <template #cell(name)="items">
              <span v-if="items.item.item_translations"> {{ items.item.item_translations.name }}</span> 
            </template>
            <template #cell(is_guide)="is_guide">
              <b-badge :variant="is_guide.value ? 'success' : 'danger'" pill>
                {{ is_guide.value ? 'True' : 'False' }}</b-badge
              >
            </template>
            <template #cell(active)="data">
              <b-badge :variant="data.value ? 'success' : 'danger'" pill>
                {{ data.value ? 'True' : 'False' }}</b-badge
              >
            </template>
            <template #cell(status)="data">
              <b-badge variant="primary" pill> {{ data.value }}</b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <!-- <b-pagination
            @input="fetchData()"
            aria-controls="CategoryTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          /> -->

          <b-pagination
            @input="changePage"
            aria-controls="CategoryTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      authPermissions: [],
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      // filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      // table fields
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'cover',
          label: 'Cover',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'active',
          label: 'Active',
        },
        {
          key: 'status',
          label: 'status',
        },
        {
          key: 'is_guide',
          label: 'Is Guide',
        },

        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },
        'actions',
      ],
      items: [],
      exportItems: [],
      filterOptins: { 
        activeSelect: {value: null},
        statusSelect: {value: null},
        IsGuideSelect: {value: null},
        categorySelect: {value: null},
        brandSelect: {value: null}
      },
      activeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '0',
          text: 'False',
        },
        {
          value: '1',
          text: 'True',
        },
      ],
      statusOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'enabled',
          text: 'enabled',
        },
        {
          value: 'disabled',
          text: 'disabled',
        },
      ],
      modelNumberOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'has_model_num',
          text: 'has model num',
        },
        {
          value: 'not_has_model_num',
          text: 'not has model num',
        },
      ],
      IsGuideOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '0',
          text: 'False',
        },
        {
          value: '1',
          text: 'True',
        },
      ],

      // searchType: 'normal', 
      categoryOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      brandOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      currentPage: 1, // Default to page 1
      // rows: 1, // Example total rows
      perPage: 10 // Example per page

      
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData();
    this.getCategories();
    this.getbrands();
  },
  computed: {
    // Accessing state directly using this.$store.state.filter
   // Getter and Setter for statusSelect
   activeSelect: {
    get() {
      return this.$store.state.filter.activeSelect;
    },
    set(value) {
      this.$store.dispatch('filter/updateFilterOption', {
        filterType: 'activeSelect',
        value,
      });
    },
  },
   statusSelect: {
    get() {
      return this.$store.state.filter.statusSelect;
    },
    set(value) {
      this.$store.dispatch('filter/updateFilterOption', {
        filterType: 'statusSelect',
        value,
      });
    },
  },
  modelNumberSelect: {
    get() {
      return this.$store.state.filter.modelNumberSelect;
    },
    set(value) {
      this.$store.dispatch('filter/updateFilterOption', {
        filterType: 'modelNumberSelect',
        value,
      });
    },
  },


  

  // Getter and Setter for IsGuideSelect
  IsGuideSelect: {
    get() {
      return this.$store.state.filter.IsGuideSelect;
    },
    set(value) {
      this.$store.dispatch('filter/updateFilterOption', {
        filterType: 'IsGuideSelect',
        value,
      });
    },
  },

  // Getter and Setter for categorySelect
  categorySelect: {
    get() {
      return this.$store.state.filter.categorySelect;
    },
    set(value) {
      this.$store.dispatch('filter/updateFilterOption', {
        filterType: 'categorySelect',
        value,
      });
    },
  },

  // Getter and Setter for brandSelect
  brandSelect: {
    get() {
      return this.$store.state.filter.brandSelect;
    },
    set(value) {
      this.$store.dispatch('filter/updateFilterOption', {
        filterType: 'brandSelect',
        value,
      });
    },
  },
    // Getter and Setter for fitler 
  filter: {
    get() {
      return this.$store.state.filter.filter;
    },
    set(value) {
      this.$store.dispatch('filter/updateFilterOption', {
        filterType: 'filter',
        value,
      });
    },
  },
  searchType: {
    get() {
      return this.$store.state.filter.searchType;
    },
    set(value) {
      this.$store.dispatch('filter/updateFilterOption', {
        filterType: 'searchType',
        value,
      });
    },
  },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
  },
  watch: {
    '$route.query.page'(newPage) {
      this.currentPage = newPage ? parseInt(newPage) : 1;
    },
    sortDesc: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    sortBy: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    filter: function () {
      if (this.filter == '' || this.filter == null) {
        this.fetchData()
      }
    },
  },
  mounted() 
  {
    // Set the current page from the query parameter when the component is mounted
    if (this.$route.query.page) {
      this.currentPage = parseInt(this.$route.query.page);
    }
  },
  methods: {
    updateFilterOption(filterType, value) {
      this.$store.dispatch('filter/updateFilterOption', {
        filterType,
        value,
      });
    },
    // fetch data of items
    fetchData() {
      this.showDismissibleAlert = false
      // request phones guide index API
      var url = null
      // if (this.filter == '') {
      //   url =
      //     'items-guide-pagi/' +
      //     this.perPage.toString() +
      //     '/sortBy-' +
      //     this.sortBy +
      //     '-' +
      //     this.sortDirection +
      //     '?page=' +
      //     this.currentPage
      // }else {
        url =
          'items-guide-pagi/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.searchType +
          '/' +
          this.filter +
          '?page=' +
          this.currentPage
      // } 
      let {filterOptins} = this;

      filterOptins = {
        activeSelect: this.activeSelect,
        statusSelect: this.statusSelect,
        IsGuideSelect: this.IsGuideSelect,
        categorySelect: this.categorySelect,
        brandSelect: this.brandSelect,
        modelNumberSelect: this.modelNumberSelect
      }

      const promise = axios.post(url , { filterOptins })
      return promise
        .then((result) => {
          this.totalRows = result.data.data.meta.total
          this.items.length = 0

          this.items = result.data.data.data

          this.exportItems = this.items

          this.$refs.admint.refresh()
          return this.items
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    searchData() {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      
      this.fetchData()
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    // sort function

    // delete function to delete item
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete item.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('items-guide/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    removeExitem() {
      for (let ex in this.exportItems) {
        delete this.exportItems[ex].cover
      }
    },
    genPDF() {
      const { jsPDF } = require('jspdf')
      const doc = new jsPDF()
      let print_body = []
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].name,
          this.items[index].active,
          this.items[index].created_at,
          this.items[index].updated_at,
        ]
      }
      doc.autoTable({
        head: [['ID', 'Name', 'Active', 'CREATED_AT', 'UPDATED_AT']],
        body: print_body,
      })
      doc.save('table.pdf')
    },
    getCategories(){
      axios
      .get('categories')
      .then((result) => {
        const data = result.data.data
        if (data.length > 0) {
          for (let ind in data) {
            if (data[ind].translation != null) {
              this.categoryOptions.push({
                text: data[ind].translation.name,
                value: data[ind].id.toString(),
              })
            } else {
              this.categoryOptions.push({
                text: 'undefinded',
                value: data[ind].id.toString(),
              })
            }
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
        }
      })
    },
    getbrands(){
      axios
      .get('brands')
      .then((result) => {
        const data = result.data.data
        if (data.length > 0) {
          for (let ind in data) {
            if (data[ind].translation != null) {
              this.brandOptions.push({
                text: data[ind].translation.name,
                value: data[ind].id.toString(),
              })
            } else {
              this.brandOptions.push({
                text: 'undefinded',
                value: data[ind].id.toString(),
              })
            }
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
        }
      })
    },
    changePage(page) {
      if (this.$route.query.page != page) {
        this.$router.push({ query: { ...this.$route.query, page } });
      }
      this.currentPage = page;
      this.fetchData();
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
