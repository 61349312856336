import Vue from 'vue'; // Vue 2 doesn't require this if it's already globally installed, but it's safe to keep

export  default  {
  namespaced: true, // Enable namespacing

  state: {
    activeSelect: { value: null },
    statusSelect: { value: null },
    modelNumberSelect: { value: null },
    IsGuideSelect: { value: null },
    categorySelect: { value: null },
    brandSelect: { value: null },
    filter: null,
    searchType: 'normal'
  },

  mutations: {
    SET_FILTER_OPTION(state, { filterType, value }) {
      // We use Vue.set to ensure reactivity in Vue 2
      Vue.set(state, filterType, value);
    },
  },

  actions: {
    updateFilterOption({ commit }, payload) {
      commit('SET_FILTER_OPTION', payload);
    },
  },

  getters: {
    filterOptions(state) {
      return state;
    },
  },
};
