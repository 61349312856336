<template>
  <div @click="closeOpenSearch" class="store-order">

    <b-row>
      <b-col md="9">

        <form-wizard color="#08308B" :title="null" :subtitle="null" layout="vertical" finish-button-text="Submit"
          back-button-text="Previous" next-button-text="Next" class="wizard-vertical mb-3" @on-complete="formSubmitted">


          <tab-content :before-change="validationStep1Form">
            <validation-observer ref="Step1Rules">
              <b-card title="Products">
                <b-row>
                  <b-col cols="12">
                    <div style="position: relative">
                      <b-row>
                        <b-col cols="7">
                          <b-form-input id="searchText" class="mt-2" style="height: 37.45px" type="search"
                            :state="orderData.variants.length == 0 ? false : true" v-model="searchText"
                            placeholder="Search For Add Product" @click="ShowVariantResultIfExists" />
                        </b-col>
                        <b-col cols="2">
                          <b-button @click="searchResultsubmit(searchText)" class="mt-2"
                            variant="outline-primary">Search</b-button>
                        </b-col>
                        <b-col cols="2" class="mt-2" v-if="noResult">
                          <b-alert variant="warning" style="" :show="noResult">
                            <div class="alert-body">No Result Found.</div>
                          </b-alert>
                        </b-col>

                        <b-col class="mt-2" style="
                              position: absolute;
                              z-index: 9999;
                              padding: 0.438rem 1rem;
                              top: 38px;
                              height: 380px !important;
                              overflow: scroll;
                              scroll-padding: 0px !important;
                              padding-right: 0;
                              background-color: #fff;
                            " v-if="resultShow" cols="12">
                          <b-list-group>
                            <b-list-group-item @click="
                              addProduct(
                                item
                              )
                              " v-for="(item, index) in productsSR" :key="index">
                              <b-row>
                                <b-col md="1">
                                  <b-img-lazy width="auto" height="55" :src="item.cover" class="ml-1" />

                                </b-col>
                                <b-col md="11">
                                  <feather-icon icon="" class="mr-75" />
                                  <p style="font-weight: 600">{{ item.name }}</p>
                                  <div class="d-inline">
                                    <small style="padding-left: 10px">Price: {{ item.price }}</small>
                                    <small style="padding-left: 10px">SKU: {{ item.sku }}</small>
                                    <small style="padding-left: 10px">Storename: {{ item.vendor_storename }}</small>
                                  </div>

                                </b-col>
                              </b-row>
                            </b-list-group-item>
                            <infinite-loading @infinite="searchResult"></infinite-loading>
                          </b-list-group>
                        </b-col>

                        <b-col cols="12">
                          <h2 v-if="orderData.variants.length" class="mr-3 pt-2">
                            Items List:
                          </h2>

                          <div v-if="orderData.variants.length">
                            <b-row v-for="(item, index) in orderData.variants" :key="index">
                              <b-col cols="12">
                                <hr />
                              </b-col>

                              <b-img-lazy width="auto" height="55" :src="item.cover" class="ml-1" />
                              <b-col class="pl-5" cols="6">
                                <h4>{{ item.name }}</h4>
                                <small style="padding-left: 10px">Sku: {{ item.sku }}</small>
                                <small style="padding-left: 10px">Price: {{ item.price }}</small>
                                <small style="padding-left: 10px">Storename: {{ item.vendor_storename }}</small>
                              </b-col>
                              <b-col md="2">
                                <b-form-group label="Quantity" label-for="Quantity">
                                  <validation-provider #default="{ errors }" name="Quantity">
                                    <b-form-input min="1" id="Quantity" type="number"
                                      :disabled="item.max_qun == item.quantity" :max="item.max_qun"
                                      v-model="item.quantity" @input="recalcCost()" :state="errors.length > 0 ? false : null
                                        " placeholder="15" />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>

                              <b-col md="3" class="mb-50">
                                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger"
                                  class="mt-0 mt-md-2" @click="removeItem(index)">
                                  <feather-icon icon="XIcon" class="mr-25" />
                                  <span>Remove</span>
                                </b-button>
                              </b-col>
                              <b-col cols="12">
                                <hr />
                              </b-col>
                            </b-row>
                          </div>

                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </validation-observer>
          </tab-content>
          <tab-content :before-change="validationStep2Form">
            <validation-observer ref="simpleRules">

              <b-card title="Product List" class="p-0">
                <b-col v-if="orderData.variants.length" class="pt-1 pr-2 pl-2" cols="12">


                  <div v-if="orderData.variants.length">
                    <b-row v-for="(item, index) in orderData.variants" :key="index">

                      <b-img-lazy width="auto" height="55" :src="item.cover" class="ml-1" />
                      <b-col class="pl-2" cols="7">
                        <h4>{{ item.name }}</h4>
                        <small style="padding-left: 10px">Sku: {{ item.sku }}</small>
                        <small style="padding-left: 10px">Price: {{ item.price }}</small>
                        <small style="padding-left: 10px">Quantity: {{ item.quantity }}</small>
                        <small style="padding-left: 10px">Storename: {{ item.vendor_storename }}</small>
                      </b-col>

                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-card>
              <b-row>
                <b-col cols="12">
                  <b-card title="Order Data">
                    <b-row>

                      <b-col md="6">
                        <validation-provider #default="{ errors }" name="payment_provider" rules="required">
                          <b-form-group label="Payment Provider" label-for="payment_provider">
                            <v-select v-model="selectedPaymentProvider"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" value="id"
                              :state="errors.length > 0 ? false : null"
                              :options="paymentProviderOptions.map(payment_provider => ({ id: payment_provider.id, name: payment_provider.name, icon: payment_provider.icon, code: payment_provider.code }))" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col md="6">
                        <validation-provider #default="{ errors }" name="order_type" rules="required">
                          <b-form-group label="Type" label-for="order_type">
                            <v-select disabled v-model="orderData.type"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="orderTypeOptions"
                              :state="errors.length > 0 ? false : null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col md="12" v-if="orderData.type == 'cash-on-delivery'">
                        <span class="mr-1" style="color: #FF875C;font-weight: bold;">Pay with an digital wallet
                          {{ this.orderData.remaining_amount }}
                        </span>
                        <b-row>
                          <b-col md="6">
                            <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">


                              <b-row>
                                <validation-provider rules="required" name="Payment Method" v-slot="{ errors }">
                                  <b-col md="12" class="mt-1 d-flex">
                                    <b-row>
                                      <b-col md="12" class=" d-flex">
                                        <b-form-checkbox v-model="downPaymentMethod" value="digital-wallet">
                                        </b-form-checkbox>
                                        <span class="mr-1" style="color: #1F1F1F">Pay with an digital wallet</span>
                                        <img width="35" :src="require('@/assets/images/icons/walet_icon_svg.svg')" />
                                      </b-col>
                                      <b-col md="12" v-if="downPaymentMethod == 'digital-wallet'">

                                        <b-form-group label="Wallet Number" label-for="Wallet Number">
                                          <validation-provider #default="{ errors }" name="Wallet Number"
                                            rules="required|integer|egyptMobile">
                                            <b-form-input id="wallet_number" v-model="orderData.wallet_number"
                                              :state="errors.length > 0 ? false : null" placeholder="015XXXXXXXX" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </validation-provider>
                                        </b-form-group>
                                      </b-col>


                                    </b-row>
                                  </b-col>
                                  <b-col md="12" class="mb-1 d-flex">

                                    <b-form-checkbox v-model="downPaymentMethod" value="visa">
                                      <span class="mr-1" style="color: #1F1F1F">Pay with Bank Card</span>
                                      <img width="35" :src="require('@/assets/images/icons/visa.svg')" />

                                    </b-form-checkbox>
                                  </b-col>

                                  <b-col md="12" class="d-flex">
                                    <b-row>
                                      <b-col md="12" class="mb-1  d-flex">
                                        <b-form-checkbox v-model="downPaymentMethod" value="instapay">
                                          <span class="mr-1" style="color: #1F1F1F">Pay with InstaPay</span>
                                        </b-form-checkbox>
                                      </b-col>
                                      <b-col md="12" class="mb-1  d-flex">
                                        <b-form-checkbox v-model="downPaymentMethod" value="other">
                                          <span class="mr-1" style="color: #1F1F1F">Pay with Other</span>
                                        </b-form-checkbox>
                                      </b-col>
                                      <b-col md="10"
                                        v-if="downPaymentMethod == 'other' || downPaymentMethod == 'instapay'">
                                        <b-form-group label="Upload File" label-size="sm">
                                          <validation-provider #default="{ errors }" name="Upload File">
                                            <b-form-file @change="fileUpload" :state="errors.length > 0 ? false : null"
                                              id="order_attachment" size="sm"></b-form-file>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </validation-provider>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-row>
                            </b-form-group>
                          </b-col>
                        </b-row>

                      </b-col>

                      <!-- Is Shipping Option -->
                      <b-col md="6" style="display: flex;" class="mb-2">
                        <b-form-group label="Shipping By Fedex ?" label-for="is_shipping" class="mr-4">
                          <validation-provider #default="{ errors }" name="is_shipping" rules="">
                            <b-form-checkbox switch v-model="orderData.is_shipping"
                              :disabled="orderData.type == 'cos' || orderData.type == 'cash-on-delivery'">
                              <p v-if="orderData.is_shipping">
                                Shipping the order to the customer
                              </p>
                              <p v-else>Picking the order from the store</p>
                            </b-form-checkbox>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                        <b-form-group label="Add Down Payment For Order ?" label-for="is_down_payment"
                          v-if="(selectedPaymentProvider && !(selectedPaymentProvider.code == 'cod'))">
                          <validation-provider #default="{ errors }" name="is_down_payment" rules="">
                            <b-form-checkbox switch v-model="orderData.is_down_payment">
                              <p v-if="orderData.is_downpayment">
                                Add Down Payment
                              </p>
                              <p v-else>Not have Down Payment</p>
                            </b-form-checkbox>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Down Payment -->
                      <b-col md="6"
                        v-if="(selectedPaymentProvider && !(selectedPaymentProvider.code == 'cod')) && orderData.is_down_payment">
                        <b-form-group label="Down Payment" label-for="Down Payment">
                          <validation-provider ref="downPaymentProvider" #default="{ errors }" name="Down Payment"
                            rules="required|integer">
                            <b-form-input id="down_payment" @input="validateMaxPayment()"
                              v-model="orderData.down_payment"
                              :state="((errors.length > 0) || (downPaymentValidation != null)) ? false : null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small class="text-danger" v-if="(downPaymentValidation != null)">{{ downPaymentValidation
                              }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="12"
                        v-if="(selectedPaymentProvider && !(selectedPaymentProvider.code == 'cod')) && orderData.is_down_payment">
                        <span class="mr-1" style="color: #FF875C;font-weight: bold;">Pay Down Payment
                          {{ this.orderData.down_payment }}
                        </span>
                        <b-row class=" mt-1">
                          <b-col md="6">
                            <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
                              <b-row>
                                <b-col md="12" class="d-flex">
                                  <b-row>
                                    <validation-provider rules="required" name="Payment Method" v-slot="{ errors }">
                                      <b-col md="12" class="mb-1  d-flex"
                                        v-if="(orderData.type != 'cos' && orderData.is_shipping)">
                                        <b-form-checkbox v-model="downPaymentMethod" value="cash-collect">
                                          <span class="mr-1" style="color: #1F1F1F">Pay with Cash Collect</span>
                                        </b-form-checkbox>
                                      </b-col>
                                      <b-col md="12" class="mb-1  d-flex">
                                        <b-form-checkbox v-model="downPaymentMethod" value="instapay">
                                          <span class="mr-1" style="color: #1F1F1F">Pay with InstaPay</span>
                                        </b-form-checkbox>
                                      </b-col>
                                      <b-col md="12" class="mb-1  d-flex">
                                        <b-form-checkbox v-model="downPaymentMethod" value="other">
                                          <span class="mr-1" style="color: #1F1F1F">Pay with Other</span>
                                        </b-form-checkbox>
                                      </b-col>
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    <b-col md="10"
                                      v-if="downPaymentMethod == 'other' || downPaymentMethod == 'instapay'">
                                      <b-form-group label="Upload File" label-size="sm">
                                        <validation-provider #default="{ errors }" name="Upload File">
                                          <b-form-file @change="fileUpload" :state="errors.length > 0 ? false : null"
                                            id="order_attachment" size="sm"></b-form-file>
                                          <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-col>
                        </b-row>

                      </b-col>
                      <b-col md="6" v-if="orderData.type == 'digital-wallet'">

                        <b-form-group label="Wallet Number" label-for="Wallet Number">
                          <validation-provider #default="{ errors }" name="Wallet Number"
                            rules="required|integer|egyptMobile">
                            <b-form-input id="wallet_number" v-model="orderData.wallet_number"
                              :state="errors.length > 0 ? false : null" placeholder="015XXXXXXXX" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>


                    </b-row>
                  </b-card>
                </b-col>
                <b-col cols="12">
                  <b-card title="User">
                    <b-row>
                      <b-col md="6">
                        <div style="position: relative">
                          <b-row>
                            <b-col cols="9">
                              <validation-provider #default="{ errors }" name="User" rules="required">
                                <b-form-group label="User" label-for="User" :state="errors.length > 0 ? false : null">
                                  <b-form-input id="searchUser" :state="orderData.user_id == null ? false : true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                    " v-model="searchUser" placeholder="Search For Add user" />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col cols="2">
                              <b-button class="mt-2" @click="searchUserR(searchUser, 0)"
                                variant="outline-primary">Search</b-button>
                            </b-col>
                            <b-alert variant="warning" style="margin: 0.438rem 1rem" :show="noUser">
                              <div class="alert-body">No Result Found.</div>
                            </b-alert>
                            <b-col class="mt-2" style="
                              position: absolute;
                              z-index: 9999;
                              padding: 0.438rem 1rem;
                              top: 35px;
                              max-height: 380px !important;
                              overflow-y: scroll;
                              overflow-x: hidden;
                              background-color: white;
                              scroll-padding: 0px !important;
                              padding-right: 0;
                            " v-if="userShow" cols="12">
                              <b-list-group>
                                <b-list-group-item @click="addUser(item)" v-for="(item, index) in userSR" :key="index">
                                  <feather-icon icon="" class="mr-75" />
                                  <p style="font-weight: 600">{{ item.name }}</p>
                                  <small style="padding-left: 10px">Email: {{ item.email }} - PhoneNumber:
                                    {{ item.phone }}</small>
                                </b-list-group-item>
                                <infinite-loading
                                  @infinite="searchUserR(searchUser, userCurrentPage)"></infinite-loading>
                              </b-list-group>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>

                      <!-- phone Number 1 -->
                      <b-col md="6">
                        <b-form-group label="Phone Number" label-for="Phone Number">
                          <validation-provider #default="{ errors }" name="Phone Number"
                            rules="required|integer|egyptMobile">
                            <b-form-input id="phone" v-model="orderData.phone" :state="errors.length > 0 ? false : null"
                              placeholder="015XXXXXXXX" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- User Name -->
                      <b-col md="6">
                        <b-form-group label="User Name" label-for="User Name">
                          <validation-provider #default="{ errors }" name="User Name" rules="required">
                            <b-form-input id="Name" v-model="orderData.name" :state="errors.length > 0 ? false : null"
                              placeholder="Name" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>



                      <!-- Add Address Data -->
                      <b-col cols="3" v-if="orderData.is_shipping && selectedUser != null">
                        <b-button class="mt-2" @click="modalToStoreAddress" variant="outline-warning">Add Address For
                          User</b-button>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>

      </b-col>

      <b-col md="3">
        <div class="sticky-col">
          <b-card title="Cost" class="px-0 mx-0">
            <p><span style="font-weight: bold; color: #0d6efd">Total :
              </span> {{ this.products_total }}</p>
            <p> <span style="font-weight: bold; color: #0d6efd">Shipping Price :
              </span> {{ shipping }}</p>
            <p><span style="font-weight: bold; color: #0d6efd">Total Payment Required:
              </span> {{ total }}</p>
          </b-card>

          <b-card title="Shipping Address" v-if="address && orderData.is_shipping">
            <p> Street <span style="color: #FF5103"> {{ address.street }}</span> _Building <span style="color: #FF5103">
                {{ address.building_number }}</span> _Floor <span style="color: #FF5103"> {{ address.floor_number
                }}</span> _Apartment <span style="color: #FF5103"> {{ address.flat_number }}</span>_Area <span
                style="color: #FF5103"> {{ address.region }}</span>_Town <span style="color: #FF5103"> {{ address.town
                }}</span>_Special Mark<span style="color: #FF5103"> {{ address.special_mark }}</span>_Postal Code<span
                style="color: #FF5103"> {{ address.postal_code }}</span> </p>
          </b-card>
        </div>

      </b-col>

    </b-row>
    <b-modal ref="modal-add-address-for-user" size="lg" centered title="Store Address For User">
      <validation-observer ref="observer">

        <b-row>
          <b-col cols="6">
            <validation-provider #default="{ errors }" name="governorate" rules="required">
              <b-form-group label="Governorate" label-for="governorate" :state="errors.length > 0 ? false : null">
                <v-select id="governorate" v-model="selectedCity" :state="cities == null ? false : true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cities.map(city => ({ id: city.id, name: city.name }))" label="name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

          </b-col>

          <!-- Town -->
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Town" rules="required">
              <b-form-group label="Town" label-for="Town">
                <b-form-input id="Town" v-model="addressData.town" placeholder="Town" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Area -->
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Area" rules="required">
              <b-form-group label="Area" label-for="Area">
                <b-form-input id="Area" v-model="addressData.region" placeholder="Area" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Street -->
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Street" rules="required">
              <b-form-group label="Street" label-for="Street">
                <b-form-input id="Street" v-model="addressData.street" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Special Mark -->
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Special Mark">
              <b-form-group label="Special Mark" label-for="Special Mark">
                <b-form-input id="Special Mark" v-model="addressData.special_mark" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Postal Code -->
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Postal Code">
              <b-form-group label="Postal Code" label-for="Postal Code">
                <b-form-input id="Postal Code" v-model="addressData.postal_code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Building -->
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Building" rules="required|integer">
              <b-form-group label="Building" label-for="Building">
                <b-form-input id="Building" v-model="addressData.building_number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Apartment -->
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Apartment" rules="required|integer">
              <b-form-group label="Apartment Number" label-for="Apartment">
                <b-form-input id="Apartment" v-model="addressData.flat_number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Floor -->
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Floor" rules="required|integer">
              <b-form-group label="Floor" label-for="Floor">
                <b-form-input id="Floor" v-model="addressData.floor_number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Main Address Check -->
          <b-col md="6">
            <b-form-group label="Shipping By Fedex ?" label-for="is_main_address">
              <validation-provider #default="{ errors }" name="is_main_address" rules="required">
                <b-form-checkbox disabled v-model="addressData.is_main_address">
                  <p v-if="addressData.is_main_address">

                    Make this address the main delivery address
                  </p>
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>



      <template #modal-footer>
        <b-button variant="outline-secondary" @click="closeSaveAddressModal">Cancel</b-button>
        <b-button variant="primary" @click="handleSaveAddress">Save</b-button>
      </template>
    </b-modal>


    <!-- share  -->
    <b-modal size="lg" id="modal-center" ref="show-share" hide-footer centered @hide="beforeModalClose">
      <b-card title="Order Data" v-if="storedOrderData">
        <b-row>
          <b-col md="4" class="d-flex justify-content-center mb-1">
            <qr-code :size="200" :text="paymentLink"></qr-code>
          </b-col>
          <b-col md="8">
            <b-row class="mb-2">
              <b-col cols="3" class="text-primary font-weight-bold">Code</b-col>
              <b-col cols="9" class="border border-primary rounded text-primary py-1">{{ storedOrderData.code }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3" class="text-primary font-weight-bold">Order Total</b-col>
              <b-col cols="9" class="border border-primary rounded text-primary py-1">{{ storedOrderData.total_paid
                }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3" class="text-primary font-weight-bold">Total For Pay</b-col>
              <b-col cols="9" class="border border-primary rounded text-primary py-1">{{
                OrderreturnedData.payment_data.cart_amount != 0 ? OrderreturnedData.payment_data.cart_amount :
                storedOrderData.total_paid}}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3" class="text-primary font-weight-bold">Payment Provider</b-col>
              <b-col cols="9" class="border border-primary rounded text-primary py-1">{{
                OrderreturnedData.payment_provider.name}}</b-col>
            </b-row>

          </b-col>
          <b-col cols="12">
            <p class="text-primary mb-0">Transaction Payment Link</p>
            <div class="d-flex justify-content-start">
              <p id="myInput" v-if="paymentLink" class="text-primary p-1 mb-0 border border-primary rounded">
                {{ paymentLink.slice(0, 75) + '...' }}
              </p>
              <b-button @click="copyPaymentLink" variant="none">
                <feather-icon size="20" icon="CopyIcon" /> Copy
              </b-button>
            </div>

            <p class="text-secondary mb-0">
              This link is valid for 30 minutes only
            </p>
          </b-col>

          <!-- <b-col cols="12" class="text-center p-1">
            <p class="h3 text-secondary mb-1 font-weight-bold text-center">
              Share link
            </p>
            <b-row>
              <b-col
                class="mb-2"
                v-for="network in networks"
                :key="network.network"
              >
                <ShareNetwork
                  :network="network.network"
                  :style="{ backgroundColor: network.color }"
                  :url="share_link"
                  :title="'MobileMasr'"
                  :description="'بيع مباشر'"
                  class="h-100 w-100"
                >
                  <span class="text-white p-2">{{ network.name }}</span>
                </ShareNetwork>
              </b-col>
            </b-row>
          </b-col> -->
        </b-row>
      </b-card>
    </b-modal>
    <!-- error handelr -->
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <div class="loading-overlay text-center" v-if="is_loading">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { required, integer } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)


export default {
  components: {

    FormWizard,
    TabContent,
  },
  mixins: [heightTransition],
  data() {
    return {
      is_loading: false,
      downPaymentMethod: null,
      address: null,
      paymentProviderOptions: [],
      storeAddressErrorsBack: [],
      showStoreAddressDismissibleAlert: false,
      orderTypeOptions: [
        {
          text: "Cash On Site",
          value: "cos",
        },
        {
          text: "Digital Wallet",
          value: "digital-wallet"
        },
        {
          text: "Instalment",
          value: "instalment"
        },
        {
          text: "Credit",
          value: "credit"

        },
        {
          text: "Cash On Delivery",
          value: "cash-on-delivery"
        }
      ],
      addressData: {
        is_main_address: true,
        street: null,
        flat_number: null,
        postal_code: null,
        special_mark: null,
        building_number: null,
        floor_number: null,
        city_id: null,
        region: null,
        town: null,
      },
      shipComOptions: [],
      shipComSelect: null,
      couponSerial: '',
      couponData: null,
      couponState: null,
      cost: 0,
      noResult: false,
      noResultBund: false,
      resultShow: false,
      resultShowBund: false,
      userShow: false,
      searchText: '',
      searchText2: '',
      searchUser: '',
      noUser: false,
      errors_back: [],
      showDismissibleAlert: false,
      required,
      integer,
      orderData: {
        file: null,
        wallet_number: null,
        remaining_amount: 0,
        type: null,
        is_shipping: false,
        is_down_payment: false,
        down_payment: 0,
        // remaing: 0,
        payment_provider_id: null,
        phone: null,
        name: null,
        coupon_id: null,
        user_id: null,
        variants: [],
        bundles: [],
      },
      selectedPaymentProvider: null,
      selectedUser: null,
      selectedProducts: [],
      usersOptions: [],
      countrySelect: null,
      countryOptions: [],
      citySelect: null,
      cityOptions: [],
      areaSelect: null,
      areaOptions: [],
      couponSelect: null,
      couponsOptions: [],
      productsSR: [],
      productsSRBund: [],
      userSR: [],
      area: true,
      city: true,
      ship: true,
      startCPrice: '',
      endCPrice: '',
      percentC: 0,
      shipping: 0,
      calculateShipping: 0,
      products_total: 0,
      total: 0,
      showerrorCoup: true,
      couponActive: false,
      addedProduct: {
        variant_id: null,
        quantity: null,
        name: null,
        price: null,
      },
      currentPage: 0,
      userCurrentPage: 0,
      cities: [],
      selectedCity: null,
      downPaymentValidation: null,
      paymentLink: null,
      storedOrderData: null,
      orderTotalWeight: 0,
      orderTotalPeices: 0,
      OrderreturnedData: null,
      vendorData: {
        id: null,
        address: null,
        city_id: null,
      },
      attributesSettingKey: [
        "cod_limit_remaining_fees",
        "cod_remaining_min_fees",
        "cod_remaining_fees"
      ],
      codSettingData: {
        cod_limit_remaining_fees: 0,
        cod_remaining_min_fees: 0,
        cod_remaining_fees: 0,
      },
      isConfirming: false,
    }
  },
  watch: {
    cost: function () {
      if (this.couponSerial != '') {
        this.handleSubmit()
      }
    },
    couponSerial: function () {
      this.showerrorCoup = true
    },
    async 'selectedPaymentProvider'(newVal, oldVal) {
      console.log(newVal)
      if (newVal.code == 'visa') {

        this.orderData.type = "credit"
      } else if (newVal.code == 'mobile-wallet') {

        this.orderData.type = "digital-wallet"
      } else if (newVal.code == 'cod') {
        if (this.codSettingData.cod_limit_remaining_fees == 0 || this.codSettingData.cod_remaining_min_fees == 0 || this.codSettingData.cod_remaining_fees == 0) {

          await this.getSettingRemainingAmountData()
          //console.log("test", this.orderData.remaining_amount)

        }
        this.orderData.type = "cash-on-delivery"
        this.orderData.is_shipping = true

      } else if (newVal.code == 'cos') {
        this.orderData.type = "cos"

      } else {

        this.orderData.type = "instalment"
      }
    },
    'orderData.is_shipping'(newVal, oldVal) {
      // console.log(newVal)
      if (newVal == true) {
        if (this.address) {
          if (this.calculateShipping == 0) {

            this.calculateShippingPrice()

          }
          this.shipping = this.calculateShipping
        }
      } else {
        this.shipping = 0

      }
    },
    'shipping'(newVal, oldVal) {
      this.total = parseFloat((this.products_total + this.shipping).toFixed(2))

    },
    'total'(newVal, oldVal) {
      if (this.selectedPaymentProvider && this.selectedPaymentProvider.code == 'cod') {
        this.calculateRemainingFees()
      }

    }
  },
  async created() {
    await this.getPaymentProviderData()
  },
  methods: {
    beforeModalClose(evt) {
      // If already confirming, let the modal close without asking again
      if (this.isConfirming) {
        this.isConfirming = false; // Reset the flag for future closings
        return;
      }
      evt.preventDefault();
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to close the modal?', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.isConfirming = true;
            // If the user confirms, manually hide the modal
            this.$bvModal.hide('modal-center');

          }


        })
    },

    calculateRemainingFees() {
      if (this.total <= this.codSettingData.cod_limit_remaining_fees) {
        this.orderData.remaining_amount = this.codSettingData.cod_remaining_min_fees * this.orderData.variants.length

      } else {

        this.orderData.remaining_amount = this.codSettingData.cod_remaining_fees * this.orderData.variants.length
      }
    },
    validateMaxPayment() {
      this.downPaymentValidation = null;
      if (Number(this.orderData.down_payment) > Number(this.total)) {
        this.downPaymentValidation = `The down payment must not exceed ${this.orderData.total}.`;
        return false
      } else if (Number(this.orderData.down_payment) == 0) {
        this.downPaymentValidation = `The down payment can not be ${this.orderData.down_payment}.`;
        return false

      }
      return true
    },
    async calculateShippingPrice() {
      const receiver = {
        address: this.address.street,
        city_id: this.address.city.id
      }

      const sender = {
        address: this.vendorData.address,
        city_id: this.vendorData.city_id
      }
      await axios
        .post('airWayBills/calcOrderShippingPrice', {
          total_of_weight: this.orderTotalWeight,
          number_of_peices: this.orderTotalPeices,
          receiver: receiver,
          sender: sender,
        })
        .then((result) => {

          this.calculateShipping = result.data.net_amount
          this.shipping = this.calculateShipping

          // this.$router.push({
          //   path: '/orders/OrderIndex/',
          // })
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    async getSettingRemainingAmountData() {
      await axios
        .post('setting/attributes/get', { setting_attributes: this.attributesSettingKey })
        .then((result) => {
          const data = result.data.data

          for (let index in data) {
            this.codSettingData[data[index].attribute] = parseFloat(data[index].value)
          }
          this.calculateRemainingFees()

        })
        .catch((err) => {
          // console.log(err)
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })

        })
    },
    validationStep1Form() {
      return new Promise((resolve, reject) => {
        if (this.orderData.variants.length != 0) {
          // Extract all vendor_ids
          const allSameVendorId = this.orderData.variants.every(variant => variant.vendor_id === this.orderData.variants[0].vendor_id);
          if (!allSameVendorId) {
            this.$swal({
              position: 'center',
              icon: 'error',
              title: 'Seledted Products must belong to the same vendor remove or replace the selected products',
              showConfirmButton: false,
              timer: 2500,
            })
            reject()

          } else {
            this.vendorData.id = this.orderData.variants[0].vendor_id
            this.vendorData.address = this.orderData.variants[0].vendor_address
            this.vendorData.city_id = this.orderData.variants[0].vendor_city_id
            // Calculate the sum of weight * quantity
            const totals = this.orderData.variants.reduce((acc, variant) => {
              const weightQuantity = parseFloat(variant.weight) * variant.quantity;
              return {
                totalWeight: acc.totalWeight + weightQuantity,
                totalQuantity: acc.totalQuantity + variant.quantity
              };
            }, { totalWeight: 0, totalQuantity: 0 });

            this.orderTotalWeight = totals.totalWeight;
            this.orderTotalPeices = totals.totalQuantity;

            resolve(true)
          }


        } else {
          reject()
        }

      })
    },
    validationStep2Form() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    copyPaymentLink() {
      // let text = document.getElementById('myText').innerHTML;
      navigator.clipboard.writeText(this.paymentLink)
      this.$swal({
        position: 'center',
        icon: 'success',
        title: 'Copied ',
        showConfirmButton: false,
        timer: 1500,
      })
    },
    // submit add form
    formSubmitted() {
      this.showDismissibleAlert = false
      if (this.paymentLink != null) {
        this.$swal({
          position: 'center',
          icon: 'error',
          title: 'this variants already stored in order',
          showConfirmButton: false,
          timer: 1500,
        })

        this.$router.push({
          path: '/orders/show/' + this.storedOrderData.id,
        })

        return
      }
      this.$refs.simpleRules.validate().then((success) => {
        // console.log(this.orderData.variants)
        if (success) {

          if (this.orderData.is_down_payment && (this.validateMaxPayment() == false)) {
            this.$swal({
              position: 'center',
              icon: 'error',
              title: 'invalid down payment data',
              showConfirmButton: false,
              timer: 1500,
            })
            return false;

          } else {

            this.is_loading = true
            let formData = new FormData()
            if (this.orderData.type == 'digital-wallet') {
                const found = this.paymentProviderOptions.find((el) => el.code === 'mobile-wallet')
                this.orderData.payment_provider_id = found.id
                formData.append('payment_provider_id', found.id)
                formData.append('wallet_number', this.orderData.wallet_number)
              } 
            if (this.selectedPaymentProvider.code == 'cod') {
              if (this.downPaymentMethod == 'digital-wallet') {
                const found = this.paymentProviderOptions.find((el) => el.code === 'mobile-wallet')
                this.orderData.payment_provider_id = found.id
                formData.append('payment_provider_id', found.id)
                formData.append('wallet_number', this.orderData.wallet_number)
              } else if (this.downPaymentMethod == 'visa') {

                const found = this.paymentProviderOptions.find((el) => el.code === 'visa')

                this.orderData.payment_provider_id = found.id
                formData.append('payment_provider_id', found.id)
              } else {

                this.orderData.payment_provider_id = this.selectedPaymentProvider.id
                formData.append('payment_provider_id', this.selectedPaymentProvider.id)
              }
            } else {

              this.orderData.payment_provider_id = this.selectedPaymentProvider.id
              formData.append('payment_provider_id', this.selectedPaymentProvider.id)
            }
            if (
              this.orderData.bundles.length > 0 ||
              this.orderData.variants.length > 0
            ) {

              formData.append('type', this.orderData.type)
              formData.append('is_down_payment', this.orderData.is_down_payment ? 1 : 0)
              if (this.orderData.is_down_payment == true) {
                formData.append('down_payment', this.orderData.down_payment)

              }
              if (this.downPaymentMethod) {

                formData.append('down_payment_method', this.downPaymentMethod)
              }
              formData.append('phone', this.orderData.phone)
              formData.append('user_id', this.orderData.user_id)
              formData.append('is_shipping', this.orderData.is_shipping ? 1 : 0)
              if (this.downPaymentMethod == 'instapay' || this.downPaymentMethod == 'other') {
                if (this.orderData.file == null) {
                  this.showDismissibleAlert = true
                  this.errors_back.push({
                    error: 'must upload attachment file ',
                  })
                  this.is_loading = false
                  return
                } else {

                  formData.append('file', this.orderData.file)
                }
              }

              if (this.couponActive) {
                formData.append('coupon_id', this.orderData.coupon_id)
                this.orderData.coupon_id = this.couponData.id
              }


              this.orderData.variants.forEach((variant, index) => {
                formData.append(`variants[${index}][variant_slug]`, variant.variant_slug)
                formData.append(`variants[${index}][quantity]`, variant.quantity)
              })

              axios
                .post('orders/store', formData)
                .then((result) => {
                  this.is_loading = false

                  this.$swal({
                    position: 'center',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500,
                  })

                  if (result.data.data.payment_data) {
                    this.OrderreturnedData = result.data.data
                    this.storedOrderData = result.data.data.orders[0]
                    this.paymentLink = result.data.data.payment_data.redirect_url
                    this.$refs['show-share'].show()

                  } else {

                    this.$router.push({
                      path: '/orders/OrderIndex/',
                    })


                  }



                })
                .catch((err) => {
                  this.is_loading = false
                  // console.log(err)
                  this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Error!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  this.errors_back.length = 0
                  if (err.response.data.data != null) {
                    if (this.isString(err.response.data.data)) {
                      this.errors_back.push({
                        error: err.response.data.data,
                      })
                    } else {
                      this.errors_back = err.response.data.data
                    }
                    this.showDismissibleAlert = true
                  } else {
                    this.errors_back = []
                    this.errors_back.push({
                      error: 'internal server error',
                    })
                    this.showDismissibleAlert = true
                  }
                })
            } else {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Choose bundel or product first',
                showConfirmButton: false,
                timer: 1500,
              })
            }

          }
        }
      })
    },

    resetModal() {
      this.couponState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async modalToStoreAddress() {
      await this.getCitiesData()


      this.$refs['modal-add-address-for-user'].show()
    },
    async handleSaveAddress() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        // Execute your function here
        await this.storeUserAddress();

      } else {


      }
    },
    closeSaveAddressModal() {
      this.$refs['modal-add-address-for-user'].hide();

    },
    async generateOrderAddress(addressData) {

      // this.address = addressData.building_number + " " + addressData.street + '' + addressData.region + ' - ' + addressData.town + addressData.city.name + ' ' + 'الدور ' + addressData.floor_number + addressData.flat_number;
      this.address = addressData;
      if (this.orderData.is_shipping) {

        await this.calculateShippingPrice()
        this.shipping = this.calculateShipping
      }
    },
    async storeUserAddress() {
      this.addressData.city_id = this.selectedCity.id
      this.storeAddressErrorsBack = []
      this.showStoreAddressDismissibleAlert = false
      await axios
        .post('users/' + this.selectedUser.id + '/address/create', this.addressData)
        .then((result) => {
          const data = result.data.data
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'the address has been worked',
            showConfirmButton: false,
            timer: 1500,
          })
          this.generateOrderAddress(data)

          // Close the modal
          this.$refs['modal-add-address-for-user'].hide();
        })
        .catch((err) => {

          this.storeAddressErrorsBack.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.storeAddressErrorsBack.push({
                error: err.response.data.data,
              })
            } else {
              this.storeAddressErrorsBack = err.response.data.data
            }
            this.showStoreAddressDismissibleAlert = true
          } else {
            this.storeAddressErrorsBack = []
            this.storeAddressErrorsBack.push({
              error: 'internal server error',
            })
            this.showStoreAddressDismissibleAlert = true
          }
        })

    },
    async getCitiesData() {
      await axios
        .get('cities')
        .then((result) => {
          this.cities = result.data.data
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    async getPaymentProviderData() {

      this.storeAddressErrorsBack.length = 0
      this.showStoreAddressDismissibleAlert = false

      await axios
        .get('/payment-providers/create-order')
        .then((result) => {

          this.paymentProviderOptions = result.data.data
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Invalid Payment Provider Data',
            showConfirmButton: false,
            timer: 1500,
          })
        })

    },
    handleSubmit() {
      axios
        .get('coupons/' + this.couponSerial + '/check/active/' + this.cost)
        .then((result) => {
          if (this.showerrorCoup) {
            this.$swal({
              position: 'center',
              icon: 'success',
              title: 'Your coupon has been worked',
              showConfirmButton: false,
              timer: 1500,
            })
          }
          this.showDismissibleAlert = false
          this.showerrorCoup = false
          this.$refs['my-modal'].hide()
          const data = result.data.data
          this.couponData = data
          this.startCPrice = data.startprice
          this.endCPrice = data.endprice
          this.percentC = data.percentage
          this.couponActive = true

          const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
          this.products_total = totalBeforShip
          this.total = totalBeforShip + this.shipping
        })
        .catch((err) => {
          if (this.showerrorCoup) {
            this.$swal({
              position: 'center',
              icon: 'error',
              title: 'invalid coupon',
              showConfirmButton: false,
              timer: 1500,
            })
          }
          this.couponActive = false
          this.showerrorCoup = false
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    ShowVariantResultIfExists() {
      // console.log(this.resultShow)
      if (this.productsSR.length > 0) {
        this.resultShow = true
      }

    },
    searchUserR(text, $page) {
      this.noUser = false
      this.userCurrentPage = $page + 1
      axios
        .get(
          'users/store-orders/20/sortBy-id-desc/' +
          text +
          '?page=' +
          this.userCurrentPage
        )
        .then((result) => {
          const data = result.data.data.data
          // this.userSR = data
          if (this.userCurrentPage == 1) {
            this.userSR = []
          }
          this.userSR = this.userSR.concat(data)
          // console.log(this.userSR)
          if (this.userSR.length > 0) {
            this.userShow = true
          } else {
            if (this.userCurrentPage == 1) {
              this.userSR = []
              this.noUser = true
              this.userShow = false

            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    searchResultsubmit($state) {
      this.noResult = false
      this.currentPage = 1
      axios
        .get(
          'orders/variants-pagi/20/sortBy-id-desc/' +
          this.searchText +
          '?page=' +
          this.currentPage
        )
        .then((result) => {
          const data = result.data.data.data

          this.productsSR = []

          if (data.length > 0) {
            this.productsSR = data
            if (this.productsSR.length > 0) {
              this.resultShow = true
            } else {
              this.noResult = true
              this.resultShow = false
            }
            // $state.loaded()
          } else {
            if (this.productsSR.length > 0) {
              this.resultShow = true
            } else {
              this.noResult = true
              this.resultShow = false
            }
            // $state.complete();
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    searchResult($state) {
      this.noResult = false
      this.currentPage += 1
      axios
        .get(
          'orders/variants-pagi/20/sortBy-id-desc/' +
          this.searchText +
          '?page=' +
          this.currentPage
        )
        .then((result) => {
          const data = result.data.data.data
          // this.productsSR.length = 0
          // this.loadMore = false
          if (data.length > 0) {
            this.productsSR = this.productsSR.concat(data)
            // this.productsSR = data
            if (this.productsSR.length > 0) {
              this.resultShow = true
            } else {
              this.noResult = true
              this.resultShow = false
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    setData(id, name, price) {
      addedProductSubmit(id, name, price, 1)
    },
    searchResultBundle(text) {
      this.noResultBund = false

      axios
        .get('bundles/index/' + text)
        .then((result) => {
          const data = result.data.data.data

          this.productsSRBund.length = 0
          for (let index in data) {
            this.productsSRBund.push({
              bundle_id: data[index].id,
              name: data[index].title,
              bundPrice: data[index].bundle_price,
              originalPrice: data[index].original_price,
            })
          }

          if (this.productsSRBund.length > 0) {
            this.resultShowBund = true
          } else {
            this.noResultBund = true
            this.resultShowBund = false
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    addUser(user) {
      this.userShow = false
      this.selectedUser = user
      if (this.selectedUser.addresses.length > 0) {
        this.generateOrderAddress(this.selectedUser.addresses[0])
      }
      this.searchUser = user.firstname + user.lastname
      this.orderData.user_id = user.id
      this.orderData.name = user.firstname + ' ' + user.lastname
      this.orderData.phone = user.phone
      this.userSR.length = 0
    },


    // addProduct(id, sku ,name, price, quen, quantity , cover) {
    //   this.resultShow = false
    //   const found = this.orderData.variants.some((el) => el.variant_id === id)

    //   if (!found) {
    //     this.orderData.variants.push({
    //       variant_id: id,
    //       sku: sku,
    //       price: price,
    //       quantity: quen,
    //       name: name,
    //       cover: cover,
    //       max_qun: quantity,
    //     })
    //   } else {
    //     const index = this.orderData.variants.findIndex(
    //       (x) => x.variant_id === id
    //     )
    //     this.orderData.variants[index].quantity += quen
    //   }

    //   this.cost = 0
    //   if (this.orderData.variants.length > 0) {
    //     for (let index in this.orderData.variants) {
    //       const qun = parseInt(this.orderData.variants[index].quantity)
    //       const pri = parseFloat(this.orderData.variants[index].price)
    //       this.cost += qun * pri
    //     }
    //   }
    //   if (this.orderData.bundles.length > 0) {
    //     for (let index in this.orderData.bundles) {
    //       const qun = parseInt(this.orderData.bundles[index].quantity)
    //       const pri = parseFloat(this.orderData.bundles[index].bundPrice)
    //       this.cost += qun * pri
    //     }
    //   }
    //   const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
    //   this.products_total = totalBeforShip
    //   this.total = totalBeforShip + this.shipping
    //   // this.productsSR.length = 0
    // },
    addProduct(product) {
      this.resultShow = false
      const found = this.orderData.variants.some((el) => el.variant_id === product.id)

      if (!found) {
        this.orderData.variants.push({
          variant_id: product.id,
          variant_slug: product.variant_slug,
          sku: product.sku,
          price: product.sale_price,
          quantity: 1,
          name: product.name,
          max_qun: product.quantity,
          cover: product.cover,
          weight: product.weight,
          vendor_city_id: product.vendor_city_id,
          vendor_address: product.vendor_address,
          vendor_id: product.vendor_id,
          vendor_storename: product.vendor_storename,
        })
      } else {
        const index = this.orderData.variants.findIndex(
          (x) => x.variant_id === product.id
        )
        if (product.is_new && this.orderData.variants[index].quantity < product.id) {

          this.orderData.variants[index].quantity += 1
        }
      }

      this.cost = 0
      if (this.orderData.variants.length > 0) {
        for (let index in this.orderData.variants) {
          const qun = parseInt(this.orderData.variants[index].quantity)
          const pri = parseFloat(this.orderData.variants[index].price)
          this.cost += qun * pri
        }
      }
      if (this.orderData.bundles.length > 0) {
        for (let index in this.orderData.bundles) {
          const qun = parseInt(this.orderData.bundles[index].quantity)
          const pri = parseFloat(this.orderData.bundles[index].bundPrice)
          this.cost += qun * pri
        }
      }
      const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
      this.products_total = totalBeforShip
      this.total = totalBeforShip + this.shipping
      // this.productsSR.length = 0

    },
    addBundle(id, name, bundPrice, originalPrice) {
      this.resultShowBund = false
      const found = this.orderData.bundles.some((el) => el.bundle_id === id)

      if (!found) {
        this.orderData.bundles.push({
          bundle_id: id,
          bundPrice: bundPrice,
          originalPrice: originalPrice,
          quantity: 1,
          name: name,
        })
      } else {
        const index = this.orderData.bundles.findIndex(
          (x) => x.bundle_id === id
        )
        this.orderData.bundles[index].quantity += 1
      }
      this.cost = 0
      if (this.orderData.variants.length > 0) {
        for (let index in this.orderData.variants) {
          const qun = parseInt(this.orderData.variants[index].quantity)
          const pri = parseFloat(this.orderData.variants[index].price)
          this.cost += qun * pri
        }
      }
      if (this.orderData.bundles.length > 0) {
        for (let index in this.orderData.bundles) {
          const qun = parseInt(this.orderData.bundles[index].quantity)
          const pri = parseFloat(this.orderData.bundles[index].bundPrice)
          this.cost += qun * pri
        }
      }
      const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
      this.products_total = totalBeforShip
      this.total = totalBeforShip + this.shipping
      this.productsSRBund.length = 0
    },
    recalcCost() {
      this.cost = 0
      if (this.orderData.variants.length > 0) {
        for (let index in this.orderData.variants) {
          const qun = parseInt(this.orderData.variants[index].quantity)
          const pri = parseFloat(this.orderData.variants[index].price)
          this.cost += qun * pri
        }
      }
      if (this.orderData.bundles.length > 0) {
        for (let index in this.orderData.bundles) {
          const qun = parseInt(this.orderData.bundles[index].quantity)
          const pri = parseFloat(this.orderData.bundles[index].bundPrice)
          this.cost += qun * pri
        }
      }
      const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
      this.products_total = totalBeforShip
      this.total = totalBeforShip + this.shipping
    },
    closeOpenSearch() {
      if (
        this.resultShow == true ||
        this.userShow == true ||
        this.resultShowBund == true
      ) {
        this.resultShow = false
        this.userShow = false
        this.resultShowBund = false
      }
    },
    removeItem(index) {
      this.orderData.variants.splice(index, 1)
      this.cost = 0
      this.cost = 0
      if (this.orderData.variants.length > 0) {
        for (let index in this.orderData.variants) {
          const qun = parseInt(this.orderData.variants[index].quantity)
          const pri = parseFloat(this.orderData.variants[index].price)
          this.cost += qun * pri
        }
      }
      if (this.orderData.bundles.length > 0) {
        for (let index in this.orderData.bundles) {
          const qun = parseInt(this.orderData.bundles[index].quantity)
          const pri = parseFloat(this.orderData.bundles[index].bundPrice)
          this.cost += qun * pri
        }
      }
      const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
      this.products_total = totalBeforShip
      this.total = totalBeforShip + this.shipping
    },
    removeItemBundle(index) {
      this.orderData.bundles.splice(index, 1)
      this.cost = 0
      this.cost = 0
      if (this.orderData.variants.length > 0) {
        for (let index in this.orderData.variants) {
          const qun = parseInt(this.orderData.variants[index].quantity)
          const pri = parseFloat(this.orderData.variants[index].price)
          this.cost += qun * pri
        }
      }
      if (this.orderData.bundles.length > 0) {
        for (let index in this.orderData.bundles) {
          const qun = parseInt(this.orderData.bundles[index].quantity)
          const pri = parseFloat(this.orderData.bundles[index].bundPrice)
          this.cost += qun * pri
        }
      }
      const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
      this.products_total = totalBeforShip
      this.total = totalBeforShip + this.shipping
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    fileUpload(e) {
      this.orderData.file = e.target.files[0]
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';


.store-order {
  .vertical.wizard-vertical.vue-form-wizard .wizard-navigation .wizard-nav {
    min-width: 75px !important;
    display: none !important;
  }

  .vertical.wizard-vertical.vue-form-wizard .wizard-card-footer {
    margin-left: 0px !important;
  }
}

.repeater-form {
  transition: 0.35s height;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

.app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
}

.suggestion-group-title {
  font-weight: 500;
  padding: 0.75rem 1rem 0.25rem;
}

.suggestion-group-suggestion {
  padding: 0.75rem 1rem;
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}

.sticky-col {
  position: sticky;
  top: 100px;
  /* Adjust as needed */
  // z-index: 100;
  /* To ensure it stays above other content */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.075);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>
