var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"click":_vm.closeOpenSearch}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{attrs:{"name":"create-item","autocomplete":"on"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":"Main Data"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('div',_vm._l((_vm.itemData.translations),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Language","label-for":"language","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":'language' + index,"state":_vm.tranLocaleSelect[index] === null ? false : true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.localeOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.tranLocaleSelect[index]),callback:function ($$v) {_vm.$set(_vm.tranLocaleSelect, index, $$v)},expression:"tranLocaleSelect[index]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{style:(_vm.tranLocaleSelect[index].vlaue == 'ar'
                            ? 'text-align: right !important;'
                            : 'text-align: right !important;'),attrs:{"id":'TranslationName' + index,"autocomplete":"on","state":errors.length > 0 ? false : null},model:{value:(_vm.itemData.translations[index].name),callback:function ($$v) {_vm.$set(_vm.itemData.translations[index], "name", $$v)},expression:"itemData.translations[index].name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Short Description","label-for":"short-description"}},[_c('b-form-input',{style:(_vm.tranLocaleSelect[index].vlaue == 'ar'
                          ? 'text-align: right !important;'
                          : 'text-align: right !important;'),attrs:{"id":'short-description' + index,"autocomplete":"on"},model:{value:(_vm.itemData.translations[index].short_description
                            ),callback:function ($$v) {_vm.$set(_vm.itemData.translations[index], "short_description", $$v)},expression:"itemData.translations[index].short_description\n                            "}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('vue-editor',{attrs:{"id":'translationDescription' + index,"autocomplete":"on"},model:{value:(_vm.itemData.translations[index].description),callback:function ($$v) {_vm.$set(_vm.itemData.translations[index], "description", $$v)},expression:"itemData.translations[index].description"}})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"2","md":"3"}},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.tranLocaleSelect[index].disa),expression:"!tranLocaleSelect[index].disa"},{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.translationsremoveItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1)]),_c('b-col',{attrs:{"sm":"2"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","block":""},on:{"click":_vm.translationsrepeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add New")])],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":"General Details"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Cover","label-for":"cover"}},[_c('validation-provider',{attrs:{"name":"Cover","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":errors.length > 0 ? false : null,"id":"cover","accept":"image/*"},on:{"change":_vm.uploadImage},model:{value:(_vm.coverImage),callback:function ($$v) {_vm.coverImage=$$v},expression:"coverImage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Purchase Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Purchase Date","label-for":"purchase date","state":errors.length > 0 ? false : null}},[_c('flat-pickr',{staticClass:"form-control",model:{value:(_vm.itemData.purchse_date),callback:function ($$v) {_vm.$set(_vm.itemData, "purchse_date", $$v)},expression:"itemData.purchse_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Active","label-for":"active","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"active","state":_vm.activeSelect === null ? false : true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.activeOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.activeSelect),callback:function ($$v) {_vm.activeSelect=$$v},expression:"activeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status","label-for":"status","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"status","state":_vm.statusSelect === null ? false : true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.statusSelect),callback:function ($$v) {_vm.statusSelect=$$v},expression:"statusSelect"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('h4',[_vm._v("Is Guide :")]),_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"d-flex",attrs:{"id":"checkbox-group-2","button-variant":"outline-primary","aria-describedby":ariaDescribedby,"name":"checkbox-group-2"}},[_c('b-form-radio',{attrs:{"value":true},model:{value:(_vm.itemData.is_guide),callback:function ($$v) {_vm.$set(_vm.itemData, "is_guide", $$v)},expression:"itemData.is_guide"}},[_vm._v("Yes")]),_c('b-form-radio',{staticClass:"ml-1",attrs:{"value":false},model:{value:(_vm.itemData.is_guide),callback:function ($$v) {_vm.$set(_vm.itemData, "is_guide", $$v)},expression:"itemData.is_guide"}},[_vm._v("No")])],1)]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":"Classifications"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Category","label-for":"category","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"category","state":_vm.categorySelect === null ? false : true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.categoryOption,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},on:{"change":_vm.getItems},model:{value:(_vm.categorySelect),callback:function ($$v) {_vm.categorySelect=$$v},expression:"categorySelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"brand","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Brand","label-for":"brand","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"brand","state":_vm.brandSelect === null ? false : true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.brandOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},on:{"change":_vm.getItems},model:{value:(_vm.brandSelect),callback:function ($$v) {_vm.brandSelect=$$v},expression:"brandSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Family","label-for":"family"}},[_c('v-select',{attrs:{"id":"family","disabled":_vm.itemSe,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.itemOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.itemSelect),callback:function ($$v) {_vm.itemSelect=$$v},expression:"itemSelect"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tag","label-for":"tag"}},[_c('v-select',{attrs:{"multiple":"","id":"tag","mu":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.tagOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.tagSelect),callback:function ($$v) {_vm.tagSelect=$$v},expression:"tagSelect"}})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":"Media"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('div',_vm._l((_vm.itemData.images),function(item,index){return _c('b-row',{key:item.id,attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Image","label-for":"image"}},[_c('validation-provider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":errors.length > 0 ? false : null,"id":'image' + index,"accept":"image/*"},on:{"change":function($event){return _vm.uploadImage2($event, index)}},model:{value:(_vm.itemDataImages[index].file),callback:function ($$v) {_vm.$set(_vm.itemDataImages[index], "file", $$v)},expression:"itemDataImages[index].file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('b-form-input',{attrs:{"id":'imagedescription' + index,"autocomplete":"on"},model:{value:(_vm.itemData.images[index].description),callback:function ($$v) {_vm.$set(_vm.itemData.images[index], "description", $$v)},expression:"itemData.images[index].description"}})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"1"}},[(index > 0)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1)]),_c('b-col',{attrs:{"sm":"2"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","block":""},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add New")])],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":"Specifications"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},_vm._l((_vm.itemData.taxonomy),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,style:(_vm.taxLocaleSelect[index].value == 'ar'
                  ? 'direction:rtl;'
                  : 'direction:ltr;'),attrs:{"id":item.id},on:{"focus":function($event){_vm.resultShowBund[index] = false}}},[_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Locale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"active","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":'locale' + index,"placeholder":"Select Locale","disabled":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.localeOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.taxLocaleSelect[index]),callback:function ($$v) {_vm.$set(_vm.taxLocaleSelect, index, $$v)},expression:"taxLocaleSelect[index]"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Term","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"term","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":'locale' + index,"placeholder":"Select Term","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.termOptions[index],"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.termSelect[index]),callback:function ($$v) {_vm.$set(_vm.termSelect, index, $$v)},expression:"termSelect[index]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"taxonomy_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{style:(_vm.taxLocaleSelect[index].vlaue == 'ar'
                          ? 'text-align: right !important;'
                          : 'text-align: right !important;'),attrs:{"id":'name' + index,"autocomplete":"on","placeholder":"Name","state":errors.length > 0 ? false : null},model:{value:(_vm.itemData.taxonomy[index].name),callback:function ($$v) {_vm.$set(_vm.itemData.taxonomy[index], "name", $$v)},expression:"itemData.taxonomy[index].name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(
                    _vm.termSelect[index] != null &&
                    _vm.termSelect[index].text != 'مميزات اضافية' &&
                    _vm.termSelect[index].text != 'ملحوظات'
                  )?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"description"}},[_c('validation-provider',{attrs:{"name":"taxonomy description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [(
                          _vm.itemData.taxonomy[index].name == 'المعالج' ||
                          _vm.itemData.taxonomy[index].name ==
                          'عدد أنوية المعالج' ||
                          _vm.itemData.taxonomy[index].name == 'WLAN' ||
                          _vm.itemData.taxonomy[index].name == 'GPS' ||
                          _vm.itemData.taxonomy[index].name ==
                          'معلومات عن الشاحن' ||
                          _vm.itemData.taxonomy[index].typeCheck == 'new-record'
                        )?_c('b-form-textarea',{staticClass:"taxDesc",attrs:{"autocomplete":"off","placeholder":"Description","id":_vm.findAndReplace(
                          _vm.itemData.taxonomy[index].name,
                          ' ',
                          '-'
                        ),"name":_vm.findAndReplace(
                            _vm.itemData.taxonomy[index].name,
                            ' ',
                            '-'
                          ),"state":errors.length > 0 ? false : null},model:{value:(_vm.itemData.taxonomy[index].description),callback:function ($$v) {_vm.$set(_vm.itemData.taxonomy[index], "description", $$v)},expression:"itemData.taxonomy[index].description"}}):_c('b-form-input',{staticClass:"taxDesc",attrs:{"list":_vm.itemData.taxonomy[index].name,"placeholder":"Description","id":_vm.findAndReplace(
                          _vm.itemData.taxonomy[index].name,
                          ' ',
                          '-'
                        ),"name":_vm.findAndReplace(
                            _vm.itemData.taxonomy[index].name,
                            ' ',
                            '-'
                          ),"autocomplete":"off","state":errors.length > 0 ? false : null},model:{value:(_vm.itemData.taxonomy[index].description),callback:function ($$v) {_vm.$set(_vm.itemData.taxonomy[index], "description", $$v)},expression:"itemData.taxonomy[index].description"}}),_c('datalist',{attrs:{"id":_vm.itemData.taxonomy[index].name}},_vm._l((_vm.itemData.taxonomy[index]
                            .resultTaxonomySearch),function(ite,ind){return _c('option',{key:ind},[_vm._v(" "+_vm._s(ite.name)+" ")])}),0),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_c('b-col',{attrs:{"md":"9"}},[_c('b-form-group',{attrs:{"label-for":"description"}},[_c('validation-provider',{attrs:{"name":"taxonomy description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('vue-editor',{attrs:{"id":_vm.findAndReplace(
                          _vm.itemData.taxonomy[index].name,
                          ' ',
                          '-'
                        ),"name":_vm.findAndReplace(
                            _vm.itemData.taxonomy[index].name,
                            ' ',
                            '-'
                          ),"state":errors.length > 0 ? false : null,"rows":"20","editor-toolbar":_vm.customToolbar,"autocomplete":"on"},on:{"imageAdded":_vm.handleImageAdded},model:{value:(_vm.itemData.taxonomy[index].description),callback:function ($$v) {_vm.$set(_vm.itemData.taxonomy[index], "description", $$v)},expression:"itemData.taxonomy[index].description"}}),_c('datalist',{attrs:{"id":"my-list-id"}},_vm._l((_vm.itemData.taxonomy[index]
                            .resultTaxonomySearch),function(ite,ind){return _c('option',{key:ind},[_vm._v(" "+_vm._s(ite.name)+" ")])}),0),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(
                    !(
                      _vm.itemData.taxonomy[index].name == 'المعالج' ||
                      _vm.itemData.taxonomy[index].name ==
                      'عدد أنوية المعالج' ||
                      _vm.itemData.taxonomy[index].name == 'WLAN' ||
                      _vm.itemData.taxonomy[index].name == 'GPS' ||
                      _vm.itemData.taxonomy[index].name ==
                      'معلومات عن الشاحن' ||
                      _vm.itemData.taxonomy[index].typeCheck == 'new-record'
                    )
                  )?_c('b-col',{attrs:{"cols":"1"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon rounded-circle",staticStyle:{"padding":"7px","margin":"6px","width":"35px","height":"35px","float":"right"},attrs:{"variant":"info"},on:{"click":function($event){return _vm.searchResultBundle(
                          _vm.itemData.taxonomy[index].name,
                          _vm.termSelect[index].value,
                          _vm.taxLocaleSelect[index].value,
                          index,
                          _vm.itemData.taxonomy[index].description
                        )}}},[_c('feather-icon',{staticStyle:{"width":"15px","height":"15px"},attrs:{"icon":"SearchIcon"}})],1)],1):_c('b-col',{attrs:{"cols":"1"}}),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Locale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"active","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":'locale' + index,"placeholder":"Select Locale","disabled":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.localeOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.taxLocaleEnSelect),callback:function ($$v) {_vm.taxLocaleEnSelect=$$v},expression:"taxLocaleEnSelect"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Term","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"term","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":'locale' + index,"placeholder":"Select Term","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.termOptions[index],"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.termEnSelect[index]),callback:function ($$v) {_vm.$set(_vm.termEnSelect, index, $$v)},expression:"termEnSelect[index]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"taxonomy_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{style:(_vm.taxLocaleSelect[index].vlaue == 'ar'
                          ? 'text-align: right !important;'
                          : 'text-align: right !important;'),attrs:{"id":'name' + index,"autocomplete":"on","placeholder":"Name","state":errors.length > 0 ? false : null},model:{value:(_vm.itemData.taxonomy[index].name_en),callback:function ($$v) {_vm.$set(_vm.itemData.taxonomy[index], "name_en", $$v)},expression:"itemData.taxonomy[index].name_en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(
                    _vm.termSelect[index] != null &&
                    _vm.termSelect[index].text != 'مميزات اضافية' &&
                    _vm.termSelect[index].text != 'ملحوظات'
                  )?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"description"}},[_c('validation-provider',{attrs:{"name":"taxonomy description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [(
                          _vm.itemData.taxonomy[index].name == 'المعالج' ||
                          _vm.itemData.taxonomy[index].name ==
                          'عدد أنوية المعالج' ||
                          _vm.itemData.taxonomy[index].name == 'WLAN' ||
                          _vm.itemData.taxonomy[index].name == 'GPS' ||
                          _vm.itemData.taxonomy[index].name ==
                          'معلومات عن الشاحن' ||
                          _vm.itemData.taxonomy[index].typeCheck == 'new-record'
                        )?_c('b-form-textarea',{staticClass:"taxDesc",attrs:{"autocomplete":"off","placeholder":"Description","id":_vm.findAndReplace(
                          _vm.itemData.taxonomy[index].name_en,
                          ' ',
                          '-'
                        ),"name":_vm.findAndReplace(
                            _vm.itemData.taxonomy[index].name_en,
                            ' ',
                            '-'
                          ),"state":errors.length > 0 ? false : null},model:{value:(_vm.itemData.taxonomy[index].description_en),callback:function ($$v) {_vm.$set(_vm.itemData.taxonomy[index], "description_en", $$v)},expression:"itemData.taxonomy[index].description_en"}}):_c('b-form-input',{staticClass:"taxDesc",attrs:{"list":_vm.itemData.taxonomy[index].name,"placeholder":"Description","id":_vm.findAndReplace(
                          _vm.itemData.taxonomy[index].name_en,
                          ' ',
                          '-'
                        ),"name":_vm.findAndReplace(
                            _vm.itemData.taxonomy[index].name_en,
                            ' ',
                            '-'
                          ),"autocomplete":"off","state":errors.length > 0 ? false : null},model:{value:(_vm.itemData.taxonomy[index].description_en),callback:function ($$v) {_vm.$set(_vm.itemData.taxonomy[index], "description_en", $$v)},expression:"itemData.taxonomy[index].description_en"}}),_c('datalist',{attrs:{"id":_vm.itemData.taxonomy[index].name}},_vm._l((_vm.itemData.taxonomy[index]
                            .resultTaxonomySearch),function(ite,ind){return _c('option',{key:ind},[_vm._v(" "+_vm._s(ite.name)+" ")])}),0),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_c('b-col',{attrs:{"md":"9"}},[_c('b-form-group',{attrs:{"label-for":"description"}},[_c('validation-provider',{attrs:{"name":"taxonomy description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('vue-editor',{attrs:{"id":_vm.findAndReplace(
                          _vm.itemData.taxonomy[index].name_en,
                          ' ',
                          '-'
                        ),"name":_vm.findAndReplace(
                            _vm.itemData.taxonomy[index].name_en,
                            ' ',
                            '-'
                          ),"state":errors.length > 0 ? false : null,"rows":"20","editor-toolbar":_vm.customToolbar,"autocomplete":"on"},on:{"imageAdded":_vm.handleImageAdded},model:{value:(_vm.itemData.taxonomy[index].description_en),callback:function ($$v) {_vm.$set(_vm.itemData.taxonomy[index], "description_en", $$v)},expression:"itemData.taxonomy[index].description_en"}}),_c('datalist',{attrs:{"id":"my-list-id"}},_vm._l((_vm.itemData.taxonomy[index]
                            .resultTaxonomySearch),function(ite,ind){return _c('option',{key:ind},[_vm._v(" "+_vm._s(ite.name)+" ")])}),0),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(
                    !(
                      _vm.itemData.taxonomy[index].name == 'المعالج' ||
                      _vm.itemData.taxonomy[index].name ==
                      'the number of processor cores' ||
                      _vm.itemData.taxonomy[index].name == 'WLAN' ||
                      _vm.itemData.taxonomy[index].name == 'GPS' ||
                      _vm.itemData.taxonomy[index].name ==
                      'charging info' ||
                      _vm.itemData.taxonomy[index].typeCheck == 'new-record'
                    )
                  )?_c('b-col',{attrs:{"cols":"1"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon rounded-circle",staticStyle:{"padding":"7px","margin":"6px","width":"35px","height":"35px","float":"right"},attrs:{"variant":"info"},on:{"click":function($event){return _vm.searchResultBundle(
                          _vm.itemData.taxonomy[index].name,
                          _vm.termSelect[index].value,
                          _vm.taxLocaleSelect[index].value,
                          index,
                          _vm.itemData.taxonomy[index].description
                        )}}},[_c('feather-icon',{staticStyle:{"width":"15px","height":"15px"},attrs:{"icon":"SearchIcon"}})],1)],1):_vm._e(),_c('b-col',{staticClass:"mb-50",attrs:{"md":"1"}},[(index > 0)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mb-0",staticStyle:{"margin-bottom":"0 !important"},attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.taxonomyremoveItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr',{staticStyle:{"margin-top":"0 !important"}})])],1)}),1),(_vm.itemData.taxonomy.length > 0)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":_vm.taxonomyrepeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add New")])],1)],1):_vm._e(),(_vm.itemData.taxonomy.length == 0)?_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"alert alert-danger p-1",attrs:{"role":"alert"}},[_vm._v(" Please Select Category ")])]):_vm._e()],1)],1)],1),_c('b-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":"Attributes and Values"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',_vm._l((_vm.attrOptions),function(item,index){return _c('b-row',{key:index},[_c('b-col',{staticClass:"d-flex justify-content-center align-items-center",attrs:{"md":"2"}},[_c('p',{staticClass:"text-primary mb-0 font-weight-bold h4"},[_vm._v(" "+_vm._s(item.text)+" ")])]),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"values","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Values","label-for":"values","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"multiple":"","id":'values' + index,"clearable":false,"state":_vm.valuesSelect[index] === null ? false : true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":item.values,"label":"text"},model:{value:(_vm.valuesSelect[index]),callback:function ($$v) {_vm.$set(_vm.valuesSelect, index, $$v)},expression:"valuesSelect[index]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1)]),(_vm.attrOptions.length == 0)?_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"alert alert-danger p-1",attrs:{"role":"alert"}},[_vm._v(" Please Select Category ")])]):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":"Prices"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('div',_vm._l((_vm.itemData.prices),function(item,index){return _c('b-row',{key:item.id,attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',[_c('label',[_vm._v("Price")]),_c('validation-provider',{attrs:{"rules":"required|regex:\\b\\d+(?:\\.\\d\\d?)?\\b","name":"Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"on","state":errors.length > 0 ? false : null,"placeholder":"Enter Number Only"},model:{value:(_vm.itemData.prices[index].price),callback:function ($$v) {_vm.$set(_vm.itemData.prices[index], "price", $$v)},expression:"itemData.prices[index].price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"price_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Price Date","label-for":"price_date","state":errors.length > 0 ? false : null,"placeholder":"Purchase Date"}},[_c('flat-pickr',{staticClass:"form-control",model:{value:(_vm.itemData.prices[index].date),callback:function ($$v) {_vm.$set(_vm.itemData.prices[index], "date", $$v)},expression:"itemData.prices[index].date"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Currency","label-for":"currency","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":'language' + index,"state":_vm.currencySelect[index] === null ? false : true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.currencyOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.currencySelect[index]),callback:function ($$v) {_vm.$set(_vm.currencySelect, index, $$v)},expression:"currencySelect[index]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"1"}},[(index > 0)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.pricesremoveItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Description")]),_c('validation-provider',{attrs:{"rules":"required","name":"price description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('vue-editor',{attrs:{"id":'translationDescription' + index,"autocomplete":"on","state":errors.length > 0 ? false : null},model:{value:(_vm.itemData.prices[index].description),callback:function ($$v) {_vm.$set(_vm.itemData.prices[index], "description", $$v)},expression:"itemData.prices[index].description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1)]),_c('b-col',{attrs:{"sm":"2"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","block":""},on:{"click":_vm.pricesrepeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add New")])],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1),_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],attrs:{"fade":"","show":_vm.showDismissibleAlert,"variant":"danger"},on:{"dismissed":function($event){_vm.showDismissibleAlert = false}}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Alert")]),_c('div',{staticClass:"alert-body"},_vm._l((_vm.errors_back),function(values,index){return _c('ul',{key:index},_vm._l((values),function(value,indexVal){return _c('li',{key:indexVal},[_vm._v(" "+_vm._s(value)+" ")])}),0)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }