<template>
  <div>
    <b-card-code title="Update Status" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- Active -->
            <b-col md="6">
              <b-form-group label="Status" label-for="status" >
              <validation-provider #default="{ errors }" name="status" rules="required">
                  <v-select id="status" v-model="statusSelect" :state="statusSelect.value === null ? false : true" 
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="statusOptions" :selectable="(option) => !option.value.includes('select_value')
                      " label="text" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Note" label-for="note">
                <validation-provider #default="{ errors }" name="note" rules="required">
                  <b-form-textarea id="note" v-model="loggerNote" placeholder="Note...." rows="3"
                    :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button size="lg" variant="primary" type="submit" @click.prevent="validationForm">
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code";

import { heightTransition } from "@core/mixins/ui/transition";

import { required } from "@validations";

export default {
  components: {
    BCardCode,
  },
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      id: 0,
      errors_back: [],
      required,
      statusSelect: {
        text: null,
        value: null,
      },
      statusOptions: [],
      loggerNote: null,
      VendorData: {},
    };
  },
  created() {
    // request status data
    this.id = this.$route.params.id;
    axios
      .get("status")
      .then((result) => {
        const data = result.data.data;
        for (let index in data) {
          this.statusOptions.push({
            value: data[index].slug,
            text: data[index].name,
          });
        }
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
    axios
      .get("vendors/" + this.id + "/show")
      .then((result) => {
        this.VendorData = result.data.data;
        this.statusSelect.text = this.VendorData.status.name;
        this.statusSelect.value = this.VendorData.status.slug;
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
  },
  methods: {
    // update function to status
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {

          if( this.VendorData.status.slug == this.statusSelect.value){
            this.$swal({
                position: "center",
                icon: "error",
                title: "Please Select New Status!",
                showConfirmButton: false,
                timer: 1500,
              });
              return;
          }
          axios
            .post(
              "vendors/" +
              this.id +
              "/change-status/" +
              this.statusSelect.value , {note : this.loggerNote}
            )
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.back(1);
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
