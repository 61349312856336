<template>
  <section class="app-ecommerce-details">
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, index) in values" :key="index">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <!-- Content -->
    <b-card v-if="itemData" no-body>
      <b-badge
        variant="danger"
        class="p-1"
        style="font-size: 15px"
        v-if="!delCat"
        >Archived Data</b-badge
      >
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="mainImage"
                :alt="`Image of ${itemTrans_name}`"
                class="product-img"
                fluid
              />
            </div>
          </b-col>
          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4>
              <span style="color: #0d6efd">Name:</span> {{ itemTrans_name }} -
              {{ itemTrans_name2 }}
            </h4>
            <br />
            <h4><span style="color: #0d6efd">SKU: </span> {{ sku }}</h4>
            <br />

            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color: #0d6efd">Original Price: </span
                >{{ itemData.original_price }} EGP
              </h4>
            </b-card-text>
            <br v-if="offer != null" />
            <b-card-text class="item-company mb-0">
              <h4
                v-if="offer != null"
                v-show="
                  offer.amount != 0 &&
                  offer.amount != '' &&
                  offer.amount != null
                "
              >
                <span style="color: #0d6efd">Offer: </span>
                <b-badge varaint="primary" v-if="offer.type == 'fixed_price'"
                  >{{ offer.amount }} EGP</b-badge
                >
                <b-badge variant="primary" v-else>{{ offer.amount }}%</b-badge>
              </h4>
            </b-card-text>
            <br />
            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color: #0d6efd">Sale Price: </span>{{ price }}
              </h4>
            </b-card-text>
            <br />
            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color: #0d6efd">Profit: </span
                >{{ itemData.profit }} EGP
              </h4>
            </b-card-text>
            <br />
            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color: #0d6efd">Brand:</span>
                <b-link
                  style="color: black"
                  :to="{ path: '/Show-Brand/' + brand_id }"
                >
                  {{ brandTrans_name }}
                </b-link>
              </h4>
            </b-card-text>
            <br />
            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color: #0d6efd">Category:</span>
                <b-link
                  style="color: black"
                  :to="{ path: '/Show-Category/' + cat_id }"
                >
                  {{ catTrans_name }}</b-link
                >
              </h4>
            </b-card-text>
            <br />
            <!-- Avability -->
            <b-card-text v-if="itemData.status === 'confirmed'">
              <span style="color: #0d6efd">Status</span>
              -
              <b-badge variant="success">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-else-if="itemData.status === 'enabled'"
              ><span style="color: #0d6efd">Status </span>-
              <b-badge variant="success">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-else-if="itemData.status === 'disabled'"
              ><span style="color: #0d6efd">Status </span> -
              <b-badge variant="danger">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-else-if="itemData.status === 'review'"
              ><span style="color: #0d6efd">Status </span> -
              <b-badge variant="warning">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-else-if="itemData.status === 'archieved'"
              ><span style="color: #0d6efd">Status </span> -
              <b-badge variant="dark">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-else-if="itemData.status === 'blocked'"
              ><span style="color: #0d6efd">Status </span> -
              <b-badge variant="dark">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-else-if="itemData.status === 'sold_out'"
              ><span style="color: #0d6efd">Status </span> -
              <b-badge variant="info">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-else
              ><span style="color: #0d6efd">Status </span> -
              <b-badge variant="dark">{{ itemData.status }}</b-badge>
            </b-card-text>
            <br />
            <b-card-text
              ><span style="color: #0d6efd">Sales Channel </span> -
              <b-badge v-if="itemData.is_portal" variant="primary"
                >Portal</b-badge
              >
              <b-badge v-else variant="secondary">In Store</b-badge>
            </b-card-text>
            <b-card-text v-if="itemData.payment_provider"
              ><span style="color: #0d6efd">Payment Method </span> -
              <b-badge  variant="primary"
                >{{itemData.payment_provider.name}}</b-badge
              >
            </b-card-text>
            <b-card-text v-if="itemData.vendor">
              <span style="color: #0d6efd">Verified Seller : </span>
              <b-link
                style="color: black"
                :to="{ path: '/Vendor/Show/' + itemData.vendor.id }"
                >{{ itemData.vendor.storename }} -
                {{ itemData.vendor.code }}
              </b-link>
            </b-card-text>
            <b-card-text v-if="itemData.user">
              <span style="color: #0d6efd">Private Seller : </span>

              <b-link
                v-if="itemData.user"
                style="color: black"
                :to="{ path: '/User/Show/' + itemData.user.id }"
                >{{ itemData.user.firstname }} - {{ itemData.user.code }}
              </b-link>
            </b-card-text>

            <b-card-text
              ><span style="color: #0d6efd">Product </span> -
              <b-link
                style="color: black"
                :to="{ path: '/New/ShowProduct/' + parentID }"
                >{{ PhGuide }}
              </b-link>
            </b-card-text>
            <b-card-text
              ><span style="color: #0d6efd">Guide </span> -
              <b-link
                style="color: black"
                :to="{ path: '/ShowPhoneGuide/' + p_ID }"
                >{{ PhGuide }}
              </b-link>
            </b-card-text>
            <b-card-text v-if="itemData.is_insurance === true">
              <span style="color: #0d6efd">Insurance</span>
              -
              <img  v-if="itemData.is_insurance" width="25" class="mx-1" :src="require('@/assets/images/icons/insurance.png')" />
              <!-- <b-badge variant="success">{{ itemData.is_insurance }}</b-badge> -->
            </b-card-text>
            <b-card-text v-if="itemData.insurance_rate != null">
              <h4>
                <span style="color: #0d6efd">Insurance Rate: </span>{{ itemData.insurance_rate }}  EGP
              </h4>
            </b-card-text>
            
            <b-card-text
            v-if="itemData.publish_date"
              ><span style="color: #0d6efd">Publish Date : </span> 
             {{ itemData.publish_date }}
            
            </b-card-text>
            <hr />
            <div
              v-if="
                delCat && !itemData.is_portal && itemData.status === 'enabled'
              "
            >
              <b-button
                block
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1 mt-1"
                @click="showShare"
              >
                <span>Share Buy Link</span>
              </b-button>
            </div>
            <div v-if="delCat" class="d-flex flex-column flex-sm-row">
              <b-button
                block
                v-if="authPermissions.includes('update-product-status') && itemData.status != 'expired' && itemData.status != 'sold_out'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="mr-1 mt-1"
                :to="{ path: '/New/ShowProduct/update/variant/' + itemData.id }"
              >
                <feather-icon icon="RefreshCwIcon" class="mr-50" />
                <span>Update</span>
              </b-button>
              <b-button
                block
                v-show="itemData.status === 'confirmed'"
                v-if="authPermissions.includes('enabled-product')"
                @click="activeVariant(itemData.id)"
                variant="outline-info"
                class="mr-1 mt-1"
              >
                Active
              </b-button>

              <b-button
                block
                v-show="itemData.status === 'enabled'"
                v-b-modal.modal-deactive
                v-if="authPermissions.includes('update-product-status')"
                variant="outline-info"
                class="mr-1 mt-1"
              >
                Deactive
              </b-button>
              <b-modal
                id="modal-deactive"
                cancel-variant="outline-secondary"
                @ok="deActiveVariant(itemData.id)"
                ok-title="Ok"
                cancel-title="Close"
                centered
                title="Deactive Varaint"
              >
                <b-form>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="deactive"
                    name="checkbox-1"
                    value="1"
                    unchecked-value="0"
                  >
                    Force Deactive
                  </b-form-checkbox>
                </b-form>
              </b-modal>
              <b-button
                block
                v-b-modal.modal-delete
                v-if="authPermissions.includes('archive-product')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="mr-1 mt-1"
              >
                <feather-icon icon="DeleteIcon" class="mr-50" />
                <span>Delete Variant</span>
              </b-button>
              <b-modal
                id="modal-delete"
                cancel-variant="outline-secondary"
                @ok="deleteVariant(itemData.id)"
                ok-title="Ok"
                cancel-title="Close"
                centered
                title="Delete Variant"
              >
                <b-form>
                  <b-form-checkbox
                    id="checkbox-2"
                    v-model="deletedVariant"
                    name="checkbox-2"
                    value="1"
                    unchecked-value="0"
                  >
                    Force Delete
                  </b-form-checkbox>
                </b-form>
              </b-modal>
            </div>
            <div v-else class="d-flex justify-content-center">
              <b-button
                block
                @click="restoreItem(itemData.id)"
                v-if="authPermissions.includes('update')"
                variant="outline-danger"
                class="mr-1 mt-1"
              >
                Restore
              </b-button>
            </div>
            <hr />
            <div
              v-if="delCat"
              class="d-flex justify-content-md-center align-items-center text-center"
            >
              <div class="m-4">
                <h6 class="text-muted font-weight-bolder">Created at</h6>
                <h3 class="mb-0">
                  {{ itemData.created_at }}
                </h3>
              </div>
              <div class="m-4">
                <h6 class="text-muted font-weight-bolder">Updated at</h6>
                <h3 class="mb-0">
                  {{ itemData.updated_at }}
                </h3>
              </div>
            </div>
            <div
              v-else
              class="d-flex justify-content-md-center align-items-center text-center"
            >
              <div class="m-4">
                <h6 class="text-muted font-weight-bolder">Created at</h6>
                <h3 class="mb-0">
                  {{ itemData.created_at }}
                </h3>
              </div>
              <div class="m-4">
                <h6 class="text-muted font-weight-bolder">Deleted at</h6>
                <h3 class="mb-0">
                  {{ itemData.deleted_at }}
                </h3>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-col md="12">
      <b-row>
        <b-col
          class="mb-1"
          cols="12"
          md="6"
          sm="12"
          v-if="itemData.variant_details"
        >
          <b-card
            title="Ads Details"
            class="h-100 d-flex flex-wrap table-responsive"
          >
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <th class="text-left">City</th>
                  <td class="text-right">
                    {{ itemData.variant_details.city.name }}
                  </td>
                </tr>
                <tr>
                  <th class="text-left">Seller Phone</th>
                  <td class="text-right">
                    <a
                      :href="'tel:' + itemData.variant_details.phone"
                      class="h4"
                    >
                      {{ itemData.variant_details.phone }}</a
                    >
                  </td>
                </tr>
                <tr>
                  <th class="text-left">Expire Date</th>
                  <td class="text-right">
                    {{ itemData.variant_details.end_date }}
                  </td>
                </tr>
                <tr>
                  <th class="text-left">Description</th>
                  <td class="text-right">
                    {{ itemData.variant_details.description }}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <h4 v-if="itemData.warranty">Warranty Details</h4>
            <table class="table table-bordered" v-if="itemData.warranty ">
              <tbody>
                <tr>
                  <th class="text-left">Type</th>
                  <td class="text-right">
                    {{ itemData.warranty.type }}
                  </td>
                </tr>
                <tr>
                  <th class="text-left">End Date</th>
                  <td class="text-right">
                    {{ itemData.warranty.end_date }}
                  </td>
                </tr>
                <tr>
                  <th class="text-left">Duration</th>
                  <td class="text-right">
                    {{ itemData.warranty.duration }}
                  </td>
                </tr>
                <tr v-if="itemData.warranty && itemData.warranty.agency_file">
                  <th class="text-left">File</th>
                  <td class="text-right" v-if="itemData.warranty && itemData.warranty.agency_file">
                    <span>
                      <img
                        v-b-modal.modal-warranty-file
                        :src="require('@/assets/images/icons/photo-up.svg')"
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- Madal For id-pictures User Action-->
            <b-modal
            v-if=" itemData.warranty"
              id="modal-warranty-file"
              cancel-variant="outline-secondary"
              ok-title="Ok"
              cancel-title="Close"
              centered
              title="Warranty File"
            >
              <b-row>
                <b-col cols="12" md="6" class="mt-2">
                  <b-card title="Warranty File">
                    <div class="d-flex justify-content-start">
                    
                      <b-img
                        rounded
                        :src="itemData.warranty.agency_file"
                        :alt="itemData.warranty.type"
                      />
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </b-modal>
          </b-card>
        </b-col>
        <b-col
          class="mb-1"
          cols="12"
          md="6"
          sm="12"
          v-if="
            itemData.variant_details &&
            itemData.variant_details.payment_options.length > 0
          "
        >
          <b-card
            class="h-100 d-flex flex-wrap table-responsive"
            title="Variant Payment Options"
          >
            <!-- start data table -->
            <b-table
              striped
              hover
              responsive
              :items="itemData.variant_details.payment_options"
              :fields="paymentOptionsFeilds"
            >
              <template #cell(logo)="data">
                <!-- <b-avatar :src="data.value" /> -->

                <b-img-lazy
                  width="40"
                  :src="data.value"
                />
                <!-- <p v-else>
                  <b-badge variant="light-primary">{{
                    data.item.name
                  }}</b-badge>
                </p> -->
              </template>

              <template #cell(payout_price)="data">
                {{
                  itemData.sale_price -
                  ((itemData.sale_price * data.item.fees) / 100)
                }}
              </template>

              <template #cell(margin_price)="data">
                {{ ((itemData.sale_price * data.item.fees) / 100) }}
              </template>

              <template #cell(sale_price)="">
                {{ itemData.sale_price }}
              </template>
              <template #cell(fees)="data"> {{ data.value }} % </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col class="mb-1" cols="12" md="6" v-if="itemData.user">
          <b-card title="Bidding Requests">
            <b-table
              id="DTTable"
              ref="admint"
              striped
              hover
              responsive
              show-empty
              class="position-relative"
              :items="itemData.biddings"
              :fields="biddingFields"
            >
              <template #cell(payment_provider)="items">
                <b-img-lazy
                  v-if="items.value.logo"
                  width="40"
                  :src="items.value.logo"
                />
                <p v-else>
                  <b-badge variant="light-primary">{{
                    items.value.name
                  }}</b-badge>
                </p>
              </template>

              <template #cell(status)="items">
                <b-badge
                  size="lg"
                  :variant="
                    items.value == 'accept'
                      ? 'light-success'
                      : items.value == 'canceled'
                      ? 'light-primary'
                      : items.value == 'reject'
                      ? 'light-warning'
                      : 'light-secondary'
                  "
                >
                  {{ items.value }}
                </b-badge>
              </template>

              <template #cell(buyer_username)="items">
                <b-badge
                  size="sm"
                  variant="info"
                  :to="{ path: '/User/Show/' + items.item.buyer_id }"
                >
                  {{ items.value }}
                </b-badge>
              </template>
              <template #cell(actions)="items">
                <div class="demo-inline-spacing">
                  <b-button
                    style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                    :to="{ path: '/biddings-requests/show/' + items.item.id }"
                    variant="success"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      style="width: 18px; height: 18px"
                    />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col
          class="mb-1"
          cols="12"
          md="6"
          v-if="itemData.user"
        >
          <b-card title="subscription Details">
            <b-table
            v-if="subscriptionDetails.length > 0"
              id="DTTable"
              ref="admint"
              striped
              hover
              responsive
              show-empty
              class="position-relative"
              :items="subscriptionDetails"
              :fields="subscriptionFields"
            >
            

              <template #cell(subscription_status)="data">
                <b-badge :variant="data.value == 'success' ? 'success' : (data.value == 'failed' ? 'danger' : 'secondary')">{{ data.value }}</b-badge>
           
              </template>

              <template #cell(package_snapshot)="data">
                <b-badge variant="light-info">

                  {{ JSON.parse(data.value).code }} 
                </b-badge>
            </template>

            <template #cell(created_at)="data">
                  {{ data.value | formatDate }}
                </template>
            <template #cell(updated_at)="data">
                  {{ data.value | formatDate }}
                </template>


              <template #cell(actions)="items">
                <div class="demo-inline-spacing">
                          <b-button
                  style="padding: 3px; margin: 3px; width: 35px; height: 35px;"
                  size="sm"
                  v-if="authPermissions.includes('show')"
                  variant="success"
                  type="submit"
                  :to="{ path: '/subscription/show/' + items.item.id }"
                >
                

                    <feather-icon
                      icon="EyeIcon"
                      style="width: 18px; height: 18px"
                    />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
        
      </b-row>
    </b-col>
    <b-card title="Main Specification" v-if="itemData.attributes != null">
      <div>
        <table class="table text-left">
          <thead>
            <tr>
              <th scope="col">Specification</th>
              <th scope="col">Values</th>
              <th
                scope="col"
                v-if="
                  authPermissions.includes('update-variant-values') &&
                  !itemData.is_portal
                "
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in itemData.attributes"
              class="mb-2 mt-2"
              :key="index"
              :rowspan="item.itemValues.length"
            >
              <th scope="row">{{ item.translation.name }}</th>

              <td class="">
                <div
                  v-for="(val, ind) in item.itemValues"
                  class="border-0"
                  :key="ind"
                >
                  <td :class="item.itemValues.length > 1 ? '' : 'border-0'">
                    {{ val.translation.name }}
                  </td>
                </div>
              </td>

              <td
                v-if="
                  authPermissions.includes('update-variant-values') &&
                  !itemData.is_portal
                "
              >
                <b-button
                  v-if="!item.is_variant"
                  target="_blank"
                  variant="none"
                  type="submit"
                  @click="UpdateVariantValueModal(index, item.id)"
                >
                  <img :src="require('@/assets/images/icons/edit-new.svg')" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>

    <b-col md="12">
      <b-row>
        <b-col class="mb-1" cols="12" md="6" sm="12">
          <!-- start accessories section -->
          <b-card v-if="itemData.is_portal" title="Accessories">
            <div
              v-for="(accessory, index) in accessories"
              class="d-inline p-2"
              :key="index"
            >
              <span style="font-size: 17px"
                ><b-badge
                  variant="light-primary"
                  style="padding: 10px"
                  class="badge-glow badge-lg"
                >
                  {{ accessory.translation.name }}</b-badge
                ></span
              >
            </div>
          </b-card>
          <!-- end accessories section -->
        </b-col>
        <b-col class="mb-1" cols="12" md="6" sm="12">
          <!-- tags section -->
          <b-card v-if="itemData.is_portal" title="Tags">
            <div v-for="(tag, index) in tags" class="d-inline p-2" :key="index">
              <span style="font-size: 17px"
                ><b-badge
                  variant="light-primary"
                  style="padding: 10px"
                  class="badge-glow badge-lg"
                  >{{ tag.name }}</b-badge
                ></span
              >
            </div>
          </b-card>
        </b-col>
        <b-col class="mb-1" cols="12" md="6" sm="12">
          <b-card title="Prices">
            <!-- prices table -->
            <b-table
              :sticky-header="stickyHeader"
              :no-border-collapse="noCollapse"
              responsive
              :items="itemData.price_loggers"
              :fields="fields"
            >
            </b-table>
          </b-card>
        </b-col>
        <b-col class="mb-1" cols="12" md="6" sm="12">
          <!-- Description section -->
          <b-card v-if="itemData.is_portal" title="Description">
            <b-tabs align="center" class="text-center">
              <b-tab
                v-for="(translation, index) in itemTrans"
                :title="translation.locale == 'en' ? 'English' : 'Arabic'"
                :key="index"
              >
                <b-card-text>
                  <div v-html="translation.short_description"></div>
                </b-card-text>
              </b-tab>
            </b-tabs>
            <!-- add new translation -->
          </b-card>
        </b-col>
      </b-row>
    </b-col>

    <!-- photo gallery section -->
    <CoolLightBox
      :items="photoGallery"
      :index="imgIndex"
      @close="imgIndex = null"
    >
    </CoolLightBox>
    <b-card v-if="itemData.is_portal" title="Media">
      <b-row>
        <b-col v-if="videoSect" cols="6" md="6">
          <h2>Video</h2>
          <!-- <video-player
            class="video-player-box"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
            customEventName="customstatechangedeventname"
            @play="onPlayerPlay($event)"
            @pause="onPlayerPause($event)"
            @statechanged="playerStateChanged($event)"
            @ready="playerReadied"
          >
          </video-player> -->
          <video controls>
            <source :src="playerOptions.sources[0].src" />
          </video>
        </b-col>
        <b-col class="mt-2" cols="12">
          <h2>Photo Gallery</h2>
          <swiper
            class="swiper-autoplay"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide v-for="(data, index) in photoGallery" :key="index">
              <b-img
                @click="imgIndex = index"
                width="150px"
                height="200px"
                :src="data.src"
                style="display: block; margin: auto"
              />
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn-icon mt-1" :to="{name: 'EditImage',params:{imgID:data.id,image:data.src, desc:data.alt}}">
            
                                                                                                                Update
            
                                                                                                            </b-button> -->

                <b-button
                  v-if="authPermissions.includes('update_product_media')"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="btn-icon ml-1 mt-1"
                  @click="deleteImages(data.id)"
                >
                  Delete
                </b-button>
              </div>
            </swiper-slide>
            <div slot="pagination" class="swiper-pagination" />
          </swiper>
          <b-button
            v-if="authPermissions.includes('update_product_media')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="success"
            block
            class="btn-cart mt-3"
            :to="{ path: '/New/ShowProduct/variant/add/image/' + itemData.id }"
          >
            <feather-icon icon="RefreshCwIcon" class="mr-50" />
            <span>Add New Image</span>
          </b-button>
        </b-col>
        <!-- <b-col cols="12">
                    <b-card title="Reports"> -->
        <!-- start data table -->
        <!-- <b-col cols="12">
                    <b-table striped hover responsive  :items="items" :fields="fields2" >
                        <template #cell(user)="items">
                            <b-badge size="lg" v-if="items.value.includes('Vendor:')" variant="primary">{{items.value}}</b-badge>
                            <b-badge size="lg" v-else variant="info">{{items.value}}</b-badge>

                        </template>
                        
                        <template #cell(actions)="items">
                            <div class="demo-inline-spacing">
                                <b-button style='padding:7px; margin:6px; width:35px; height:35px;' :to="{path: '/diagnostic/reports/show/' + items.item.id}" variant="success" class="btn-icon rounded-circle">
                                    <feather-icon icon="EyeIcon" style="width:18px; height:18px;" />
                                </b-button>
                                <b-button style='padding:7px; margin:6px; width:35px; height:35px;' v-if="authPermissions.includes('delete')" @click="deleteReport(items.item.id)" variant="danger" class="btn-icon rounded-circle">
                                    <feather-icon icon="Trash2Icon" style="width:18px; height:18px;" />
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </b-col>
                    </b-card>
                </b-col> -->
      </b-row>
    </b-card>

     <!-- Variant Logger -->
     <b-card title="Rejected Notes" class="d-flex flex-wrap" v-if="itemData.variant_loggers">
      <b-row>

        <b-col class="mt-3" cols="12">
          <app-timeline>
            <app-timeline-item
              v-for="(item, index) in itemData.variant_loggers"
              :key="index"
              :variant="item.admin != null ? 'primary' : 'success'"
            >
              <b-media v-if="item.admin != null">
                <template #aside>
                  <b-avatar :src="item.admin.profile_picture" />
                </template>
                <h6>{{ item.admin.firstname + ' ' + item.admin.lastname }}</h6>
                <p>{{ item.admin.role }}</p>
              </b-media>
              <b-media v-else>
                <template #aside>
                </template>
              </b-media>
              
              <div
                class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
              >
              <ul>
                <li 
                v-for="(value, index) in item.note"
                :key="index">
                  <h5>{{ value.key }}</h5>
                  <p>{{ value.value }}</p>
                </li>
              </ul>
              
              </div>
            </app-timeline-item>
          </app-timeline>
        </b-col>
      </b-row>
    </b-card>
    <!-- share  -->
    <b-modal
      hide-footer
      size="lg"
      ref="update-variant-values"
      ok-title="Ok"
      cancel-title="Close"
    >
      <b-card>
        <!-- select values box -->
        <b-col :md="12">
          <v-select
            v-model="selectedValue"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="selectedValueOptions"
          />
        </b-col>
        <!-- assign permission button -->
        <b-col :md="4" v-if="selectedValueOptions.length > 1">
          <div class="demo-inline-spacing">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              @click="UpdateVariantValuesAction(selectedValue.attribute_id)"
            >
              Update
            </b-button>
          </div>
        </b-col>

        <!-- error handelr -->
        <b-col md="12" class="mt-1">
          <b-alert
            v-height-fade.appear
            fade
            :show="showDismissibleAlertUpdateValues"
            @dismissed="showDismissibleAlertUpdateValues = false"
            variant="danger"
          >
            <h4 class="alert-heading">Alert</h4>
            <div class="alert-body">
              <ul
                v-for="(values, index) in update_values_errors_back"
                :key="index"
              >
                <li v-for="(value, index) in values" :key="index">
                  {{ value }}
                </li>
              </ul>
            </div>
          </b-alert>
        </b-col>
      </b-card>
    </b-modal>
    <!-- share  -->
    <b-modal size="lg" id="modal-center" ref="show-share" hide-footer centered>
      <b-card>
        <b-row>
          <b-col md="4" class="d-flex justify-content-center mb-1">
            <qr-code :size="200" :text="share_link"></qr-code>
          </b-col>
          <b-col md="8">
            <b-row class="mb-2">
              <b-col cols="3" class="text-primary font-weight-bold">Name</b-col>
              <b-col
                cols="9"
                class="border border-primary rounded text-primary py-1"
                >{{ PhGuide }}</b-col
              >
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3" class="text-primary font-weight-bold"
                >Brand</b-col
              >
              <b-col
                cols="9"
                class="border border-primary rounded text-primary py-1"
                >{{ brandTrans_name }}</b-col
              >
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3" class="text-primary font-weight-bold"
                >Category</b-col
              >
              <b-col
                cols="9"
                class="border border-primary rounded text-primary py-1"
                >{{ catTrans_name }}</b-col
              >
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3" class="text-primary font-weight-bold"
                >Price</b-col
              >
              <b-col
                cols="9"
                class="border border-primary rounded text-primary py-1"
                ><span class="text-secondary font-weight-bold h3"
                  >{{ itemData.sale_price }}
                </span>
                EGP
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <p class="text-primary mb-0">Product Buy Link</p>
            <div class="d-flex justify-content-start">
              <p
                id="myInput"
                v-if="share_link"
                class="text-primary p-1 mb-0 border border-primary rounded"
              >
                {{ share_link.slice(0, 75) + '...' }}
              </p>
              <b-button @click="copy" variant="none">
                <feather-icon size="20" icon="CopyIcon" /> Copy
              </b-button>
            </div>

            <p class="text-secondary mb-0">
              This link is valid for 3 hours only
            </p>
          </b-col>

          <b-col cols="12" class="text-center p-1">
            <p class="h3 text-secondary mb-1 font-weight-bold text-center">
              Share link
            </p>
            <b-row>
              <b-col
                class="mb-2"
                v-for="network in networks"
                :key="network.network"
              >
                <ShareNetwork
                  :network="network.network"
                  :style="{ backgroundColor: network.color }"
                  :url="share_link"
                  :title="'MobileMasr'"
                  :description="'بيع مباشر'"
                  class="h-100 w-100"
                >
                  <span class="text-white p-2">{{ network.name }}</span>
                </ShareNetwork>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
  </section>
</template>

<script>
import Vue from 'vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'
// import axios from '@/libs/axios'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)
export default {
  name: 'MyComponent',
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },
  },
  components: {
    AppEchartLine,
    Swiper,
    SwiperSlide,
    CoolLightBox,
    AppTimeline,
    AppTimelineItem
  },
  data() {
    return {
      share_link: null,
      networks: [
        {
          network: 'whatsapp',
          name: 'Whatsapp',
          color: '#25d366',
        },
        {
          network: 'facebook',
          name: 'Facebook',

          color: '#1877f2',
        },

        {
          network: 'messenger',
          name: 'Messenger',

          color: '#0084ff',
        },

        {
          network: 'email',
          name: 'Email',

          color: '#333333',
        },

        {
          network: 'sms',
          name: 'SMS',

          color: '#333333',
        },

        {
          network: 'telegram',
          name: 'Telegram',

          color: '#0088cc',
        },
      ],
      optionDiagram: {
        xAxisData: [],
        series: [],
      },
      tags: null,
      accessories: [],
      stickyHeader: true,
      deletedVariant: '0',
      noCollapse: true,
      fields: [
        {
          key: 'id',
          stickyColumn: true,
          isRowHeader: true,
          variant: 'primary',
        },
        {
          key: 'price',
          label: 'Price',
        },
        {
          key: 'date',
          label: 'Date',
        },
      ],
      options: [],
      deactive: '0',
      deletedProduct: false,
      videoSect: false,
      trashed: false,
      playerOptions: {
        // videojs options
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: 'video/mp4',
            src: '',
          },
        ],
        poster: '',
      },
      selectedValueOptions: [],
      selectedValue: {},
      related: [],
      relatedParent: false,
      varAR: [],
      varEN: [],
      p_ID: 0,
      brand_id: 0,
      cat_id: 0,
      delCat: true,
      itemIDV: '',
      authPermissions: [],
      offer: null,
      parentSet: true,
      showImg: false,
      imgIndex: null,
      mainImage: '',
      id: 0,
      itemData: {},
      itemComments: [],
      errors_back: [],
      showDismissibleAlert: false,
      update_values_errors_back: [],
      showDismissibleAlertUpdateValues: false,
      itemTrans: '',
      itemDesc: null,
      itemTrans_name: null,
      itemTrans_name2: null,
      catTrans: null,
      catTrans_name: null,
      brandTrans: null,
      brandTrans_name: null,
      taxonomies: [],
      Catactive: [],
      price: '',
      active: '',
      ShortDesc: '',
      ShortDesc2: '',
      ShortDesc3: '',
      parentSKU: '',
      parentID: 0,
      sku: '',
      average_rate: '',

      PhGuide: '',
      PhGuide_Desc: '',

      parentCover: '',
      phonegudieCover: '',

      length: '',
      width: '',
      height: '',
      weight: '',
      mass_unit: '',
      distance_unit: '',
      storename: '',
      quantity: '',
      Pdesc: '',
      Pname: '',
      aRate: '',
      variants: null,
      option: {
        xAxisData: [],
        series: [],
      },
      photoGallery: [],
      settings: {
        dots: true,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
      },
      items: [],

      // table fields
      biddingFields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'buyer_username',
          label: 'Buyer Username',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'offer_price',
          label: 'Offer Price',
        },

        {
          key: 'payment_provider',
          label: 'Payment Method',
        },
        {
          key: 'phone',
          label: 'Phone',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      fields2: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'user',
          label: 'user / vendor',
        },
        {
          key: 'type',
          label: 'type',
        },
        {
          key: 'variant_id',
          label: 'variant id',
        },
        {
          key: 'serial_number',
          label: 'serial number',
        },

        {
          key: 'score',
          label: 'score',
        },
        {
          key: 'score',
          label: 'score',
        },
        {
          key: 'brand_name',
          label: 'brand name',
        },
        {
          key: 'actions',
        },
      ],
      subscriptionFields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'code',
          label: 'code',
        },
       
        
        {
          key: 'package_snapshot',
          label: 'packages Code',
        },

        {
          key: 'subscription_status',
          label: 'Status',
        },

        
        {
          key: 'total_paid',
          label: 'total_paid',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },

        {
          key: 'actions',
          label: 'actions',
        },



      ],
      paymentOptionsFeilds: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'logo',
          label: 'Payment Provider',
        },
        {
          key: 'fees',
          label: 'Payment Fees',
        },
        {
          key: 'sale_price',
          label: 'Sale Price',
        },

        {
          key: 'payout_price',
          label: 'Payout Price',
        },
        {
          key: 'margin_price',
          label: 'Margin Price',
        },
      ],
      stickyHeader: true,
      noCollapse: true,
      fields: [
        {
          key: 'id',
          stickyColumn: true,
          isRowHeader: true,
          variant: 'primary',
        },
        {
          key: 'price',
          label: 'Price',
        },

        {
          key: 'created_at',
          label: 'Date',
        },
      ],
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
      currentPage: 0,
      restoreSelected: {
        variants: [],
      },
      currentPage2: 0,
      options2: [],
      restoreSelected2: {
        variants: [],
      },
      subscriptionDetails: [],
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    this.showDismissibleAlert = false
    this.id = this.$route.params.id
    this.fetchData()
    // this.getAllVariantsInSystem()
    // this.getAllTrashedVariantsInSystem()
  },
  methods: {
    copy() {
      // let text = document.getElementById('myText').innerHTML;

      navigator.clipboard.writeText(this.share_link)
      this.$swal({
        position: 'center',
        icon: 'success',
        title: 'Copied',
        showConfirmButton: false,
        timer: 1500,
      })
    },
    showShare() {
      axios
        .get('variants/' + this.id + '/in_store/link/generate')
        .then((result) => {
          this.share_link = result.data.data.link
          this.$refs['show-share'].show()
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    async UpdateVariantValueModal(index, attribute_id) {
      // if the values already exists for the attribute
      if (!this.itemData.attributes[index].hasOwnProperty('values')) {
        await this.getValuesByItemAndAttribute(index, attribute_id)
      }

      await this.setUpdateSelectedValues(index)

      await this.openUpdateVariantValuesModel()
    },
    setUpdateSelectedValues(index) {
      this.selectedValueOptions = this.itemData.attributes[index].values
      // console.log('selected options ', this.selectedValueOptions)
      this.selectedValue = {
        id: this.itemData.attributes[index].itemValues[0].id,
        attribute_id:
          this.itemData.attributes[index].itemValues[0].attribute_id,
        name: this.itemData.attributes[index].itemValues[0].translation.name,
      }
    },
    openUpdateVariantValuesModel() {
      this.$refs['update-variant-values'].show()
    },
    fetchData() {
      axios
        .get('variants/' + this.id + '/show')
        .then((result) => {
          this.itemData = result.data.data
          this.offer = this.itemData.offer
          //console.log(result)
          //console.log(this.itemData)
          if (this.itemData.deleted_at != null) {
            this.delCat = false
          } else {
            this.delCat = true
          }
          this.tags = this.itemData.product.tags
          this.accessories = this.itemData.product.accessories
          this.optionDiagram.xAxisData.length = 0
          this.optionDiagram.series.length = 0
          if (this.itemData.price_loggers.length > 0) {
            for (let price in this.itemData.price_loggers) {
              this.optionDiagram.xAxisData.push(
                this.itemData.price_loggers[price].date
              )
              this.optionDiagram.series.push(
                this.itemData.price_loggers[price].price
              )
            }
          }
          //console.log(this.optionDiagram)
          this.itemIDV = this.itemData.id.toString()
          this.price = this.itemData.price + ' EGP'
          if (this.itemData.translation.length > 0) {
            this.ShortDesc = this.itemData.translation[0].short_description
            this.itemTrans = this.itemData.translation
            this.itemTrans_name = this.itemData.translation[0].name
          }
          this.sku = this.itemData.sku
          this.average_rate = this.itemData.average_rate
          if (this.itemData.product.item.category.translation != null) {
            this.catTrans_name =
              this.itemData.product.item.category.translation.name
          }
          this.brand_id = this.itemData.product.item.brand.id
          this.cat_id = this.itemData.product.item.category.id
          if (this.itemData.product.item.brand.translation != null) {
            this.brandTrans_name =
              this.itemData.product.item.brand.translation.name
          }

          this.parentID = this.itemData.product.id
          if (this.itemData.product.translation != null) {
            this.PhGuide = this.itemData.product.translation.name
            this.ShortDesc2 =
              this.itemData.product.translation.short_description
          }

          this.parentCover = this.itemData.product.cover

          this.parentSKU = this.itemData.product.sku
          this.aRate = this.itemData.product.average_rate
          this.phonegudieCover = this.itemData.product.item.cover
          this.p_ID = this.itemData.product.item.id
          if (this.itemData.product.shipping != null) {
            this.length = this.itemData.product.shipping.length
            this.width = this.itemData.product.shipping.width
            this.height = this.itemData.product.shipping.height
            this.weight = this.itemData.product.shipping.weight
            this.distance_unit = this.itemData.product.shipping.distance_unit
            this.mass_unit = this.itemData.product.shipping.mass_unit
          }

          this.quantity = this.itemData.quantity
          // this.phname = this.itemData.product.item.name

          this.Pname = this.itemData.product.item.item_translations.name
          this.Pdesc =
            this.itemData.product.item.item_translations.short_description

          this.photoGallery.length = 0
          if (this.itemData.Media.length > 0) {
            this.mainImage = this.itemData.Media[0].name
            for (let img in this.itemData.Media) {
              if (this.itemData.Media[img].type != 'video') {
                this.photoGallery.push({
                  src: this.itemData.Media[img].name,
                  id: this.itemData.Media[img].id,
                })
              } else {
                // this.videoSect = true
                // this.playerOptions.sources[0].src =
                //   this.itemData.Media[img].name
              }
            }
          } else {
            this.mainImage = this.itemData.cover
          }

          // set video data
          if (this.itemData.video != null) {
            this.videoSect = true
            this.playerOptions.sources[0].src = this.itemData.video.name
          }

          this.items.length = 0
          if (this.itemData.reports.length > 0) {
            for (let index in this.itemData.reports) {
              this.items.push({
                id: this.itemData.reports[index].id,
                user:
                  this.itemData.reports[index].user != null
                    ? 'User: ' +
                      this.itemData.reports[index].user.firstname +
                      ' ' +
                      this.itemData.reports[index].user.lastname
                    : 'Vendor: ' +
                      this.itemData.reports[index].vendor.storename,
                type: this.itemData.reports[index].type,
                variant_id: this.itemData.reports[index].variant_id,
                serial_number: this.itemData.reports[index].serial_number,
                score: this.itemData.reports[index].score + ' %',
                brand_name: this.itemData.reports[index].brand_name,
              })
            }
          }

          if(this.itemData.variant_details){
            this.subscriptionDetails.push(
              this.itemData.variant_details.user_subscription
            )  
          }
        })
        .catch((err) => {
          console.log(err)
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    async getValuesByItemAndAttribute(index, attribute_id) {
      await axios
        .get(
          'items/' +
            this.itemData.product.item.id +
            '/attributes/' +
            attribute_id +
            '/get-values'
        )
        .then((result) => {
          // console.log('returned values ', result.data.data)
          this.itemData.attributes[index].values = result.data.data
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })

          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    UpdateVariantValuesAction(attribute_id) {
      // check if the select value that want to be updated not equal the variant value
      var attributeIndex = this.itemData.attributes.findIndex(
        (obj) => obj.id === attribute_id
      )
      if (
        this.itemData.attributes[attributeIndex].itemValues[0].id ==
        this.selectedValue.id
      ) {
        this.$refs['update-variant-values'].hide()
        return
      }
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to Update This Value.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.update_values_errors_back.length = 0
            this.showDismissibleAlertUpdateValues = false
            axios
              .post(
                'variants/' +
                  this.itemData.id +
                  '/attributes/' +
                  attribute_id +
                  '/updateValues',
                {
                  value_id: this.selectedValue.id,
                }
              )
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.itemData.attributes = result.data.data.attributes
                this.itemData.status = result.data.data.status
                this.$refs['update-variant-values'].hide()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.update_values_errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.update_values_errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.update_values_errors_back = err.response.data.data
                  }
                  this.showDismissibleAlertUpdateValues = true
                } else {
                  this.update_values_errors_back = []
                  this.update_values_errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlertUpdateValues = true
                }
              })
          }
        })
    },
    deleteReport(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete report.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('diagnostic/reports/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteVariant(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete variant.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete(
                'variants/' + id + '/archive-variant/' + this.deletedVariant
              )
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },

    deleteImages(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete image.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('variants/media/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteReview(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete comment.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('variants/reviews/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    activeVariant(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to active varaint.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('variants/' + id + '/active-variant')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deActiveVariant(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to deactive varaint.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('variants/' + id + '/deactive-variant/' + this.deactive)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    restoreItem(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to restore variant.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('variants/' + id + '/restore-variant')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    // listen event
    onPlayerPlay(player) {},
    onPlayerPause(player) {},
    // ...player event
    // or listen state event
    playerStateChanged(playerCurrentState) {},
    // player is ready
    playerReadied(player) {
      // you can use it to do something...
      // player.[methods]
    },

    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>

<style>
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px !important;
}
.swiper-container {
  padding: 30px !important;
}
[dir] .video-js {
  width: 100% !important;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
