import { render, staticRenderFns } from "./Stock.vue?vue&type=template&id=7cf98caa&scoped=true&"
import script from "./Stock.vue?vue&type=script&lang=js&"
export * from "./Stock.vue?vue&type=script&lang=js&"
import style0 from "./Stock.vue?vue&type=style&index=0&id=7cf98caa&prod&lang=scss&"
import style2 from "./Stock.vue?vue&type=style&index=2&id=7cf98caa&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cf98caa",
  null
  
)

export default component.exports