<template>
  <div v-if="paymentSessionData">
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, index) in values" :key="index">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-row class="row-eq-height">
      <b-col class="mb-1" cols="12" md="6">
        <b-card title="Payment Session" class="h-100 d-flex flex-wrap">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">{{ paymentSessionData.id }}</td>
              </tr>
              <tr>
                <th class="text-left">Code</th>
                <td class="text-right">{{ paymentSessionData.code }}</td>
              </tr>
              <tr>
                <th class="text-left">Status</th>
                <td class="text-right">
                  <b-badge
                    :variant="
                      paymentSessionData.status == 'pending'
                        ? 'light-primary'
                        : paymentSessionData.status == 'delivered'
                        ? 'light-success'
                        : paymentSessionData.status == 'failed'
                        ? 'light-danger'
                        : 'light-secondary'
                    "
                  >
                    {{ paymentSessionData.status }}
                  </b-badge>
                </td>
              </tr>
              <tr>
                <th class="text-left">Start Date</th>
                <td class="text-right">{{ paymentSessionData.start_date }}</td>
              </tr>
              <tr>
                <th class="text-left">End Date</th>
                <td class="text-right">{{ paymentSessionData.end_date }}</td>
              </tr>
              <tr>
                <th class="text-left">success Date</th>
                <td class="text-right">
                  {{ paymentSessionData.success_date }}
                </td>
              </tr>
              <tr v-if="paymentSessionData.deleted_at">
                <th class="text-left">Deleted Date</th>
                <td class="text-right">{{ paymentSessionData.deleted_at }}</td>
              </tr>

              <tr>
                <th class="text-left">Transaction ref</th>
                <td class="text-right" dir="rtl">
                  {{
                    paymentSessionData.transactions.length > 0
                      ? paymentSessionData.transactions[0].transaction_ref
                      : ''
                  }}
                </td>
              </tr>

              <tr>
                <th class="text-left">payment gateway</th>
                <td class="text-right" dir="rtl">
                  <b-img-lazy
                    v-if="paymentSessionData.transactions.length != 0 && paymentSessionData.transactions[0].payment_provider"
                    width="35"
                    :src="
                      paymentSessionData.transactions[0].payment_provider.logo
                    "
                  />
                  <b-badge class="badge btn-primary mx-1">
                    {{ paymentSessionData.payment_gateway }}</b-badge
                  >
                </td>
              </tr>

              <tr>
                <th class="text-left">Total Paid</th>
                <td class="text-right" dir="rtl">
                  {{ paymentSessionData.total_paid }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Payout Amount</th>
                <td class="text-right" dir="rtl">
                  {{ paymentSessionData.payout_amount }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Margin Amount</th>
                <td class="text-right" dir="rtl">
                  {{ paymentSessionData.margin_amount }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="12" class="mb-1">
            <b-card class="h-100 d-flex flex-wrap" title="Bidding Data" v-if="paymentSessionData.bidding">
              <table class="table table-bordered">
                <router-link
                  class="ml-1 position-absolute"
                  style="top: 20px; right: 25px"
                  :to="{
                    path:
                      '/biddings-requests/show/' +
                      paymentSessionData.bidding.id,
                  }"
                >
                  <img :src="require('@/assets/images/icons/show.svg')" />
                </router-link>
                <tbody>
                  <tr>
                    <th class="text-left">ID</th>
                    <td class="text-right">
                      {{ paymentSessionData.bidding.id }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Status</th>

                    <td class="text-right">
                      <b-badge
                        size="lg"
                        :variant="
                          paymentSessionData.bidding.status == 'accept'
                            ? 'light-success'
                            : paymentSessionData.bidding.status == 'canceled'
                            ? 'light-primary'
                            : paymentSessionData.bidding.status == 'reject'
                            ? 'light-warning'
                            : 'light-secondary'
                        "
                      >
                        {{ paymentSessionData.bidding.status }}
                      </b-badge>
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Offer Price</th>
                    <td class="text-right">
                      {{ paymentSessionData.bidding.offer_price }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Payment Fees</th>
                    <td class="text-right">
                      {{ paymentSessionData.payment_method.fees }} %
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>

          <b-col md="12" class="mb-1">
            <b-card class="h-100 d-flex flex-wrap" footer-tag="footer">
              <div
                v-if="paymentSessionData.deleted_at == null"
                class="d-flex flex-wrap justify-content-center"
              >
                <b-button
                  block
                  v-if="
                    authPermissions.includes(
                      'check-payment-session-transaction-data'
                    ) && paymentSessionData.status == 'pending'
                  "
                  variant="outline-warning "
                  @click="checkOrderTransactionData(paymentSessionData.id)"
                  class="mt-1"
                >
                  Refresh PaymentSession Transaction
                </b-button>

                <!--  -->
                <b-button
                  block
                  v-if="
                    authPermissions.includes('show-eContract') &&
                    paymentSessionData.status == 'delivered'
                  "
                  :to="{
                    path:
                      '/paymentSessions/show/' + paymentSessionData.id + '/e-contract',
                  }"
                  variant="primary"
                  class="mt-1"
                >
                  Show E-Contract
                </b-button>
              </div>

              <!-- Add New PaymentSession Attachment Modal -->
              <b-modal
                style="height: 700px !important"
                id="modal-add-payment-session-attachments"
                cancel-variant="outline-secondary"
                @ok="storeAttachmentFile"
                ok-title="Ok"
                cancel-title="Close"
                centered
                title="Add PaymentSession Attachment"
              >
                <b-row>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="file_type"
                      rules="required"
                    >
                      <b-form-group
                        label="File Type"
                        label-for="file_type"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          id="file_type"
                          v-model="paymentSessionAttachmentData.type"
                          :state="
                            paymentSessionAttachmentData.type === null
                              ? false
                              : true
                          "
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="paymentSessionFileAttachmentOptions"
                          :selectable="
                            (option) => !option.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- file -->
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="file"
                      rules="required"
                    >
                      <b-form-group label="File" label-for="file">
                        <input
                          type="file"
                          class="form-control-file"
                          name="file"
                          id="file"
                          ref="file"
                          change
                          @change="fileUploadIndex"
                          accept="application/pdf, image/*"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <label for="textarea-default">Note</label>
                    <validation-provider
                      #default="{ errors }"
                      name="note"
                      rules="required"
                    >
                      <b-form-textarea
                        id="textarea-default"
                        v-model="paymentSessionAttachmentData.note"
                        placeholder="Note"
                        :state="errors.length > 0 ? false : null"
                        rows="2"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-modal>

              <hr />

              <div v-if="paymentSessionData.deleted_at == null" class="d-flex">
                <div class="m-4 w-50">
                  <h6 class="text-muted font-weight-bolder">Created at</h6>
                  <h3 class="mb-0">
                    {{ paymentSessionData.created_at }}
                  </h3>
                </div>
                <div class="m-4 w-50">
                  <h6 class="text-muted font-weight-bolder">Updated at</h6>
                  <h3 class="mb-0">
                    {{ paymentSessionData.updated_at }}
                  </h3>
                </div>
              </div>
              <div
                v-else
                class="d-flex justify-content-md-center align-items-center text-center"
              >
                <div class="m-4">
                  <h6 class="text-muted font-weight-bolder">Created at</h6>
                  <h3 class="mb-0">
                    {{ paymentSessionData.created_at }}
                  </h3>
                </div>
                <div class="m-4">
                  <h6 class="text-muted font-weight-bolder">Deleted at</h6>
                  <h3 class="mb-0">
                    {{ paymentSessionData.deleted_at }}
                  </h3>
                </div>
              </div>

              <!-- <template #footer v-if="authPermissions.includes('create_refund')">
                  <em>
                    <b-row>
                      <b-col cols="12" v-if="paymentSessionData.refund_id != null">
                        <b-button
                          block
                          variant="outline-warning"
                          @click="
                            $router.push('/orders/refund/show/' + paymentSessionData.refund_id)
                          "
                        >
                          Show Refund Data
                        </b-button>
                      </b-col>
                    </b-row>
                  </em>
                </template> -->
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <!-- Seller User Info: Left col -->
      <b-col cols="12" md="6" class="mb-1">
        <b-card
          v-if="paymentSessionData.seller != null"
          title="Seller"
          class="h-100 cardH d-flex flex-wrap table-responsive"
        >
          <router-link
            class="ml-1 position-absolute"
            style="top: 20px; right: 25px"
            :to="{ path: '/User/Show/' + paymentSessionData.seller.id }"
          >
            <img :src="require('@/assets/images/icons/show.svg')" />
          </router-link>
          <!-- User Avatar & Action Buttons -->
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">{{ paymentSessionData.seller.id }}</td>
              </tr>
              <tr>
                <th class="text-left">First Name</th>
                <td class="text-right">
                  {{ paymentSessionData.seller.firstname }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Last Name</th>
                <td class="text-right">
                  {{ paymentSessionData.seller.lastname }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Email</th>
                <td class="text-right">
                  {{ paymentSessionData.seller.email }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Phone</th>
                <td class="text-right">
                  <a
                    :href="'tel:' + paymentSessionData.seller.phone"
                    class="h4"
                    >{{ paymentSessionData.seller.phone }}</a
                  >
                </td>
              </tr>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">
                  <img
                    @click="OpenIdModal('seller')"
                    :src="require('@/assets/images/icons/photo-up.svg')"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>

      <!-- Madal For id-pictures User Action-->
      <b-modal
      ref="modal-id-pictures"
        cancel-variant="outline-secondary"
        ok-title="Ok"
        cancel-title="Close"
        centered
        title="ID Pictures"
      >
        <b-row>
          <b-col cols="12" md="6" class="mt-2">
            <b-card title="ID fornt picture">
              <div class="d-flex justify-content-start">
               
                <b-img
                  @click="img2Index = 0"
                  rounded
                  :src="id_front"
                  alt="d"
                  style="width: 100%; height: auto"
                />
              </div>
            </b-card>
          </b-col>
          <b-col cols="12" md="6" class="mt-2 h-100">
            <b-card title="ID back picture">
              <div class="d-flex justify-content-start">
              
                <b-img
                  @click="img3Index = 0"
                  rounded
                  :src="id_back"
                  alt="h"
                  style="width: 100%; height: auto"
                />
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-modal>
      <b-col class="mb-1" cols="12" md="6">
        <b-card
          title="Buyer User"
          class="h-100 d-flex flex-wrap table-responsive"
        >
          <table class="table table-bordered">
            <router-link
              class="ml-1 position-absolute"
              style="top: 20px; right: 25px"
              :to="{ path: '/User/Show/' + paymentSessionData.buyer.id }"
            >
              <img :src="require('@/assets/images/icons/show.svg')" />
            </router-link>
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">{{ paymentSessionData.buyer.id }}</td>
              </tr>
              <tr>
                <th class="text-left">First Name</th>
                <td class="text-right">
                  {{ paymentSessionData.buyer.firstname }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Last Name</th>
                <td class="text-right">
                  {{ paymentSessionData.buyer.lastname }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Email</th>
                <td class="text-right">{{ paymentSessionData.buyer.email }}</td>
              </tr>
              <tr>
                <th class="text-left">Phone</th>

                <td class="text-right">
                  <a :href="'tel:' + paymentSessionData.buyer.phone" class="h4">
                    <the-mask
                      mask="#### ### ####"
                      disabled
                      v-model="paymentSessionData.buyer.phone"
                      class="border-0 text-right phonenumber"
                    >
                    </the-mask>
                  </a>
                </td>
              </tr>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">
                  <img
                    @click="OpenIdModal('buyer')"
                    :src="require('@/assets/images/icons/photo-up.svg')"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <!-- <b-col class="mb-1" md="12">
          <b-row>
            
            <b-col class="mb-1" md="6">
              <b-card
                title="Customer Details"
                class="h-100 d-flex flex-wrap table-responsive"
              >
                <a
                  class="ml-1 position-absolute"
                  style="top: 20px; right: 25px"
                  @click="enableUpdateCustomerDataAction()"
                  v-if="authPermissions.includes('update-eContract-data')"
                >
                  <img :src="require('@/assets/images/icons/edit-new.svg')" />
                </a>
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <th class="text-left">Customer Name</th>
                      <td class="text-right" v-if="paymentSessionData.e_contract">
                        {{ paymentSessionData.e_contract.customer_name }}
                      </td>
                    </tr>
                    <tr>
                      <th class="text-left">Customer  Id Number</th>
                      <td class="text-right" v-if="paymentSessionData.e_contract">
                        {{ paymentSessionData.e_contract.customer_id_number }}
                      </td>
                    </tr>
                    <tr>
                      <th class="text-left">Id Front Image</th>
                      <td class="text-right" v-if="paymentSessionData.e_contract">
                        <a
                          v-if="paymentSessionData.e_contract.id_front_image != null"
                          :href="paymentSessionData.e_contract.id_front_image"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Open</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <th class="text-left">Id Back Image</th>
                      <td class="text-right" v-if="paymentSessionData.e_contract">
                        <a
                          v-if="paymentSessionData.e_contract.id_back_image != null"
                          :href="paymentSessionData.e_contract.id_back_image"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Open</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="paymentSessionData.e_contract != null">
                  <b-button
                    v-if="paymentSessionData.e_contract.e_contract_image != null"
                    block
                    v-b-modal.eContractImage
                    variant="primary"
                    class="mr-1 mt-1"
                  >
                    Report Image
                  </b-button>
                  <b-modal
                    id="eContractImage"
                    ok-only
                    centered
                    size="lg"
                    title="Report Image"
                  >
                    <img
                      width="100%"
                      :src="paymentSessionData.e_contract.e_contract_image"
                    />
                  </b-modal>
                </div>
              </b-card>
            </b-col>
            <b-col
              class="mb-1"
              md="6"
              v-if="
                (enableUpdateIdFrontImage ||
                  enableUpdateIdBackImage ||
                  enableUpdateCustomerData) &&
                authPermissions.includes('update-eContract-data')
              "
            >
              <b-card
                title="Update Customer Data"
                class="table-responsive"
                v-if="
                  (enableUpdateIdFrontImage ||
                    enableUpdateIdBackImage ||
                    enableUpdateCustomerData) &&
                  authPermissions.includes('update-eContract-data')
                "
              >
                <validation-observer ref="simpleRules">
                  <b-row>
                    
                    <b-col md="6">
                      <b-form-group
                        label="Customer Name"
                        label-for="first-name"
                        v-if="enableUpdateCustomerData"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Customer Name"
                          rules="required"
                        >
                          <b-form-input
                            id="first-name"
                            v-model="customerData.customer_name"
                            placeholder="John"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    
                    <b-col md="6">
                      <b-form-group
                        v-if="enableUpdateCustomerData"
                        label="Customer Id Number"
                        label-for="customer-id"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Customer Id Number"
                          rules="required"
                        >
                          <b-form-input
                            id="customer-id"
                            v-model="customerData.customer_id_number"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    
                    <b-col
                      md="6"
                      v-if="enableUpdateIdFrontImage || enableUpdateCustomerData"
                    >
                      <b-form-group
                        label="Id front picture"
                        label-for="Id front picture"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Id front picture"
                        >
                          <b-form-file
                            @change="ChangeIDFrontImage($event)"
                            v-model="customerData.id_front_image_file"
                            id="ProfilePictureBase642"
                            accept="image/*"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    
                    <b-col
                      md="6"
                      v-if="enableUpdateIdBackImage || enableUpdateCustomerData"
                    >
                      <b-form-group
                        label="Id back picture"
                        label-for="Id back picture"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Id back picture"
                        >
                          <b-form-file
                            @change="ChangeIDBackImage($event)"
                            v-model="customerData.id_back_image_file"
                            id="ProfilePictureBase643"
                            accept="image/*"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
  
                    <b-button variant="success" @click="updateEcontractIds" block>
                      Submit</b-button
                    >
                  </b-row>
                </validation-observer>
              </b-card>
            </b-col>
          </b-row>
        </b-col> -->
    </b-row>
    <!-- variants data -->
    <b-row>
      <b-col v-if="variants" class="" cols="12">
        <b-card title="Items List" class="table-responsive">
          <b-table
            striped
            hover
            :items="variants"
            :fields="variantFields"
            class=""
          >
            <template #cell(show)="items">
              <b-button
                style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                class="btn-icon rounded-circle"
                :to="{
                  path:
                    (items.item.is_new ? '/New' : '/Used') +
                    '/ShowProduct/variant/show/' +
                    items.item.id,
                }"
                variant="success"
              >
                <feather-icon
                  style="width: 18px; height: 18px"
                  icon="EyeIcon"
                />
              </b-button>
              <!--:to="{name: 'OrderShowCompareReports', params: { selling: items.value[0].type == 'selling' ? items.value[0].serial_number : items.value[1].serial_number, buying: items.value[1].type == 'buying' ? items.value[1].serial_number : items.value[0].serial_number} }"-->

              <!-- <h1 v-else>-</h1> -->
            </template>
            <template #cell(status)="data">
              <b-badge variant="primary">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(is_new)="data">
              <b-badge :variant="data.value ? 'success' : 'info'">
                {{ data.value ? 'New' : 'Used' }}
              </b-badge>
            </template>
            <template #cell(identical)="data">
              <img
                :src="
                  data.value == false
                    ? require('@/assets/images/remove.png')
                    : require('@/assets/images/check.png')
                "
                alt="alternate"
                title="tit"
                width="20px"
                height="20px"
              />
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <!-- princing data -->
    <b-row>
      <b-col cols="12">
        <b-card title="Transactions">
          <b-table
            id="TransactionsTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            class="position-relative"
            :items="paymentSessionData.transactions"
            :fields="fieldsTransactions"
          >
            <template #cell(status)="data">
              <b-badge
                :variant="
                  data.value == 'success'
                    ? 'success'
                    : data.value == 'failed'
                    ? 'danger'
                    : 'secondary'
                "
                >{{ data.value }}</b-badge
              >
            </template>
            <template #cell(actions)="items">
              <div class="demo-inline-spacing">
                <!-- show payout -->
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                  class="btn-icon rounded-circle"
                  v-if="authPermissions.includes('show')"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  :to="{ path: '/transaction/show/' + items.item.id }"
                >
                  <feather-icon
                    icon="EyeIcon"
                    style="width: 18px; height: 18px"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>

      <b-col md="12">
        <b-card title="PaymentSession Attachments">
          <b-button
            v-if="authPermissions.includes('add-paymentSession-attachments')"
            style="top: 10px; right: 20px"
            class="position-absolute"
            target="_blank"
            variant="none"
            type="submit"
            v-b-modal.modal-add-payment-session-attachments
          >
            <img :src="require('@/assets/images/icons/add-new.svg')" />
          </b-button>

          <b-alert
            v-height-fade.appear
            fade
            :show="storeAttachmentShowDismissibleAlert"
            @dismissed="storeAttachmentShowDismissibleAlert = false"
            variant="danger"
          >
            <h4 class="alert-heading">Alert</h4>
            <div class="alert-body">
              <ul
                v-for="(values, index) in storeAttachmentErrorsBack"
                :key="index"
              >
                <li v-for="(value, index) in values" :key="index">
                  {{ value }}
                </li>
              </ul>
            </div>
          </b-alert>

          <b-table
            v-if="paymentSessionData.payment_session_attachments.length != 0"
            striped
            hover
            :items="paymentSessionData.payment_session_attachments"
            :fields="orderAttachmentFields"
            class=""
          >
            <template #cell(actions)="items">
              <div class="d-flex">
                <!-- delete Order -->
                <b-button
                  :href="items.item.name"
                  target="_blank"
                  variant="none"
                  type="submit"
                >
                  <img :src="require('@/assets/images/icons/photo-up.svg')" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>

    <!-- PaymentSession Logger -->
    <b-card title="Notes" class="d-flex flex-wrap">
      <b-row>
        <b-col cols="12">
          <label for="textarea-default">Note</label>
          <b-form-textarea
            id="textarea-default"
            v-model="loggerNote.note"
            placeholder="Note"
            rows="3"
          />
          <b-button
            variant="primary"
            class="mt-1"
            @click="storeLogger(paymentSessionData.id)"
          >
            Submit
          </b-button>
        </b-col>
        <b-col class="mt-3" cols="12">
          <app-timeline>
            <app-timeline-item
              v-for="(
                item, index
              ) in paymentSessionData.payment_session_loggers"
              :key="index"
              :variant="item.admin != null ? 'primary' : 'success'"
            >
              <div
                class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
              >
                <h6>{{ item.note }}</h6>
              </div>
              <b-media v-if="item.admin != null">
                <template #aside>
                  <b-avatar :src="item.admin.profile_picture" />
                </template>
                <h6>{{ item.admin.firstname + ' ' + item.admin.lastname }}</h6>
                <p>{{ item.admin.role }}</p>
              </b-media>
              <b-media v-else>
                <template #aside>
                  <!-- <b-avatar :src="item.vendor.profile_picture" /> -->
                </template>
                <!-- <h6>{{item.vendor.storename}}</h6> -->
              </b-media>
            </app-timeline-item>
          </app-timeline>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
  
  <script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { TheMask } from 'vue-the-mask'
import { required } from '@validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        name: 'viewport',
        content:
          'width=device-width,initial-scale=1.0, maximum-scale=2.0, user-scalable=yes',
      },
    ],
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    TheMask,
    AppCollapse,
    AppCollapseItem,
  },

  data() {
    return {
      required,
      authPermissions: [],
      paymentSessionData: null,
      errors_back: [],
      storeAttachmentErrorsBack: [],
      storeAttachmentShowDismissibleAlert: false,
      showDismissibleAlert: false,
      id: 0,
      status: '',
      area: '',
      id_front:'',
      id_back:'',
      variants: [],
      loggerNote: {
        note: '',
      },
      discount: '',
      compareItems: [],
      compareFields: [
        {
          key: 'name',
          label: 'name',
        },
        {
          key: 'selling',
          label: 'selling',
        },
        {
          key: 'buying',
          label: 'buying',
        },
        {
          key: 'identical',
          label: 'identical',
        },
      ],
      variantFields: [
        {
          key: 'product_name',
          label: 'Product',
        },
        {
          key: 'sku',
          label: 'SKU',
        },
        {
          key: 'quantity',
          label: 'Qty',
        },
        {
          key: 'sale_price',
          label: 'Price',
        },
        {
          key: 'profit',
          label: 'Margin',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'is_new',
          label: 'Type',
        },
        {
          key: 'show',
          label: 'show',
        },
      ],
      orderAttachmentFields: [
        {
          key: 'type',
          label: 'Attachment Type',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      // table fildes
      fieldsTransactions: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'transaction_ref',
          label: 'transaction ref',
        },
        {
          key: 'status',
          label: 'status',
        },

        {
          key: 'transaction_type',
          label: 'transaction type',
        },
        {
          key: 'customer_name',
          label: 'customer name',
        },

        {
          key: 'customer_data',
          label: 'customer (email/phone)',
        },

        {
          key: 'payment_method',
          label: 'payment method',
        },
        {
          key: 'amount',
          label: 'amount',
        },
        {
          key: 'remaining_amount',
          label: 'remaining amount',
        },
        {
          key: 'payment_commission',
          label: 'payment commission',
        },
        {
          key: 'transaction_fees',
          label: 'transaction fees',
        },
        {
          key: 'created_at',
          label: 'created at',
        },
        {
          key: 'updated_at',
          label: 'updated at',
        },
        {
          key: 'actions',
        },
      ],
      itemsTransactions: [],
      identicalType: false,
      shipData: null,
      shipPDF: null,
      cashStatusOptions: ['delivered', 'canceled'],
      paymentSessionFileAttachmentOptions: [
        'invoice',
        'agreement',
      ],
      cashStatusSelect: null,
      customerData: {
        customer_name: null,
        customer_id_number: null,
        id_front_image: null,
        id_back_image: null,
        id_front_image_file: null,
        id_back_image_file: null,
      },
      enableUpdateCustomerData: false,
      enableUpdateIdFrontImage: false,
      enableUpdateIdBackImage: false,
      paymentSessionAttachmentData: {
        file: null,
        type: null,
        note: null,
      },
    }
  },

  created() {
    this.id = this.$route.params.id
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  methods: {
    OpenIdModal(model_type){
      if(model_type == 'seller'){
        this.id_front = this.paymentSessionData.payment_session_identification.seller_id_front
        this.id_back = this.paymentSessionData.payment_session_identification.seller_id_back
      }else{
        this.id_front = this.paymentSessionData.payment_session_identification.buyer_id_front
        this.id_back = this.paymentSessionData.payment_session_identification.buyer_id_back

      }
        this.$refs['modal-id-pictures'].show()
      
    },
    async checkOrderTransactionData() {
      await this.$bvModal
        .msgBoxConfirm('Please confirm that you want to Reresh Order.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get(
                'payment-sessions/' +
                  this.paymentSessionData.id +
                  '/checkPaymentTransaction'
              )
              .then((result) => {
                this.paymentSessionData.status = result.data.data.status
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Order Data Checked Success',
                  showConfirmButton: false,
                  timer: 1500,
                })
              })

              .catch((err) => {
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    validatepaymentSessionAttachmentData() {
      if (this.paymentSessionAttachmentData.file == null) {
        this.showDismissibleAlert = true
        this.errors_back.push({
          error: 'must upload attachment file ',
        })
        return
      }
      if (this.paymentSessionAttachmentData.type == null) {
        this.showDismissibleAlert = true
        this.errors_back.push({
          error: 'invalid selected type ',
        })
        return
      }
    },
    // store function of (store button) Order
    async storeAttachmentFile() {
      this.storeAttachmentErrorsBack = []
      this.storeAttachmentShowDismissibleAlert = false
      let formData = new FormData()
      await this.validatepaymentSessionAttachmentData()

      formData.append('note', this.paymentSessionAttachmentData.note)
      formData.append('type', this.paymentSessionAttachmentData.type)
      formData.append('file', this.paymentSessionAttachmentData.file)

      axios
        .post(
          'payment-sessions-attachments/' +
            this.paymentSessionData.id +
            '/store',
          formData
        )
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
         
          this.paymentSessionData.payment_session_attachments.push(
            result.data.data
          )
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.storeAttachmentErrorsBack.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.storeAttachmentErrorsBack.push({
                error: err.response.data.data,
              })
            } else {
              this.storeAttachmentErrorsBack = err.response.data.data
            }
            this.storeAttachmentShowDismissibleAlert = true
          } else {
            this.storeAttachmentErrorsBack = []
            this.storeAttachmentErrorsBack.push({
              error: 'internal server error',
            })
            this.storeAttachmentShowDismissibleAlert = true
          }
        })
    },
    // delete function of (delete button) Order
    deleteAttachmentFile(id) {
      //console.log('delete')
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete This File.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('payment-sessions-attachments/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },

    fetchData() {
      axios
        .get('payment-sessions/' + this.id)
        .then((result) => {
          this.paymentSessionData = result.data.data
          this.variants.push(this.paymentSessionData.variant)
        })
        .catch((err) => {
        
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    selectRow(index, identical) {
      this.identicalType = identical
      this.compareItems = []
      for (let inde in this.paymentSessionData.variants[index].compareResult) {
        this.compareItems.push({
          name: Object.keys(
            this.paymentSessionData.variants[index].compareResult[inde]
          )[0],
          selling:
            this.paymentSessionData.variants[index].compareResult[inde][
              Object.keys(
                this.paymentSessionData.variants[index].compareResult[inde]
              )[0]
            ].selling,
          buying:
            this.paymentSessionData.variants[index].compareResult[inde][
              Object.keys(
                this.paymentSessionData.variants[index].compareResult[inde]
              )[0]
            ].buying,
          identical:
            this.paymentSessionData.variants[index].compareResult[inde][
              Object.keys(
                this.paymentSessionData.variants[index].compareResult[inde]
              )[0]
            ].identical,
        })
      }
    },
    storeLogger(id) {
      axios
        .post('payment-sessions/' + id + '/logger/store', this.loggerNote)
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.fetchData()
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    enableUpdateCustomerDataAction() {
      this.enableUpdateCustomerData = !this.enableUpdateCustomerData
      if (
        this.enableUpdateCustomerData == true &&
        this.paymentSessionData.e_contract != null
      ) {
        this.customerData.customer_id_number =
          this.paymentSessionData.e_contract.customer_id_number
        this.customerData.customer_name =
          this.paymentSessionData.e_contract.customer_name
      }
    },
    ChangeIDFrontImage(e) {
      // this.id_front_image_file = e
      const image = e.target.files[0]
     
      if (e != null && image != null) {
        var fileReader = new FileReader()
        let vm = this
        fileReader.onload = (e) => {
          this.customerData.id_front_image = e.target.result
            .split(',')
            .map((item) => item.trim())[1]
          // console.log(this.id_front_image)
        }
        fileReader.readAsDataURL(image)
      }
    },
    ChangeIDBackImage(e) {
      // this.id_back_image_file = e
      const image = e.target.files[0]
      if (e != null) {
        var fileReader = new FileReader()
        fileReader.readAsDataURL(image)

        fileReader.onload = (e) => {
          this.customerData.id_back_image = e.target.result
            .split(',')
            .map((item) => item.trim())[1]
        }
      }
    },

    updateEcontractIds() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axios
            .post('orders/' + this.id + '/e-contract/update', {
              id_front_image: this.customerData.id_front_image,
              id_back_image: this.customerData.id_back_image,
              customer_id_number: this.customerData.customer_id_number,
              customer_name: this.customerData.customer_name,
            })
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.paymentSessionData.e_contract = result.data.data
              this.enableUpdateCustomerData = false
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },

    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    fileUploadIndex(e) {
      this.paymentSessionAttachmentData.file = e.target.files[0]
     
    },
  },
}
</script>
  
  <style scoped>
/* #card {
      display: flex !important;
      flex-wrap: wrap !important;
  } */
#table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#table td,
#table th {
  border: 1px solid #dddddd;
  padding: 8px;
}
#table tr:nth-child(even) {
  background-color: #f5f5f5;
}
.cardH {
  margin-bottom: 0px;
}
.phonenumber {
  font-weight: bolder !important;
  color: #08308B;
  background: none;
}
</style>
  