<template>
    <div>
      <!-- error handelr -->
      <b-alert
        v-height-fade.appear
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert = false"
        variant="danger"
      >
        <h4 class="alert-heading">
          Alert
        </h4>
        <div class="alert-body">
          <ul v-for="(values , index) in errors_back" :key="index">
            <li v-for="(value , indexVal) in values" :key="indexVal">{{ value }}</li>
          </ul>
        </div>
      </b-alert>
      <b-card>
          <validation-observer ref="simpleRules">
        <b-row>
        
            <b-col md="6" >
                  <div style="position: relative">
                    <b-row>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="Vendor Search"
                          rules="required"
                        >
                          <b-form-group
                            label="Vendor"
                            label-for="vendor"
                            :state="errors.length > 0 ? false : null"
                          >
                            <b-form-input
                              id="searchUser"
                              :state="searchUser == null ? false : true"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              v-model="searchUser"
                              placeholder="Search For Add vendor"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="2">
                        <b-button
                          class="mt-2"
                          @click="searchUserR(searchUser, 0)"
                          variant="outline-primary"
                          >Search</b-button
                        >
                      </b-col>
                      <b-alert
                        variant="warning"
                        style="margin: 0.438rem 1rem"
                        :show="noUser"
                      >
                        <div class="alert-body">No Result Found.</div>
                      </b-alert>
                      <b-col
                        class="mt-2"
                        style="
                          position: absolute;
                          z-index: 9999;
                          padding: 0.438rem 1rem;
                          top: 35px;
                          max-height: 380px !important;
                          overflow-y: scroll;
                          overflow-x: hidden;
                          background-color: white;
                          scroll-padding: 0px !important;
                          padding-right: 0;
                        "
                        v-if="vendorShow"
                        cols="12"
                      >
                        <b-list-group>
                          <b-list-group-item
                            @click="addVendor(item)"
                            v-for="(item, index) in userSR"
                            :key="index"
                          >
                            <feather-icon icon="" class="mr-75" />
                            <p style="font-weight: 600">{{ item.storename}}</p>
                            <small style="padding-left: 10px"
                              > Email: {{ item.email }}  - PhoneNumber:
                              {{ item.phone }}</small
                            >
                          </b-list-group-item>
                          <infinite-loading
                            @infinite="searchUserR(searchUser, userCurrentPage)"
                          ></infinite-loading>
                        </b-list-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>

          <!-- start data table -->
          <b-col cols="12">
            <b-table
              id="roleTable"
              striped
              hover
              responsive
              show-empty
              :items="items"
              :fields="fields"
              
            >
              <template #cell(service_type)="data">
                <b-badge variant="warning">{{ data.value }}</b-badge>
              </template>
              <template #cell(active)="data">
                <b-badge variant="primary">{{ data.value }}</b-badge>
              </template>
  
              <template #cell(is_portal)="data">
                <b-badge variant="info" v-if="data.value"> Portal</b-badge>
                <b-badge variant="info" v-else> InStore</b-badge>
              </template>
  
              <template #cell(rate)="data">
                <!-- <p v-if="data.item.type == 'percentage'">
                  {{ data.value }}  %
                </p>
                <p v-else>
                  {{ data.value }}  EGP
                </p> -->

            
                <b-form-group label-for="rate" class="d-flex align-items-center">
                <validation-provider
                    #default="{ errors }"
                    name="rate"
                    rules="required|regex:^([0-9]+)$"
                >
                    <div class="d-flex align-items-center w-100">
                    <!-- Input field -->
                    <b-form-input
                        :disabled="!authPermissions.includes('update-vendors-margin')"
                        id="rate"
                        min="1"
                        type="number"
                        class="me-2"
                        v-model="data.item.rate"
                        :state="errors.length > 0 ? false : null"
                    />
                    
                    <!-- Unit (Percentage or EGP) -->
                    <span v-if="data.item.type == 'percentage'">
                        %
                    </span>
                    <span v-else>
                        EGP
                    </span>
                    </div>
                    <small class="text-danger w-100 mt-1">{{ errors[0] }}</small>
                </validation-provider>

                </b-form-group>
           

              </template>
            </b-table>

             <!-- submit button -->
             <b-col v-if="items.length > 0"  class="d-flex justify-content-end my-2">
              <b-button
                size="lg"
                
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-col>
        </b-row>
        </validation-observer>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <div>
            <!-- start pagination -->
            <b-pagination
              aria-controls="roleTable"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </div>
        </b-card-body>
      </b-card>
    </div>
  </template>
  
  <script>
import { required } from '@validations'
  
  export default {
    data() {
      return {
        id: 0,
        authPermissions: [],
        showDismissibleAlert: false,
        errors_back: {},
        perPage: 25,
        pageOptions: [25, 50, 100],
        totalRows: 1,
        currentPage: 1,
        sortBy: 'id',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        isBusy: false,
        required,
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        // table fields
        fields: [
          {
            key: 'id',
            label: 'Id',
            sortable: true,
          },
          {
            key: 'active',
            label: 'Active',
          },
          {
            key: 'service_type',
            label: 'Product Type',
          },
          {
            key: 'is_portal',
            label: 'Sales Channel',
          },
          {
            key: 'type',
            label: 'type',
          },
          {
            key: 'from',
            label: 'from',
            sortable: true,
          },
          {
            key: 'to',
            label: 'to',
            sortable: true,
          },
          {
            key: 'rate',
            label: 'rate',
            sortable: true,
          },
          {
            key: 'PaymentProvider.name',
            label: 'Payment Method',
            sortable: true,
          },
        ],
        items: [],
        // permissions:[]
        searchUser: '',
        searchText: '',
        vendorShow: false,
        vendorSelected: null,
        userSR: [],
        noUser: false,
      
      }
    },
    created() {
      this.authPermissions = this.$store.state.authPermissions
      this.id = this.$route.params.id
      this.fetchData()
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({
            text: f.label,
            value: f.key,
          }))
      },
      rows() {
        return this.items.length
      },
    },
    mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },
    watch: {
      sortDesc: function () {
        this.currentPage = 1
        if (this.sortDesc == true) {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
        this.fetchData()
      },
      sortBy: function () {
        this.currentPage = 1
        if (this.sortDesc == true) {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
        this.fetchData()
      },
      filter: function () {
        if (this.filter == '' || this.filter == null) {
          this.fetchData()
        }
      },
    },
    methods: {
      
      searchData() {
        this.currentPage = 1
        if (this.sortDesc == true) {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
        this.fetchData()
      },
      fetchData() {
        // request roles index
      
      },
      searchUserR(text, $page) {
      this.noUser = false
      this.userCurrentPage = $page + 1
      axios
        .get(
          'vendors/search/20/sortBy-id-desc/' +
            text +
            '?page=' +
            this.userCurrentPage
        )
        .then((result) => {
          const data = result.data.data.data
          // this.userSR = data
          if(this.userCurrentPage == 1){
            this.userSR = []
          }
          this.userSR = this.userSR.concat(data)
          // console.log(this.userSR)
          if (this.userSR.length > 0) {
            this.vendorShow = true
          } else {
            if(this.userCurrentPage == 1){
              this.userSR = []
              this.noUser = true
              this.vendorShow = false

            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    addVendor(vendor) {
      this.vendorShow = false
      this.vendorSelected = vendor;
      if(vendor){
        axios
          .get('vendors/price-rates/' + vendor.id + '/index')
          .then((result) => {
            const data = result.data.data
  
            this.items = []
            this.items = result.data.data
       
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      }
    },
    validationForm() {

    

      const filteredData = this.items.map((item) => ({
        id: item.id,
        rate: item.rate,
      }));


      this.$refs.simpleRules.validate().then((success) => {
        if (success) {

          axios
            .put("vendors/price-rates/updateVendorRates/" + this.vendorSelected.id, { "rateData" :filteredData} )
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
     
     
    
      isString(value) {
        return typeof value === 'string' || value instanceof String
      },
    },
  }
  </script>
  