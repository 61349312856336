<template>
    <div>
      <b-card-code title="Add Payouts" :before-change="validationForm">
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>

        <b-col cols="12">
            <b-card title="User">
              <b-row>
                <b-col md="6">
                  <div style="position: relative">
                    <b-row>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="User"
                          rules="required"
                        >
                          <b-form-group
                            label="User"
                            label-for="User"
                            :state="errors.length > 0 ? false : null"
                          >
                            <b-form-input
                              id="searchUser"
                              :state="data.user_id == null ? false : true"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              v-model="searchUser"
                              placeholder="Search For Add user"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            
                            <b-alert
                                variant="danger"
                                style="margin: 0.438rem 1rem"
                                :show="payment_sessionOption.length == 1 && data.user_id != null"
                            >
                                <div class="alert-body">No Payment Session Found for this user.</div>
                            </b-alert>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="2">
                        <b-button
                          class="mt-2"
                          @click="searchUserR(searchUser, 0)"
                          variant="outline-primary"
                          >Search</b-button
                        >
                      </b-col>
                      <b-alert
                        variant="warning"
                        style="margin: 0.438rem 1rem"
                        :show="noUser"
                      >
                        <div class="alert-body">No Result Found.</div>
                      </b-alert>
                      <b-col
                        class="mt-2"
                        style="
                          position: absolute;
                          z-index: 9999;
                          padding: 0.438rem 1rem;
                          top: 35px;
                          max-height: 380px !important;
                          overflow-y: scroll;
                          overflow-x: hidden;
                          background-color: white;
                          scroll-padding: 0px !important;
                          padding-right: 0;
                        "
                        v-if="userShow"
                        cols="12"
                      >
                        <b-list-group>
                          <b-list-group-item
                            @click="addUser(item)"
                            v-for="(item, index) in userSR"
                            :key="index"
                          >
                            <feather-icon icon="" class="mr-75" />
                            <p style="font-weight: 600">{{ item.name }}</p>
                            <small style="padding-left: 10px"
                              >Email: {{ item.email }} - PhoneNumber:
                              {{ item.phone }}</small
                            >
                          </b-list-group-item>
                          <infinite-loading
                            @infinite="searchUserR(searchUser, userCurrentPage)"
                          ></infinite-loading>
                        </b-list-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>

                <!-- phone Number 1 -->
                <b-col md="6">
                  <b-form-group label="Phone Number" label-for="Phone Number">
                    <validation-provider
                      #default="{ errors }"
                      name="Phone Number"
                      rules="required|egyptMobile"
                    >
                      <b-form-input
                        id="phone"
                        v-model="data.phone"
                        :state="errors.length > 0 ? false : null"
                        placeholder="015XXXXXXXX"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                

                <!-- User Name -->
                <b-col md="6">
                  <b-form-group label="User Name" label-for="User Name">
                    <validation-provider
                      #default="{ errors }"
                      name="User Name"
                      rules="required"
                    >
                      <b-form-input
                        id="Name"
                        v-model="data.name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                
                <b-col md="6">
                    <b-form-group
                    label="select Payment session"
                    label-for="select Payment session"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="select Payment session"
                        rules="required"
                    >
                        <v-select
                        multiple
                        class="text-capitalize"
                        id="Type"
                        v-model="data.payment_sessions"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="payment_sessionOption"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Payment Session"
                        :selectable="(option) => !option.value.includes('select_value')"
                        label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>

              </b-row>
            </b-card>
          </b-col>


          
              <!-- Vendors -->
              <b-col md="6">
                <b-row>
                  <!-- <b-col cols="12"> -->
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="Vendor"
                      rules="required"
                    >
                      <b-form-group
                        label="Vendor"
                        label-for="vendor"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          id="vendor"
                          v-model="vendorSelect"
                          :state="vendorSelect === null ? false : true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="vendorOptions"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider> -->
                  <!-- </b-col> -->
                  <b-col cols="12">
                    <b-form-group label="Note" label-for="Note">
                      <validation-provider
                        #default="{ errors }"
                        name="Note"
                        rules="required"
                      >
                        <b-form-input
                          id="Note"
                          v-model="data.note"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="options.length > 0" md="6">
                <b-form-group>
                  <template #label>
                    <b>ordres: </b><br />
                    <b-form-checkbox
                      v-model="allSelected"
                      :indeterminate="indeterminate"
                      aria-describedby="options"
                      aria-controls="options"
                      class=""
                      @change="toggleAll"
                    >
                      {{ allSelected ? "Un-select All" : "Select All" }}
                    </b-form-checkbox>
                  </template>
  
                  <template v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      id="options"
                      v-model="data.orders"
                      :options="options"
                      :aria-describedby="ariaDescribedby"
                      name="options"
                      class="ml-4 h1"
                      aria-label="Individual options"
                      stacked
                    ></b-form-checkbox-group>
                  </template>
                </b-form-group>
                <infinite-loading
                  @infinite="selectedVendorState"
                ></infinite-loading>
              </b-col>
              <b-col cols="12">
                <b-button
                  size="lg"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-code>
      <!-- error handelr -->
      <b-alert
        v-height-fade.appear
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert = false"
        variant="danger"
      >
        <h4 class="alert-heading">
          Alert
        </h4>
        <div class="alert-body">
         <ul v-for="values in errors_back" :key="values">
                      <li v-for="value in values" :key="value">{{value}}</li>
                  </ul>
        </div>
      </b-alert>
    </div>
  </template>
  
  <script>
  import BCardCode from "@core/components/b-card-code";
  import { heightTransition } from "@core/mixins/ui/transition";
  import { required } from "@validations";
  export default {
    components: {
      BCardCode,
    },
  
    mixins: [heightTransition],
    watch: {
      vendorSelect: function() {
        this.options = [];
        this.currentPage = 0;
        if (this.vendorSelect != null) {
          this.selectedVendor();
        }
      },
      "data.orders": function(newValue, oldValue) {
        // Handle changes in individual flavour checkboxes
        if (newValue.length === 0) {
          this.indeterminate = false;
          this.allSelected = false;
        } else if (newValue.length === this.options.length) {
          this.indeterminate = false;
          this.allSelected = true;
        } else {
          this.indeterminate = true;
          this.allSelected = false;
        }
      },
    },
  
    data() {
      return {
        selected: [],
        allSelected: false,
        indeterminate: false,
        vendorOptions: [],
        vendorSelect: null,
        errors_back: [],
        available_amount: 0,
        showDismissibleAlert: false,
        required,
        userShow: false,
        searchText: '',
        searchText2: '',
        searchUser: '',
        data: {
          vendor_id: "",
          user_id: null,
          type: "manual",
          note: "",
          payment_sessions: [],
          phone: null,
           name: null,
        },
        orderData: {
        phone: null,
        name: null,
        type: 'cos',
        coupon_id: null,
        user_id: "",
        variants: [],
        bundles: [],
          },
        userSelect: null,
        usersOptions: [],
        noUser: false,

        payment_sessionOption: [
                {
                value: "select_value",
                text: "Select Value",
                },
        ],

        options: [],
      };
    },
    methods: {
      toggleAll(checked) {
        if (checked) {
          for (let index in this.options) {
            this.data.orders.push(this.options[index].value);
          }
        } else {
          this.data.orders = [];
        }
        // this.data.orders = checked ? this.options.slice() : []
      },
      // submit Brand API
      validationForm() {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            // this.data.user_id = this.vendorSelect.value;
            axios
              .post("payouts/create", this.data)
              .then((result) => {
              
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.$router.back(1);
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
      },
  
      selectedVendor() {
        this.currentPage += 1;
        axios
          .get(
            "vendors/" +
              this.vendorSelect.value +
              "/orders/settled/50/sortBy-id-desc?page=" +
              this.currentPage
          )
          .then((result) => {
           
            const data = result.data.data.data;
            if (data.length > 0) {
              for (let index in data) {
                this.options.push({
                  text:
                    "Code: " +
                    data[index].code +
                    " " +
                    "- " +
                    " " +
                    "Amount: Worthy " +
                    data[index].payout_total +
                    " " +
                    "EGP" +
                    " " +
                    "| Date: " +
                    data[index].created_at,
                  value: data[index].id,
                });
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
      },
      selectedVendorState($state) {
        this.currentPage += 1;
        axios
          .get(
            "vendors/" +
              this.vendorSelect.value +
              "/orders/settled/50/sortBy-id-desc?page=" +
              this.currentPage
          )
          .then((result) => {
           
            const data = result.data.data.data;
            if (data.length > 0) {
              for (let index in data) {
                this.options.push({
                  text:
                    "Code: " +
                    data[index].code +
                    " " +
                    "- " +
                    " " +
                    "Amount: Worthy " +
                    data[index].payout_total +
                    " " +
                    "EGP" +
                    " " +
                    "| Date: " +
                    data[index].created_at,
                  value: data[index].id,
                });
              }
              $state.loaded();
            } else {
              $state.complete();
            }
          })
          .catch((err) => {
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
      },

      searchUserR(text, $page) {
      this.noUser = false
      this.userCurrentPage = $page + 1
      axios
        .get(
          'users/store-payouts/20/sortBy-id-desc/' +
            text +
            '?page=' +
            this.userCurrentPage
        )
        .then((result) => {
          const data = result.data.data.data
          // this.userSR = data
          if(this.userCurrentPage == 1){
            this.userSR = []
          }
          this.userSR = this.userSR.concat(data)
          // console.log(this.userSR)
          if (this.userSR.length > 0) {
            this.userShow = true
          } else {
            if(this.userCurrentPage == 1){
              this.userSR = []
              this.noUser = true
              this.userShow = false

            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
           
          }
        })
    },


    addUser(user) {
      this.userShow = false
      this.searchUser = user.firstname + user.lastname
      this.data.user_id = user.id
      this.data.name = user.firstname + ' ' + user.lastname 
      this.data.phone = user.phone
      this.userSR.length = 0

      if (user.payment_sessions.length != 0) {
      for (let ind in user.payment_sessions) {
        if(user.payment_sessions[ind].is_payout != true && user.payment_sessions[ind].payouts.length == 0){
            this.payment_sessionOption.push({
                        text: user.payment_sessions[ind].code,
                        value: user.payment_sessions[ind].code.toString(),
                });
          }
        }
     }else{
            this.data.payment_sessions = [];
            this.payment_sessionOption  = [
                {
                value: "select_value",
                text: "Select Value",
                },
                 ];
         }




         
    // if (user.payout.length != 0) {
    //   for (let pay in user.payout) {
    //     if(user.payout[pay].payment_sessions.length != 0  &&   ){
    //     for (let ind in user.payout[pay].payment_sessions) {
    //         this.payment_sessionOption.push({
    //                     text: user.payout[pay].payment_sessions[ind].code,
    //                     value: user.payout[pay].payment_sessions[ind].code.toString(),
    //             });
    //    }
    //   }
    //     }
    //  }else{
    //         this.data.payment_sessions = [];
    //         this.payment_sessionOption  = [
    //             {
    //             value: "select_value",
    //             text: "Select Value",
    //             },
    //              ];

    //      }




   
    },

   

      isString(value) {
        return typeof value === "string" || value instanceof String;
      },
      // basic
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  
  <style lang="scss" scoped></style>
  
  <style></style>
  