<template>
  <div>
    <b-card-code :title="'Update Category: ' + nameCat" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- image Cover -->
            <b-col md="6">
              <div align="center" class="text-center">
                <h4>Cover</h4>
                <b-media class="mb-1 mr-auto">
                  <b-avatar class="" ref="previewEl" :src="img" size="180px" />
                </b-media>
                <div class="mb-4">
                  <b-button variant="primary" @click="$refs.refInputEl.click()">
                    <input ref="refInputEl" type="file" class="d-none" @change="uploadImage" />
                    <feather-icon icon="EditIcon" class=" " />
                  </b-button>
                </div>
              </div>
            </b-col>
            <!-- image Icon -->
            <b-col md="6">
              <div align="center" class="text-center">
                <h4>Icon</h4>
                <b-media class="mb-1">
                  <b-avatar class="" ref="previewEl" :src="img2" size="180px" />
                </b-media>
                <div class="mb-4">
                  <b-button variant="primary" @click="$refs.refInputEl2.click()">
                    <input ref="refInputEl2" type="file" class="d-none" @change="uploadImage2" />
                    <feather-icon icon="EditIcon" class="" />
                  </b-button>
                </div>
              </div>
            </b-col>

            <!-- code -->
            <b-col md="6">
              <b-form-group v-b-popover.hover.top="'Please Enter Code'" label="Code" label-for="Code">
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <b-form-input type="number" id="code" v-model="data.code" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group v-b-popover.hover.top="'Please Enter Weight'" label="Weight" label-for="Weight">
                <validation-provider #default="{ errors }" name="weight" rules="required">
                  <b-form-input type="number" id="weight" v-model="data.weight" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">

              <b-row>
                

                <!-- active -->
                <b-col md="4">
                  <validation-provider #default="{ errors }" name="active" rules="required">

                    <b-form-group label="Category Activation" label-for="active">
                      <validation-provider #default="{ errors }" name="active" rules="required">
                        <b-form-checkbox switch v-model="data.active">
                          <p v-if="data.active">
                            Category Active
                          </p>
                          <p v-else>Category DisActive</p>
                        </b-form-checkbox>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>


                  </validation-provider>
                </b-col>

                <!-- Sell New Option -->
                <b-col md="4">
                  <b-form-group label="Can Sell C2C Products ?" label-for="is_c2c_sell">
                    <validation-provider #default="{ errors }" name="is_c2c_sell" rules="required">
                      <b-form-checkbox switch v-model="data.is_c2c_sell">
                        <p v-if="data.is_c2c_sell">
                          Can Sell C2C Products
                        </p>
                        <p v-else>Can't Sell C2C Products</p>
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Sell Used Option -->
                <b-col md="4">
                  <b-form-group label="Can Sell B2C Products ?" label-for="is_b2c_sell">
                    <validation-provider #default="{ errors }" name="is_b2c_sell" rules="required">
                      <b-form-checkbox switch v-model="data.is_b2c_sell">
                        <p v-if="data.is_b2c_sell">
                          Can Sell B2C Products
                        </p>
                        <p v-else>Can't Sell B2C Products</p>
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>



              </b-row>

            </b-col>

            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button size="lg" variant="primary" type="submit" @click.prevent="validationForm">
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
// import axios from '@/libs/axios'
// import router from '../../router/index.js'
// import { delete } from 'vue/types/umd'
export default {
  components: {
    BCardCode,
  },
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      id: 0,
      img: '',
      img2: '',
      activeSelect: {
        text: '',
        value: '',
      },
      activeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '0',
          text: 'False',
        },
        {
          value: '1',
          text: 'True',
        },
      ],
      data: {
        active: '',
        code: '',
        is_c2c_sell: false,
        is_b2c_sell: false,
      },
      nameCat: '',
      errors_back: [],
      required,
    }
  },
  created() {
    // request caategoru data
    this.id = this.$route.params.id
    axios
      .get('categories/' + this.id)
      .then((result) => {
        const data = result.data.data
        this.img = data.cover
        this.img2 = data.icon
        this.data = result.data.data
        this.nameCat = data.translation[0].name
        delete this.data.icon
        delete this.data.cover

      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {
    // update function to category
    validationForm() {
      this.$refs.simpleRules.validate().
      then((success) => {
        if (success) {
          if (this.img.includes('data:image/')) {
            this.data.cover = this.img.split(',').map((item) => item.trim())
            this.data.cover = this.data.cover[1]
          }
          if (this.img2.includes('data:image/')) {
            this.data.icon = this.img2.split(',').map((item) => item.trim())
            this.data.icon = this.data.icon[1]
          }
          axios
            .put('categories/' + this.id, this.data)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
      .catch((data)=>{
        console.log(data)
      })
    },
    // cencode profile picture
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        this.img = e.target.result
      }
      reader.readAsDataURL(file)
    },
    // encode id front pictuer
    uploadImage2(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage2(files[0])
    },
    createImage2(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        this.img2 = e.target.result
      }
      reader.readAsDataURL(file)
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss"></style>

<style lang="scss" scoped></style>
