<template>
  <div v-if="userData != null">
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <b-row class="row-eq-height">
      <!-- main data for user -->
      <b-col md="6" class="d-flex justify-content-between flex-column position-relative">
        <b-card class="h-100 cardH">
          <!-- User Avatar & Main Data -->

          <b-row class="mt-2">
            <!-- user profile image -->
            <b-col md="5" class="justify-content-center align-items-center text-center">
              <CoolLightBox :items="profileArr" :index="imgIndex" @close="imgIndex = null">
              </CoolLightBox>
              <b-img @click="imgIndex = 0" rounded :src="userData.profile_picture" :alt="userData.firstname"
                style="height: 170px; width: auto" />

              <h5 style="line-height: 2" variant="outline-danger">
                <b-button pill disabled variant="outline-warning" class="mr-1 mt-1">
                  <!-- <feather-icon icon="HomePlus" size="14" /> -->
                  <span class="mr-25 align-middle">
                    <b-img :src="require('@/assets/images/icons/shop-plus.svg')" alt="Meeting Pic" />

                    {{ userData.storename }}</span>
                </b-button>
              </h5>
            </b-col>
            <!-- user profile data -->
            <b-col lg="7" md="9">
              <div class="d-flex justify-content-start">
                <div class="d-flex flex-column ml-1">
                  <div class="">
                    <h5 style="line-height: 2">
                      <span style="font-weight: bold; color: #0d6efd">Name:
                      </span>

                    <span  v-b-popover.hover.top.click="''" :id="'badge'+userData.firstname" @click="hidePopover('badge'+userData.firstname)">  {{ userData.firstname }} {{ userData.lastname }}</span>
                      
                      <!-- for copy value -->
                      <b-popover    :id="'badge'+userData.firstname" :target="'badge'+userData.firstname" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(userData.firstname +' - '+ userData.lastname)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>

                    
                    </h5>
                    <h5 style="line-height: 2">
                      <span style="font-weight: bold; color: #0d6efd">Store Name:
                      </span>
                      <b-button  pill disabled variant="outline-primary">

                        <span  v-b-popover.hover.top.click="''" :id="'badgestorename'+userData.storename" @click="hidePopover('badgestorename'+userData.storename)">  {{ userData.storename }} </span>
                      
                      <!-- for copy value -->
                      <b-popover    :id="'badgestorename'+userData.storename" :target="'badgestorename'+userData.storename" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(userData.storename)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>

                      </b-button>
                    </h5>

                    <h5 style="line-height: 2">
                      <span style="font-weight: bold; color: #0d6efd">Code:
                      </span>
                      <span  v-b-popover.hover.top.click="''" :id="'badgecode'+userData.code" @click="hidePopover('badgecode'+userData.code)">  {{ userData.code }} </span>

                       <!-- for copy value -->
                       <b-popover    :id="'badgecode'+userData.code" :target="'badgecode'+userData.code" placement="top" triggers="hover click" custom-class="popover-custom">
                          <template >
                            <div @click="copyText(userData.code)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                          </template>
                        </b-popover>

                    </h5>

                    <h5 style="line-height: 2">
                      <span style="font-weight: bold; color: #0d6efd">Email:
                      </span>
                      <span  v-b-popover.hover.top.click="''" :id="'badgeemail'+userData.email" @click="hidePopover('badgeemail'+userData.email)">  {{ userData.email }} </span>

                        <!-- for copy value -->
                        <b-popover    :id="'badgeemail'+userData.email" :target="'badgeemail'+userData.email" placement="top" triggers="hover click" custom-class="popover-custom">
                          <template >
                            <div @click="copyText(userData.email)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                          </template>
                        </b-popover>
                    </h5>
                    <h5 style="line-height: 2">
                      <span style="font-weight: bold; color: #0d6efd">Status:
                      </span>
                      <b-badge badge-outline variant="primary">
                        {{ status }}
                      </b-badge>
                    </h5>
                    <h5 style="line-height: 2">
                      <span style="font-weight: bold; color: #0d6efd">Selling Products:
                      </span>
                      <b-badge variant="success" v-if="userData.is_portal_new" class="mr-1">
                        Portal New
                      </b-badge>
                      <b-badge variant="warning" v-if="userData.is_portal_used"> Portal Used </b-badge>
                    </h5>
                    <h5 style="line-height: 2">
                      <span style="font-weight: bold; color: #0d6efd">Sales Channel:
                      </span>
                      <b-badge variant="success" v-if="userData.is_instore_new" class="mr-1">
                        In Store New
                      </b-badge>
                      <b-badge variant="secondary" v-if="userData.is_instore_used"> In Store Used </b-badge>
                    </h5>

                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <!-- Image in top right corner -->
          <img v-if="userData.verified_at != null" :src="require('@/assets/images/icons/verified-icon3.png')"
            class="position-absolute" style="top: 14px; right: 7px" />
        </b-card>
      </b-col>

      <!-- Main User Actions -->
      <b-col md="6">
        <b-card class="h-100">
          <b-row class="justify-content-md-center align-items-center text-center">
            <!-- Main User Actions -->
            <b-col md="6">
              <!-- Check If The User Not Archive -->
              <b-row v-if="delUser">
                <!-- Updated Action For User -->
                <b-col md="12" v-if="
                  authPermissions.includes('verifiy-vendor-data') &&
                  userData.verified_at == null
                ">
                  <b-button block @click="VerifiyVendorAccount()" variant="info mr-1 mt-1">
                    Verifiy Account
                  </b-button>
                </b-col>
                <!-- Updated Action For User -->
                <b-col md="12" v-if="authPermissions.includes('update-vendor-data')">
                  <b-button block :to="{ path: '/Vendor/Update/' + userData.id }" variant="primary mr-1 mt-1">
                    Edit profile
                  </b-button>
                </b-col>
                <!-- export excel for seller orders -->
                <b-col md="12" v-if="authPermissions.includes('vendor-sales-export')">
                  <b-button block v-b-modal.modal-export-orders variant="primary mr-1 mt-1">
                    Export orders
                  </b-button>

                  <b-modal id="modal-export-orders" cancel-variant="outline-secondary" ok-title="submit"
                    cancel-title="Close" centered title="Seller Success Orders Export" @ok="exportOrders(userData.id)">
                    <validation-observer ref="simpleRules">
                      <b-form @submit.prevent>
                        <b-row>
                          <b-col md="6">
                            <validation-provider #default="{ errors }" name="start date" rules="required">
                              <b-form-group label="Start Date" label-for="start date"
                                :state="errors.length > 0 ? false : null">
                                <flat-pickr v-model="orderExport.startdate
                                  " class="form-control" :config="{
                                          enableTime: true,
                                          dateFormat: 'Y-m-d',
                                        }" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null
                                  ">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col md="6">
                            <validation-provider #default="{ errors }" name="end date" rules="required">
                              <b-form-group label="End Date" label-for="end date"
                                :state="errors.length > 0 ? false : null">
                                <flat-pickr v-model="orderExport.enddate" class="form-control" :config="{
                                  enableTime: true,
                                  dateFormat: 'Y-m-d',
                                }" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null
                                  ">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <!-- error handelr -->
                          <b-alert v-height-fade.appear fade :show="showDismissibleAlert"
                            @dismissed="showDismissibleAlert = false" variant="danger">
                            <h4 class="alert-heading">Alert</h4>
                            <div class="alert-body">
                              <ul v-for="(values, index) in errors_back" :key="index">
                                <li v-for="(value, indexVal) in values" :key="indexVal">
                                  {{ value }}
                                </li>
                              </ul>
                            </div>
                          </b-alert>
                        </b-row>
                      </b-form>
                    </validation-observer>

                  </b-modal>


                </b-col>
                <!-- Updated Status Action For User -->
                <b-col md="12" v-if="authPermissions.includes('update-vendor-status')">
                  <b-button block :to="{
                    path: '/Vendor/Update/Status/' + userData.id,
                  }" variant="warning mr-1 mt-1">
                    Update Status
                  </b-button>
                </b-col>
                <!-- Active And Deactive Action For User -->
                <b-col md="12" v-if="
                  authPermissions.includes('vendor-activation') &&
                  (statusSlug == 'disabled' || statusSlug == 'active')
                ">
                  <!-- Active Action For User -->
                  <b-button v-if="statusSlug == 'disabled'" block v-b-modal.modal-active @click="resetData"
                    variant="outline-info" class="mr-1 mt-1">
                    Active
                  </b-button>
                  <!-- Madal For Active User Action-->
                  <b-modal id="modal-active" cancel-variant="outline-secondary" @ok="activeVendor(userData.id)"
                    ok-title="Ok" cancel-title="Close" centered title="Active Dealer">
                    <b-form style="
                        max-height: 500px;
                        overflow-x: hidden;
                        overflow-y: auto;
                      ">
                      <div v-for="(item, index) in options" :key="index">
                        <b-form-checkbox :id="'checkbox-' + index" v-model="selected[index].product_id"
                          :name="'checkbox-' + index" :value="item.value">
                          {{ item.text }} </b-form-checkbox><br />
                        <div v-for="(ite, ind) in item.variants" class="ml-1" :key="ind">
                          <b-form-checkbox :id="'checkbox-' + index + ind" v-model="selected[index].variants[ind]"
                            :name="'checkbox-' + index + ind" :value="ite.value">
                            {{ ite.text }} </b-form-checkbox><br />
                        </div>
                      </div>
                      <infinite-loading @infinite="getAllVariantsInSystem"></infinite-loading>
                    </b-form>
                  </b-modal>
                  <!-- Deactive Action For User -->
                  <b-button block v-b-modal.modal-deactive v-if="statusSlug == 'active'" variant="outline-info"
                    class="mr-1 mt-1">
                    Deactive
                  </b-button>
                  <!-- Madal For Deactive User Action-->
                  <b-modal id="modal-deactive" cancel-variant="outline-secondary" @ok="deActiveVendor(userData.id)"
                    ok-title="Ok" cancel-title="Close" centered title="Deactivate Account">
                    <b-form>
                      <b-form-checkbox id="checkbox-1" v-model="deactive" name="checkbox-1" value="1"
                        unchecked-value="0">
                        Force Deactive
                      </b-form-checkbox>
                    </b-form>
                  </b-modal>
                </b-col>
                <!-- Archive Action For User -->
                <b-col md="12" v-if="authPermissions.includes('delete')">
                  <b-button block v-b-modal.modal-delete variant="outline-danger" class="mr-1 mt-1">
                    Close Account
                  </b-button>
                  <b-modal id="modal-delete" cancel-variant="outline-secondary" @ok="deleteVendor(userData.id)"
                    ok-title="Ok" cancel-title="Close" centered title="Close Account">
                    <b-form>
                      <b-form-checkbox id="checkbox-2" v-model="deletedVendor" name="checkbox-2" value="1"
                        unchecked-value="0">
                        Force Close
                      </b-form-checkbox>
                    </b-form>
                  </b-modal>
                </b-col>
                <hr />
              </b-row>

              <b-row v-else>
                <!-- Restore Action For Archived User -->

                <b-button @click="restoreData(userData.id)" block v-if="authPermissions.includes('update')"
                  variant="outline-danger" class="mr-1 mt-1">
                  Restore Account
                </b-button>

                <hr />
              </b-row>
            </b-col>

            <!-- Created and Updated Date -->
            <b-col md="12">
              <b-row class="justify-content-md-center align-items-center text-center">
                <b-col md="3" class="my-3 mx-2">
                  <h6 class="text-muted font-weight-bolder">Created at</h6>
                  <h3 class="mb-0">
                    {{ userData.created_at }}
                  </h3>
                </b-col>

                <b-col md="3" v-if="delUser" class="my-3 mx-2">
                  <h6 class="text-muted font-weight-bolder">Updated at</h6>
                  <h3 class="mb-0">
                    {{ userData.updated_at }}
                  </h3>
                </b-col>

                <b-col class="my-3 mx-2" md="3" v-else>
                  <h6 class="text-muted font-weight-bolder">Deleted at</h6>
                  <h3 class="mb-0">
                    {{ userData.deleted_at }}
                  </h3>
                </b-col>

                <b-col md="3" class="my-3 mx-2" v-if="delUser && userData.verified_at">
                  <h6 class="text-muted font-weight-bolder">Verified at</h6>
                  <h3 class="mb-0">
                    {{ userData.verified_at }}
                  </h3>
                </b-col>
              </b-row>
            </b-col>

            <!-- Any Another Action For User -->
            <b-col md="12" class="justify-content-md-center align-items-center text-center">
              <b-card-footer>
                <b-button block variant="success" v-if="authPermissions.includes('vendor_price_rate')"
                  :to="{ path: '/Vendor/Pricing/' + this.id }">
                  Margin Fees
                </b-button>
              </b-card-footer>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="showID">
      <b-col cols="12" md="6" class="mt-2">
        <b-card title="ID fornt picture">
          <div class="d-flex justify-content-start">
            <CoolLightBox :items="profileArr" :index="img2Index" @close="img2Index = null">
            </CoolLightBox>
            <b-img @click="img2Index = 0" rounded :src="userData.idfront_picture" :alt="userData.firstname"
              style="width: 100%; height: auto" />
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" class="mt-2 h-100">
        <b-card title="ID back picture">
          <div class="d-flex justify-content-start">
            <CoolLightBox :items="profileArr" :index="img3Index" @close="img3Index = null">
            </CoolLightBox>
            <b-img @click="img3Index = 0" rounded :src="userData.idback_picture" :alt="userData.firstname"
              style="width: 100%; height: auto" />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- Right Col: Table -->
    <b-row class="mt-2">
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col md="12">
              <b-card title="Main Info">
                <table style="width: 85%">
                  <tbody style="width: 85%">
                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">Store Name:</span>
                        </h5>
                      </td>
                      <td>
                        <b-button pill disabled variant="outline-primary">
                          {{ userData.storename }}
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />

                <table style="width: 80%">
                  <tbody style="width: 80%">
                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">First Name:</span>
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">
                        
                          <span  v-b-popover.hover.top.click="''" :id="'badge-firstname'+userData.firstname" @click="hidePopover('badge-firstname'+userData.firstname)">  {{ userData.firstname }} </span>
                      
                          <!-- for copy value -->
                          <b-popover    :id="'badge-firstname'+userData.firstname" :target="'badge-firstname'+userData.firstname" placement="top" triggers="hover click" custom-class="popover-custom">
                          <template >
                            <div @click="copyText(userData.firstname)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                          </template>
                        </b-popover>

                    </h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">last Name:</span>
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">
                        
                          <span  v-b-popover.hover.top.click="''" :id="'badge-lastname'+userData.lastname" @click="hidePopover('badge-lastname'+userData.lastname)">  {{ userData.lastname }} </span>
                          
                          <!-- for copy value -->
                          <b-popover    :id="'badge-lastname'+userData.lastname" :target="'badge-lastname'+userData.lastname" placement="top" triggers="hover click" custom-class="popover-custom">
                          <template >
                            <div @click="copyText(userData.lastname)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                          </template>
                        </b-popover>
                        
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">Email:</span>
                        </h5>
                      </td>
                      <td>
                        <h5  class="mt-1"> <span  v-b-popover.hover.top.click="''" :id="'badge2'+userData.email"  @click="hidePopover('badge2'+userData.email)"> {{ userData.email }}</span>  </h5>

                      
                      <!-- for copy value -->
                      <b-popover   :id="'badge2'+userData.email" :target="'badge2'+userData.email" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(userData.email)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>


                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">Type:</span>
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">{{ userData.type }}</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">Code:</span>
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">
                        
                          <span  v-b-popover.hover.top.click="''" :id="'badge-code'+userData.code" @click="hidePopover('badge-code'+userData.code)">  {{ userData.code }} </span>
                          
                          <!-- for copy value -->
                          <b-popover    :id="'badge-code'+userData.code" :target="'badge-code'+userData.code" placement="top" triggers="hover click" custom-class="popover-custom">
                          <template >
                            <div @click="copyText(userData.code)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                          </template>
                        </b-popover>
                        </h5>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">Birethdate:</span>
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">{{ userData.birthdate }}</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">ID Number:</span>
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">

                          <span  v-b-popover.hover.top.click="''" :id="'badge-idnumber'+userData.idnumber"  @click="hidePopover('badge-idnumber'+userData.idnumber)">  {{ userData.idnumber }} </span>
                          
                          <!-- for copy value -->
                          <b-popover    :id="'badge-idnumber'+userData.idnumber" :target="'badge-idnumber'+userData.idnumber" placement="top" triggers="hover click" custom-class="popover-custom">
                          <template >
                            <div @click="copyText(userData.idnumber)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                          </template>
                        </b-popover>

                          <!-- Image in top right corner -->
                          <span>

                            <img v-b-modal.modal-id-pictures :src="require('@/assets/images/icons/photo-up.svg')" />
                          </span>
                        </h5>

                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">Landline phone :</span>
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">
                        
                          <span  v-b-popover.hover.top.click="''" :id="'badge-phone'+userData.phone"  @click="hidePopover('badge-phone'+userData.phone)">  {{ userData.phone }} </span>
                          
                          <!-- for copy value -->
                          <b-popover    :id="'badge-phone'+userData.phone" :target="'badge-phone'+userData.phone" placement="top" triggers="hover click" custom-class="popover-custom">
                          <template >
                            <div @click="copyText(userData.phone)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                          </template>
                        </b-popover>
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </b-card>
              <hr />
              <b-col cols="12">
              <b-card title="Categories">

               

                <table  id="table" class="table  text-center" >
                  <tbody >
                     
                    <tr>
                      <td>
                      </td>
                      <td>
                        <h5 >
                          Portal New
                        </h5>
                      </td>
                      <td>
                        <h5 >
                          Portal Used
                        </h5>
                      </td>
                      <td>
                        <h5 >
                          In Stor new
                        </h5>
                      </td>
                      <td>
                        <h5 >
                          In Stor Used
                        </h5>
                      </td>
                    </tr>
                    

                    <tr v-for="(category, index) in userData.categories" :key="index">
                      <td style="min-width: 140px;">
                        <h5 v-for="translation in  category.translation" :key="translation.id">
                          <span style="font-weight: bold; color: #0d6efd;"  v-if="translation.locale == 'en'"
                            >{{translation.name }}:
                            </span
                          >
                        </h5>
                      </td>

                      <td>
                        <h5  v-if="category.pivot.is_portal_new">
                          <img width="20" :src="require('@/assets/images/icons/checked-true.svg')" />
                        </h5>
                      </td>

                      <td>
                        <h5  v-if="category.pivot.is_portal_used">
                          <img width="20" :src="require('@/assets/images/icons/checked-true.svg')" />
                        </h5>
                      </td>


                      <td>
                        <h5 v-if="category.pivot.is_instore_new">
                          <img width="20" :src="require('@/assets/images/icons/checked-true.svg')" />
                        </h5>
                      </td>

                      <td>
                        <h5 v-if="category.pivot.is_instore_used == true">
                          <img width="20" :src="require('@/assets/images/icons/checked-true.svg')" />
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                  <hr>

                </table>
              </b-card>
              </b-col>

              <hr />
              <b-card title="Internal Manager Data">
                <table style="width: 90%">
                  <tbody style="width: 90%">
                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">Name:</span>
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">
                          {{ userData.internal_manager_name }}
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">Phone:</span>
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">

                          <span  v-b-popover.hover.top.click="''" :id="'badge-internal_manager_phone'+userData.internal_manager_phone" @click="hidePopover('badge-internal_manager_phone'+userData.internal_manager_phone)">  {{ userData.internal_manager_phone }} </span>
                          
                          <!-- for copy value -->
                          <b-popover    :id="'badge-internal_manager_phone'+userData.internal_manager_phone" :target="'badge-internal_manager_phone'+userData.internal_manager_phone" placement="top" triggers="hover click" custom-class="popover-custom">
                          <template >
                            <div @click="copyText(userData.internal_manager_phone)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                          </template>
                        </b-popover>

                        </h5>
                      </td>
                    </tr>


                  </tbody>
                </table>
              </b-card>
              <hr />
              <b-card title="Store Feature">
                <table style="width: 47%">
                  <tbody style="width: 47%">
                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">Status:</span>
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">
                          <b-badge variant="primary"> {{ status }} </b-badge>
                        </h5>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd"
                            >Portal Option:</span
                          >
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">
                          <b-badge
                            :variant="userData.portal ? 'success' : 'secondary'"
                          >
                            {{ userData.portal ? 'Active' : 'Deactivate' }}
                          </b-badge>
                        </h5>
                      </td>
                    </tr> -->
                    <!-- <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd"
                            >In Store Option:</span
                          >
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">
                          <b-badge
                            :variant="
                              userData.in_store ? 'success' : 'secondary'
                            "
                          >
                            {{ userData.in_store ? 'Active' : 'Deactivate' }}
                          </b-badge>
                        </h5>
                      </td>
                    </tr> -->
                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">Can Update Price Option:</span>
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">
                          <b-badge :variant="userData.can_update_price
                              ? 'success'
                              : 'secondary'
                            ">
                            {{
                              userData.can_update_price
                                ? 'Active'
                                : 'Deactivate'
                            }}
                          </b-badge>
                        </h5>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd"
                            >Can Sell New Products:</span
                          >
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">
                          <b-badge
                            :variant="
                              userData.is_sell_new
                                ? 'success'
                                : 'secondary'
                            "
                          >
                            {{
                              userData.is_sell_new
                                ? 'Active'
                                : 'Deactivate'
                            }}
                          </b-badge>
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd"
                            >Can Sell Used Products:</span
                          >
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">
                          <b-badge
                            :variant="
                              userData.is_sell_used
                                ? 'success'
                                : 'secondary'
                            "
                          >
                            {{
                              userData.is_sell_used
                                ? 'Active'
                                : 'Deactivate'
                            }}
                          </b-badge>
                        </h5>
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </b-card>
              <hr />
              <b-card title="Location">
                <table style="width: 80%">
                  <tbody style="width: 80%">
                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">Area:</span>
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">{{ area }}</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">Address:</span>
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">{{ userData.address }}</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5 class="mt-1">
                          <span style="font-weight: bold; color: #0d6efd">Order Location:</span>
                        </h5>
                      </td>
                      <td>
                        <h5 class="mt-1">{{ userData.orderlocation }}</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card title="Account Manager">
          <div class="table-responsive">
            <table id="table" v-if="userData.self_management == false">
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
              <tr>
                <td>
                  {{
                    userData.account_manager.firstname +
                    ' ' +
                    userData.account_manager.lastname
                  }}
                </td>
                <td>{{ userData.account_manager.email }}</td>
                <td>
                  <b-button style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                    :to="{ path: '/admin/show/' + userData.account_manager.id }" variant="success"
                    class="btn-icon rounded-circle">
                    <feather-icon style="width: 18px; height: 18px" icon="EyeIcon" />
                  </b-button>
                </td>
              </tr>
            </table>
            <b-badge variant="warning" v-else> No account manager </b-badge>
          </div>
        </b-card>
      </b-col>

      <!-- Madal For id-pictures User Action-->
      <b-modal id="modal-id-pictures" cancel-variant="outline-secondary" ok-title="Ok" cancel-title="Close" centered
        title="ID Pictures">
        <b-row>
          <b-col cols="12" md="6" class="mt-2">
            <b-card title="ID fornt picture">
              <div class="d-flex justify-content-start" v-if="userData.idfront_picture">
                <CoolLightBox :items="Array(userData.idfront_picture)" :index="img2Index" @close="img2Index = null">
                </CoolLightBox>
                <b-img @click="img2Index = 0" rounded :src="userData.idfront_picture" :alt="userData.firstname"
                  style="width: 100%; height: auto" />
              </div>
            </b-card>
          </b-col>
          <b-col cols="12" md="6" class="mt-2 h-100">
            <b-card title="ID back picture">
              <div class="d-flex justify-content-start" v-if="userData.idback_picture">
                <CoolLightBox :items="Array(userData.idback_picture)" :index="img3Index" @close="img3Index = null">
                </CoolLightBox>
                <b-img @click="img3Index = 0" rounded :src="userData.idback_picture" :alt="userData.firstname"
                  style="width: 100%; height: auto" />
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-modal>
      <b-col cols="12">
        <b-card title="Mobiles Number">
          <!-- <b-button v-if="authPermissions.includes('update')" class='mb-2 float-right' :to="{path: '/Vendor/AddMobileNumbers/' + userData.id}" variant='success'>Add New Mobile number</b-button> -->
          <b-button v-if="authPermissions.includes('update')" class="mb-2 float-right" @click="mob = !mob"
            variant="success">Add New Mobile number</b-button>
          <table id="table">
            <tr>
              <th>ID</th>
              <th>Mobile</th>
              <th>Actions</th>
            </tr>
            <tr v-for="(item, index) in userData.phoneNumber" :key="index">
              <td>{{ item.id }}</td>
              <td>
                <span  v-b-popover.hover.top.click="''" :id="'badge-mobile'+item.mobile" @click="hidePopover('badge-mobile'+item.mobile)">  {{ item.mobile }} </span>
                          
                  <!-- for copy value -->
                  <b-popover    :id="'badge-mobile'+item.mobile" :target="'badge-mobile'+item.mobile" placement="top" triggers="hover click" custom-class="popover-custom">
                  <template >
                    <div @click="copyText(item.mobile)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                  </template>
                </b-popover>

              </td>
              <td>
                <b-button style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                  v-if="authPermissions.includes('delete')" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger" type="submit" @click="deleteMobile(item.id)" class="btn-icon rounded-circle">
                  <feather-icon icon="Trash2Icon" style="width: 18px; height: 18px" class="align-middle" />
                </b-button>
                <!-- <b-button v-if="authPermissions.includes('delete')" @click="deleteMobile(item.id)" variant='danger'>Delete</b-button> -->
              </td>
            </tr>
          </table>

          <div v-show="mob" class="mt-5">
            <b-row>
              <!-- Mobile Number -->
              <b-col md="6">
                <b-form-group label="Mobile Number" label-for="mobilenumber">
                  <validation-provider #default="{ errors }" name="Mobile Number" rules="required|egyptMobile">
                    <b-form-input id="phone" v-model="phone" :state="errors.length > 0 ? false : null"
                      placeholder="01XXXXXXXXX" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-button class="mt-2" @click="saveMobileNumber()" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary" type="submit">
                  Save
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card title="Documents">
          <b-button v-if="authPermissions.includes('update')" class="mb-2 float-right" @click="official = !official"
            variant="success">Add Official Documents</b-button>
          <div class="table-responsive">
            <table id="table">
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>File Number</th>
                <th>File</th>
                <th>Actions</th>
              </tr>
              <tr v-for="item in userData.officcialDocuments" :key="item.id">
                <td>{{ item.id }}</td>
                <td style="text-transform: capitalize">
                  <div v-if="item.name != null">
                    {{ item.name.replace('_', ' ') }}
                  </div>
                </td>
                <td>{{ item.number }}</td>
                <td><a :href="item.file">Press here to open file</a></td>
                <td>
                  <b-button style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                    v-if="authPermissions.includes('delete')" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger" type="submit" @click="deleteOfficialDocument(item.id)"
                    class="btn-icon rounded-circle">
                    <feather-icon icon="Trash2Icon" style="width: 18px; height: 18px" class="align-middle" />
                  </b-button>
                  <b-button style="padding: 7px; margin: 6px" v-if="authPermissions.includes('update')"
                    @click="officialUpdate(item.id, item.number)" variant="warning">
                    <feather-icon icon="EditIcon" style="width: 18px; height: 18px" />
                  </b-button>
                </td>
              </tr>
            </table>
          </div>
          <div v-show="official" class="mt-5">
            <b-row>
              <!-- file -->
              <b-col md="2">
                <b-form-group label="File" label-for="file">
                  <input type="file" multiple="multiple" class="form-control-file" name="file" id="file" ref="file"
                    change @change="fileUploadOfiicial" accept="application/pdf" />
                </b-form-group>
              </b-col>
              <!-- type -->
              <b-col md="4">
                <validation-provider #default="{ errors }" name="Type" rules="required">
                  <b-form-group label="type" label-for="Type" :state="errors.length > 0 ? false : null">
                    <v-select id="sType" v-model="typeFileSelect" :state="typeFileSelect == null ? false : true"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="typeFileOptions" :selectable="(option) => !option.value.includes('select_value')
                        " label="text" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- fileNumber -->
              <b-col md="4">
                <b-form-group label="File Number" label-for="filenumber">
                  <validation-provider #default="{ errors }" name="File Number" rules="required|integer">
                    <b-form-input id="filenumber" v-model="addOfficial.number" :state="errors.length > 0 ? false : null"
                      placeholder="XXXXXXXXXX" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-button class="mt-2" @click="saveOfficalDocument()" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary" type="submit">
                  Save
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div v-show="officialUpdateShow" class="mt-5">
            <b-row>
              <!-- file -->
              <b-col md="2">
                <b-form-group label="File" label-for="file">
                  <input type="file" multiple="multiple" class="form-control-file" name="file" id="file" ref="file"
                    change @change="fileUpdateOfiicial" accept="application/pdf" />
                </b-form-group>
              </b-col>
              <!-- fileNumber -->
              <b-col md="4">
                <b-form-group label="File Number" label-for="filenumber">
                  <validation-provider #default="{ errors }" name="File Number" rules="required|integer">
                    <b-form-input id="filenumber" v-model="updateOfficial.number"
                      :state="errors.length > 0 ? false : null" placeholder="XXXXXXXXXX" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-button class="mt-2" @click="updateOfficalDocument()" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary" type="submit">
                  Save
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-card title="Payouts Accounts" v-if="authPermissions.includes('show-vendor-payout-accounts')">
          <b-button v-if="authPermissions.includes('update-vendor-payout-accounts')" class="mb-2 float-right"
            @click="StoreNewPAyoutAccount" variant="success">Add Payouts Documents</b-button>
          <div class="table-responsive">
            <table id="table">
              <tr>
                <th>ID</th>
                <th>Account Type</th>
                <th>Provider Name</th>
                <th>Account Name</th>
                <th>Account Number</th>
                <th>Account IBan</th>
                <th>File</th>
                <th>Actions</th>
              </tr>
              <tr v-for="item in userData.payouts_accounts" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.account_type }}</td>
                <td>{{ item.provider_name }}</td>
                <td style="text-transform: capitalize">
                  <div v-if="item.name != null">
                    {{ item.name.replace('_', ' ') }}
                  </div>
                </td>
                <td>
                      <span  v-b-popover.hover.top.click="''" :id="'badge-number'+item.number" @click="hidePopover('badge-number'+item.number)">  {{ item.number }} </span>
                              
                      <!-- for copy value -->
                      <b-popover    :id="'badge-number'+item.number" :target="'badge-number'+item.number" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(item.number)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>
                </td>
                <td>{{ item.iban }}</td>
                <td><a :href="item.file">Press here to open file</a></td>
                <td>
                  <b-button style="padding: 7px; margin: 6px; width: 35px; height: 35px" v-if="
                    authPermissions.includes('update-vendor-payout-accounts')
                  " size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit"
                    @click="updatePayoutModal(item)" v-b-modal.modal-update-payout-account
                    class="btn-icon rounded-circle">
                    <feather-icon icon="Edit2Icon" style="width: 18px; height: 18px" class="align-middle" />
                  </b-button>

                  <b-button style="padding: 7px; margin: 6px; width: 35px; height: 35px" v-if="
                    authPermissions.includes('update-vendor-payout-accounts')
                  " size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" type="submit"
                    @click="deletePayoutDocument(item.id)" class="btn-icon rounded-circle">
                    <feather-icon icon="Trash2Icon" style="width: 18px; height: 18px" class="align-middle" />
                  </b-button>
                </td>
              </tr>
            </table>
          </div>

          <div v-show="payouts" class="mt-5">
            <b-row>
              <!-- Account Type -->
              <b-col md="4">
                <b-form-group label="Account Type" label-for="account_type">
                  <v-select id="account_type" @input="updateProviderName()"
                    v-model="payoutAccountUpdateData.account_type" :state="payoutAccountUpdateData.account_type == null
                        ? false
                        : true
                      " :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="payoutAccountsType"
                    :selectable="(option) => !option.includes('select_value')" label="text" />
                </b-form-group>
              </b-col>

              <!--Bank  name -->
              <b-col md="4" v-if="payoutAccountUpdateData.account_type == 'Bank'">
                <b-form-group label="Bank Name" label-for=" Bankname">
                  <validation-provider #default="{ errors }" name="Bank Name" :rules="payoutAccountUpdateData.account_type != null &&
                      payoutAccountUpdateData.account_type == 'Bank'
                      ? 'required'
                      : ''
                    ">
                    <v-select id="Bank-name" v-model="payoutAccountUpdateData.provider_name" :state="payoutAccountUpdateData.provider_name == null
                        ? false
                        : true
                      " :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="payoutBankTypes"
                      :selectable="(option) => !option.includes('select_value')" label="text" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--Wallet  name -->
              <b-col md="4" v-if="payoutAccountUpdateData.account_type == 'Wallet'">
                <b-form-group label="Wallet Name" label-for="Walletname">
                  <validation-provider #default="{ errors }" name="Wallet Name" :rules="payoutAccountUpdateData.account_type != null &&
                      payoutAccountUpdateData.account_type == 'Wallet'
                      ? 'required'
                      : ''
                    ">
                    <v-select id="Wallet-name" v-model="payoutAccountUpdateData.provider_name" :state="payoutAccountUpdateData.provider_name == null
                        ? false
                        : true
                      " :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="payoutWalletTypes"
                      :selectable="(option) => !option.includes('select_value')" label="text" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Account Number -->
              <b-col md="4">
                <b-form-group label="Acoount Number" label-for="Acoount Number">
                  <validation-provider #default="{ errors }" name="Acoount Number" :rules="payoutAccountUpdateData.account_type != null
                      ? 'integer|required'
                      : 'integer'
                    ">
                    <b-form-input id="Acoount-Number" v-model="payoutAccountUpdateData.number"
                      :state="errors.length > 0 ? false : null" placeholder="XXXXXXXXXX" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Account Name -->
              <b-col md="4" v-if="payoutAccountUpdateData.account_type == 'Bank'">
                <b-form-group label="Acoount Name" label-for="Acoount Name">
                  <validation-provider #default="{ errors }" name="Account Name" :rules="payoutAccountUpdateData.type != null &&
                      payoutAccountUpdateData.account_type == 'Bank'
                      ? 'required'
                      : ''
                    ">
                    <b-form-input id="Acoount-Name" v-model="payoutAccountUpdateData.name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Account IBAN -->
              <b-col md="4" v-if="payoutAccountUpdateData.account_type == 'Bank'">
                <b-form-group label="Acoount IBAN" label-for="Acoount IBAN">
                  <validation-provider #default="{ errors }" name="Acoount IBAN" :rules="payoutAccountUpdateData.account_type != null &&
                      payoutAccountUpdateData.account_type == 'Bank'
                      ? 'required'
                      : ''
                    ">
                    <b-form-input id="Acoount-IBAN" v-model="payoutAccountUpdateData.iban" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- file -->
              <b-col md="2">
                <b-form-group label="File" label-for="file">
                  <input type="file" multiple="multiple" class="form-control-file" name="file" id="file" ref="file"
                    change @change="fileUploadPayout" accept="application/pdf" />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-button class="mt-2" @click="savePayoutDocument()" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary" type="submit">
                  Save
                </b-button>
              </b-col>
            </b-row>
            <b-alert v-height-fade.appear fade :show="showDismissiblePayoutAlert"
              @dismissed="showDismissiblePayoutAlert = false" variant="danger">
              <h4 class="alert-heading">Alert</h4>
              <div class="alert-body">
                <ul v-for="(values, name, index) in payoutResponceErrors" :key="index">
                  <li v-for="(value, indexVal) in values" :key="indexVal">
                    {{ name }} {{ value }}
                  </li>
                </ul>
              </div>
            </b-alert>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-tabs>
            <b-tab title="Wallet" v-if="userData.vendor_wallet">
              <card-statistics-group title="" :statisticsItems="wallet" />
            </b-tab>
            <b-tab title="Statistics" v-if="userData.vendor_statistic">
              <card-statistics-group title="" :statisticsItems="statistics" />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
      <b-col cols="12">

        <!-- Order Logger -->
        <b-card title="Notes" class="d-flex flex-wrap">
          <b-row>
            <!-- <b-col cols="12">
          <label for="textarea-default">Note</label>
          <b-form-textarea
            id="textarea-default"
            v-model="loggerNote.note"
            placeholder="Note"
            rows="3"
          />
          <b-button
            variant="primary"
            class="mt-1"
          >
            Submit
          </b-button>
        </b-col> -->
            <b-col class="mt-3" cols="12">
              <app-timeline>
                <app-timeline-item v-for="(item, index) in userData.vendor_loggers" :key="index"
                  :variant="item.admin_name != null ? 'primary' : 'success'">
                  <b-media v-if="item.admin_name != null">
                    <template #aside>
                      <b-avatar :src="item.profile_picture" />
                    </template>
                    <h6>{{ item.admin_name }}</h6>
                  </b-media>
                  <b-media v-else>
                    <template #aside>
                      <!-- <b-avatar :src="item.vendor.profile_picture" /> -->
                    </template>
                    <!-- <h6>{{item.vendor.storename}}</h6> -->
                  </b-media>

                  <div class="d-flex flex-column flex-wrap mt-1 mb-sm-0">
                    <p class="text-primary h5 ">
                      Status Action : <span class="h6">{{ item.action }}</span>
                    </p>
                    <p class="text-primary h5 ">
                      Note : <span class="h6">{{ item.note }}</span>
                    </p>
                  </div>
                </app-timeline-item>
              </app-timeline>
            </b-col>
          </b-row>
        </b-card>

      </b-col>


    </b-row>
    <b-card>
      <b-tabs>
        <b-tab title="Reports" v-if="authPermissions.includes('diagnostic')">
          <reports-table :vendorID="id"></reports-table>
        </b-tab>
        <b-tab @click="ordersClick = true" title="Orders">
          <orders-table v-if="ordersClick || !authPermissions.includes('diagnostic')"
            :link="'vendors/' + id + '/orders-pagi/'"></orders-table>
        </b-tab>
        <b-tab @click="refundsClick = true" title="Refunds" v-if="authPermissions.includes('vendor-refunds')">
          <refund-table v-if="refundsClick" :link="'vendors/' + id + '/refunds-pagi/'"></refund-table>
        </b-tab>
        <b-tab @click="productsClick = true" title="Products">
          <variants-table v-if="productsClick" :link="'vendors/' + id + '/variants-pagi/'"
            :type="'Used'"></variants-table>
        </b-tab>
        <b-tab @click="offersClick = true" title="Offers">
          <variants-table v-if="offersClick" :link="'vendors/' + id + '/variants-offers-pagi/'"
            :type="'Used'"></variants-table>
        </b-tab>
        <b-tab @click="payoutsClick = true" title="Payouts" v-if="authPermissions.includes('vendor-payouts')">
          <vendor-payout v-if="payoutsClick" :link="'vendors/' + id + '/payouts-pagi/'"></vendor-payout>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import OrdersTable from '@/Components/OrdersTable'
import VariantsTable from '@/Components/VariantsTable'
import ReportsTable from '@/Components/Reports'
import VendorPayout from '@/Components/VendorPayouts'
import CardStatisticsGroup from './CardStatisticsGroup.vue'
import RefundTable from '@/Components/RefundTable'
import { required, email, integer, egyptMobile } from '@validations'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import flatPickr from 'vue-flatpickr-component'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
export default {
  mixins: [heightTransition],
  components: {
    AppTimeline,
    AppTimelineItem,
    CoolLightBox,

    OrdersTable,
    VariantsTable,
    CardStatisticsGroup,
    VendorPayout,
    RefundTable,
    ReportsTable,
    flatPickr
  },

  data() {
    return {
      officialUpdateShow: false,
      officialUpdateID: null,
      required,
      email,
      integer,
      egyptMobile,
      mob: false,
      phone: null,
      mobileNumberData: {
        phoneNumbers: [
          {
            mobile: null,
          },
        ],
      },
      official: false,
      typeFileOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      addOfficial: {
        name: null,
        file: null,
        number: null,
      },
      updateOfficial: {
        file: null,
        number: null,
      },

      typeFileSelect: null,
      payouts: false,
      productsClick: false,
      ordersClick: false,
      refundsClick: false,
      offersClick: false,
      payoutsClick: false,
      showID: false,
      authPermissions: [],
      userData: {},
      errors_back: [],
      showDismissibleAlert: false,
      payoutResponceErrors: [],
      showDismissiblePayoutAlert: false,

      id: 0,
      status: '',
      statusSlug: '',
      area: '',
      delUser: true,
      imgIndex: null,
      img2Index: null,
      img3Index: null,
      profileArr: [],
      frontArr: [],
      backArr: [],
      statistics: [],
      wallet: [],
      comm_id: '',
      deactive: '0',
      deletedVendor: '0',
      selectedTwo: {
        products: [],
      },
      selected: [],
      options: [],
      loadMore: true,
      payoutAccountUpdateData: {
        id: null,
        account_type: null,
        name: null,
        iban: null,
        file: null,
        number: null,
        provider_name: null,
      },
      payoutAccountsType: [],
      payoutBankTypes: [],
      payoutWalletTypes: [],
      providerNames: [],
      savePayoutUrl: null,
      orderExport: {
        startdate: '',
        enddate: '',
      }
    }
  },
  created() {
    this.id = this.$route.params.id
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  methods: {
    updateProviderName() {
      this.payoutAccountUpdateData.provider_name = null
      this.payoutAccountUpdateData.name = null
      this.payoutAccountUpdateData.iban = null
      this.payoutAccountUpdateData.file = null
      this.payoutAccountUpdateData.number = null
    },
    async StoreNewPAyoutAccount() {
      if (this.payoutAccountsType.length == 0) {
        await this.getPayoutAccountsInitialData()
      }
      this.payoutAccountUpdateData.id = null
      this.payoutAccountUpdateData.account_type = null
      this.payoutAccountUpdateData.name = null
      this.payoutAccountUpdateData.iban = null
      this.payoutAccountUpdateData.number = null
      this.payoutAccountUpdateData.provider_name = null
      this.payouts = !this.payouts
    },
    async updatePayoutModal(payoutAccount) {
      if (this.payoutAccountsType.length == 0) {
        await this.getPayoutAccountsInitialData()
      }
      this.setPayoutUpdateDatapayoutAccount(payoutAccount)
      this.payouts = !this.payouts
    },
    setPayoutUpdateDatapayoutAccount(payoutAccount) {
      this.payoutAccountUpdateData.id = payoutAccount.id
      this.payoutAccountUpdateData.account_type = payoutAccount.account_type
      this.payoutAccountUpdateData.name = payoutAccount.name
      this.payoutAccountUpdateData.iban = payoutAccount.iban
      this.payoutAccountUpdateData.number = payoutAccount.number
      this.payoutAccountUpdateData.provider_name = payoutAccount.provider_name
    },
    async getPayoutAccountsInitialData() {
      await axios
        .get('vendors/payoutAccount/initial-data')
        .then((result) => {
          this.payoutAccountsType = result.data.data.payoutAccountsType
          this.payoutBankTypes = result.data.data.payoutBankTypes
          this.payoutWalletTypes = result.data.data.payoutWalletTypes
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    fetchData() {
      axios
        .get('vendors/' + this.$route.params.id + '/show')
        .then((result) => {
          this.userData = result.data.data

          this.status = this.userData.status.name
          this.statusSlug = this.userData.status.slug
          this.area = this.userData.area.name
          this.profileArr.push(this.userData.profile_picture)
          this.frontArr.push(this.userData.idfront_picture)
          this.backArr.push(this.userData.idback_picture)
          if (this.userData.type == 'personal') {
            this.typeFileOptions = [
              {
                value: 'select_value',
                text: 'Select Value',
              },
              {
                value: 'contract',
                text: 'Contract',
              },
            ]
          } else {
            this.typeFileOptions = [
              {
                value: 'select_value',
                text: 'Select Value',
              },
              {
                value: 'commercial_register ',
                text: 'Commercial Register ',
              },
              {
                value: 'tax_card ',
                text: 'Tax Card ',
              },
              {
                value: 'contract',
                text: 'Contract',
              },
            ]
          }
          if (this.userData.deleted_at != null) {
            this.delUser = false
          } else {
            this.delUser = true
          }
          this.comm_id = this.userData.vendor_wallet.id
          // set commision here
          this.wallet.length = 5
          this.wallet[0] = {
            icon: 'DollarSignIcon',
            color: 'light-primary',
            title: this.userData.vendor_wallet.total_sale,
            subtitle: 'Total Sale',
            customClass: 'mb-2 mb-xl-0',
          }
          this.wallet[1] = {
            icon: 'PercentIcon',
            color: 'light-info',
            title: this.userData.vendor_wallet.margin_amount,
            subtitle: 'Margin Amount',
            customClass: 'mb-2 mb-xl-0',
          }
          this.wallet[2] = {
            icon: 'BoxIcon',
            color: 'light-danger',
            title: this.userData.vendor_wallet.pending_amount,
            subtitle: 'Pending Amount',
            customClass: 'mb-2 mb-xl-0',
          }
          this.wallet[3] = {
            icon: 'TrendingUpIcon',
            color: 'light-success',
            title: this.userData.vendor_wallet.received_amount,
            subtitle: 'Received Amount',
            customClass: 'mb-2 mb-xl-0',
          }
          this.wallet[4] = {
            icon: 'TrendingDownIcon',
            color: 'light-info',
            title: this.userData.vendor_wallet.last_paid,
            subtitle: 'Last Paid',
            customClass: 'mb-2 mb-xl-0',
          }
          this.wallet[5] = {
            icon: 'ArrowUpIcon',
            color: 'light-warning',
            title: this.userData.vendor_wallet.last_paid,
            subtitle: 'Last Paied',
            customClass: 'mb-2 mb-xl-0',
          }
          this.wallet[6] = {
            icon: 'DollarSignIcon',
            color: 'light-primary',
            title: this.userData.vendor_wallet.offer_amount,
            subtitle: 'Offer Amount',
            customClass: 'mb-2 mb-xl-0',
          }
          this.wallet[7] = {
            icon: 'BoxIcon',
            color: 'light-info',
            title: this.userData.vendor_wallet.available_amount,
            subtitle: 'Available Amount',
            customClass: 'mb-2 mb-xl-0',
          }
          this.statistics.length = 5
          this.statistics[0] = {
            icon: 'DollarSignIcon',
            color: 'light-primary',
            title: this.userData.vendor_statistic.order_completed,
            subtitle: 'Order Completed',
            customClass: 'mb-2 mb-xl-0',
          }
          this.statistics[1] = {
            icon: 'PercentIcon',
            color: 'light-info',
            title: this.userData.vendor_statistic.average_rate,
            subtitle: 'Average Rate',
            customClass: 'mb-2 mb-xl-0',
          }
          this.statistics[2] = {
            icon: 'BoxIcon',
            color: 'light-danger',
            title: this.userData.vendor_statistic.order_canceled,
            subtitle: 'Order Canceled',
            customClass: 'mb-2 mb-xl-0',
          }
          this.statistics[3] = {
            icon: 'TrendingUpIcon',
            color: 'light-success',
            title: this.userData.vendor_statistic.order_declined,
            subtitle: 'Order Declined',
            customClass: 'mb-2 mb-xl-0',
          }
          this.statistics[4] = {
            icon: 'TrendingDownIcon',
            color: 'light-info',
            title: this.userData.vendor_statistic.order_refunded,
            subtitle: 'Order Refunded',
            customClass: 'mb-2 mb-xl-0',
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    fileUploadOfiicial(e) {
      this.addOfficial.file = e.target.files[0]
    },
    fileUploadPayout(e) {
      this.payoutAccountUpdateData.file = e.target.files[0]
    },
    fileUpdateOfiicial(e) {
      this.updateOfficial.file = e.target.files[0]
    },
    officialUpdate(id, number) {
      this.officialUpdateShow = !this.officialUpdateShow
      this.officialUpdateID = id
      this.updateOfficial.number = number
    },
    updateOfficalDocument() {
      let formData = new FormData()

      formData.append('file', this.updateOfficial.file)
      formData.append('number', this.updateOfficial.number)
      axios
        .put(
          'vendors/official-docs/' + this.officialUpdateID + '/update',
          formData
        )
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.fetchData()
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    saveMobileNumber() {
      this.mobileNumberData.phoneNumbers[0].mobile = this.phone
      axios
        .post(
          'vendors/' + this.id + '/numbers/store',
          this.mobileNumberData
        )
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.fetchData()
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    saveOfficalDocument() {
      let formData = new FormData()
      this.addOfficial.name = this.typeFileSelect.value
      formData.append('name', this.addOfficial.name)
      formData.append('file', this.addOfficial.file)
      formData.append('number', this.addOfficial.number)
      axios
        .post('vendors/' + this.id + '/official-docs/store', formData)
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.fetchData()
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    savePayoutDocument() {
      if (this.payoutAccountUpdateData.id == null) {
        this.savePayoutUrl =
          'vendors/' + this.id + '/payouts-accounts/store'
      } else {
        this.savePayoutUrl =
          'vendors/payouts-accounts/' +
          this.payoutAccountUpdateData.id +
          '/update'
      }
      let formData = new FormData()

      if (this.payoutAccountUpdateData.file != null) {
        formData.append('file', this.payoutAccountUpdateData.file)
      }
      formData.append('account_type', this.payoutAccountUpdateData.account_type)
      formData.append('number', this.payoutAccountUpdateData.number)
      formData.append(
        'provider_name',
        this.payoutAccountUpdateData.provider_name
      )
      if (this.payoutAccountUpdateData.account_type == 'Bank') {
        formData.append('name', this.payoutAccountUpdateData.name)
        formData.append('iban', this.payoutAccountUpdateData.iban)
      }

      axios
        .post(this.savePayoutUrl, formData)
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })

          if (this.payoutAccountUpdateData.id == null) {
            this.userData.payouts_accounts.push(result.data.data)
          } else {
            let payoutsUpdated = this.userData.payouts_accounts.find(
              (x) => x.id === this.payoutAccountUpdateData.id
            )
            let payoutIndex =
              this.userData.payouts_accounts.indexOf(payoutsUpdated)
            // console.log(this.userData.payouts_accounts[payoutIndex])
            this.userData.payouts_accounts[payoutIndex].file =
              result.data.data.file
            this.userData.payouts_accounts[payoutIndex].account_type =
              result.data.data.account_type
            this.userData.payouts_accounts[payoutIndex].payment_provider =
              result.data.data.payment_provider
            this.userData.payouts_accounts[payoutIndex].number =
              result.data.data.number
            this.userData.payouts_accounts[payoutIndex].name =
              result.data.data.name
            this.userData.payouts_accounts[payoutIndex].iban =
              result.data.data.iban
          }
          this.payouts = false
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.payoutResponceErrors.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.payoutResponceErrors.push({
                error: err.response.data.data,
              })
            } else {
              this.payoutResponceErrors = err.response.data.data
            }
            this.showDismissiblePayoutAlert = true
          } else {
            this.payoutResponceErrors = []
            this.payoutResponceErrors.push({
              error: 'internal server error',
            })
            this.showDismissiblePayoutAlert = true
          }
        })
    },
    deletePayoutDocument(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete file.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('vendors/payouts-accounts/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    // delete Officail Document
    deleteOfficialDocument(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete file.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('vendors/official-docs/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    // Verifiy Vendor Account
    VerifiyVendorAccount() {
      this.$bvModal
        .msgBoxConfirm('Are you sure that you want to Verifiy This Vendor documentation ?', {
          title: 'Confirmation',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('vendors/' + this.userData.id + '/verifiedAccount')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.userData.verified_at = result.data.data.verified_at
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    getAllVariantsInSystem($state) {
      this.currentPage += 1
      axios
        .get(
          'vendors/' +
          this.userData.id +
          '/products-pagi/blocked/50?page=' +
          this.currentPage
        )
        .then((result) => {
          const products = result.data.data.data
          this.loadMore = false
          if (products.length > 0) {
            for (let index in products) {
              this.selected.push({
                product_id: null,
                variants: [],
              })
              if (products[index].translation != null) {
                this.options.push({
                  text: products[index].translation.name,
                  value: products[index].id.toString(),
                  variants: [],
                })
              } else {
                this.options.push({
                  text: 'undefinded',
                  value: products[index].id.toString(),
                  variants: [],
                })
              }

              for (let ind in products[index].variants) {
                this.selected[index].variants.push()
                if (products[index].variants[ind].translation != null) {
                  this.options[index].variants.push({
                    text: products[index].variants[ind].translation.name,
                    value: products[index].variants[ind].id.toString(),
                  })
                } else {
                  this.options[index].variants.push({
                    text: 'undefinded',
                    value: products[index].variants[ind].id.toString(),
                  })
                }
              }
            }
            // $state.loaded();
          } else {
          }
          $state.complete()
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    resetData() {
      this.currentPage = 0
      this.options.length = 0
      this.selected.length = 0
      this.selectedTwo.products = []
    },
    activeVendor(id) {
      this.selectedTwo.products.length = 0
      var contu = true
      for (let index in this.selected) {
        if (
          this.selected[index].product_id != null &&
          !this.selected[index].variants.every((element) => element === null)
        ) {
          this.selectedTwo.products.push({
            product_id: this.selected[index].product_id,
            variants: [],
          })
          for (let cour in this.selected[index].variants) {
            if (this.selected[index].variants[cour] != null) {
              this.selectedTwo.products[
                this.selectedTwo.products.length - 1
              ].variants.push(this.selected[index].variants[cour])
            }
          }
        } else if (
          (this.selected[index].product_id != null &&
            this.selected[index].variants.every(
              (element) => element === null
            )) ||
          (this.selected[index].product_id == null &&
            !this.selected[index].variants.every((element) => element === null))
        ) {
          this.$bvModal.msgBoxConfirm(
            " Wrong, Must be select an area and then select product's variants, please try again.",
            {
              title: 'Are You Sure?',
              size: 'sm',
              okVariant: 'danger',
              okTitle: 'Ok',
              hideHeaderClose: false,
              centered: true,
            }
          )
          contu = false
          break
        } else {
        }
      }

      if (contu == true) {
        this.$bvModal
          .msgBoxConfirm('Please confirm that you want to active vendor.', {
            title: 'Are You Sure?',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              axios
                .post('vendors/' + id + '/active-vendor', this.selectedTwo)
                .then((result) => {
                  this.$swal({
                    position: 'center',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  this.fetchData()
                })
                .catch((err) => {
                  this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Error!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  this.errors_back.length = 0
                  if (err.response.data.data != null) {
                    if (this.isString(err.response.data.data)) {
                      this.errors_back.push({
                        error: err.response.data.data,
                      })
                    } else {
                      this.errors_back = err.response.data.data
                    }
                    this.showDismissibleAlert = true
                  } else {
                    this.errors_back = []
                    this.errors_back.push({
                      error: 'internal server error',
                    })
                    this.showDismissibleAlert = true
                  }
                })
            }
          })
      }
    },
    deActiveVendor(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to deactive acount.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('vendors/' + id + '/deactive-vendor/' + this.deactive)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteVendor(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to close acoount.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('vendors/' + id + '/archive-vendor/' + this.deletedVendor)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteMobile(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete mobile.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('vendors/number/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    restoreData(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to restore vendor.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('vendors/' + id + '/restore')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    exportOrders(vendor_id) {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {

          const current = new Date()
          const date =
            current.getFullYear() +
            '-' +
            (current.getMonth() + 1) +
            '-' +
            current.getDate() +
            ' ' +
            current.getHours() +
            ':' +
            current.getMinutes() +
            ':' +
            current.getSeconds()

          axios
            .post(
              'vendors/export-orders/' + vendor_id,
              this.orderExport,
              { responseType: 'blob' }
            )
            .then((result) => {

              // console.log(result.data)
              const url = window.URL.createObjectURL(new Blob([result.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', 'SellerOrders-' + this.userData.code + '-(' + this.orderExport.startdate + ')-(' + this.orderExport.enddate + ')' + '.xlsx')
              document.body.appendChild(link)
              link.click()

              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'downloaded success',
                showConfirmButton: false,
                timer: 1500,
              })


              // this.$swal({
              //   position: 'center',
              //   icon: 'success',
              //   title: 'Your work has been saved',
              //   showConfirmButton: false,
              //   timer: 1500,
              // })
              this.fetchData()
            })
            .catch((err) => {
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            });
        }
      })
    },
    copyText(value) {
      navigator.clipboard.writeText(value)
        .then(() => {
          this.$bvToast.toast('copied to clipboard!', {
            title: 'Success',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          });
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
          this.$bvToast.toast('Failed to copy IMEI.', {
            title: 'Error',
            variant: 'danger',
            solid: true
          });
        });
    },
    hidePopover(PopId) {
      this.$root.$emit('bv::hide::popover', `${PopId}`);
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style scoped>
#table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#table td,
#table th {
  border: 1px solid #dddddd;
  padding: 8px;
}

#table tr:nth-child(even) {
  background-color: #f5f5f5;
}

.cardH {
  margin-bottom: 0px;
}
</style>

<style lang="scss"></style>
